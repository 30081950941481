import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Chip from "@material-ui/core/Chip";
import DeleteIcon from "@material-ui/icons/Delete";
import TableChartIcon from "@material-ui/icons/TableChart";
import DoneIcon from "@material-ui/icons/Done";
import GetAppIcon from "@material-ui/icons/GetApp";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment-timezone";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import Input from "@material-ui/core/Input";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { axios } from "../../utils/axiosConfig";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ReactApexChart from "react-apexcharts";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";

const chartData = {
  series: [
    {
      name: "series1",
      data: [31, 40, 28, 51, 42, 109, 100]
    },
    
  ],
  options: {
    fill: {
      colors: ["rgb(0, 171, 85)", "rgb(255, 231, 0)"],
      opacity: 0.9,
      type: "image"
    },
    chart: {
      background: "#fff",
      height: 350,
      type: "area",
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "smooth",
      colors: ["rgba(35,35,35, 0.9)", "rgb(255, 231, 0)"]
    },
    xaxis: {
      type: "datetime",
      categories: [
        "2018-09-19T00:00:00.000Z",
        "2018-09-19T01:30:00.000Z",
        "2018-09-19T02:30:00.000Z",
        "2018-09-19T03:30:00.000Z",
        "2018-09-19T04:30:00.000Z",
        "2018-09-19T05:30:00.000Z",
        "2018-09-19T06:30:00.000Z"
      ]
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm"
      }
    }
  }
};

const useStyles = makeStyles({
  root: {
    // background: "rgba(35,35,35, 0.9)"
    // padding: "8px"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  titleDiv: {
    display: "flex"
  },
  status: {
    fontSize: 14,
    color: "#fff",
    marginTop: "2px",
    fontStyle: "italic"
  },
  statusTime: {
    marginTop: 15
  },
  pos: {
    marginBottom: 12
  },
  linearProgress: {
    marginTop: "15px"
  },
  chip: {
    color: "primary",
    height: "23px"
  },
  jobCardTitle: {
    color: "rgba(35,35,35, 0.9)",
    fontWeight: 600,
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    margin: "none"
  },
  cardSubTitle: {
    fontWeight: 400,
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    // margin: '4px 0px 0px',
    color: "rgb(99, 115, 129)",
    display: "block"
  },
  jobCardPrice: {
    color: "rgba(35,35,35, 0.9)",
    fontSize: "1rem",
    marginBottom: "7px",
    fontWeight: "600",
    // letterSpacing: "0.1rem",
    lineHeight: 1.5
  },
  icon: {
    color: "#fff",
    margin: "4px 4px"
  },
  oilgasIcon: {
    margin: "auto",
    marginRight: "5px",
    color: "#e8e8e8",
    width: "65px",
    height: "40px"
  },
  editIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px"
  },
  circularProgress: {
    width: "20px !important",
    height: "20px !important"
  },
  closeIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px",
    marginTop: "20px"
  },
  center: {
    marginTop: "15px",
    // background: "rgba(35,35,35, 0.9)",
    // padding: 16,
    // display: "flex",
    backgroundColor: "rgb(255, 255, 255)",
    color: "rgba(35,35,35, 0.9)",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "8px",
    position: "relative",
    zIndex: 0,
    // display: "flex",
    alignItems: "center",
    padding: "24px"
  },
  inputFeild: {
    color: "#fff",
    fontSize: "32px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  jobCardButton: {
    color: "#fff",
    // borderRadius: '60%',
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#f1b640"
    }
  },

  jobCardButton2: {
    // color:"#f1b640",
    // borderRadius: '60%',
    fontSize: "12px",
    fontWeight: 600
    // "&:hover": {
    //   backgroundColor: '#ffff'
    // },
    // backgroundColor:"#ffff"
  },
  cardInfo: {
    marginTop: "15px"
  },
  tablePagination: {
    // position: 'sticky'
  },
  borderLeft: {
    borderLeft: "1px solid gray"
  },
  borderRight: {
    // paddingTop: "25px",
    // paddingBottom: "15px",
    // paddingLeft: "15px"
  },
  stackRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "16px",
    marginBottom: "8px"
  },
  stackIcon: {
    width: "24px",
    height: "24px",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(84, 214, 44)",
    backgroundColor: "rgba(84, 214, 44, 0.16)"
  },
  stackIconDetail: {
    padding: 3
  },
  stackSubtitle: {
    color: "rgba(35,35,35, 0.9)",
    fontWeight: "600",
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    margin: "0px 0px 0px 8px"
  },
  chartRoot: {
    margin: "0px 0px 0px auto"
  }
});

function capitalizeFirstLetter([first, ...rest], locale = navigator.language) {
  return [first.toLocaleUpperCase(locale), ...rest].join("");
}

function DashboardChart(props) {
  const optionsData = props.sumOfPaymentsByDriverChartOptions;
  console.log(
   props.totalPaymentsChartOption,
    "sadsadsadsadas"
  );
  const classes = useStyles();

  const [sumOfPaymentsByType, setSumOfPaymentsByType] = React.useState([]);
  const [donutData, setDonutChartData] = React.useState([]);

  useEffect(() => {
    setSumOfPaymentsByType(props.sumOfPaymentsByType);

    const data = props.sumOfPaymentsByType.map(item =>
      parseInt(item.totalPayment)
    );

    setDonutChartData(data);
  }, [props.sumOfPaymentsByType]);

  return (
    <Grid container direction="row" spacing={2} >
        <Grid item xs={12} sm={12} md={4} lg={4} className={classes.borderRight}>
        <Card className={classes.center}>
          {/* <CustomerAccountRefIcon className={classes.oilgasIcon} /> */}
          <div>
            <Typography
              variant="h6"
              component="h6"
              className={classes.jobCardPrice}
            >
             Jobs by status
            </Typography>
          </div>
          {props.sumOfPaymentsByType.length > 0 &&
            Object.keys(props.sumOfPaymentsByTypeChartOptions).length > 0 && (
            <ReactApexChart
              options={Object.keys(props.sumOfPaymentsByTypeChartOptions).length >
                0 && props.sumOfPaymentsByTypeChartOptions.options }
              series={props.sumOfPaymentsByType.map(
                item => Math.round(item.countOfJobs * 100) / 100
              )}
              type="donut"
            />
          )}
        </Card>
        <Card className={classes.center}>
          <Typography
            variant="h6"
            component="h6"
            className={classes.jobCardPrice}
          >
            Jobs By Operator
          </Typography>
          {props.jobsByOperator.length > 0 &&
            Object.keys(props.sumOfPaymentsByDriverChartOptions).length > 0 && (
              <ReactApexChart
                options={
                  Object.keys(props.sumOfPaymentsByDriverChartOptions).length >
                    0 && props.sumOfPaymentsByDriverChartOptions.options
                }
                series={props.jobsByOperator.map(
                  item => Math.round(item.countOfJobs * 100) / 100
                )}
                type="donut"
              />
            )}

          {/* <hr/> */}
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={8} className={classes.borderRight}>
        <Card className={classes.center}>
       
          <div>
            <Typography
              variant="h6"
              component="h6"
              className={classes.jobCardPrice}
            >
            Amount Earned by  Jobs
            </Typography>
            {/* <Typography
              variant="body2"
              component="body2"
              className={classes.cardSubTitle}
            >
              (+43%) than last year
            </Typography> */}
          </div>
          
          {props.totalPaymentsChartOption.series&& props.totalPaymentsChartOption.series.length > 0 && (
          <ReactApexChart
            options={props.totalPaymentsChartOption.options}
            series={props.totalPaymentsChartOption.series}
            type="area"
            height={500}
          />
            )} 
        </Card>
      </Grid>
      
    </Grid>
  );
}

export default DashboardChart;
