export interface UserData {
    email: string,
    firstName: string,
    lastName: string
    role: UserRoles
    isActivated: boolean
    createdAt: string,
    updatedAt: string
}

export enum UserRoles {
    USER = 'user',
    ADMIN = 'admin'
}

export interface LoginResponse {
    success: boolean
    message: string | string[]
    responseCode: string
    token?: string
    data?: UserData
}