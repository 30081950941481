import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {  combineReducers } from 'redux'
import createJobConfigurationReducer from '../Features/createJobConfigurationSlice';
import createJobDataReducer from '../Features/createJobDataSlice';
import editJobDataReducer from '../Features/editJobDataSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const rootReducer = combineReducers({
  createJobConfiguration: createJobConfigurationReducer,
  createJob: createJobDataReducer,
  editJob: editJobDataReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

// import { combineReducers, createStore, configureStore } from "@reduxjs/toolkit";
// import persistReducer from "redux-persist/es/persistReducer";
// import persistStore from "redux-persist/es/persistStore";
// import storage from "redux-persist/lib/storage";
// import createJobConfigurationReducer from '../Features/createJobConfigurationSlice';
// import createJobDataReducer from '../Features/createJobDataSlice';

// const reducers = combineReducers({
//   createJobConfiguration: createJobConfigurationReducer,
//   createJob: createJobDataReducer,
// });

// const persistConfig = {
//   key: "root",
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, reducers);  

// export const store = createStore(
//   persistedReducer,
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );;

// import { createStore } from 'redux'
// import { persistStore, persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
// import createJobConfigurationReducer from '../Features/createJobConfigurationSlice';
// import createJobDataReducer from '../Features/createJobDataSlice';
// import {  combineReducers } from 'redux'

// const persistConfig = {
//   key: 'root',
//   storage,
// }
// const rootReducer = combineReducers({
//   createJobConfigurationReducer,
//   createJobDataReducer,
// })

// const persistedReducer = persistReducer(persistConfig, rootReducer)

// export const store =createStore(persistedReducer)


