import React, { PureComponent } from "react";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import ConfirmDialog from "../../utils/ConfirmDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import ConfirmedSnackBar from "../../utils/ConfirmedSnackbar";
import { UserContextData, UserContext } from "../../Contexts/userContext";
import { axios } from "../../utils/axiosConfig";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from '@material-ui/icons/Cancel';
const styles = {
  textField: {
    width: "100%",
    marginLeft: "15px",
    marginRight: "15px"
  },
  selectRole:{
    width: "100%",
    marginLeft: "15px",
    marginRight: "15px",
    marginTop:"20px",   
  },
  addUserDiv: {},
  createUser: {
    width: "82px",
    height: "52px",
    margin: "12px",
    background: `Black !#000`,
    "&:hover": {
        background: `Black !#000`,
        color: "#fff"
      },
    color: "#ffff"
  },
  createUserSecondary: {
    width: "82px",
    height: "52px",
    margin: "12px",
    background: `#000`,
    color: "#ffff"
  },
  saveData: {
    marginLeft: "15px",
    marginRight: "15px",
    color: "#fff",
    width: "130px",
    margin: "10px",
    fontSize: "16px",
    "&:hover": {
    
      color: "#fff"
    }
  },
  cancelData: {
    marginLeft: "15px",
    marginRight: "15px",
    color: "#fff",
    background: "#000;",
    width: "130px",
    margin: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#000;",
      color: "#fff"
    }
  },
  circularProgress:{

  }
};
class AddUser extends PureComponent {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      emailAddress: "",
      password:"",
      userRole: 0,
      errorFirstName: false,
      errorLastName: false,
      errorEmailAddress: false,
      errorPassword: false,
      errorRole: false,
      openConfirmDialog: false,
      confirmDialogMessage: "",
      anchorEl: null,
      showLoader: false,
      openSnackbar: false,
      snackBarMessage: ""
    };
  }

  componentDidMount=()=>{
    this.cancelUser();
  }
  handleAddUser = event => {
    const { firstName, lastName, emailAddress, userRole,password } = this.state;
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (firstName == "" || firstName == null) {
      this.setState({ errorFirstName: true });
      this.props.showSnackBar("First Name Invalid");
    } else if (lastName === null || lastName === "") {
      this.setState({ errorLastName: true });
      this.props.showSnackBar("Last Name Invalid");
    } else if (
      emailAddress === null ||
      emailAddress === "" ||
      !re.test(String(emailAddress).toLowerCase())
    ) {
      this.setState({ errorEmailAddress: true });
      this.props.showSnackBar("Email Invalid");
    } else if (password == "") {
        this.setState({ errorPassword: true });

        this.props.showSnackBar("Password Invalid");
      }  else if (userRole === 0) {
      this.setState({ errorRole: true });

      this.props.showSnackBar("Role Invalid");
    } else {
      this.setState({ showLoader: true });
      this.setState({
        openConfirmDialog: true,
        confirmDialogMessage: "Are you sure you add new User",
        anchorEl: null
      });
    }
  };

  handleSnackBarClose = () => {
    this.setState({ openSnackbar: false });
  };

  onCloseConfirmDialog = () => {
    this.setState({
      openConfirmDialog: false,
      showLoader: false
    });
  };

  onYesConfirmDialog = () => {
    this.addNewUser();
  };

  addNewUser = () => {

    let body = {
        email: this.state.emailAddress,
        password:this.state.password,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
      role: this.state.userRole
    };
    this.setState({
      
      openConfirmDialog: false
    });
    this.cancelUser();
    let url = "/user/register";
    console.log(body,"vody")
    axios.post(url, body).then(res => {
        let response = res.data;
        this.setState({showLoader: false})
        if (response.success === true) {
            this.props.showSnackBar("Data Saved");
            this.props.getAllUsers();
         
        } else {
          this.props.showSnackBar(response.message);
          this.props.getAllUsers();
        }
      }).catch(function(error) {
        if (error.response.status === 403) {
          const userContext = this.context;
          userContext.logoutUser();
        }
      });
  };
  onChange = event => {
    this.setState({
      errorFirstName: false,
      errorLastName: false,
      errorEmailAddress: false,
      errorRole: false,
      errorPassword: false
    });
    this.setState({ [event.target.name]: event.target.value });
  };

  cancelUser = () => {
    this.setState({
      firstName: "",
      lastName: "",
      emailAddress: "",
      password:"",
      userRole: 0
    });
  };

  render() {
    const { classes } = this.props;
    const {
      userRole,
      errorFirstName,
      errorLastName,
      errorEmailAddress,
      errorPassword,
      errorRole,
      openConfirmDialog,
      confirmDialogMessage,
      showLoader,
      openSnackbar,
      snackBarMessage
    } = this.state;
    return (
      <Grid container className={classes.addUserDiv}>
        <Grid container item xs={12} lg={3} md={3} sm={3}>
          <TextField
            value={this.state.firstName}
            name="firstName"
            label="First Name"
            className={classes.textField}
            margin="normal"
            onChange={this.onChange}
            variant="outlined"
            error={errorFirstName}
          />

          <TextField
            value={this.state.lastName}
            id="outlined-email-input"
            name="lastName"
            label="Last Name"
            className={classes.textField}
            margin="normal"
            onChange={this.onChange}
            variant="outlined"
            error={errorLastName}
          />
        </Grid>
        <Grid container item xs={12} lg={3} md={3} sm={3}>
          <TextField
            value={this.state.emailAddress}
            id="outlined-email-input"
            name="emailAddress"
            label="Email Address"
            className={classes.textField}
            margin="normal"
            onChange={this.onChange}
            variant="outlined"
            error={errorEmailAddress}
          />
          <TextField
            value={this.state.password}
            id="outlined-email-input"
            name="password"
            label="Password"
            type="password"
            className={classes.textField}
            margin="normal"
            onChange={this.onChange}
            variant="outlined"
            error={errorPassword}
          />
        </Grid>
        <Grid container item xs={12} lg={2} md={2} sm={2}>
          <FormControl >
            <Select
            className={classes.selectRole}
              error={errorRole}
              value={userRole}
              onChange={this.onChange}
              name="userRole"
            >
              <MenuItem value={0}>Select Role</MenuItem>

              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="user">User</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid container item xs={12} lg={2} md={2} sm={2}>
        <Button
            variant="contained"
            color="primary"
            className={classes.saveData}
            // disabled={saveProgress}
            startIcon={!showLoader && <SaveIcon />}
            onClick={this.handleAddUser}
          >
              {!showLoader && (
              "Submit"
            )}
            {showLoader && (
              <CircularProgress
                size={20}
                color="secondary"
                className={classes.circularProgress}
              />
            )}
          </Button>

          <Button
            variant="contained"
            className={classes.cancelData}
            startIcon={!showLoader && <CancelIcon />}
            onClick={this.cancelUser}
          >
         Cancel
          </Button>



          {/* <Button
            variant="contained"
            type="submit"
            onClick={this.handleAddUser}
            className={classes.createUser}
          >
            {showLoader && <CircularProgress size={28} color="inherit" />}

            {!showLoader && "Submit"}
          </Button> */}

        
        </Grid>
        <ConfirmDialog
          openConfirmDialog={openConfirmDialog}
          onCloseConfirmDialog={this.onCloseConfirmDialog}
          onYesConfirmDialog={this.onYesConfirmDialog}
          confirmDialogMessage={confirmDialogMessage}
          classes={classes}
        />
        <ConfirmedSnackBar
          open={openSnackbar}
          message={snackBarMessage}
          handleClose={this.handleSnackBarClose}
          classes={classes}
        />
      </Grid>
    );
  }
}

export default withStyles(styles)(AddUser);
