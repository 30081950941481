import React from "react";
import { Grid, CssBaseline, Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "250px"
    },
    rootOther: {
      height: "20px"
    },
    iconRoot: {
      textAlign:'center',
      margin:'auto'
    },
    icon: {
      width: "400px",
      height: "400px"
    }
  })
);
function CreateJobIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 409.024 347.32">
        <g id="OBJEKTS" transform="translate(-48.976 -71.68)">
          <g id="Group_24" data-name="Group 24">
            <path
              id="Path_1"
              data-name="Path 1"
              d="M449.235,341.091H57.741a8.765,8.765,0,0,1-8.765-8.765h0a8.765,8.765,0,0,1,8.765-8.765H449.235A8.765,8.765,0,0,1,458,332.326h0A8.765,8.765,0,0,1,449.235,341.091Z"
              fill="#5fb387"
              opacity="0.31"
            />
            <g id="Group_1" data-name="Group 1">
              <path
                id="Path_2"
                data-name="Path 2"
                d="M64.224,327.973V219.862h60.114v16.465H81.932v28.892h36.5v16.155h-36.5V311.2h42.406v16.775Z"
                fill="#f15a2f"
              />
              <path
                id="Path_3"
                data-name="Path 3"
                d="M185.382,327.973l-16.155-45.046H155.4v45.046H137.7V219.862h34.794a38.617,38.617,0,0,1,12.427,1.942,25.827,25.827,0,0,1,16.309,15.533,36.436,36.436,0,0,1,2.33,13.591,34.555,34.555,0,0,1-4.349,17.709,27.708,27.708,0,0,1-12.892,11.339l18.484,48Zm.776-77.045q0-7.608-3.883-11.261t-11.184-3.65H155.4v30.911h15.534q6.988,0,11.106-4.039T186.158,250.928Z"
                fill="#f15a2f"
              />
              <path
                id="Path_4"
                data-name="Path 4"
                d="M267.4,327.973l-16.154-45.046H237.418v45.046H219.71V219.862H254.5A38.6,38.6,0,0,1,266.93,221.8a25.825,25.825,0,0,1,16.31,15.533,36.435,36.435,0,0,1,2.33,13.591,34.546,34.546,0,0,1-4.35,17.709,27.693,27.693,0,0,1-12.892,11.339l18.485,48Zm.778-77.045q0-7.608-3.884-11.261t-11.184-3.65H237.418v30.911h15.533q6.991,0,11.107-4.039T268.174,250.928Z"
                fill="#f15a2f"
              />
              <path
                id="Path_5"
                data-name="Path 5"
                d="M365.1,295.2a38.422,38.422,0,0,1-2.407,13.98,30.343,30.343,0,0,1-6.835,10.64,29.713,29.713,0,0,1-10.484,6.757,40.654,40.654,0,0,1-28.582-.544,27.616,27.616,0,0,1-10.329-7.766,31.876,31.876,0,0,1-5.825-11.34,48.7,48.7,0,0,1-1.864-13.591V252.482a40.05,40.05,0,0,1,1.787-11.65,30.075,30.075,0,0,1,16-18.64q6.291-3.105,15.457-3.107a34.137,34.137,0,0,1,14.756,2.952,31.237,31.237,0,0,1,10.329,7.689,30.646,30.646,0,0,1,6.058,10.718,38.787,38.787,0,0,1,1.941,12.038Zm-18.018-42.993a19.461,19.461,0,0,0-.7-4.747,16.4,16.4,0,0,0-2.407-5.216,15.225,15.225,0,0,0-4.582-4.278,13.752,13.752,0,0,0-7.378-1.792,15.71,15.71,0,0,0-7.535,1.636,14.346,14.346,0,0,0-4.815,4.045,15.8,15.8,0,0,0-2.563,5.217,19.792,19.792,0,0,0-.777,5.291l.156,41.4a22.826,22.826,0,0,0,3.572,13.074q3.573,5.293,12.117,5.292a14.87,14.87,0,0,0,10.485-4.124q4.426-4.127,4.426-11.286Z"
                fill="#f15a2f"
              />
              <path
                id="Path_6"
                data-name="Path 6"
                d="M429.561,327.973l-16.154-45.046H399.582v45.046H381.875V219.862h34.794A38.607,38.607,0,0,1,429.1,221.8a25.825,25.825,0,0,1,16.31,15.533,36.455,36.455,0,0,1,2.33,13.591,34.546,34.546,0,0,1-4.35,17.709,27.7,27.7,0,0,1-12.892,11.339l18.484,48Zm.777-77.045q0-7.608-3.883-11.261t-11.184-3.65H399.582v30.911h15.534q6.99,0,11.106-4.039T430.338,250.928Z"
                fill="#f15a2f"
              />
            </g>
            <g id="Group_3" data-name="Group 3">
              <line
                id="Line_1"
                data-name="Line 1"
                y2="22.113"
                transform="translate(185.317 104.407)"
                fill="none"
                stroke="#262525"
                stroke-miterlimit="10"
                stroke-width="3"
              />
              <rect
                id="Rectangle_3"
                data-name="Rectangle 3"
                width="84.029"
                height="38.919"
                transform="translate(143.303 71.68)"
                fill="#f15a2f"
              />
              <g id="Group_2" data-name="Group 2">
                <path
                  id="Path_7"
                  data-name="Path 7"
                  d="M174.541,97.255v3.506h-3.818V97.255h-8.711V93.889l6.733-17.284h4.026l-6.629,17.041h4.581V87.537h3.818v6.109h1.77v3.609Z"
                  fill="#fff"
                />
                <path
                  id="Path_8"
                  data-name="Path 8"
                  d="M191.652,94.895a6.183,6.183,0,0,1-.5,2.568,5.662,5.662,0,0,1-1.367,1.892,5.861,5.861,0,0,1-1.99,1.18,7.244,7.244,0,0,1-4.742,0,5.657,5.657,0,0,1-1.973-1.18,5.774,5.774,0,0,1-1.35-1.892,6.183,6.183,0,0,1-.5-2.568V82.5a6.313,6.313,0,0,1,.5-2.586A5.618,5.618,0,0,1,181.1,78.01a5.926,5.926,0,0,1,1.973-1.18,6.919,6.919,0,0,1,2.354-.4,7.21,7.21,0,0,1,2.388.4,5.662,5.662,0,0,1,1.99,1.18,5.742,5.742,0,0,1,1.35,1.909,6.313,6.313,0,0,1,.5,2.586ZM187.834,82.5a2.4,2.4,0,1,0-4.79,0V94.86a2.37,2.37,0,0,0,.66,1.806,2.724,2.724,0,0,0,3.47,0,2.37,2.37,0,0,0,.66-1.806Z"
                  fill="#fff"
                />
                <path
                  id="Path_9"
                  data-name="Path 9"
                  d="M206.818,97.255v3.506H203V97.255h-8.711V93.889l6.733-17.284h4.026l-6.629,17.041H203V87.537h3.818v6.109h1.77v3.609Z"
                  fill="#fff"
                />
              </g>
            </g>
            <path
              id="Path_10"
              data-name="Path 10"
              d="M343.224,212.728a9.511,9.511,0,0,1,1.742,3.481c.744,2.118,1.439,4.252,2.135,6.386.7,2.151,1.41,4.407,1.022,6.635a3.07,3.07,0,0,1-1.33,2.247,2.445,2.445,0,0,1-1.712.188,4.949,4.949,0,0,1-3.179-3.121c-.6-1.424-.833-2.974-1.386-4.415-.971-2.53-2.918-5.118-5.726-5.424-1.44-.156-3.306-.3-4.305-1.507-1.026-1.237-1.1-3.964.325-4.919a4.172,4.172,0,0,1,1.574-.572,19.932,19.932,0,0,1,5.351-.015c1.555.124,3.245-.177,4.714.484A2.582,2.582,0,0,1,343.224,212.728Z"
              fill="#fff"
              stroke="#000"
              stroke-miterlimit="10"
              stroke-width="1"
            />
            <path
              id="Path_11"
              data-name="Path 11"
              d="M190.156,134.089q.418-1.893.8-3.795c.542-2.684,2.009-5.461-.071-7.825-1.846-2.1-5.678-1.375-7.878-.1a6.093,6.093,0,0,0-3.115,6.247,5.028,5.028,0,0,0,4.579,3.842q-.869,2.8-1.59,5.649c-.687,2.707.973,3.663,3.4,3.147a4.108,4.108,0,0,0,1.727-.657,4.169,4.169,0,0,0,1.2-2.412Q189.7,136.14,190.156,134.089Z"
              fill="#fff"
              stroke="#000"
              stroke-miterlimit="10"
              stroke-width="1"
            />
            <path
              id="Path_12"
              data-name="Path 12"
              d="M334.724,209.471c-.363-.08-.726-.161-1.088-.228-11.443-2.113-23.888-5.622-35.575-4.547-2.516.232-5.007.71-7.529.869-8.512.536-16.675-2.48-24.87-4.27-3.337-.729-6.67-1.115-10-1.806-4.067-.844-8.271.015-12.371-.519-3.583-.468-7.09-2.02-10.676-1.57-4.643.584-8.738,3.458-9.953,8.056a14.756,14.756,0,0,0,.221,7.07c.505,2.172,1.372,4.484,3.308,5.59,4.052,2.314,11.7.819,16.193.9,6.08.1,12.157.306,18.233.509,11.976.4,23.961.815,35.944.511,12.334-.312,24.672-.017,37.005.416C333.975,216.791,334.368,213.133,334.724,209.471Z"
            />
            <path
              id="Path_13"
              data-name="Path 13"
              d="M132.13,233.5a15.987,15.987,0,0,0-3.379,6.989,8.41,8.41,0,0,0-.12,4.525c.546,1.832,2.246,3.528,4.287,3.112a3.1,3.1,0,0,0,2.294-2.955,2.639,2.639,0,0,0-.91-1.895,15.142,15.142,0,0,1,.285-1.847,1.212,1.212,0,0,1,.275-.627c.583-.582.76.327.721.723-.042.43-.462,1.535.053,1.792.341.131.612-.306.7-.66a22.412,22.412,0,0,0,.532-3.893l.279-3.588A4.582,4.582,0,0,0,137.14,234a2.53,2.53,0,0,0-2.611-1.984A4.455,4.455,0,0,0,132.13,233.5Z"
              fill="#fff"
              stroke="#000"
              stroke-miterlimit="10"
              stroke-width="1"
            />
            <g id="Group_5" data-name="Group 5">
              <g id="Group_4" data-name="Group 4">
                <path
                  id="Path_14"
                  data-name="Path 14"
                  d="M366.169,168.615l-.9.429a.63.63,0,0,1-.759-.2,9.5,9.5,0,0,0-2.76-2.505.619.619,0,0,1-.255-.722l.441-1.3a.617.617,0,0,0-.374-.778l-2.644-.958a.614.614,0,0,0-.785.36l-.492,1.3a.608.608,0,0,1-.629.4,12.524,12.524,0,0,0-3.781.254.738.738,0,0,1-.787-.286l-.577-1.216c-.145-.307-.387-.758-.694-.613l-2.551,1.209a.948.948,0,0,0-.279,1.074l.577,1.216a.581.581,0,0,1-.138.731,10.882,10.882,0,0,0-2.308,2.585.6.6,0,0,1-.721.247l-1.217-.454a.612.612,0,0,0-.791.371l-.92,2.61a.615.615,0,0,0,.358.778l1.333.516a.612.612,0,0,1,.388.647,9.534,9.534,0,0,0,.2,3.733.627.627,0,0,1-.327.713l-1.139.54a.667.667,0,0,0-.391.822l1.209,2.552c.146.307.545.3.852.15l1.132-.536a.664.664,0,0,1,.758.211,11.908,11.908,0,0,0,2.662,2.407.628.628,0,0,1,.236.74l-.407,1.088a.62.62,0,0,0,.373.8l2.567.9a.616.616,0,0,0,.789-.39l.351-1.081a.619.619,0,0,1,.668-.416,11.014,11.014,0,0,0,3.535-.141.581.581,0,0,1,.651.356l.553,1.167c.145.307.548.288.855.142l2.551-1.209c.307-.145.263-.3.118-.6l-.553-1.166a.739.739,0,0,1,.276-.79,12.388,12.388,0,0,0,2.6-2.818.653.653,0,0,1,.74-.25l1.18.4a.626.626,0,0,0,.789-.376l.938-2.6a.616.616,0,0,0-.393-.8l-1.038-.326a.608.608,0,0,1-.427-.643,12.116,12.116,0,0,0-.185-3.6.663.663,0,0,1,.317-.719l.9-.426c.307-.146.255-.3.11-.607l-1.209-2.551C366.594,168.674,366.476,168.469,366.169,168.615Zm-7.956,12.324a5.928,5.928,0,1,1,2.818-7.895A5.928,5.928,0,0,1,358.213,180.939Z"
                  fill="#5fb387"
                  opacity="0.31"
                />
              </g>
            </g>
            <g id="Group_7" data-name="Group 7">
              <g id="Group_6" data-name="Group 6">
                <path
                  id="Path_15"
                  data-name="Path 15"
                  d="M398.064,185.678l-1.223.579a.849.849,0,0,1-1.024-.267,12.812,12.812,0,0,0-3.727-3.384.832.832,0,0,1-.344-.974l.6-1.757a.833.833,0,0,0-.505-1.05l-3.57-1.294a.831.831,0,0,0-1.061.487l-.663,1.755a.822.822,0,0,1-.85.533,16.9,16.9,0,0,0-5.106.344.994.994,0,0,1-1.063-.387l-.778-1.642c-.2-.414-.523-1.023-.938-.827l-3.445,1.633a1.275,1.275,0,0,0-.376,1.449l.778,1.643a.784.784,0,0,1-.186.987,14.719,14.719,0,0,0-3.117,3.49.812.812,0,0,1-.974.335l-1.643-.614a.826.826,0,0,0-1.068.5l-1.243,3.524a.831.831,0,0,0,.485,1.051l1.8.7a.824.824,0,0,1,.524.873,12.928,12.928,0,0,0,.274,5.042.849.849,0,0,1-.441.963l-1.538.729a.9.9,0,0,0-.528,1.11l1.633,3.445c.2.415.735.4,1.15.2l1.529-.724a.9.9,0,0,1,1.023.285,16.084,16.084,0,0,0,3.6,3.25.85.85,0,0,1,.319,1l-.551,1.469a.837.837,0,0,0,.505,1.084l3.466,1.217a.83.83,0,0,0,1.065-.527l.474-1.459a.835.835,0,0,1,.9-.562,14.859,14.859,0,0,0,4.775-.191.784.784,0,0,1,.878.482l.746,1.575c.2.414.74.388,1.155.192l3.445-1.632c.415-.2.356-.4.159-.815l-.746-1.575a1,1,0,0,1,.372-1.066,16.719,16.719,0,0,0,3.51-3.806.883.883,0,0,1,1-.338l1.593.539a.843.843,0,0,0,1.065-.508l1.267-3.512a.83.83,0,0,0-.531-1.075l-1.4-.44a.825.825,0,0,1-.578-.869,16.326,16.326,0,0,0-.249-4.866.9.9,0,0,1,.428-.971l1.213-.575c.415-.2.344-.4.148-.819l-1.633-3.445C398.638,185.758,398.478,185.481,398.064,185.678ZM387.32,202.32a8,8,0,1,1,3.8-10.66A8,8,0,0,1,387.32,202.32Z"
                  fill="#5fb387"
                  opacity="0.31"
                />
              </g>
            </g>
            <g id="Group_12" data-name="Group 12">
              <path
                id="Path_16"
                data-name="Path 16"
                d="M69.523,388.651c-2.783-.448-5.627-.643-8.227-1.7-3.131-1.279-5.92-4.412-5.268-7.731.563-2.862,3.513-4.74,6.41-5.079s5.784.5,8.611,1.224a108.842,108.842,0,0,0,14.595,2.68c7.135.813,14.876,1.116,20.464,5.627,3.706,2.992,6.409,7.776,11.088,8.663a25.2,25.2,0,0,1,2.924.478,2.691,2.691,0,0,1,1.99,1.983c.278,2.022-4.679,2.252-6.093,2.638-4.089,1.118-7.965,2.612-11.988,3.768-4.235,1.217-8.783.854-13.11.458-4.346-.4-9.251-1.105-11.773-4.666-1.312-1.854-1.78-4.274-3.387-5.88a9,9,0,0,0-4.542-2.142C70.656,388.845,70.091,388.743,69.523,388.651Z"
                fill="#5fb387"
                opacity="0.31"
              />
              <g id="Group_11" data-name="Group 11">
                <g id="Group_10" data-name="Group 10">
                  <g id="Group_8" data-name="Group 8">
                    <path
                      id="Path_17"
                      data-name="Path 17"
                      d="M57.575,371.161a7.9,7.9,0,0,0,1.315,6.358,7.82,7.82,0,0,0,4.66,3.067,7.6,7.6,0,0,0,5.964-1.017l43.458,9.377,2.446-11.032-43.553-9.4a9.255,9.255,0,0,0-4.9-3.373,7.907,7.907,0,0,0-9.393,6.017Zm6-.367c.042-.024.077-.057.121-.077a2.717,2.717,0,0,1,.555-.189c.037-.01.076-.028.111-.035v.016a3.144,3.144,0,0,1,1.234.029l.039.008a2.5,2.5,0,0,1,1.756,2.487,2.55,2.55,0,0,1-1.953,2.328l-.033.007.382-.141a2.562,2.562,0,0,1-1.711.112,2.491,2.491,0,0,1-.5-4.545Z"
                    />
                  </g>
                  <line
                    id="Line_2"
                    data-name="Line 2"
                    x2="43.458"
                    y2="9.377"
                    transform="translate(70.028 376.206)"
                    fill="none"
                    stroke="#fff"
                    stroke-miterlimit="10"
                    stroke-width="1"
                  />
                  <line
                    id="Line_3"
                    data-name="Line 3"
                    x2="43.458"
                    y2="9.377"
                    transform="translate(71.032 371.886)"
                    fill="none"
                    stroke="#fff"
                    stroke-miterlimit="10"
                    stroke-width="1"
                  />
                  <g id="Group_9" data-name="Group 9">
                    <path
                      id="Path_18"
                      data-name="Path 18"
                      d="M107.858,382.86a10.545,10.545,0,0,0,1.411,8q.171.271.359.529a10.618,10.618,0,0,0,13.419,3.226l1.441-.736-8.056-5.558,4.361-6.32,7.923,5.469.1-1.714a10.612,10.612,0,0,0-20.957-2.9Z"
                      fill="#fff"
                      stroke="#000"
                      stroke-miterlimit="10"
                      stroke-width="1"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="Group_18" data-name="Group 18">
              <path
                id="Path_19"
                data-name="Path 19"
                d="M138.95,376.286c-5.441-.612-11.955-2.568-13.148-7.912-1.035-4.636,3.008-9.148,7.562-10.5s9.425-.437,14.126.242c6.926,1,56.452,1.674,55.055,11.472-.705,4.94-24.352,5.979-28.938,6.464A159.734,159.734,0,0,1,138.95,376.286Z"
                fill="#5fb387"
                opacity="0.31"
              />
              <g id="Group_17" data-name="Group 17">
                <g id="Group_16" data-name="Group 16">
                  <g id="Group_13" data-name="Group 13">
                    <path
                      id="Path_20"
                      data-name="Path 20"
                      d="M180.59,360.6l-.8,3.146-37.531-6.58.8-3.146Z"
                    />
                  </g>
                  <g id="Group_14" data-name="Group 14">
                    <path
                      id="Path_21"
                      data-name="Path 21"
                      d="M207.8,361.342c.076.1.149.195.22.3a6.252,6.252,0,0,1,1.064,4.724,6.515,6.515,0,0,1-7.565,5.146l-36.548-6.188,1.877-12.051,36.809,5.634A6.655,6.655,0,0,1,207.8,361.342Z"
                      fill="#fff"
                      stroke="#000"
                      stroke-miterlimit="10"
                      stroke-width="1"
                    />
                  </g>
                  <g id="Group_15" data-name="Group 15">
                    <path
                      id="Path_22"
                      data-name="Path 22"
                      d="M149.715,349.4l4.831,6.885-.926,2.8-6.255,4.222-14.213-5.988,1.467-7.952Z"
                      fill="#fff"
                      stroke="#000"
                      stroke-miterlimit="10"
                      stroke-width="1"
                    />
                  </g>
                </g>
                <line
                  id="Line_4"
                  data-name="Line 4"
                  x2="33.677"
                  y2="5.391"
                  transform="translate(175.481 358.829)"
                  fill="none"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <line
                  id="Line_5"
                  data-name="Line 5"
                  x2="33.677"
                  y2="5.391"
                  transform="translate(174.75 362.999)"
                  fill="none"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <line
                  id="Line_6"
                  data-name="Line 6"
                  y1="12.207"
                  x2="2.382"
                  transform="translate(167.929 353.616)"
                  fill="none"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <line
                  id="Line_7"
                  data-name="Line 7"
                  x2="7.938"
                  y2="3.035"
                  transform="translate(137.61 356.984)"
                  fill="#fff"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
              </g>
            </g>
            <path
              id="Path_23"
              data-name="Path 23"
              d="M166.96,202.037c-3.769-.526-7.659.633-11.259,1.205l-4.418.866a24.189,24.189,0,0,0-5.483,1.443c-2.616,1.233-4.489,3.63-5.973,6.112a75.658,75.658,0,0,0-3.947,7.951c-1.97,4.391-3.716,8.829-5.53,13.243a.53.53,0,0,0,.269.684c2.674,1.237,5.293,2.589,7.891,3.983a.21.21,0,0,0,.3-.122c.7-2.227,9.717-17,11.841-18.037a26.65,26.65,0,0,1,3.965-.961,52.338,52.338,0,0,0,14.722-6.894,4.774,4.774,0,0,0,1.7-1.953C172.735,205.82,170.834,202.577,166.96,202.037Z"
              fill="#fff"
              stroke="#000"
              stroke-miterlimit="10"
              stroke-width="1"
            />
            <path
              id="Path_24"
              data-name="Path 24"
              d="M281.309,255a41.852,41.852,0,0,1-2.151-3.849,6.5,6.5,0,0,0-1.352-2.167,6.214,6.214,0,0,0-3.482-1.206,14.747,14.747,0,0,0-5.448-.038,4.131,4.131,0,0,0-1.988,1.03c-1.494,1.525-.072,4.23,1.394,5.267,1.588,1.123,3.489,1.357,5.027,2.624a13.836,13.836,0,0,1,3.3,4.863q1.245,2.572,2.491,5.145a1.357,1.357,0,0,0,.689.789,1.142,1.142,0,0,0,.6-.009c2.117-.478,3.375-2.8,3.447-4.969C283.923,259.592,282.677,257.3,281.309,255Z"
              fill="#fff"
              stroke="#000"
              stroke-miterlimit="10"
              stroke-width="1"
            />
            <path
              id="Path_25"
              data-name="Path 25"
              d="M150.4,216.561a12.527,12.527,0,0,0,4.717,2.772,5.18,5.18,0,0,0,5.425-1.354c1.142-1.413,1.55-3.789,3.348-4.057a3.446,3.446,0,0,1,1.564.236c2.508.827,5,2.215,7.617,1.831a7.321,7.321,0,0,0,4.306-2.489,6.453,6.453,0,0,0,1.762-5.356c-.758-3.843-5.722-4.655-8.876-4.844-4.275-.255-8.651-.205-12.826-1.145-3.094-.7-5.721-.551-8.163,1.99a8.475,8.475,0,0,0-1.292,9.289A10.471,10.471,0,0,0,150.4,216.561Z"
              fill="#fff"
              stroke="#000"
              stroke-miterlimit="10"
              stroke-width="1"
            />
            <path
              id="Path_26"
              data-name="Path 26"
              d="M174.426,192.833c-5.108.522-6.733,3.917-9.367,7.778-2.183,3.2-4.236,7.94-1.417,11.3,2.272,2.706,5.269,3.924,8.231,5.63,2.508,1.445,4.532,2.563,7.72,2.432,7.749-.318,15.585.551,23.424.505,5.01-.029,9.82.013,14.644-1.507,4.218-1.33,5.744-5.433,6.852-9.506,1.131-4.161,1.719-10.114-1.176-13.765a7.593,7.593,0,0,0-1.3-1.289,10.282,10.282,0,0,0-2.436-1.3,8.591,8.591,0,0,0-5.137-.858c-.387.091-.761.231-1.142.346a55.984,55.984,0,0,1-10.579,1.9,60.638,60.638,0,0,1-12.45-.377C185.092,193.579,179.653,192.3,174.426,192.833Z"
              fill="#fff"
              stroke="#000"
              stroke-miterlimit="10"
              stroke-width="1"
            />
            <path
              id="Path_27"
              data-name="Path 27"
              d="M270.4,252.484c.232-1.36.7-5.007-.212-5.9A6.121,6.121,0,0,0,267,245.391c-1.444-.33-2.871-.828-4.294-1.239q-4.414-1.273-8.825-2.562a134.922,134.922,0,0,1-13.938-5.046c-6.226-2.586-12.7-5.506-19.41-4.823a42.389,42.389,0,0,0-4.617,1.093,12.914,12.914,0,0,0-4.959,2.058,5.934,5.934,0,0,0-1.794,6.968c2.276,6.039,8.471,6.334,13.814,7.759a49.915,49.915,0,0,0,8.572,1.128q14.371,1.231,28.644,3.381,3.609.544,7.209,1.146c.657.11,1.5.37,2.284.446C269.957,254.635,270.218,253.567,270.4,252.484Z"
            />
            <path
              id="Path_28"
              data-name="Path 28"
              d="M212.994,246.3a2.479,2.479,0,0,0,1.272.008c5.079-1.3,9.5-5.826,12.554-9.853,4.891-6.46,7.868-14.031,12.466-20.624,1.412-2.024,3.307-3.421,4.483-5.594a21.361,21.361,0,0,0,2.275-13.33c-1.558-8.348-11.231-10.806-18.585-9.575-4.821.808-9.119,3.612-13.924,4.506a3.8,3.8,0,0,0-1.72.588,1.265,1.265,0,0,0-.355.656c.019,5.851.045,11.694-.038,17.551-.052,3.645.367,8.31-.122,11.3-.3,1.839-1.757,3.875-2.337,5.7a20.779,20.779,0,0,0-.281,13.214C209.3,242.609,210.9,245.735,212.994,246.3Z"
            />
            <path
              id="Path_29"
              data-name="Path 29"
              d="M186.89,208.633c.094.188.187.376.281.563"
              fill="#262525"
            />
            <path
              id="Path_30"
              data-name="Path 30"
              d="M185.361,202.444a53.684,53.684,0,0,0,4.412-9.1,52.393,52.393,0,0,0,3.129-12.7,69.62,69.62,0,0,0-.056-11.542c-.678-11.283-.848-22.743-2.08-33.972-1.161.585-9.546,1.361-9.512,1.084q-2.3,18.75-4.6,37.5a43.042,43.042,0,0,1-1.69,8.731,52.5,52.5,0,0,1-4.092,8.262c-2.539,4.582-8.435,12.687-7.3,17.982"
              fill="#fff"
              stroke="#000"
              stroke-miterlimit="10"
              stroke-width="1"
            />
            <path
              id="Path_31"
              data-name="Path 31"
              d="M206.32,197.088v.422"
              fill="#262525"
            />
            <path
              id="Path_32"
              data-name="Path 32"
              d="M211.682,221.03a94.89,94.89,0,0,1,11.4-17.037"
              fill="none"
              stroke="#fff"
              stroke-miterlimit="10"
              stroke-width="1"
            />
            <path
              id="Path_33"
              data-name="Path 33"
              d="M187.808,206.672c-.195-1.623-.518-3.175-.686-4.807a12.53,12.53,0,0,0-.4-2.378,1.6,1.6,0,0,0-.079-.205c-1.58,3.264-4.424,5.774-7.331,7.916l8.145,4.577C188.224,212.205,187.878,207.259,187.808,206.672Z"
            />
            <path
              id="Path_34"
              data-name="Path 34"
              d="M159.026,208.125a2.694,2.694,0,0,1-.754,1.507c4.759,2.4,5.6,12.114,5.137,16.706-.312,3.1-1.216,6.119-1.473,9.223-.382,4.608.8,9.495,3.959,12.875a14.615,14.615,0,0,0,15.509,3.373A17.1,17.1,0,0,0,191.715,239.4c2.83-13.485-10.315-25.387-18.705-34.09-3.307-3.43-7.5-5.845-12.41-5.649a23.893,23.893,0,0,0-4.386.674c-3.677.846-7.019,2.175-8.925,5.547-1.15,2.034-3.193,3.582-3.8,5.838a4.813,4.813,0,0,0,1.331,4.752,4.21,4.21,0,0,0,4.823.543c3.29-1.955,2.222-9.193,6.659-8.971a.908.908,0,0,1,.562-.964,2.625,2.625,0,0,1,1.19-.148,1.436,1.436,0,0,1,.539.087C158.969,207.186,159.022,207.764,159.026,208.125Z"
            />
            <path
              id="Path_35"
              data-name="Path 35"
              d="M156.525,218.262a3.2,3.2,0,0,1,.637-3.065"
              fill="#fff"
              stroke="#000"
              stroke-miterlimit="10"
              stroke-width="1"
            />
            <path
              id="Path_36"
              data-name="Path 36"
              d="M245,205.4c-1.9,4.848-5.619,8.824-7.456,13.7"
              fill="none"
              stroke="#fff"
              stroke-miterlimit="10"
              stroke-width="1"
            />
            <g id="Group_21" data-name="Group 21">
              <path
                id="Path_37"
                data-name="Path 37"
                d="M378.726,368.33a4.513,4.513,0,0,0-.579,2.4,4.586,4.586,0,0,0,2.8,3.747,10.54,10.54,0,0,0,4.82.717,44.814,44.814,0,0,0,14.978-3.542c4.232-1.69,9.29-3.4,12.576-6.645.826-.816,1.387-2.153.747-3.123a3.207,3.207,0,0,0-2.256-1.09,46.75,46.75,0,0,0-19.345.809C388.9,362.509,380.943,364.32,378.726,368.33Z"
                fill="#5fb387"
                opacity="0.31"
              />
              <g id="Group_20" data-name="Group 20">
                <path
                  id="Path_38"
                  data-name="Path 38"
                  d="M405.529,319.505a5.01,5.01,0,0,1,.056-.515c.309-1.871,1.78-3.294,2.788-4.9a10.734,10.734,0,0,0,1.62-5.221c.145-3.452.045-7.883,1.532-11.079,1.34-2.881,4.527-4.445,6.162-7.153a16.943,16.943,0,0,0,1.584-4.969,17.531,17.531,0,0,1,3.479-7.49,23.973,23.973,0,0,1,5.035-4.063,25.4,25.4,0,0,1,5.509-3.048c2.606-.92,5.5-1.225,6.707,1.461.975,2.168.484,5.241-.545,7.283a22.37,22.37,0,0,1-3.645,4.7,68.393,68.393,0,0,0-6.329,8.175,6.324,6.324,0,0,0-1.175,2.609,11.281,11.281,0,0,0,.282,2.776c.593,4.7-1.346,8.5-4.975,11.362-2.866,2.26-4.655,3.539-5.376,7.452-.447,2.421-.646,4.992-1.992,7.052a23.877,23.877,0,0,1-1.921,2.324,18.287,18.287,0,0,0-4.254,10.154c-.165,1.858-.557,6.048-3.543,5.205-3.653-1.032-3.209-11.7-2.138-14.231a16.381,16.381,0,0,0,1.356-3.216C406.035,322.636,405.445,321.054,405.529,319.505Z"
                />
                <path
                  id="Path_39"
                  data-name="Path 39"
                  d="M390.059,327.285a12.752,12.752,0,0,1,.645-4.236c.577-1.778,1.522-3.559,1.238-5.407-.469-3.056-3.994-4.722-5.089-7.613-1.5-3.96,2.029-7.936,2.819-12.1.67-3.528-.68-7.078-1.591-10.552-.392-1.5-2.621-10.035,1.806-8.672a2.833,2.833,0,0,1,1.487,1.533c2.148,4.087,2.692,9.265,6.122,12.629,1.4,1.376,3.217,2.3,4.548,3.744a10.609,10.609,0,0,1,2.25,8.776c-.838,5.393-6.157,9-4.033,14.521a46.155,46.155,0,0,0,3.64,6.415c2.57,4.415,7.8,22.111-2.722,16C395.6,339.085,390.2,333.809,390.059,327.285Z"
                  fill="#fff"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <path
                  id="Path_40"
                  data-name="Path 40"
                  d="M410.8,327.657c.059-.117.119-.233.179-.349,1.308-2.5,3.053-4.749,4.4-7.228,1.094-2.011,1.923-4.166,3.14-6.106a9.733,9.733,0,0,1,5.107-4.372,5.3,5.3,0,0,1,4.6.851c.347.33.611.737.947,1.077a10.413,10.413,0,0,0,2.157,1.436c3.792,2.294,5.416,6.609,6.8,10.593,1.513,4.36,3.348,8.063,3.957,12.646a3.583,3.583,0,0,1-.489,2.978,2.367,2.367,0,0,1-2.452.45,6.289,6.289,0,0,1-2.161-1.471,9.538,9.538,0,0,1-2.174-2.63c-.431-.865-.624-1.841-1.111-2.676a10.515,10.515,0,0,0-2.411-2.469c-2.546-2.2-4.109-4.837-5.924-7.585-1.833.749-3.633,3.136-5.609,4.191-1.726.921-4.622,1.251-5.583,3.168-.946,1.891-.506,4.629-1.188,6.7-1.518,4.607-5.069,3.954-4.577-.957A23.734,23.734,0,0,1,410.8,327.657Z"
                  fill="#fff"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <path
                  id="Path_41"
                  data-name="Path 41"
                  d="M422,336.458H388.022V340.7h2.727l4,22.65h20.527l4-22.65H422Z"
                />
                <line
                  id="Line_8"
                  data-name="Line 8"
                  x2="30.436"
                  transform="translate(389.792 341.059)"
                  fill="none"
                  stroke="#fff"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <g id="Group_19" data-name="Group 19">
                  <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="0.829"
                    cy="0.829"
                    r="0.829"
                    transform="translate(388.851 306.401)"
                  />
                  <path
                    id="Path_42"
                    data-name="Path 42"
                    d="M397.966,310.545a.829.829,0,1,1-.828-.829A.828.828,0,0,1,397.966,310.545Z"
                  />
                  <path
                    id="Path_43"
                    data-name="Path 43"
                    d="M396.309,319.66a.828.828,0,1,1-.829-.829A.829.829,0,0,1,396.309,319.66Z"
                  />
                  <path
                    id="Path_44"
                    data-name="Path 44"
                    d="M401.281,301.429a.829.829,0,1,1-.829-.829A.829.829,0,0,1,401.281,301.429Z"
                  />
                  <path
                    id="Path_45"
                    data-name="Path 45"
                    d="M391.337,284.856a.829.829,0,1,1-.829-.829A.829.829,0,0,1,391.337,284.856Z"
                  />
                  <circle
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="0.829"
                    cy="0.829"
                    r="0.829"
                    transform="translate(401.281 328.775)"
                  />
                  <path
                    id="Path_46"
                    data-name="Path 46"
                    d="M395.48,327.947a.829.829,0,1,1-.828-.829A.828.828,0,0,1,395.48,327.947Z"
                  />
                  <path
                    id="Path_47"
                    data-name="Path 47"
                    d="M394.652,297.286a.829.829,0,1,1-.829-.829A.829.829,0,0,1,394.652,297.286Z"
                  />
                </g>
              </g>
            </g>
            <g id="Group_23" data-name="Group 23">
              <path
                id="Path_48"
                data-name="Path 48"
                d="M234.634,416.5c-5.623-1.125-11.2-2.544-16.722-4.078-5.191-1.442-10.655-3.178-14.15-7.278-10.495-12.313,6.618-25.016,15.676-30.323,7.4-4.335,16.211-9.415,25.042-8.143,5.013.723,9.726,2.772,14.336,4.87,15.372,7,30.731,14.871,47.468,17.135,3.505.474,32.591,1.244,31.258,5.983a4.266,4.266,0,0,1-1.592,2c-6.115,4.954-13.733,7.594-21.194,10.1-18.837,6.323-38.173,12.438-58.042,12.232A118.953,118.953,0,0,1,234.634,416.5Z"
                fill="#5fb387"
                opacity="0.31"
              />
              <g id="Group_22" data-name="Group 22">
                <path
                  id="Path_49"
                  data-name="Path 49"
                  d="M244.977,379.433c-2.443,4.465,1.372,7.684,4.55,10.681a10.233,10.233,0,0,0,2.9,2.069c1.9.813,4.063.62,6.117.392a446.129,446.129,0,0,0,44.314-6.747c3.414-.734,6.792-1.63,10.2-2.4a92.348,92.348,0,0,0,13.265-4.616,42.63,42.63,0,0,0,11.832-7.125c.061-.052.121-.108.182-.161a4.153,4.153,0,0,0,1.565-1.558c1.824-2.08,3.081-4.671,2.032-7.094-1.208-2.792-4.74-3.588-7.772-3.828-9.052-.718-18.123.257-27.07,1.6-6.034.908-12.011,2.192-18.069,2.94-6.164.761-12.038.076-18.166.218A30.692,30.692,0,0,0,244.977,379.433Z"
                />
                <path
                  id="Path_50"
                  data-name="Path 50"
                  d="M271.26,357.4c-3.746-1.477-7.605-.093-9.668-4.355a41.544,41.544,0,0,1-1.644-4.859,20.761,20.761,0,0,0-10.148-11.71c3.686-3.475,8.627-5.208,13.121-7.547a50.948,50.948,0,0,0,11.333-8.037c3.119-2.927,7.952-9.211,5.366-13.768-2.081-3.668-7.243-2.525-10.581-1.673-4.647,1.187-8.7,1.409-12.9,4.148-4.036,2.637-7.364,6.2-10.623,9.756-5.089,5.55-16.193,16.4-8.934,24.191a84.191,84.191,0,0,0,13.307,12.082,26.617,26.617,0,0,0,4.395,2.584c3.082,1.36,4.272,3.866,7.357,5.251a22.723,22.723,0,0,0,8.125,2.126c2.086.1,5.479-.384,5.678-2.9C275.636,360.319,273.225,358.176,271.26,357.4Z"
                  fill="#fff"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <path
                  id="Path_51"
                  data-name="Path 51"
                  d="M323.642,350.6c-1.825,2.409-4.871,3.991-7.785,3.31-3.085-.721-5.118-3.591-6.775-6.291A52.96,52.96,0,0,1,302.669,334c-1.453-5.347-2.134-13.857,3.175-17.411,3.776-2.528,9.949-1.1,12.868,2.137,6.3,6.978,10.472,22.184,5.6,30.843A9.089,9.089,0,0,1,323.642,350.6Z"
                  fill="#fff"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <path
                  id="Path_52"
                  data-name="Path 52"
                  d="M314.011,307.862c-4.671-4.944-10.221-8.575-17.5-8.395-1.961.049-3.907.37-5.867.439-6.877.244-13.693-1.474-20.322,1.175a97.7,97.7,0,0,0-19.834,11.248c-.5.353,11.6,16.954,12.887,18.459,5.008-3.669,3.807,13.618,3.728,15.957-.2,5.821-.345,11.705.853,17.44a2.069,2.069,0,0,1,1.115-.943c.59-.128.961.584,1.486.714a17.631,17.631,0,0,0,3.862-.016c2.225-.008,4.451,0,6.676-.017a117.608,117.608,0,0,0,15.35-.776,51.35,51.35,0,0,1,7.933-.708c1.395-4.83.906-10.645,1.043-15.446.144-4.617-.227-9.141.128-13.739A143.478,143.478,0,0,0,324.8,327.03c.377-.152-3-7.828-3.368-8.568a41.881,41.881,0,0,0-5.225-8.111C315.5,309.494,314.765,308.661,314.011,307.862Z"
                />
                <path
                  id="Path_53"
                  data-name="Path 53"
                  d="M277.531,394.067c-.95.95-20.955,5.34-20.955,5.34l-.943-10.169,18.615-6.663Z"
                  fill="#fff"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <path
                  id="Path_54"
                  data-name="Path 54"
                  d="M328.15,381.92a50.614,50.614,0,0,1,7.188-3.625c2.666-1.005,5.622-1.62,7.675-3.6,2.175-2.091,2.861-5.248,3.443-8.207a22.786,22.786,0,0,0-.269-10.226,13.525,13.525,0,0,0-9.744-9.57,12.794,12.794,0,0,0-8.505,1.043c-1.918.967-3.562,2.407-5.457,3.418a45.572,45.572,0,0,1-4.978,2.024c-5.075,1.977-9.745,4.932-14.367,7.727-4.646,2.811-8.806,6.339-13.3,9.376-1.774,1.2-20.412,10.247-20.41,10.253,2.417,5.382,4.528,10.916,6.739,16.4,12.053-1.632,23.668-5.423,35.39-8.573,2.325-.625,4.485-1.559,6.813-2.09a29.258,29.258,0,0,0,7.443-3.043C326.6,382.808,327.372,382.364,328.15,381.92Z"
                />
                <path
                  id="Path_55"
                  data-name="Path 55"
                  d="M255.9,400.476c-3.688.73-7.391-.059-11.369-.488-3.1-.334-6.283.237-9.365-.238-4.116-.634-7.315-3.546-7.618-7.662-.171-2.315-.256-4.9,1.271-6.652a6.152,6.152,0,0,1,5.211-1.735,23.807,23.807,0,0,1,5.481,1.517,80.268,80.268,0,0,0,9.417,2.491c2.574.547,4.787,1.826,7.339.562a.588.588,0,0,1,.436-.092c.154.054.227.227.282.381q1.365,3.834,2.454,7.759c.376,1.359,1,2.146-.113,2.988A9.046,9.046,0,0,1,255.9,400.476Z"
                />
                <path
                  id="Path_56"
                  data-name="Path 56"
                  d="M295.766,394.067c.95.95,20.955,5.34,20.955,5.34l.942-10.169-18.614-6.663Z"
                  fill="#fff"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <path
                  id="Path_57"
                  data-name="Path 57"
                  d="M244.468,383.109a67.85,67.85,0,0,1-11.127-5.293,11.277,11.277,0,0,1-3.22-2.6,12.612,12.612,0,0,1-1.97-5.495c-1.366-7.192-3.3-12.672,1.911-18.782,4.244-4.973,11.056-6.9,16.828-3.31,3.749,2.335,6.265,6.565,10.43,8.035,2.244.792,4.8.634,6.973,1.638a65.431,65.431,0,0,1,5.868,3.6c4.646,2.811,8.806,6.339,13.3,9.376,1.774,1.2,20.412,10.247,20.41,10.253-2.417,5.382-4.528,10.916-6.739,16.4-12.053-1.632-23.669-5.423-35.39-8.573C255.938,386.809,250.083,385.228,244.468,383.109Z"
                />
                <path
                  id="Path_58"
                  data-name="Path 58"
                  d="M317.4,400.476c3.688.73,7.39-.059,11.369-.488,3.1-.334,6.282.237,9.365-.238,3.9-.6,8.342-4.469,8.518-8.6a9.071,9.071,0,0,0-.771-3.768,7.294,7.294,0,0,0-.98-1.782,5.734,5.734,0,0,0-3.978-2.04c-2.336-.216-4.678.539-6.89,1.186a53.4,53.4,0,0,0-6.589,1.979,17.211,17.211,0,0,1-9.065,1.249,5.783,5.783,0,0,0-2.392-.044c-1.731.505-1.558,3.115-1.69,4.544a20.624,20.624,0,0,1-1.174,6.374A13.522,13.522,0,0,0,317.4,400.476Z"
                />
                <path
                  id="Path_59"
                  data-name="Path 59"
                  d="M308.478,316.852c0-.028,0-.056-.005-.085l-.151-2.531a5.938,5.938,0,0,1,.018-1.306,6.023,6.023,0,0,1,2.245-3.215c.745-.678,7.032-6.816,7.513-6.462,1.962,1.489,3.169.155,4.6-1.371a2.5,2.5,0,0,0,.867-1.657c-.016-.64-.785-1.239-1.3-.862q1.709-1.89,3.322-3.863c.948-1.158,1.886-1.868,1.24-3.4a11.3,11.3,0,0,0-5.611-5.5c-1.9-.93-4.95-2.234-7.114-2.074-2.387.177-3.619,2.191-5.414,3.418a.437.437,0,0,1-.261.1.52.52,0,0,1-.285-.178,2.008,2.008,0,0,0-2.473.047,4.823,4.823,0,0,0-1.458,2.208,3.09,3.09,0,0,0-.285,1.672,1.2,1.2,0,0,0,1.177,1.028c-.448.069-1.19,3.473-1.312,3.906-.609,2.163-1.941,3.377-3.454,5.047-1.363,1.505-3.007,3.135-5.036,3.046-.9-.04-2.019-.341-2.537.4a1.735,1.735,0,0,0-.248.868c-.361,4.451,2.5,8.4,6.087,10.739C300.484,318.052,308.652,321.4,308.478,316.852Z"
                  fill="#fff"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <path
                  id="Rectangle_4"
                  data-name="Rectangle 4"
                  d="M1.661,0H52.867a1.66,1.66,0,0,1,1.66,1.66V32.553a1.66,1.66,0,0,1-1.66,1.66H1.66A1.66,1.66,0,0,1,0,32.553V1.661A1.661,1.661,0,0,1,1.661,0Z"
                  transform="translate(254.359 335.108)"
                  fill="#f15a2f"
                />
                <path
                  id="Path_60"
                  data-name="Path 60"
                  d="M284.83,351.146a3.207,3.207,0,1,1-3.207-3.208A3.207,3.207,0,0,1,284.83,351.146Z"
                />
                <path
                  id="Path_61"
                  data-name="Path 61"
                  d="M331.71,286.053a4.676,4.676,0,0,0-.261-.983,3.723,3.723,0,0,0-1.7-2,5.331,5.331,0,0,0-2.012-.484,16.979,16.979,0,0,0-2.429-.033,19.241,19.241,0,0,1-2.338.091,12.211,12.211,0,0,1-3.514-.95,12.78,12.78,0,0,0-3.51-.971c-2.523-.2-4.311,1.726-5.829,3.5a36.918,36.918,0,0,0-4.233,6.588c-2.4,4.451-3.129,9.961,1.389,13.421,5.919,4.533,12.969-1.65,17.4-5.43a25.5,25.5,0,0,0,2.745-2.2,16.836,16.836,0,0,0,2.22-3.352,23.743,23.743,0,0,0,1.612-3.567A7.814,7.814,0,0,0,331.71,286.053Zm-20.149,14.983a7.027,7.027,0,0,1-2.663-1.077c-.54-.415.084-1.291.6-.863a6.227,6.227,0,0,0,2.329.932C312.4,300.185,312.135,301.17,311.561,301.036Zm14.115-7.81a5.255,5.255,0,0,1-.971,1.763c-1.867,2.472-4.578,5.586-7.3,7.132-.1.058-.208.107-.311.164-.735-2.539-1.658-5.054-3.988-6.552a9.934,9.934,0,0,0-6.806-1.206c.838-3.521,3.528-8.919,7.643-8.97a7.4,7.4,0,0,1,4.224,1.562c1.251.875,2.368,1.932,3.647,2.764C323.261,290.822,326.27,290.989,325.676,293.226Z"
                />
                <path
                  id="Path_62"
                  data-name="Path 62"
                  d="M316.894,305q.188-.463.4-.911c1.111-2.313,3.569-3.275,5.483-4.915,1.183-1.014,2.389-1.813,3.619-2.755.59-.451,1.075-1.133,1.691-1.51a1.116,1.116,0,0,1,1.3.026,1.441,1.441,0,0,1,.369,1c.157,2.385-1.172,4.228-2.334,6.147-1.016,1.677-2.612,2.729-3.674,4.357-1.044,1.6-.874,3.674-.665,5.573l1.053,9.571a61.535,61.535,0,0,0,1.794,8.386c1.1,4.444,1.026,9.609,1.112,14.163.087,4.571-2.935,10.051-7.685,11.01-9.544,1.928-7.467-11.554-7.723-16.97-.2-4.309.829-8.709,1.8-12.86,1.022-4.343,1.127-8.831,1.761-13.234A30.544,30.544,0,0,1,316.894,305Z"
                  fill="#fff"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <path
                  id="Path_63"
                  data-name="Path 63"
                  d="M316.861,350.321a5.668,5.668,0,0,1-.062,1.123,2.1,2.1,0,0,1-1.58,1.792,2.826,2.826,0,0,1-2.321-1.194,14.182,14.182,0,0,1-3.652-9.748,5.121,5.121,0,0,1,.708-2.933,2.108,2.108,0,0,1,2.674-.769,3.159,3.159,0,0,1,1.106,1.7q1.215,3.275,2.429,6.548A12.02,12.02,0,0,1,316.861,350.321Z"
                  fill="#fff"
                />
                <path
                  id="Path_64"
                  data-name="Path 64"
                  d="M320.24,351.152a2.31,2.31,0,0,1-1.512.448c-.4-.045-.463.585-.093.693a1.843,1.843,0,0,0,2.111-.635C320.972,351.358,320.536,350.96,320.24,351.152Z"
                />
                <path
                  id="Path_65"
                  data-name="Path 65"
                  d="M237.938,338.845a1.746,1.746,0,0,1-.588-1.505.815.815,0,0,0-.55-.97.793.793,0,0,0-.969.551,3.376,3.376,0,0,0,.992,3.039C237.561,340.655,238.678,339.543,237.938,338.845Z"
                />
                <path
                  id="Path_66"
                  data-name="Path 66"
                  d="M268,390.868q7.471,1.825,15.075,3.033"
                  fill="none"
                  stroke="#fff"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <path
                  id="Path_67"
                  data-name="Path 67"
                  d="M301.506,386.115q1.018-2.376,2.145-4.7c-6.486-3.639-12.81-7.519-19.234-11.265"
                  fill="none"
                  stroke="#fff"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <path
                  id="Path_68"
                  data-name="Path 68"
                  d="M259.7,387.54a98.881,98.881,0,0,1-13.862-4.425"
                  fill="none"
                  stroke="#fff"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}

export default function NotFound() {
  const classes = useStyles();
  return (
    <>
      <CssBaseline>
        <Grid
          className={classes.root}
          container
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h4" className={classes.iconRoot}>Sorry, page not found!</Typography>
            <Typography style={{marginTop:'20px'}}>
              Sorry, we couldn’t find the page you’re looking for. Perhaps
              you’ve mistyped the URL? Be sure to check your spelling
            </Typography>
          </Grid>
        </Grid>
        <Grid
          className={classes.iconRoot}
          container
          justify="center"
          alignItems="center"
        >
          <Grid item>
          <CreateJobIcon className={classes.icon} />
          </Grid>
        </Grid>
    
      </CssBaseline>
    </>
  );
}
