import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Chip from "@material-ui/core/Chip";
import DeleteIcon from "@material-ui/icons/Delete";
import TableChartIcon from "@material-ui/icons/TableChart";
import DoneIcon from "@material-ui/icons/Done";
import List from "@material-ui/core/List";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Radio from "@material-ui/core/Radio";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import GetAppIcon from "@material-ui/icons/GetApp";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import moment from "moment-timezone";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import Input from "@material-ui/core/Input";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { axios } from "../../utils/axiosConfig";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ReactApexChart from "react-apexcharts";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import SwipeableViews from "react-swipeable-views";
import PostCodeFind from "./PostCodeFind";
import ContactFind from "./ContactFind";
import AddPropertyForm from "./AddPropertyForm";

import { useSelector, useDispatch } from "react-redux";
import { createJobConfigurationData } from "../../Features/createJobConfigurationSlice";
import {
  setHeaderInfo,
  createJobData
} from "../../Features/createJobDataSlice";
import { useFormik } from "formik";
import { ListItemIcon } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    // background: "#000"
    // padding: "8px"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  titleDiv: {
    display: "flex"
  },
  status: {
    fontSize: 14,
    color: "#fff",
    marginTop: "2px",
    fontStyle: "italic"
  },
  statusTime: {
    marginTop: 15
  },
  pos: {
    marginBottom: 12
  },
  linearProgress: {
    marginTop: "15px"
  },
  chip: {
    color: "primary",
    height: "23px"
  },
  jobCardTitle: {
    color: "#000",
    fontWeight: 600,
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    margin: "none"
  },
  cardSubTitle: {
    fontWeight: 400,
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    // margin: '4px 0px 0px',
    color: "rgb(99, 115, 129)",
    display: "block"
  },
  jobCardPrice: {
    color: "#000",
    fontSize: "1rem",
    marginBottom: "7px",
    fontWeight: "600",
    // letterSpacing: "0.1rem",
    lineHeight: 1.5
  },
  icon: {
    color: "#fff",
    margin: "4px 4px"
  },
  oilgasIcon: {
    margin: "auto",
    marginRight: "5px",
    color: "#e8e8e8",
    width: "65px",
    height: "40px"
  },
  editIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px"
  },
  circularProgress: {
    width: "20px !important",
    height: "20px !important"
  },
  closeIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px",
    marginTop: "20px"
  },
  center: {
    height: `calc(100vh - 350px)`,
    marginTop: "15px",
    overflow: "auto",

    // background: "#000",
    // padding: 16,
    // display: "flex",
    backgroundColor: "rgb(255, 255, 255)",
    color: "#000",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "8px",
    position: "relative",
    zIndex: 0,
    // display: "flex",
    alignItems: "center",
    padding: "24px"
  },
  inputFeild: {
    color: "#fff",
    fontSize: "32px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  inputGrid: {
    paddingRight: "10px"
  },
  specifyGrid: {
    // paddingTop: "30px",
    paddingRight: "10px"
  },
  jobCardButton: {
    color: "#fff",
    // borderRadius: '60%',
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#f15a2f"
    }
  },

  jobCardButton2: {
    // color:"#f15a2f",
    // borderRadius: '60%',
    fontSize: "12px",
    fontWeight: 600
    // "&:hover": {
    //   backgroundColor: '#ffff'
    // },
    // backgroundColor:"#ffff"
  },
  cardInfo: {
    marginTop: "15px"
  },
  tablePagination: {
    // position: 'sticky'
  },
  borderLeft: {
    borderLeft: "1px solid gray"
  },
  borderRight: {
    // paddingTop: "25px",
    // paddingBottom: "15px",
    // paddingLeft: "15px"
  },
  stackRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "16px",
    marginBottom: "8px"
  },
  stackIcon: {
    width: "24px",
    height: "24px",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(84, 214, 44)",
    backgroundColor: "rgba(84, 214, 44, 0.16)"
  },
  stackIconDetail: {
    padding: 3
  },
  stackSubtitle: {
    color: "#000",
    fontWeight: "600",
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    margin: "0px 0px 0px 8px"
  },
  chartRoot: {
    margin: "0px 0px 0px auto"
  },
  toggleButtonUnselected: {
    height: "45px",
    width: "auto",
    borderRadius: "3px",
    color: "#fff",
    fontSize: "14px",
    margin: "2px",
    borderColor: "#f15a2f",
    background: "#f15a2f",

    "&:hover": {
      background: "#f15a2f"
    }
  },
  toggleButtonSelected: {
    height: "45px",
    width: "auto",
    borderRadius: "3px",
    color: "#f15a2f",
    fontSize: "14px",
    margin: "2px",
    borderColor: "#f15a2f",
    background: "#fff",

    "&:hover": {
      background: "#fff"
    }
  },

  borderRightNone: {
    borderRight: "none"
  },
  borderLeftNone: {
    borderLeft: "none"
  },
  priorityAccordian: {
    marginTop: "20px",
    marginRight: "10px",
    marginBottom: "20px",
    width: "100%",
    boxShadow: "none !important",
    border: "1px solid rgb(190, 190, 190)"
    // border: "1px solid red",
  },
  priorityErrorAccordian: {
    marginTop: "20px",
    marginRight: "10px",
    marginBottom: "20px",
    width: "100%",
    boxShadow: "none !important",
    // border: "1px solid rgb(190, 190, 190)",
    border: "1px solid red"
  },
  priorityAccordianSummary: {
    marginRight: "10px",

    width: "100%"
  },
  stickyRoot: {
    width: "100%",
    position: "relative",
    overflow: "auto",
    maxHeight: 300
  },
  radioOption: {
    cursor: "pointer",
    border: "1px solid rgba(224, 224, 224, 1)",
    borderRadius: "5px",
    marginBottom: "10px",
    "&:hover": {
      border: "2px solid #f15a2f",
      transition: "box-shadow 0.3s ease",
      boxShadow: "0 5px 10px rgb(77 83 88 / 23%)"
    }
  },
  headingMargin: {
    marginRight: "30px"
  },
  displayFlex: {
    display: "flex"
  }
});

function capitalizeFirstLetter([first, ...rest], locale = navigator.language) {
  console.log("called");
  return [first.toLocaleUpperCase(locale), ...rest].join("");
}

const HeaderInfo = forwardRef((props, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const createJobConfiguration = useSelector(createJobConfigurationData);
  const createJobFinalData = useSelector(createJobData);
  console.log(createJobConfiguration, "createJOB");
  const [zones, setZones] = useState([]);
  const [catagories, setCatagories] = useState([]);
  const [accountTypes, setAccountTypes] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [selectedTaxReference, setSelectedTaxReference] = useState(null);
  const [priorityTaxReference, setSelectedPriorityReference] = useState(null);
  const [taxAccordian, setTaxAccordian] = useState(false);
  const [priorityAccordian, setPriorityAccordian] = useState(false);
  const [dueType, setDueType] = useState(null);
  const [duePeriod, setDuePeriod] = useState(null);

  useEffect(() => {
    setZones(createJobConfiguration.zones);
    setCatagories(createJobConfiguration.categories);
    setAccountTypes(createJobConfiguration.accountTypes);
    setTaxes(createJobConfiguration.taxes);
    setPriorities(createJobConfiguration.priorities);
    formik.setFieldValue(
      "sageAccountRef",
      createJobConfiguration.sageAccountReference
    );

    let initialTaxReference = createJobConfiguration.taxes.find(
      o => o.default === true
    );
    setSelectedTaxReference(initialTaxReference);
    formik.setFieldValue("taxReference", initialTaxReference);
  }, [createJobConfiguration]);

  useEffect(() => {
    console.log(createJobFinalData, "createJobConfiguration222");
    formik.setFieldValue(
      "sageAccountRef",
      createJobConfiguration.sageAccountReference
    );
    if (createJobFinalData.headerInfo.accountType) {
      formik.setFieldValue(
        "accountType",
        createJobFinalData.headerInfo.accountType
      );
    }
    if (createJobFinalData.headerInfo.clientOrderNumber) {
      formik.setFieldValue(
        "clientOrderNumber",
        createJobFinalData.headerInfo.clientOrderNumber
      );
    }
    if (createJobFinalData.headerInfo.dateRaised) {
      formik.setFieldValue(
        "dateRaised",
        createJobFinalData.headerInfo.dateRaised
      );
    }
    if (createJobFinalData.headerInfo.dueCompelitionDate) {
      formik.setFieldValue(
        "dueCompelitionDate",
        createJobFinalData.headerInfo.dueCompelitionDate
      );
    }
    if (createJobFinalData.headerInfo.recievedDate) {
      formik.setFieldValue(
        "recievedDate",
        createJobFinalData.headerInfo.recievedDate
      );
    }
    if (createJobFinalData.headerInfo.priorityReference) {
      formik.setFieldValue(
        "priorityReference",
        createJobFinalData.headerInfo.priorityReference
      );
    }
    if (createJobFinalData.headerInfo.taxReference) {
      formik.setFieldValue(
        "taxReference",
        createJobFinalData.headerInfo.taxReference
      );

      setSelectedPriorityReference(createJobFinalData.headerInfo.priorityReference);
      setSelectedTaxReference(     createJobFinalData.headerInfo.taxReference)
    }

    
    // clientOrderNumber: "",
    //   dateRaised: moment(),
    //   accountType: "",
    //   taxReference: "",
    //   sageAccountRef: "",
    //   priorityReference: "",
    //   recievedDate: moment(),
    //   dueCompelitionDate: moment()
  }, [createJobFinalData]);

  const validate = values => {
    const errors = {};

    // if (!values.clientOrderNumber) {
    //   errors.clientOrderNumber = "Required";
    // }
    if (!values.dateRaised) {
      errors.dateRaised = "Required";
    }
    if (!values.accountType) {
      errors.accountType = "Required";
    }
    if (!values.taxReference) {
      errors.taxReference = "Required";
    }
    if (!values.sageAccountRef) {
      errors.sageAccountRef = "Required";
    }
    if (!values.priorityReference) {
      errors.priorityReference = "Required";
    }
    if (!values.recievedDate) {
      errors.recievedDate = "Required";
    }
    if (!values.dueCompelitionDate) {
      errors.dueCompelitionDate = "Required";
    }
    console.log(errors, "errors");
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      clientOrderNumber: "",
      dateRaised: moment(),
      accountType: "",
      taxReference: "",
      sageAccountRef: "",
      priorityReference: "",
      recievedDate: moment(),
      dueCompelitionDate: moment()
    },
    validateOnChange: false, // this one
    validateOnBlur: false,
    validate,
    onSubmit: values => {
      console.log(values, "headerInfoValues");
      props.dataSaved();
      dispatch(setHeaderInfo(values));
      formik.resetForm();
    }
  });

  useImperativeHandle(ref, () => ({
    saveData() {
      formik.handleSubmit();
    }
  }));

  const updateDueComplition = row => {
    setDueType(row.priorityType);
    setDuePeriod(row.priorityPeriod);
    if (row.priorityType === "HOURS") {
      formik.setFieldValue(
        "dueCompelitionDate",
        moment(formik.getFieldProps("recievedDate").value).add(
          row.priorityPeriod,
          "Hours"
        )
      );
    } else {
      formik.setFieldValue(
        "dueCompelitionDate",
        moment(formik.getFieldProps("recievedDate").value).add(
          row.priorityPeriod,
          "Days"
        )
      );
    }
  };

  const updateDueComplitionWithDate = date => {
    if (dueType === "HOURS") {
      formik.setFieldValue(
        "dueCompelitionDate",
        moment(date).add(duePeriod, "Hours")
      );
    } else if (dueType === "DAYS") {
      formik.setFieldValue(
        "dueCompelitionDate",
        moment(date).add(duePeriod, "Days")
      );
    } else {
      return;
    }
  };
  return (
    <Grid container direction="row">
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.borderRight}
      >
        <Card className={classes.center}>
          <div>
            
            <div className={classes.displayFlex}>
            <Typography
              variant="h6"
              component="h6"
              className={classes.jobCardPrice}
            >
              Header Info
            </Typography>
            {createJobFinalData.iwsAccount !== null && (
              <>
                {createJobFinalData.iwsAccount ? (
                  <Chip
                    size="small"
                    style={{
                      backgroundColor: "#e5f9ed",
                      color: "#1bc943",
                      fontWeight: 600,
                      padding: "5px",
                      marginLeft: "auto"
                    }}
                    label={"IWS Client"}
                  />
                ) : (
                  <Chip
                    size="small"
                    style={{
                      marginLeft: "auto",
                      backgroundColor: "#fff5f6",
                      color: "#f83245",
                      fontWeight: 600,
                      padding: "5px"
                    }}
                    label={"Non IWS Client"}
                  />
                )}
              </>
            )}
          </div>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.specifyGrid}
              >
                <TextField
                  //   value={this.state.title}
                  label="Client Order Number"
                  className={classes.inputFeild}
                  margin="normal"
                  //   onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                  id="clientOrderNumber"
                  name="clientOrderNumber"
                  onChange={formik.handleChange}
                  value={formik.values.clientOrderNumber}
                  error={formik.errors.clientOrderNumber}
                  helperText={formik.errors.clientOrderNumber}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.specifyGrid}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    fullWidth
                    label="Date Raised"
                    //   value={deliveryBy}
                    //   onChange={this.handleByDateChange}

                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    className={classes.inputFeild}
                    onChange={date => {
                      formik.setFieldValue("dateRaised", date); // Access it from props
                    }}
                    id="dateRaised"
                    name="dateRaised"
                    value={formik.values.dateRaised}
                    error={formik.errors.dateRaised}
                    helperText={formik.errors.dateRaised}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.specifyGrid}
              >
                <Autocomplete
                  options={accountTypes}
                  getOptionLabel={option => option}
                  //  onChange={(event, value) =>
                  //    this.handleDriverChange(event, value)
                  //  }
                  fullWidth
                  margin="normal"
                  className={classes.inputFeild}
                  onChange={(event, value) => {
                    formik.setFieldValue("accountType", value); // Access it from props
                  }}
                  id="accountType"
                  name="v"
                  value={formik.values.accountType}
                  error={formik.errors.accountType}
                  helperText={formik.errors.accountType}
                  renderInput={params => (
                    <TextField
                      //  value={this.state.driverName}
                      className={classes.inputFeild}
                      {...params}
                      fullWidth
                      error={formik.errors.accountType}
                      helperText={formik.errors.accountType}
                      label="Account Type"
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.specifyGrid}
              >
                <TextField
                  label="Sage Account Ref"
                  className={classes.inputFeild}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  InputProps={{readOnly: true}}
                  id="sageAccountRef"
                  name="sageAccountRef"
                  onChange={formik.handleChange}
                  value={formik.values.sageAccountRef}
                  error={formik.errors.sageAccountRef}
                  helperText={formik.errors.sageAccountRef}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Accordion
                expanded={taxAccordian}
                onClick={() => setTaxAccordian(!taxAccordian)}
                className={
                  formik.errors.taxReference
                    ? classes.priorityErrorAccordian
                    : classes.priorityAccordian
                }
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                >
                  <Typography className={classes.headingMargin}>
                    Tax Reference
                  </Typography>{" "}
                  <ListItemIcon>
                    <ArrowForwardIcon />
                  </ListItemIcon>
                  <Typography>
                    {selectedTaxReference && selectedTaxReference.taxReference}
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}
                </AccordionSummary>
                <AccordionDetails className={classes.priorityAccordianSummary}>
                  <List className={classes.stickyRoot} subheader={<li />}>
                    {taxAccordian &&
                      taxes.map(row => (
                        <ListItem
                          className={classes.radioOption}
                          onClick={() => {
                            setSelectedTaxReference(row);
                            setTaxAccordian(false);
                            formik.setFieldValue("taxReference", row);
                          }}
                        >
                          <ListItemIcon>
                            <ReceiptIcon />
                          </ListItemIcon>

                          <ListItemText
                            primary={row.taxReference}
                            secondary="Tax Reference"
                          />
                          <ListItemText
                            primary={row.taxRate}
                            secondary="Tax Rate"
                          />
                        </ListItem>
                      ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid container>
              <Accordion
                expanded={priorityAccordian}
                onClick={() => setPriorityAccordian(!priorityAccordian)}
                className={
                  formik.errors.priorityReference
                    ? classes.priorityErrorAccordian
                    : classes.priorityAccordian
                }
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                >
                  <Typography className={classes.heading}></Typography>
                  <Typography className={classes.headingMargin}>
                    Priority Reference
                  </Typography>{" "}
                  <ListItemIcon>
                    <ArrowForwardIcon />
                  </ListItemIcon>
                  <Typography>
                    {priorityTaxReference &&
                      priorityTaxReference.priorityReference}
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}
                </AccordionSummary>
                <AccordionDetails className={classes.priorityAccordianSummary}>
                  <List className={classes.stickyRoot} subheader={<li />}>
                    {priorityAccordian &&
                      priorities.map(row => (
                        <ListItem
                          className={classes.radioOption}
                          onClick={() => {
                            setSelectedPriorityReference(row);
                            setPriorityAccordian(false);
                            formik.setFieldValue("priorityReference", row);
                            updateDueComplition(row);
                          }}
                        >
                          <ListItemIcon>
                            <ReceiptIcon />
                          </ListItemIcon>

                          <ListItemText
                            primary={capitalizeFirstLetter(
                              row.priorityReference.toLowerCase()
                            )}
                            secondary="Priority Reference"
                          />
                          <ListItemText
                            primary={row.priorityPeriod}
                            secondary="Priority Period"
                          />
                          <ListItemText
                            primary={row.priorityType}
                            secondary="Priority Type"
                          />
                        </ListItem>
                      ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.specifyGrid}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    fullWidth
                    label="Recieved Date "
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    className={classes.inputFeild}
                    onChange={date => {
                      formik.setFieldValue("recievedDate", date);
                      updateDueComplitionWithDate(date);
                      // Access it from props
                    }}
                    id="recievedDate"
                    name="recievedDate"
                    value={formik.values.recievedDate}
                    error={formik.errors.recievedDate}
                    helperText={formik.errors.recievedDate}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.specifyGrid}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    margin="normal"
                    label="Recieved Time"
                    ampm={true}
                    KeyboardButtonProps={{
                      "aria-label": "change time"
                    }}
                    variant="inline"
                    // format="dd/MM/yyyy"

                    fullWidth
                    //   value={deliveryBy}
                    //   onChange={this.handleByDateChange}

                    className={classes.inputFeild}
                    onChange={date => {
                      formik.setFieldValue("recievedDate", date);
                      updateDueComplitionWithDate(date);
                    }}
                    id="recievedDate"
                    name="recievedDate"
                    value={formik.values.recievedDate}
                    error={formik.errors.recievedDate}
                    helperText={formik.errors.recievedDate}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.specifyGrid}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    fullWidth
                    disabled
                    label="Due Compelition Date"
                    //   value={deliveryBy}
                    //   onChange={this.handleByDateChange}

                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    className={classes.inputFeild}
                    id="dueCompelitionDate"
                    name="dueCompelitionDate"
                    value={formik.values.dueCompelitionDate}
                    error={formik.errors.dueCompelitionDate}
                    helperText={formik.errors.dueCompelitionDate}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.specifyGrid}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    margin="normal"
                    ampm={true}
                    disabled
                    label="Due Completion Time"
                    variant="inline"
                    // format="dd/MM/yyyy"

                    fullWidth
                    //   value={deliveryBy}
                    //   onChange={this.handleByDateChange}

                    className={classes.inputFeild}
                    id="dueCompelitionDate"
                    name="dueCompelitionDate"
                    value={formik.values.dueCompelitionDate}
                    error={formik.errors.dueCompelitionDate}
                    helperText={formik.errors.dueCompelitionDate}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
});

export default HeaderInfo;
