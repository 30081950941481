import React, { PureComponent, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import FormControl from "@material-ui/core/FormControl";
import CardContent from "@material-ui/core/CardContent";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import PageviewIcon from "@material-ui/icons/Pageview";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ListItemText from "@material-ui/core/ListItemText";
import { ListItemIcon } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { axios } from "../../utils/axiosConfig";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import RoomIcon from "@material-ui/icons/Room";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ConfirmedSnackBar from "../../utils/ConfirmedSnackbar";
import { UserContextData, UserContext } from "../../Contexts/userContext";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import DateFnsUtils from "@date-io/date-fns";
import selectedMap from "../../images/map_updated.png";

import Card from "@material-ui/core/Card";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import {
  setJobConfiguration,
  setOperators,
  setJobItems,
  resetConfiguration,
} from "../../Features/createJobConfigurationSlice";
import { Typography } from "@material-ui/core";

const styles = {
  findCalendarCSS: {
    marginLeft: "10px",
    color: "#fff",
    fontWeight: 600,
    background: "#f15a2f",
    width: "250px",
    height: "53px",

    marginTop: "17px",

    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#f15a2f",
      color: "#fff",
    },
    // float: "right"
  },
  textField: {
    // width: "100%",
    marginLeft: "5px",
    marginRight: "5px",
    marginBottom: "15px",
  },

  grid: {
    padding: "16px",
  },

  datePicker: {
    selectColor: "black",
  },

  formControl: {
    width: "65%",
  },
  updateJob: {
    color: "#f15a2f",
    // background: "#fff",
    borderColor: "#f15a2f",
    width: "auto",
    height: "53px",
    // marginTop: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#f15a2f",
    },
  },
  center: {
    // background: "#000",
    margin: "20px 16px 16px 16px",
    // display: "flex",
    backgroundColor: "rgb(255, 255, 255)",
    color: "#000",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "8px",
    position: "relative",
    zIndex: 0,
    alignItems: "center",
    padding: "24px",
  },

  card: {
    width: "100%",
    backgroud: "#000; !important",
  },

  showCircularProgressStyle: {
    margin: "auto",
    width: "fit-content",
  },

  tableHeading: {
    padding: "15px",
    fontSize: "20px",
    color: "#fff",
    display: "flex",
    background: "#000;",
  },
  tableBold: {
    fontWeight: 600,
    fontSize: "17px",
    color: "#000;",
  },

  gridHere: {
    // borderRight:"1px solid #000;"
  },

  editIcon: {
    color: "#fff",
    fontSize: "16px",
    cursor: "pointer",
    // marginLeft: "20px",
    // marginBottom: "15px"
  },

  expandIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    // marginLeft: "20px",
    // marginBottom: "15px"
  },

  inputFeild: {
    color: "#000;",
    fontSize: "16px",
    width: "380px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  inputFeildHaulage: {
    color: "#000;",
    fontSize: "20px",
    background: "none",
    width: "40px",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  inputFeildHaulageHovered: {
    color: "#fff",
    fontSize: "20px",
    background: "none",
    width: "40px",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },

  startAutoJob: {
    color: "#fff",
    background: "#000;",
    width: "auto",
    height: "53px",
    marginTop: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#000;",
      color: "#fff",
    },
  },
  startCron: {
    marginLeft: "auto",
    marginTop: "auto",
    marginBottom: "auto",
  },

  circularProgressCenter: {
    margin: "auto",
  },
  autoCircularProgress: {
    margin: "auto",
  },
  circularStyle: {
    width: "100%",
    margin: "auto",
    marginTop: "15%",
    marginBottom: "15%",
  },
  circularProgressBase: {
    width: "100%",
    height: "75vh",
  },
  circularProgressBase2: {
    width: "fit-content",
    padding: "20%",
    margin: "auto",
  },
  snackbar: {
    backgroundColor: "rgb(50, 50, 50)",
  },
  fullWidth: {
    width: "100%",
  },
  close: {
    color: "#fff",
  },
  menuButtonsIconPrimary: {
    marginLeft: "auto",
    color: "#fff",
    cursor: "pointer",
    // height: "50px"
  },
  dialogContent: {
    margin: "10px",
  },
  newCronTimePicker: {
    marginTop: "15px",
  },
  headerStyle: {
    display: "flex",
    width: "100%",
  },
  autoComplete: {
    width: "20%",
    marginRight: "10px",
    marginTop: "15px",
    /* display: flex; */

    display: "inline-flex",
    position: "relative",
    minWidth: 0,
    flexDirection: "column",
    verticalAlign: "top",
  },
  subHeading: {
    fontSize: "15px",
    fontWeight: 600,
  },
  noDataFoundText: {
    color: "#000",
    fontSize: "1rem",
    marginBottom: "7px",
    marginTop: "7px",
    margin: "auto",
    textAlign: "center",
    fontWeight: "600",
    // letterSpacing: "0.1rem",
    lineHeight: 1.5,
  },
  dateSearch: {
    display: "flex",
  },
  jobCardPrice: {
    width: "50%",
    color: "#000",
    fontSize: "1rem",
    marginTop: "25px",
    fontWeight: "600",
    // letterSpacing: "0.1rem",
    lineHeight: 1.5,
  },
  searchIconDiv: {
    marginTop: "15px",
  },

  searchIcon5: {
    padding: "5px",
    color: "rgba(224, 224, 224, 5)",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  displayFlex: {
    display: "flex",
  },
  displayFlex2: {
    width: "100%",
    display: "flex",
  },
  centerAlign: {
    // marginLeft:'auto'
    textAlign: "center",
  },
  gridMargin: {
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: "30px",
    display: "flex",
  },
  stickyRoot: {
    width: "100%",
    position: "relative",
    overflow: "auto",
    // maxHeight: 300
  },
  radioOption: {
    cursor: "pointer",
    border: "1px solid rgba(224, 224, 224, 1)",
    borderRadius: "5px",
    marginBottom: "10px",
    "&:hover": {
      border: "2px solid #f15a2f",
      transition: "box-shadow 0.3s ease",
      boxShadow: "0 5px 10px rgb(77 83 88 / 23%)",
    },
  },
  searchIcon: {
    padding: "5px",
    color: "rgba(224, 224, 224, 5)",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "#000",
  },
  image: {
    minWidth: "65%",
    height: "100%",
  },
  inputInput: {
    color: "#000",
    padding: "8px, 8px, 8px, 8px",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + 32px)`,
    width: "100%",
  },
  search: {
    cursor: "pointer",
    border: "1px solid rgba(224, 224, 224, 1)",
    borderRadius: "5px",
    marginBottom: "10px",
    "&:hover": {
      border: "2px solid #f15a2f",
      // transition: "box-shadow 0.3s ease",
      // boxShadow: "0 5px 10px rgb(77 83 88 / 23%)"
    },
    marginLeft: "auto",

    backgroundColor: alpha("#fff", 0.15),

    width: "200px",
  },
};

class ItemsPrice extends PureComponent {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      openSnackbar: false,
      snackBarMessage: null,
      showCircularProgress: false,
      items: [],
      filteredItems: [],
      jobListData: [],
      jobListFilteredData: [],
      allDrivers: [],
      open: false,
      selectedDate: new Date(),
    };
  }

  componentDidMount() {
    this.itemPrices();
  }

  itemPrices = () => {
    const { logoutUser } = this.props;
    this.setState({ showCircularProgress: true });
    const url = "job/items/zone";
    axios
      .get(url)
      .then((res) => {
        let response = res.data;
        if (response.success === true) {
          console.log("itemsssssssssherreeeeokkkk", response);
          this.setState({ filteredItems: response.data.jobItems });
          this.setState({ items: response.data.jobItems });
        } else {
        }
        this.setState({ showCircularProgress: false });
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };

  showSnackBar = (message) => {
    this.setState({ openSnackbar: true, snackBarMessage: message });
  };
  handleSnackBarClose = () => {
    this.setState({ openSnackbar: false });
  };

  handleSearch = (event) => {
    let data = this.state.items;
    const newData = data.filter((item) => {
      if (!event.target.value) {
        return item;
      }
      if (
        item.sorReference
          .toString()
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      ) {
        return item;
      }
    });
    this.setState({ filteredItems: newData });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  handleImageOpen = () => {
    this.setState({ open: true });
  };
  render() {
    const { classes } = this.props;
    const {
      openSnackbar,
      selectedDate,
      showCircularProgress,
      snackBarMessage,
      filteredItems,
      open,
      events,
      allDrivers,
    } = this.state;
    return (
      <>
        <Grid container direction="row" alignItems="center">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.borderRight}
          >
            <Card className={classes.center}>
              {showCircularProgress && (
                <div className={classes.circularProgressBase}>
                  <div className={classes.circularProgressBase2}>
                    <CircularProgress
                      size={35}
                      className={classes.showCircularProgressStyle}
                    />
                  </div>
                </div>
              )}
              {!showCircularProgress && (
                <Grid
                  className={classes.selectSORGrid}
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <div className={classes.displayFlex2}>
                    <div className={classes.displayFlex2}>
                      <Typography
                        variant="h6"
                        component="h6"
                        className={classes.jobCardPrice}
                      >
                        SORs
                      </Typography>
                      <Button
                        variant="outlined"
                        startIcon={<RoomIcon />}
                        className={classes.updateJob}
                        // disabled={createJobProgress}
                        onClick={() => this.handleImageOpen()}
                      >
                        View Map
                      </Button>
                    </div>

                    <div className={classes.search}>
                      <div className={classes.searchIcon5}>
                        <SearchIcon />
                      </div>
                      <InputBase
                        onChange={(e) => this.handleSearch(e)}
                        placeholder="Search…"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        inputProps={{ "aria-label": "search" }}
                      />
                    </div>
                  </div>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.gridMargin}
                    >
                      <List className={classes.stickyRoot} subheader={<li />}>
                        {filteredItems.map((row) => (
                          <ListItem className={classes.radioOption}>
                            <Grid container>
                              <Grid lg={2} className={classes.displayFlex}>
                                <ListItemIcon>
                                  <ListAltIcon style={{ marginTop: 15 }} />
                                </ListItemIcon>
                                <ListItemText
                                  primary={row.fullReference}
                                  secondary="Full Reference"
                                />
                              </Grid>
                              <Grid lg={2}>
                                <ListItemText
                                  primary={row.descriptionOne}
                                  secondary="Description 1"
                                />
                              </Grid>

                              <Grid lg={1}>
                                <ListItemText
                                  className={classes.centerAlign}
                                  primary={row.descriptionTwo}
                                  secondary="Description 2"
                                />
                              </Grid>

                              <Grid lg={1}>
                                <ListItemText
                                  className={classes.centerAlign}
                                  primary={row.unit}
                                  secondary="Unit"
                                />
                              </Grid>

                              <Grid lg={2}>
                                <ListItemText
                                  className={classes.centerAlign}
                                  primary={row.rates[0].rate}
                                  secondary={row.rates[0].zoneName + " / Rate"}
                                />
                                <ListItemText
                                  className={classes.centerAlign}
                                  secondary={
                                    row.rates[0].rateWithoutVat + " - Pre VAT"
                                  }
                                />
                              </Grid>

                              <Grid lg={2}>
                                <ListItemText
                                  className={classes.centerAlign}
                                  primary={row.rates[1].rate}
                                  secondary={row.rates[1].zoneName + " / Rate"}
                                />
                                <ListItemText
                                  className={classes.centerAlign}
                                  secondary={
                                    row.rates[1].rateWithoutVat + " - Pre VAT"
                                  }
                                />
                              </Grid>

                              <Grid lg={2}>
                                <ListItemText
                                  className={classes.centerAlign}
                                  primary={row.rates[2].rate}
                                  secondary={row.rates[2].zoneName + " / Rate"}
                                />
                                <ListItemText
                                  className={classes.centerAlign}
                                  secondary={
                                    row.rates[0].rateWithoutVat + " - Pre VAT"
                                  }
                                />
                              </Grid>
                            </Grid>

                            {/* </ListItemAvatar> */}
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Card>
          </Grid>
        </Grid>
        <ConfirmedSnackBar
          open={openSnackbar}
          message={snackBarMessage}
          handleClose={this.handleSnackBarClose}
          classes={classes}
        />
        <Dialog
          classes={{ paper: classes.image }}
          // className={classes.image}
          open={open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Zone Map"}</DialogTitle>
          <DialogContent classes={{ paper: classes.image }}>
            <img
              src={selectedMap}
              height="100%"
              width="100%"
              alt=""
              className={classes.image}
            ></img>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(ItemsPrice);
