// set axios interceptors to attach token to every request made to a specific domain only 

import axios  from 'axios'
import { userToken } from './constants';
import {UserTokenData} from '../Contexts/userContext'
import {API_URL} from './constants';
const instance = axios.create({
    baseURL: `${API_URL}/rest/api/`
})

instance.interceptors.request.use(function (config) {

    if(config.baseURL === `${API_URL}/rest/api/` && config.url  !== '/user/login') {
        const userTokenData: UserTokenData = JSON.parse('' + localStorage.getItem(userToken))
        config.headers.Authorization = `Bearer ${userTokenData.token}`
    }
    return config;
});




export {
    instance as axios
}