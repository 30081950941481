import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accountTypes: [],
  categories: [],
  priorities: [],
  sageAccountReference: "",
  taxes: [],
  types: [],
  zones: [],
  jobItems: [],
  operators: [],
  
};

export const createJobConfiguration = createSlice({
  name: "createJobConfiguration",
  initialState,
  reducers: {
    hydrate: (state, action) => {
      // do not do state = action.payload it will not update the store
      return action.payload;
    },
    setJobConfiguration: (state, action) => {
      console.log(action.payload, "uservalue");
      state.accountTypes = action.payload.accountTypes;
      state.categories = action.payload.categories;
      state.priorities = action.payload.priorities;
      state.sageAccountReference = action.payload.sageAccountReference;
      state.taxes = action.payload.taxes;
      state.types = action.payload.types;
      state.zones = action.payload.zones;
    },
    setJobItems: (state, action) => {
      state.jobItems = action.payload.jobItems;
    },
    setUpdatedJobItems: (state, action) => {
      state.jobItems = action.payload;
    },
    setOperators: (state, action) => {
      state.operators = action.payload;
    },
   
    resetConfiguration: (state, action) => {
      state.accountTypes = [];
      state.categories = [];
      state.priorities = [];
      state.sageAccountReference = "";
      state.taxes = [];
      state.types = [];
      state.zones = [];
      state.jobItems = [];
      state.operators = [];
    }
  }
});

export const {
  setJobConfiguration,
  resetConfiguration,
  setJobItems,
  setUpdatedJobItems,
  
  setOperators
} = createJobConfiguration.actions;
export const createJobConfigurationData = state => state.createJobConfiguration;
export default createJobConfiguration.reducer;
