import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useContext,
  useImperativeHandle,
} from "react";
import {
  IS_PROD,
  PAYMENAT_SUCCESS_URL,
  PAYMENAT_FAILURE_URL,
} from "../../utils/constants";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { UserContextData, UserContext } from "../../Contexts/userContext";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import Chip from "@material-ui/core/Chip";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import TableChartIcon from "@material-ui/icons/TableChart";
import DoneIcon from "@material-ui/icons/Done";
import Radio from "@material-ui/core/Radio";
import GetAppIcon from "@material-ui/icons/GetApp";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tabs from "@material-ui/core/Tabs";
import Table from "@material-ui/core/Table";
import Tab from "@material-ui/core/Tab";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Paper from "@material-ui/core/Paper";
import moment from "moment-timezone";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import Input from "@material-ui/core/Input";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { axios } from "../../utils/axiosConfig";

import ic_mastercard from "../../images/ic_mastercard.svg";
import ic_visa from "../../images/ic_visa.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  setProperties,
  addJobItem,
  setCompleteStatus,
  setJobID,
  editJobData,
  resetAll,
  setPayments,
} from "../../Features/editJobDataSlice";

const useStyles = makeStyles({
  root: {
    // background: "#000"
    // padding: "8px"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  titleDiv: {
    display: "flex",
  },
  status: {
    fontSize: 14,
    color: "#fff",
    marginTop: "2px",
    fontStyle: "italic",
  },
  statusTime: {
    marginTop: 15,
  },
  pos: {
    marginBottom: 12,
  },
  linearProgress: {
    marginTop: "15px",
  },
  chip: {
    color: "primary",
    height: "23px",
  },
  jobCardTitle: {
    color: "#000",
    fontWeight: 600,
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    margin: "none",
  },
  cardSubTitle: {
    fontWeight: 400,
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    // margin: '4px 0px 0px',
    color: "rgb(99, 115, 129)",
    display: "block",
  },
  jobCardPrice: {
    color: "#000",
    fontSize: "1rem",
    marginBottom: "7px",
    fontWeight: "600",
    // letterSpacing: "0.1rem",
    lineHeight: 1.5,
  },

  oilgasIcon: {
    margin: "auto",
    marginRight: "5px",
    color: "#e8e8e8",
    width: "65px",
    height: "40px",
  },
  editIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px",
  },
  circularProgress: {
    width: "20px !important",
    height: "20px !important",
  },
  closeIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px",
    marginTop: "20px",
  },
  center: {
    height: `calc(100vh - 350px)`,
    marginTop: "15px",
    // background: "#000",
    // padding: 16,
    // display: "flex",
    backgroundColor: "rgb(255, 255, 255)",
    color: "#000",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "8px",
    position: "relative",
    zIndex: 0,
    // display: "flex",
    alignItems: "center",
    padding: "24px",
  },
  inputFeild: {
    color: "#fff",
    fontSize: "32px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  jobCardButton: {
    color: "#fff",
    // borderRadius: '60%',
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#f15a2f",
    },
  },

  jobCardButton2: {
    // color:"#f15a2f",
    // borderRadius: '60%',
    fontSize: "12px",
    fontWeight: 600,
    // "&:hover": {
    //   backgroundColor: '#ffff'
    // },
    // backgroundColor:"#ffff"
  },
  cardInfo: {
    marginTop: "15px",
  },
  tablePagination: {
    // position: 'sticky'
  },
  borderLeft: {
    borderLeft: "1px solid gray",
  },
  borderRight: {
    // paddingTop: "25px",
    // paddingBottom: "15px",
    // paddingLeft: "15px"
  },
  stackRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "16px",
    marginBottom: "8px",
  },
  stackIcon: {
    width: "24px",
    height: "24px",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(84, 214, 44)",
    backgroundColor: "rgba(84, 214, 44, 0.16)",
  },
  stackIconDetail: {
    padding: 3,
  },
  stackSubtitle: {
    color: "#000",
    fontWeight: "600",
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    margin: "0px 0px 0px 8px",
  },
  chartRoot: {
    margin: "0px 0px 0px auto",
  },
  toggleButtonUnselected: {
    height: "40px",
    width: "auto",
    borderRadius: "3px",
    color: "#fff",
    fontSize: "14px",
    margin: "2px",
    borderColor: "#f15a2f",
    background: "#f15a2f",

    "&:hover": {
      background: "#f15a2f",
    },
  },
  toggleButtonSelected: {
    height: "40px",
    width: "auto",
    borderRadius: "3px",
    color: "#f15a2f",
    fontSize: "14px",
    margin: "2px",
    borderColor: "#f15a2f",
    background: "#fff",

    "&:hover": {
      background: "#fff",
    },
  },

  borderRightNone: {
    borderRight: "none",
  },
  borderLeftNone: {
    borderLeft: "none",
  },
  paymentOptionDiv: {
    marginTop: "15px",
    display: "flex",
    alignItems: "center",
    padding: "20px",
    // justifyContent: "space-between",
    borderRadius: "8px",
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    border: "1px solid rgba(145, 158, 171, 0.32)",
    // flexWrap: "wrap"
  },
  selectedPaymentOptionDiv: {
    marginTop: "15px",
    display: "flex",
    alignItems: "center",
    padding: "20px",
    // justifyContent: "space-between",
    borderRadius: "8px",
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    border: "1px solid rgba(241, 89, 47, 0.72)",
    // flexWrap: "wrap",
    // border: 1px solid rgba(145, 158, 171, 0.32);
    boxShadow: "rgb(145 158 171 / 24%) 0px 8px 16px 0px",
    width: "100%",
  },
  paymentRadio: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    "-webkit-tap-highlight-color": "transparent",
    backgroundColor: "transparent",
    outline: "0px",
    border: "0px",
    margin: "0px",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    appearance: "none",
    textDecoration: "none",
    borderRadius: "50%",
    color: "rgb(99, 115, 129)",
    padding: "8px",
    width: "100%",
  },
  formControlLabelPayment: {
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    fontWeight: 400,
  },
  paymentHeadingRadio: {
    margin: "0px",
    marginLeft: "10px",
    fontWeight: "600",
    lineHeight: "1.57143",
    fontSize: "0.875rem",
  },
  paymentHeadingSubRadio: {
    margin: "0px",
    marginLeft: "10px",
    fontWeight: "400",
    lineHeight: "1.57143",

    fontSize: "0.875rem",
    color: "rgb(99, 115, 129)",
  },
  paymentContent: {
    padding: "24px",
  },
  icon: {
    borderRadius: "50%",
    width: 22,
    height: 22,
    marginRight: "2px",
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },

  fullWidth: {
    width: "100%",
  },
  checkRadioIcon: {
    color: "#f15a2f",
  },
  rightAlign: {
    marginLeft: "auto",
  },
  tableContainer: {
    maxHeight: "400px",
    overflow: "auto",
  },
  tableHeading: {
    padding: "15px",
    fontSize: "20px",
    color: "#fff",
    display: "flex",
    background: "#000;",
  },
  paymentReceived: {
    width: "100%",
    marginTop: "50px",
  },
  accordianMain: {
    boxShadow: "none",
    width: "100%",
  },
  accordianSummary: {
    padding: "0px",
    minHeight: "fit-content",
    width: "100%",
  },
  payButton: {
    color: "#fff",
    fontWeight: 600,
    background: "#f15a2f",
    width: "150px",
    height: "43px",
    marginRight: "25px",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "#f15a2f",
      color: "#fff",
    },
    float: "right",
  },
  refreshDialogButton: {
    color: "#fff",
    background: "#f15a2f",
    width: "100px",
    height: "53px",
    marginTop: "17px",
    marginBottom: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#f15a2f",
      color: "#fff",
    },
  },
  cancelJob: {
    color: "#000",
    // background: "#f44336",
    width: "100px",
    height: "53px",
    marginTop: "17px",
    marginBottom: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#000",
      color: "#fff",
    },
  },
  txIDStyle: {
    width: "100%",
    marginLeft: "20px",
    marginRight: "20px",
  },
});

const Payments = forwardRef((props, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const { user, logoutUser } = useContext(UserContext);
  const [value, setValue] = React.useState("CARD");
  const [paymentReceived, setPaymentReceived] = React.useState(false);
  const createJobFormData = useSelector(editJobData);
  const [jobItems, setJobItems] = React.useState([]);
  const [taxRate, setTaxRate] = useState(0);
  const [properties, setProperties] = useState({});
  const [headerInfo, setHeaderInfo] = useState({});
  const [billingInfo, setBillingInfo] = useState({});
  const [jobItemsData, setJobItemsData] = useState([]);
  const [payOpen, setPayOpen] = React.useState(false);
  const [paymentProgress, setPaymentProgress] = useState(false);
  const [createJobProgress, setCreateJobProgress] = useState(false);
  const [paymentsData, setPaymentsData] = useState({});
  const [operatorData, setOperatorsData] = useState({});
  const [clientSpecifcations, setClientSpecifcations] = useState({});
  const [jobID, setJobID] = useState("");
  const [txID, setTXID] = useState("");

  console.log(createJobFormData.payments, "createJobConfiguration222");
  useEffect(() => {
    console.log(createJobFormData.payments, "createJobConfiguration222");
    setProperties(createJobFormData.properties);
    setBillingInfo(createJobFormData.billingInfo);
    setHeaderInfo(createJobFormData.headerInfo);
    setClientSpecifcations(createJobFormData.clientSpecifcations);
    setJobItemsData(createJobFormData.jobItems);
    setOperatorsData(createJobFormData.operator);
    setPaymentsData(createJobFormData.payments);
    setJobID(createJobFormData.jobID);
  }, [createJobFormData]);

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "Required";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      cardNumber: "",
      expiry: "",
      cvv: "",
    },
    validateOnChange: false, // this one
    validateOnBlur: false,
    validate,
    onSubmit: (values) => {
      formik.resetForm();
    },
  });

  const handlePayment = () => {
    setPayOpen(true);
  };

  const invoiceTotalBody = () => {
    if (headerInfo.taxReference && jobItems.length > 0) {
      const subtotal = jobItems
        .map(({ unitTotal }) => unitTotal)
        .reduce((sum, i) => sum + i, 0);

      return subtotal;
    } else {
      return 0;
    }
  };

  const invoiceTotalVat = () => {
    if (headerInfo.taxReference && jobItems.length > 0) {
      const subtotal = jobItems
        .map(({ vatAmount }) => vatAmount)
        .reduce((sum, i) => sum + i, 0);

      return subtotal
    } else {
      return 0;
    }
  };

  const invoiceTotalAmount = () => {
    if (headerInfo.taxReference && jobItemsData.length > 0) {
      const subtotal = jobItemsData
        .map(({ unitTotal }) => unitTotal)
        .reduce((sum, i) => sum + i, 0);

      return subtotal;
    } else {
      return 0;
    }
  };

  const handlePay = () => {
    const body2 = {
      sageAccountReference: properties.sageAccountRef
        ? properties.sageAccountRef
        : "",
      clientOrderNo: headerInfo.clientOrderNumber
        ? headerInfo.clientOrderNumber
        : "",
      updatedBy: `${user.firstName} ${user.lastName}`,
      iwsClientID: properties.iwsClientID ? properties.iwsClientID : -1,
      iwsClientAccountReference: properties.iwsClientAccountReference
        ? properties.iwsClientAccountReference
        : "N/A",
      property: {
        ID: properties.propertyID ? properties.propertyID : "",
        number: properties.propertyNumber ? properties.propertyNumber : "",
        contact: properties.contact ? properties.contact : "",
        reference: properties.propertyReference
          ? properties.propertyReference
          : "",
        name: properties.propertyName ? properties.propertyName : "",
        address: properties.address1 ? properties.address1 : "",
        town: properties.town ? properties.town : "",
        county: properties.county ? properties.county : "",
        postcode: properties.postcode ? properties.postcode : "",
        mobile: properties.mobile ? properties.mobile : "",
        fax: properties.fax ? properties.fax : "",
        telephone: properties.telephone ? properties.telephone : "",
        email: properties.email ? properties.email : "",
        zoneID: properties.zone ? properties.zone.zoneID : "",
        zone: properties.zone ? properties.zone.zoneName : "",
        type: properties.type ? properties.type : "",
        category: properties.catagory ? properties.catagory : "",
      },
      descriptionOfWork: clientSpecifcations.descriptionOfWork
        ? clientSpecifcations.descriptionOfWork
        : "",
      siteContact: clientSpecifcations.siteContacts
        ? clientSpecifcations.siteContacts
        : "",
      SpecialNotes: clientSpecifcations.specialInstructions
        ? clientSpecifcations.specialInstructions
        : "",
      priorityID: headerInfo.priorityReference
        ? headerInfo.priorityReference.priorityID
        : "",
      priorityDescription: headerInfo.priorityReference
        ? headerInfo.priorityReference.priorityReference
        : "",
      daysOrHours: headerInfo.priorityReference
        ? headerInfo.priorityReference.priorityType === "DAYS"
          ? "D"
          : "H"
        : "",
      timeQuantity: headerInfo.priorityReference
        ? headerInfo.priorityReference.priorityPeriod
        : "",
      requestedCompletionDate: headerInfo.dueCompelitionDate
        ? moment(headerInfo.dueCompelitionDate)
            .tz("Europe/London")
            .format("YYYY-MM-DD")
        : "",
      requestedCompletionTime: headerInfo.dueCompelitionDate
        ? moment(headerInfo.dueCompelitionDate)
            .tz("Europe/London")
            .format("HH:mm:ss")
        : "",
      totalNumberOfItems: jobItemsData.length,
      vatRate: headerInfo.taxReference ? headerInfo.taxReference.taxRate : "",
      vatID: headerInfo.taxReference ? headerInfo.taxReference.taxID : "",
      vatRef: headerInfo.taxReference
        ? headerInfo.taxReference.taxReference
        : "",
      totalNet: invoiceTotal(),
      totalVAT: invoiceTotalVat(),
      totalAmount: invoiceTotalAmount(),

      accountType: headerInfo.accountType ? headerInfo.accountType : "",
      currentStatus: createJobFormData.currentStatus,
      jobItems: jobItemsData,
      invoiceAddressDifferent: createJobFormData.invoiceAddressDifferent,
      invoice: {
        contact: billingInfo.contact ? billingInfo.contact : "",
        name: billingInfo.propertyReference
          ? billingInfo.propertyReference
          : "",
        address: billingInfo.address1 ? billingInfo.address1 : "",
        town: billingInfo.town ? billingInfo.town : "",
        county: billingInfo.county ? billingInfo.county : "",
        postcode: billingInfo.postcode ? billingInfo.postcode : "",
        mobile: billingInfo.mobile ? billingInfo.mobile : "",
        telephone: billingInfo.telephone ? billingInfo.telephone : "",
        email: billingInfo.email ? billingInfo.email : "",
      },
      paymentType: value == "PRE" ? "CARD" : value,
      preAuthPaymentTransID: txID,
    };
    const url = `update/job/` + createJobFormData.jobID;
    axios
      .put(url, body2)
      .then((res) => {
        let response = res.data;
        if (response.success === true) {
          handleCreateJobAndPay();
        } else {
          setCreateJobProgress(false);
          setPayOpen(false);
          props.showSnackBar("Unable to update job");
        }
      })
      .catch(function (error) {
        setCreateJobProgress(false);
        setPayOpen(false);
        props.showSnackBar("Unable to update job");
        if (error.response.status === 403) {
          // logoutUser();
        }
      });
  };

  const handleCreateJobAndPay = () => {
    setCreateJobProgress(true);
    const url = "payment/encrypt";
    const body = {
      crmNumber: properties.fax ? properties.fax : "N/A",
      totalNet: invoiceTotalBody(),
      totalVAT: invoiceTotalVat(),
      jobID: jobID,
      amount: invoiceTotal(),
      taxID: headerInfo.taxReference
        ? headerInfo.taxReference.taxReference
        : "",
      successURL: PAYMENAT_SUCCESS_URL,
      failureURL: PAYMENAT_FAILURE_URL,
      customerName: properties.contact ? properties.contact : "",
      customerEmail: "phil@magnasoftware.co.uk",
      // customerEmail: properties.email
      //   ? properties.email !== "N/A"
      //     ? properties.email
      //     : ""
      //   : "",
      vendorEmail: properties.email ? properties.email : "",
      billingSurname: billingInfo.contact
        ? billingInfo.contact.split(" ")[1]
          ? billingInfo.contact.split(" ")[1]
          : billingInfo.contact.split(" ")[0]
        : "",
      billingFirstname: billingInfo.contact
        ? billingInfo.contact.split(" ")[0]
          ? billingInfo.contact.split(" ")[0]
          : ""
        : "",
      billingAddressOne: billingInfo.address1 ? billingInfo.address1 : "",
      billingCity: billingInfo.town ? billingInfo.town : "",
      billingPostcode: billingInfo.postcode ? billingInfo.postcode : "",
      billingCountry: "GB",
      billingPhone: billingInfo.telephone
        ? billingInfo.telephone === "N/A"
          ? billingInfo.mobile
            ? billingInfo.mobile
            : ""
          : ""
        : "",
      deliverySurname: properties.contact
        ? properties.contact.split(" ")[1]
          ? properties.contact.split(" ")[1]
          : properties.contact.split(" ")[0]
        : "",
      deliveryFirstname: properties.contact
        ? properties.contact.split(" ")[0]
          ? properties.contact.split(" ")[0]
          : ""
        : "",
      deliveryAddressOne: properties.address1 ? properties.address1 : "",
      deliveryCity: properties.town ? properties.town : "",
      deliveryPostcode: properties.postcode ? properties.postcode : "",
      deliveryCountry: "GB",
      deliveryPhone: properties.telephone
        ? properties.telephone === "N/A"
          ? properties.mobile
            ? properties.mobile
            : ""
          : ""
        : "",
      description: properties.contact ? properties.contact + " Order" : "",
    };

    console.log("bodyyyyyyy22222", body);
    axios
      .post(url, body)
      .then((res) => {
        setCreateJobProgress(false);
        // dispatch(setCompleteStatus(true));

        let response = res.data;
        if (response.success == false) {
          props.showSnackBar("Unable to process payment");
          setCreateJobProgress(false);
          setPayOpen(false);
          // props.refreshJob();
        } else {
          const url = response.data.paymentURL;
          dispatch(resetAll());
          props.showSnackBar("Payment being processed");
          history.push("/dashboard/orderList");
          window.open(url, "_blank");
          setPaymentReceived(true);
          // props.resetJob();
          setPayOpen(false);
        }
      })
      .catch(function (error) {
        console.log(error, "errorerrorerrorerror");
      });
  };
  const handleChange = (event) => {
    setTXID("");
    setValue(event.target.value);
  };

  useImperativeHandle(ref, () => ({
    saveData() {
      const data = {
        paymentReceived: paymentReceived,
        paymentType: value,
        paymentAddedAt: moment(),
        preAuthPaymentTransID: txID,
      };

      dispatch(setPayments(data));
      props.dataSaved();
    },
  }));

  const handlePaymentReceived = (event) => {
    setPaymentReceived(event.target.checked);
  };
  useEffect(() => {
    console.log(createJobFormData, "createJobFormData");

    if (createJobFormData.payments.paymentType) {
      setValue(createJobFormData.payments.paymentType);
    }
    if (createJobFormData.payments.paymentReceived) {
      setPaymentReceived(createJobFormData.payments.paymentReceived);
    }
    const jobItems = createJobFormData.jobItems.filter(
      (pro) => pro.isDeleted !== true
    );

    if (jobItems.length > 0) {
      console.log(createJobFormData.jobItems, "row.identifier");
      setJobItems(jobItems);
    } else {
      setJobItems([]);
    }
    if (createJobFormData.headerInfo.taxReference) {
      setTaxRate(createJobFormData.headerInfo.taxReference.taxRate);
    }
  }, [createJobFormData]);

  const subtotal = (items) => {
    return items
      .map(({ unitTotal }) => unitTotal)
      .reduce((sum, i) => sum + i, 0);
  };
  const subTotalTax = (items) => {
    const subtotal = items
      .map(({ unitTotal }) => unitTotal)
      .reduce((sum, i) => sum + i, 0);

    const tax = (subtotal * taxRate) / 100;
    return tax;
  };

  const finalTotal = (items) => {};

  const invoiceSubtotal = subtotal(jobItems);

  const invoiceTaxes = subTotalTax(jobItems);

  const invoiceTotal = () => {
    const subtotal = jobItems
      .map(({ unitGross }) => parseFloat(unitGross))
      .reduce((sum, i) => sum + i, 0);
    return subtotal;
  };
  const handlePreTxIDChange = (e) => {
    console.log(e.target.value, "eeeeeee");
    setTXID(e.target.value);
  };
  return (
    <>
      <Grid container direction="row">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.borderRight}
        >
          <Card className={classes.center}>
            <div>
              <Typography
                variant="h6"
                component="h6"
                className={classes.jobCardPrice}
              >
                Payments
              </Typography>

              <Grid container direction="row">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className={classes.paymentContent}
                >
                  <FormControl
                    component="fieldset"
                    className={classes.fullWidth}
                  >
                    <FormLabel component="legend">Payment Method</FormLabel>
                    <RadioGroup
                      aria-label="gender"
                      value={value}
                      name="customized-radios"
                      onChange={handleChange}
                    >
                      {properties.iwsClientAccountReference &&
                        properties.iwsClientAccountReference !== "" &&
                        properties.iwsClientAccountReference !== "N/A" && (
                          <FormControlLabel
                            value="ACCOUNT"
                            control={<CashRadio />}
                            className={
                              value === "ACCOUNT"
                                ? classes.selectedPaymentOptionDiv
                                : classes.paymentOptionDiv
                            }
                          />
                        )}

                      <FormControlLabel
                        value="CARD"
                        control={
                          <CardRadio
                            handlePayment={handlePayment}
                            expanded={value === "CARD" ? true : false}
                            paymentProgress={paymentProgress}
                            paymentReceived={paymentReceived}
                          />
                        }
                        className={
                          value === "CARD"
                            ? classes.selectedPaymentOptionDiv
                            : classes.paymentOptionDiv
                        }
                      />

                      <FormControlLabel
                        value="PRE"
                        control={
                          <PRERadio
                            handlePayment={handlePayment}
                            expanded={value === "PRE" ? true : false}
                            paymentProgress={paymentProgress}
                            paymentReceived={paymentReceived}
                            handlePreTxIDChange={handlePreTxIDChange}
                            txID={txID}
                          />
                        }
                        className={
                          value === "PRE"
                            ? classes.selectedPaymentOptionDiv
                            : classes.paymentOptionDiv
                        }
                      />
                      {/* <FormControlLabel  control={<StyledRadio />}  /> */}
                    </RadioGroup>
                  </FormControl>

                  {/* <FormControl
                    component="fieldset"
                    className={classes.paymentReceived}
                  >
                    <FormLabel component="legend">Payment Received</FormLabel>
                    <FormControlLabel
                      checked={paymentReceived}
                      name="paymentReceived"
                      onChange={handlePaymentReceived}
                      control={<PaymentReceivedCheckbox />}
                      className={
                        paymentReceived
                          ? classes.selectedPaymentOptionDiv
                          : classes.paymentOptionDiv
                      }
                    />

                  
                  </FormControl> */}
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className={classes.tableHeading}>
                    <Typography>Items</Typography>
                  </div>
                  <TableContainer
                    component={Paper}
                    className={classes.tableContainer}
                  >
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>SOR Ref</TableCell>
                          <TableCell align="right">Qty.</TableCell>
                          <TableCell align="right">Rate</TableCell>
                          <TableCell align="right">Vat</TableCell>
                          <TableCell align="right">Unit Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {jobItems.map((row) => (
                          <TableRow key={row.sorReference}>
                            <TableCell>{row.sorReference}</TableCell>
                            <TableCell align="right">{row.quantity}</TableCell>
                            <TableCell align="right">{row.rate.toFixed(2)}</TableCell>
                            <TableCell align="right">{row.vatAmount.toFixed(2)}</TableCell>
                            <TableCell align="right">{row.unitGross.toFixed(2)}</TableCell>
                          </TableRow>
                        ))}

                        {/* <TableRow>
                          <TableCell rowSpan={3} />
                          <TableCell colSpan={2}>Subtotal</TableCell>
                          <TableCell align="right" style={{ fontWeight: 600 }}>
                            £{ccyFormat(invoiceSubtotal)}
                          </TableCell>
                        </TableRow> */}
                        {/* <TableRow>
                          <TableCell>Vat</TableCell>
                          <TableCell align="right">{`${taxRate} %`}</TableCell>
                          <TableCell align="right" style={{ fontWeight: 600 }}>
                            £{ccyFormat(invoiceTaxes)}
                          </TableCell>
                        </TableRow> */}
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={1}></TableCell>
                          <TableCell rowSpan={3} colSpan={1}></TableCell>
                          <TableCell rowSpan={3} colSpan={1}></TableCell>
                          <TableCell
                            rowSpan={3}
                            colSpan={1}
                            style={{ fontWeight: 600 }}
                          >
                            Total
                          </TableCell>
                          <TableCell align="right" style={{ fontWeight: 600 }}>
                            £{invoiceTotal()}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </div>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        open={payOpen}
        onClose={() => setPayOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Pay job amount?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            I am happy to proceed with payment processing?{" "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className={classes.cancelJob}
            disabled={createJobProgress}
            onClick={() => setPayOpen(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            className={classes.refreshDialogButton}
            disabled={createJobProgress}
            onClick={() => handlePay()}
          >
            {createJobProgress && (
              <CircularProgress
                size={20}
                className={classes.circularProgress}
              />
            )}
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

function CardRadio(props) {
  const classes = useStyles();
  return (
    <>
      <Accordion expanded={props.expanded} className={classes.accordianMain}>
        <AccordionSummary className={classes.accordianSummary}>
          <>
            <Radio
              className={classes.root}
              disableRipple
              // checked
              color="default"
              checkedIcon={
                <CheckCircleIcon className={classes.checkRadioIcon} />
              }
              icon={<span className={classes.icon} />}
              {...props}
            />

            <Box>
              <Typography variant="h6" className={classes.paymentHeadingRadio}>
                Credit / Debit Card
              </Typography>
              <Typography
                variant="p"
                className={classes.paymentHeadingSubRadio}
              >
                Mastercard, Visa, and Discover are supported.
              </Typography>
            </Box>
            <Box className={classes.rightAlign}>
              <img alt="mastercard" src={ic_mastercard} />
              <img alt="mastercard" src={ic_visa} />
            </Box>
          </>
        </AccordionSummary>
        <AccordionDetails className={classes.accordianSummary}>
          {!props.paymentReceived && (
            <Button
              variant="contained"
              className={classes.payButton}
              disabled={props.paymentProgress}
              onClick={props.handlePayment}
            >
              {props.paymentProgress && (
                <CircularProgress
                  size={20}
                  className={classes.circularProgress}
                />
              )}
              Pay
            </Button>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
function CashRadio(props) {
  const classes = useStyles();
  return (
    <>
      <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={<CheckCircleIcon className={classes.checkRadioIcon} />}
        icon={<span className={classes.icon} />}
        {...props}
      />

      <Box>
        <Typography variant="h6" className={classes.paymentHeadingRadio}>
          Payment on Account
        </Typography>
        <Typography variant="p" className={classes.paymentHeadingSubRadio}>
          Use client account to pay for the job.
        </Typography>
      </Box>
      {/* <Box className={classes.rightAlign}>
            <img alt="mastercard" src={ic_mastercard} />
            <img alt="mastercard" src={ic_visa} />
          </Box> */}
    </>
  );
}

function PRERadio(props) {
  const classes = useStyles();
  return (
    <>
      <Accordion expanded={props.expanded} className={classes.accordianMain}>
        <AccordionSummary className={classes.accordianSummary}>
          <>
            <Radio
              className={classes.root}
              disableRipple
              // checked
              color="default"
              checkedIcon={
                <CheckCircleIcon className={classes.checkRadioIcon} />
              }
              icon={<span className={classes.icon} />}
              {...props}
            />

            <Box>
              <Typography variant="h6" className={classes.paymentHeadingRadio}>
                Pre Authorized Payment
              </Typography>
              <Typography
                variant="p"
                className={classes.paymentHeadingSubRadio}
              >
                Please provide Transaction ID:
              </Typography>
            </Box>
          </>
        </AccordionSummary>
        <AccordionDetails className={classes.accordianSummary}>
          <TextField
            onChange={props.handlePreTxIDChange}
            label="Transaction ID"
            value={props.txID}
            variant="outlined"
            className={classes.txIDStyle}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}

function PaymentReceivedCheckbox(props) {
  const classes = useStyles();
  return (
    <>
      <Checkbox
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={<CheckCircleIcon className={classes.checkRadioIcon} />}
        icon={<span className={classes.icon} />}
        {...props}
      />

      <Box>
        <Typography variant="h6" className={classes.paymentHeadingRadio}>
          Payment Received
        </Typography>
        <Typography variant="p" className={classes.paymentHeadingSubRadio}>
          Leave unchecked if payment is still pending.
        </Typography>
      </Box>
      {/* <Box className={classes.rightAlign}>
              <img alt="mastercard" src={ic_mastercard} />
              <img alt="mastercard" src={ic_visa} />
            </Box> */}
    </>
  );
}

export default Payments;
