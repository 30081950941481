import React, { useContext } from 'react'
import { Route, Redirect} from 'react-router-dom'
import {UserContext} from '../Contexts/userContext'

// check to see if user is logged in.
// if user login is true show the intended page
// other wise redirect to login

interface PrivateRouteProps {
    children: React.ReactNode[] | React.ReactNode,
    path: string,
 
}

export default function RedirectIfLoggedIn({children, path, }: PrivateRouteProps){
    const {user} = useContext(UserContext)

    return (
        <Route  path={path}>
                {
                    user ?  <Redirect to="/dashboard"/> : children

                }

        </Route>
    )
}