import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LinearProgress from "@material-ui/core/LinearProgress";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteIcon from "@material-ui/icons/Delete";
import TableChartIcon from "@material-ui/icons/TableChart";
import DoneIcon from "@material-ui/icons/Done";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import { alpha } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import TableHead from "@material-ui/core/TableHead";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ListAltIcon from "@material-ui/icons/ListAlt";
import GetAppIcon from "@material-ui/icons/GetApp";
import Radio from "@material-ui/core/Radio";
import { ListItemIcon } from "@material-ui/core";
import ReceiptIcon from "@material-ui/icons/Receipt";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import moment from "moment-timezone";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import Input from "@material-ui/core/Input";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { axios } from "../../utils/axiosConfig";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ReactApexChart from "react-apexcharts";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import SwipeableViews from "react-swipeable-views";

import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { createJobConfigurationData } from "../../Features/createJobConfigurationSlice";
import {
  setProperties,
  addJobItem,
  editJobData
} from "../../Features/editJobDataSlice";
const useStyles = makeStyles({
  startAutoJob: {
    color: "#fff",
    background: "#f15a2f",
    width: "auto",
    height: "53px",
    marginRight: "10px",

    marginTop: "10px",
    marginBottom: "10px",
    marginLeft: "auto",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#f15a2f",
      color: "#fff"
    },
    float: "right"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  titleDiv: {
    display: "flex"
  },
  status: {
    fontSize: 14,
    color: "#fff",
    marginTop: "2px",
    fontStyle: "italic"
  },
  statusTime: {
    marginTop: 15
  },
  pos: {
    marginBottom: 12
  },
  linearProgress: {
    marginTop: "15px"
  },
  chip: {
    color: "primary",
    height: "23px"
  },
  jobCardTitle: {
    color: "#000",
    fontWeight: 600,
    lineHeight: "1.57143",
    fontSize: "1.275rem",
    margin: "none"
  },
  jobCardPrice: {
    color: "#000",
    fontSize: "1rem",
    // marginBottom: "7px",
    marginTop: "7px",
    fontWeight: "600",
    // letterSpacing: "0.1rem",
    lineHeight: 1.5
  },
  snackbar: {
    backgroundColor: "rgb(50, 50, 50)"
  },
  close: {
    color: "#fff"
  },
  icon: {
    color: "#fff",
    margin: "4px 4px"
  },
  oilgasIcon: {
    margin: "auto",
    marginRight: "5px",
    color: "#e8e8e8",
    width: "65px",
    height: "40px"
  },
  editIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px"
  },
  circularProgress: {
    width: "20px !important",
    height: "20px !important",
    color: "#fff"
  },
  closeIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px",
    marginTop: "20px"
  },

  feildsGrid: {
    marginTop: "8px"
  },
  jobCardButton: {
    color: "#fff",
    // borderRadius: '60%',
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#f15a2f"
    }
  },

  jobCardButton2: {
    // color:"#f15a2f",
    // borderRadius: '60%',
    fontSize: "12px",
    fontWeight: 600
    // "&:hover": {
    //   backgroundColor: '#ffff'
    // },
    // backgroundColor:"#ffff"
  },
  cardInfo: {
    marginTop: "15px"
  },
  tablePagination: {
    // position: 'sticky'
  },
  borderLeft: {
    borderLeft: "1px solid gray"
  },
  borderRight: {
    margin: "25px"
    // paddingBottom: "15px",
    // paddingLeft: "15px"
  },
  notificationsGrid: {
    margin: "0 25px 25px 25px"
  },
  stackRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "16px",
    marginBottom: "8px"
  },
  stackIcon: {
    width: "24px",
    height: "24px",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(84, 214, 44)",
    backgroundColor: "rgba(84, 214, 44, 0.16)"
  },
  stackIconDetail: {
    padding: 3
  },
  stackSubtitle: {
    color: "#000",
    fontWeight: "600",
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    margin: "0px 0px 0px 8px"
  },
  chartRoot: {
    margin: "0px 0px 0px auto"
  },
  cardSubTitle: {
    fontWeight: 400,
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    // textAlign:"left",
    // margin: '4px 0px 0px',
    color: "rgb(99, 115, 129)",
    display: "block"
  },
  textField: {
    // width: "100%",
    marginLeft: "5px",
    marginRight: "5px"
  },
  autoComplete: {
    width: "15%",
    marginTop: "5px",
    marginLeft: "5px",
    marginRight: "5px",
    /* display: flex; */

    display: "inline-flex",
    position: "relative",
    minWidth: 0,
    flexDirection: "column",
    verticalAlign: "top"
  },
  selectFeild: {
    width: "10%",
    marginTop: "15px",
    marginLeft: "5px",
    marginRight: "5px"
  },
  findNotification: {
    color: "#fff",
    background: "#000;",
    width: "auto",
    height: "53px",
    marginTop: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#000;",
      color: "#fff"
    }
  },
  tableHeading: {
    padding: "15px",
    fontSize: "20px",
    color: "#fff",
    display: "flex",
    background: "#000;"
  },
  tableBold: {
    fontWeight: 600,
    fontSize: "17px",
    color: "#000;"
  },

  searchIcon: {
    padding: "5px",
    color: "rgba(224, 224, 224, 5)",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "#000"
  },
  inputInput: {
    color: "#000",
    padding: "8px, 8px, 8px, 8px",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + 32px)`,
    width: "100%"
  },
  tableFooter: {
    width: "fit-content",
    marginTop: "20px",
    marginLeft: "auto"
  },
  noDataFoundText: {
    color: "#000",
    fontSize: "1rem",
    marginBottom: "7px",
    marginTop: "7px",
    margin: "auto",
    textAlign: "center",
    fontWeight: "600",
    // letterSpacing: "0.1rem",
    lineHeight: 1.5
  },
  root: {
    // background: "#000"
    // padding: "8px"
  },

  center: {
    height: `calc(100vh - 350px)`,
    marginTop: "15px",
    // background: "#000",
    // padding: 16,
    // display: "flex",
    backgroundColor: "rgb(255, 255, 255)",
    color: "#000",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "8px",
    position: "relative",
    zIndex: 0,
    // display: "flex",
    alignItems: "center",
    padding: "24px"
  },
  inputFeild: {
    color: "#fff",
    fontSize: "32px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },

  inputGrid: {
    paddingLeft: "10px"
  },
  inputGrid2: {
    paddingLeft: "10px",
    display: "flex"
  },

  toggleButtonUnselected: {
    height: "45px",
    width: "auto",
    borderRadius: "3px",
    color: "#fff",
    fontSize: "14px",
    margin: "2px",
    borderColor: "#f15a2f",
    background: "#f15a2f",

    "&:hover": {
      background: "#f15a2f"
    }
  },
  toggleButtonSelected: {
    height: "45px",
    width: "auto",
    borderRadius: "3px",
    color: "#f15a2f",
    fontSize: "14px",
    margin: "2px",
    borderColor: "#f15a2f",
    background: "#fff",

    "&:hover": {
      background: "#fff"
    }
  },

  borderRightNone: {
    borderRight: "none"
  },
  borderLeftNone: {
    borderLeft: "none"
  },
  iconContent: {
    cursor: "pointer",
    marginTop: "6rem",
    transition: "box-shadow 0.3s ease",
    boxShadow: "0 1px 3px rgb(77 83 88 / 23%)",
    backgroundColor: "#fff",
    border: "1px solid #C6C6C6",
    padding: "1.5rem 1.25rem",
    margin: "auto",
    width: "300px",
    "&:hover": {
      transition: "box-shadow 0.3s ease",
      boxShadow: "0 5px 10px rgb(77 83 88 / 23%)"
    }
  },
  selectSORGrid: {
    padding: "20px",
    width: "100%",
    height: "100% !important"
  },
  addIcon: {
    margin: "auto",
    width: "100%",
    fontSize: "50px"
  },
  tableContainer: {
    width: "1200px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "16px",
    position: "relative"
  },
  selectSORHeader: {
    display: "flex",
    alignItems: "center",
    padding: "24px 24px 0px"
    // marginBottom: '24px',
  },
  tableHead: {
    margin: "10px",
    padding: "16px",
    borderBottom: "none",
    color: "rgb(99, 115, 129)",
    backgroundColor: "rgb(244, 246, 248)"
  },

  tableHeadCell: {
    color: "rgb(99, 115, 129)",
    borderBottom: "none",
    linehHeight: "1.5rem",
    fontSize: "0.875rem",
    fontWeight: "600"
  },
  tableBodyCell: {
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    fontWeight: "400",
    padding: "16px",
    color: "rgb(33, 43, 54)"
    // borderBottom: 'none',
  },
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(244, 246, 248)",
      transition: "box-shadow 0.3s ease",
      boxShadow: "0 5px 10px rgb(77 83 88 / 23%)"
    }
  },

  gridMargin: {
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: "30px",
    display: "flex"
  },
  stickyRoot: {
    width: "100%",
    position: "relative",
    overflow: "auto"
    // maxHeight: 300
  },
  radioOption: {
    cursor: "pointer",
    border: "1px solid rgba(224, 224, 224, 1)",
    borderRadius: "5px",
    marginBottom: "10px",
    "&:hover": {
      border: "2px solid #f15a2f",
      transition: "box-shadow 0.3s ease",
      boxShadow: "0 5px 10px rgb(77 83 88 / 23%)"
    }
  },
  displayFlex: {
    display: "flex"
  },
  displayFlex2: {
    width: "100%",
    display: "flex"
  },
  centerAlign: {
    // marginLeft:'auto'
    textAlign: "center"
  },
  arrowBack: {
    marginRight: "10px",
    marginBottom: "10px",
    fontSize: "35px",
    cursor: "pointer"
  },
  closeButton: {
    marginLeft: "auto"
  },
  search: {
    cursor: "pointer",
    border: "1px solid rgba(224, 224, 224, 1)",
    borderRadius: "5px",
    marginBottom: "10px",
    "&:hover": {
      border: "2px solid #f15a2f"
      // transition: "box-shadow 0.3s ease",
      // boxShadow: "0 5px 10px rgb(77 83 88 / 23%)"
    },
    marginLeft: "auto",

    backgroundColor: alpha("#fff", 0.15),

    width: "200px"
  },
  fullHeight: {
    height: "inherit"
  }
});

function capitalizeFirstLetter([first, ...rest], locale = navigator.language) {
  return [first.toLocaleUpperCase(locale), ...rest].join("");
}

function AddItemFormDialog(props) {
  const { open, handleClose } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [itemsFormView, setItemsFormView] = useState(false);
  const [jobItems, setJobItems] = useState([]);
  const [filteredItems, setFitleredJobItems] = useState([]);
  const createJobConfiguration = useSelector(createJobConfigurationData);
  const createJobFormData = useSelector(editJobData);
  const [taxRate, setTaxRate] = useState(0);
  const [taxID, setTaxID] = useState("");
  useEffect(() => {
    setJobItems(createJobConfiguration.jobItems);
    setFitleredJobItems(createJobConfiguration.jobItems);
  }, [createJobConfiguration]);

  useEffect(() => {
    if (Object.keys(createJobFormData.headerInfo).length > 0) {
      console.log(createJobFormData.headerInfo, "headerInfoCreateJobData");
      formik.setFieldValue(
        "taxRef",
        createJobFormData.headerInfo.taxReference.taxReference
      );
      formik.setFieldValue(
        "vat",
        createJobFormData.headerInfo.taxReference.taxRate
      );
      setTaxRate(createJobFormData.headerInfo.taxReference.taxRate);
      setTaxID(createJobFormData.headerInfo.taxReference.taxID);
    }
  }, [createJobFormData]);

  const validate = values => {
    const errors = {};

    if (!values.sorReference) {
      errors.sorReference = "Required";
    }
    if (!values.fullReference) {
      errors.fullReference = "Required";
    }

    if (!values.descriptionOne) {
      errors.descriptionOne = "Required";
    }
    if (!values.descriptionTwo) {
      errors.descriptionTwo = "Required";
    }
    if (!values.unit) {
      errors.unit = "Required";
    }
    if (!values.rate) {
      errors.rate = "Required";
    }
    if (!values.taxRef) {
      errors.taxRef = "Required";
    }
    if (!values.quantity) {
      errors.quantity = "Required";
    }
    if (!values.unitTotal) {
      errors.unitTotal = "Required";
    }
    // if (!values.vat) {
    //   errors.vat = "Required";
    // }
    // if (!values.vatAmount) {
    //   errors.vatAmount = "Required";
    // }
    if (!values.unitGross) {
      errors.unitGross = "Required";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      itemID: "",
      sorReference: "",
      fullReference: "",
      descriptionOne: "",
      descriptionTwo: "",
      indicativeQuantity: "",
      unit: "",
      rate: 0,
      dateAdded: "",
      taxRef: "",
      quantity: 0,
      unitTotal: "",
      vatID: "",
      vat: "",
      vatRate: "",
      vatAmount: "",
      unitGross: "",
      zoneID:"",
    },
    validateOnChange: false, // this one
    validateOnBlur: false,
    validate,
    onSubmit: values => {
      values.isDeleted=false;
      values.workCardItemID=-1;
      if (
        Object.keys(createJobFormData.properties).length > 0 &&
        createJobFormData.properties.zone
      ) {
        const selectedZone = createJobFormData.properties.zone.zoneID;
        values.zoneID=selectedZone
      }
      
      dispatch(addJobItem(values));
      formik.resetForm();
      setItemsFormView(false);
      handleClose();
    }
  });

  const handleListOptionClicked = row => {
    setItemsFormView(true);
    formik.setFieldValue("itemID", row.ID);
    formik.setFieldValue("sorReference", row.sorReference);
    formik.setFieldValue("fullReference", row.fullReference);
    formik.setFieldValue("descriptionOne", row.descriptionOne);
    formik.setFieldValue("descriptionTwo", row.descriptionTwo);
    formik.setFieldValue("indicativeQuantity", row.indicativeQty);
    formik.setFieldValue(
      "dateAdded",
      moment()
        .tz("Europe/London")
        .format("YYYY-MM-DD")
    );
    formik.setFieldValue("unit", row.unit);
    formik.setFieldValue("rate", parseFloat(`${row.rate.toFixed(2)}`));
    formik.setFieldValue("quantity", parseInt(row.indicativeQty));

    const unitTotal = row.indicativeQty * row.rate;
    const vatAmount = (unitTotal * taxRate) / 100;
    formik.setFieldValue("unitTotal", unitTotal);
    formik.setFieldValue("vat", taxRate);
    formik.setFieldValue("vatID", taxID);
    formik.setFieldValue("vatRate", taxRate);
    formik.setFieldValue("vatAmount", parseFloat(vatAmount.toFixed(2)));
    formik.setFieldValue("unitGross",parseFloat(`${(unitTotal + vatAmount).toFixed(2)}`));
  };

  const handleQuantityChange = event => {
    event.preventDefault();
    const value = event.target.value;
    const unitTotal = value * formik.values.rate;
    const vatAmount = (unitTotal * taxRate) / 100;
    formik.setFieldValue("quantity", parseInt(value));
    formik.setFieldValue("unitTotal", unitTotal);
    formik.setFieldValue("vat", taxRate);
    formik.setFieldValue("vatAmount", parseFloat(vatAmount.toFixed(2)));
    formik.setFieldValue("unitGross", parseFloat(`${(unitTotal + vatAmount).toFixed(2)}`));
  };

  const handleRateChange = event => {
    event.preventDefault();
    const value = event.target.value;
    const unitTotal = value * formik.values.quantity;
    const vatAmount = (unitTotal * taxRate) / 100;
    formik.setFieldValue("rate", parseFloat(value));
    formik.setFieldValue("unitTotal", unitTotal);
    formik.setFieldValue("vat", taxRate);
    formik.setFieldValue("vatAmount",parseFloat(vatAmount.toFixed(2)));
    formik.setFieldValue("unitGross", parseFloat(`${(unitTotal + vatAmount).toFixed(2)}`));
  };

  const handleVatAmountChange = event => {
    event.preventDefault();
    const value = event.target.value;
    formik.setFieldValue("vatAmount", parseFloat(value.toFixed(2)));
    const totalGross = parseFloat(value) + parseFloat(formik.values.unitTotal);
    formik.setFieldValue("unitGross", totalGross);
  };

  const handleSearch = event => {
    let data = jobItems;
    const newData = data.filter(item => {
      if (!event.target.value) {
        return item;
      }
      if (
        item.sorReference
          .toString()
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      ) {
        return item;
      }
    });
    setFitleredJobItems(newData);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        className={classes.fullHeight}
        aria-labelledby="max-width-dialog-title"
      >
        {itemsFormView && (
          <form onSubmit={formik.handleSubmit}>
            <Grid
              className={classes.selectSORGrid}
              direction="row"
              justify="space-between"
              alignItems="center"
              container
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.inputGrid2}
              >
                <ArrowBackIcon
                  className={classes.arrowBack}
                  onClick={() => setItemsFormView(false)}
                />
                <Typography
                  variant="h6"
                  component="h6"
                  className={classes.jobCardPrice}
                >
                  Work Card Item Details
                </Typography>
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className={classes.inputGrid}
              >
                <TextField
                  //   value={this.state.title}
                  label="SOR Reference"
                  className={classes.inputFeild}
                  margin="normal"
                  InputProps={{ readOnly: true }}
                  //   onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                  id="sorReference"
                  name="sorReference"
                  onChange={formik.handleChange}
                  value={formik.values.sorReference}
                  error={formik.errors.sorReference}
                  helperText={formik.errors.sorReference}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className={classes.inputGrid}
              >
                <TextField
                  //   value={this.state.title}
                  label="Full reference"
                  className={classes.inputFeild}
                  margin="normal"
                  InputProps={{ readOnly: true }}
                  //   onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                  id="fullReference"
                  name="fullReference"
                  onChange={formik.handleChange}
                  value={formik.values.fullReference}
                  error={formik.errors.fullReference}
                  helperText={formik.errors.fullReference}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className={classes.inputGrid}
              >
                <TextField
                  //   value={this.state.title}
                  label="Description 1"
                  className={classes.inputFeild}
                  margin="normal"
                  InputProps={{ readOnly: true }}
                  multiline
                  rows={6}
                  //   onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                  id="descriptionOne"
                  name="descriptionOne"
                  onChange={formik.handleChange}
                  value={formik.values.descriptionOne}
                  error={formik.errors.descriptionOne}
                  helperText={formik.errors.descriptionOne}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className={classes.inputGrid}
              >
                <TextField
                  //   value={this.state.title}
                  label="Description 2"
                  className={classes.inputFeild}
                  margin="normal"
                  InputProps={{ readOnly: true }}
                  multiline
                  rows={6}
                  //   onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                  id="descriptionTwo"
                  name="descriptionTwo"
                  onChange={formik.handleChange}
                  value={formik.values.descriptionTwo}
                  error={formik.errors.descriptionTwo}
                  helperText={formik.errors.descriptionTwo}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.inputGrid}
              >
                <TextField
                  //   value={this.state.title}
                  label="UOM"
                  className={classes.inputFeild}
                  margin="normal"
                  InputProps={{ readOnly: true }}
                  //   onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                  id="unit"
                  name="unit"
                  onChange={formik.handleChange}
                  value={formik.values.unit}
                  error={formik.errors.unit}
                  helperText={formik.errors.unit}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.inputGrid}
              >
                <TextField
                  //   value={this.state.title}
                  label="Rate"
                  className={classes.inputFeild}
                  margin="normal"
                  // InputProps={{readOnly: true}}
                  onChange={handleRateChange}
                  variant="outlined"
                  fullWidth
                  id="rate"
                  name="rate"
                  type="number"
                  // onChange={formik.handleChange}
                  value={formik.values.rate}
                  error={formik.errors.rate}
                  helperText={formik.errors.rate}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.inputGrid}
              >
                <TextField
                  //   value={this.state.title}
                  label="Tax Ref"
                  className={classes.inputFeild}
                  margin="normal"
                  //   onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                  id="taxRef"
                  name="taxRef"
                  InputProps={{ readOnly: true }}
                  onChange={formik.handleChange}
                  value={formik.values.taxRef}
                  error={formik.errors.taxRef}
                  helperText={formik.errors.taxRef}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.inputGrid}
              >
                <TextField
                  //   value={this.state.title}
                  label="Quantity"
                  className={classes.inputFeild}
                  margin="normal"
                  type="number"
                  //   onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                  id="quantity"
                  name="quantity"
                  onChange={handleQuantityChange}
                  value={formik.values.quantity}
                  error={formik.errors.quantity}
                  helperText={formik.errors.quantity}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.inputGrid}
              >
                <TextField
                  //   value={this.state.title}
                  label="Unit Total"
                  className={classes.inputFeild}
                  margin="normal"
                  InputProps={{ readOnly: true }}
                  type="number" step="any"
                  //   onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                  id="unitTotal"
                  name="unitTotal"
                  onChange={formik.handleChange}
                  value={formik.values.unitTotal}
                  error={formik.errors.unitTotal}
                  helperText={formik.errors.unitTotal}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.inputGrid}
              >
                <TextField
                  //   value={this.state.title}
                  label="Vat %"
                  className={classes.inputFeild}
                  margin="normal"
                  InputProps={{ readOnly: true }}
                  //   onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                  id="vat"
                  name="vat"
                  onChange={formik.handleChange}
                  value={formik.values.vat}
                  error={formik.errors.vat}
                  helperText={formik.errors.vat}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.inputGrid}
              >
                <TextField
                  //   value={this.state.title}
                  label="Total Vat"
                  className={classes.inputFeild}
                  margin="normal"
                  type="number" step="any"
                  // InputProps={{readOnly: true}}
                  //   onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                  id="vatAmount"
                  name="vatAmount"
                  onChange={handleVatAmountChange}
                  value={formik.values.vatAmount}
                  error={formik.errors.vatAmount}
                  helperText={formik.errors.vatAmount}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.inputGrid}
              >
                <TextField
                  //   value={this.state.title}
                  label="Unit Gross"
                  className={classes.inputFeild}
                  type="number" step="any"
                  margin="normal"
                  InputProps={{ readOnly: true }}
                  //   onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                  id="unitGross"
                  name="unitGross"
                  onChange={formik.handleChange}
                  value={formik.values.unitGross}
                  error={formik.errors.unitGross}
                  helperText={formik.errors.unitGross}
                />
              </Grid>

              <Button
                variant="contained"
                className={classes.startAutoJob}
                type="submit"
              >
                Add Item
              </Button>
            </Grid>
          </form>
        )}

        {!itemsFormView && (
          <Grid
            className={classes.selectSORGrid}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <div className={classes.displayFlex2}>
              <Typography
                variant="h6"
                component="h6"
                className={classes.jobCardPrice}
              >
                Select SOR
              </Typography>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  onChange={e => handleSearch(e)}
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                  inputProps={{ "aria-label": "search" }}
                />
              </div>
            </div>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.gridMargin}
              >
                <List className={classes.stickyRoot} subheader={<li />}>
                  {filteredItems.map(row => (
                    <ListItem
                      className={classes.radioOption}
                      onClick={() => handleListOptionClicked(row)}
                    >
                      <Grid container>
                        <Grid lg={2} className={classes.displayFlex}>
                          <ListItemIcon>
                            <ListAltIcon style={{ marginTop: 15 }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={row.fullReference}
                            secondary="Full Reference"
                          />
                        </Grid>
                        <Grid lg={4}>
                          <ListItemText
                            primary={row.descriptionOne}
                            secondary="Description 1"
                          />
                        </Grid>

                        <Grid lg={2}>
                          <ListItemText
                            className={classes.centerAlign}
                            primary={row.descriptionTwo}
                            secondary="Description 2"
                          />
                        </Grid>

                        <Grid lg={2}>
                          <ListItemText
                            className={classes.centerAlign}
                            primary={row.unit}
                            secondary="Unit"
                          />
                        </Grid>

                        <Grid lg={2}>
                          <ListItemText
                            className={classes.centerAlign}
                            primary={row.rate}
                            secondary="Rate"
                          />
                        </Grid>
                      </Grid>

                      {/* </ListItemAvatar> */}
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Dialog>
    </>
  );
}

export default AddItemFormDialog;
