import React, { useEffect, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { axios } from "../../utils/axiosConfig";
import JobSub from "./JobSub";
import JobDetailList from "./JobDetailList";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import moment from "moment-timezone";
import JobTimeLine from "./JobTimeLine";
import FollowUpJobTimeLine from "./FollowUpJobTimeLine";
import PaymentTimeLine from "./PaymentTimeLine";
import JobPictures from "./JobPictures";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import JobReassignments from "./JobReassginments";
import { UserContextData, UserContext } from "../../Contexts/userContext";
import { createJobConfigurationData } from "../../Features/createJobConfigurationSlice";
import JobCancel from "./JobCancel";
import RiskAssessment from "./RiskAssessment";
import { createJobData } from "../../Features/createJobDataSlice";
import {
  setJobConfiguration,
  setOperators,
  setJobItems,
  resetConfiguration,
} from "../../Features/createJobConfigurationSlice";
import {
  setProperties,
  setJobEditStatus,
  setClientSpecifications,
  setBillingInfo,
  setHeaderInfo,
  addJobItem,
  updateJobItems,
  deleteJobItem,
  setCreateJobStatus,
  setPayments,
  setOperator,
  setCurrentStatus,
  setCompleteStatus,
  setInvoiceAddressDifferent,
  addAllJobItems,
  setPaymentRecievedStatus,
  setPaymentType,
  setJobID,
  resetAll,
} from "../../Features/editJobDataSlice";
import {
  setProperties as createSetProperties,
  setJobEditStatus as createSetJobEditStatus,
  setClientSpecifications as createSetClientSpecifications,
  setBillingInfo as createSetBillingInfo,
  setHeaderInfo as createSetHeaderInfo,
  addJobItem as createAddJobItem,
  updateJobItems as createUpdateJobItems,
  deleteJobItem as createDeleteJobItem,
  setCreateJobStatus as createSetCreateJobStatus,
  setPayments as createSetPayments,
  setOperator as createSetOperator,
  setCurrentStatus as createSetCurrentStatus,
  setCompleteStatus as createSetCompleteStatus,
  setInvoiceAddressDifferent as createSetInvoiceAddressDifferent,
  addAllJobItems as createAddAllJobItems,
  setPaymentRecievedStatus as createSetPaymentRecievedStatus,
  setPaymentType as createSetPaymentType,
  setJobID as createSetJobID,
  resetAll as createresetAll,
  setIWSAccount,
  setOldJobID,
  setFollowUpJobCheck,
} from "../../Features/createJobDataSlice";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    background: "#000",
    padding: "8px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },

  startAutoJob: {
    color: "#fff",
    background: "#f15a2f",
    width: "auto",
    height: "53px",
    marginTop: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#f15a2f",
      color: "#fff",
    },
  },
  updateJob: {
    color: "#f15a2f",
    // background: "#fff",
    borderColor: "#f15a2f",
    width: "auto",
    height: "53px",
    marginTop: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#f15a2f",
    },
  },
  removeJobButton: {
    color: "#fff",
    background: "#000",
    width: "auto",
    height: "53px",
    marginTop: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      background: "#000",
      color: "#fff",
    },
  },
  cancelJob: {
    color: "#000",
    // background: "#f44336",
    width: "auto",
    height: "53px",
    marginTop: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#000",
      color: "#fff",
    },
  },

  status: {
    fontSize: 14,
    color: "#fff",
    marginTop: "2px",
    fontStyle: "italic",
  },
  statusTime: {
    marginTop: 15,
  },
  enabledChip: {
    backgroudColor: "green",
  },
  disabledChip: {
    backgroud: "red",
  },
  pos: {
    marginBottom: 12,
  },
  linearProgress: {
    marginTop: "15px",
  },
  chip: {
    color: "primary",
    height: "23px",
  },
  jobCardTitle: {
    color: "#f15a2f",
  },
  jobCardButton: {
    color: "#fff",
    // borderRadius: '60%',
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#f15a2f",
    },
  },
  jobCardButton2: {
    // color:"#f15a2f",
    // borderRadius: '60%',
    fontSize: "12px",
    fontWeight: 600,
    // "&:hover": {
    //   backgroundColor: '#ffff'
    // },
    // backgroundColor:"#ffff"
  },
  cardInfo: {
    marginTop: "15px",
  },
  tablePagination: {
    // position: 'sticky'
  },
  grid: {
    padding: "16px",
    paddingTop: "0px",
  },
  subHeading: {
    marginBottom: 20,
    marginTop: 12,
    textAlign: "center",
    fontSize: "20px",
    fontWeight: 600,
  },
  alignRight: {
    marginLeft: "auto",
  },
  separator: {
    minHeight: 30,
  },
  detail: {
    padding: 10,
  },
  multiline: {
    marginTop: 10,
    width: "100%",
  },
  fullWidth: {
    width: "100%",
  },
  circularStyle: {
    width: "100%",
    margin: "auto",
    marginTop: "25%",
    marginBottom: "15%",
  },
  backIcon: {
    fontSize: 34,
    marginRight: 10,
    cursor: "pointer",
  },
  headerStyle: {
    marginRight: 5,
  },
  bold: {
    fontWeight: 600,
  },
  assignOperator: {
    marginTop: "50px",
    marginBottom: "20px",
  },
});

function JobDetail(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const createJobFormData = useSelector(createJobData);
  const { user, logoutUser } = useContext(UserContext);
  const createJobConfiguration = useSelector(createJobConfigurationData);
  const [cancelOpen, setCancelOpen] = React.useState(false);
  const [completePaymentOpen, setCompletePaymentOpen] = React.useState(false);
  const [removeOpen, setRemoveJobOpen] = React.useState(false);
  const [assignOperatorOpen, setAssignOperatorOpen] = React.useState(false);
  const [createJobExistsOpen, setCreateJobExistsOpen] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  const [completePaymentProgress, setCompletePaymentProgress] =
    React.useState(false);
  const [changeDriverOpen, setChangeDriverOpen] = React.useState(false);
  const [jobData, setJobData] = React.useState({});
  const [riskAssessmentData, setRiskAssessmentData] = React.useState({});
  const [jobPictures, setJobPictures] = React.useState([]);
  const [jobTimeline, setJobTimeLine] = React.useState([]);
  const [paymentTimeline, setPaymentTimeLine] = React.useState([]);
  const [followUpJobs, setFollowUpJobs] = React.useState([]);
  const [reassignments, setReassignments] = React.useState([]);
  const [cancelJobs, setCancel] = React.useState([]);
  const [removedJobs, setRemovedJobs] = React.useState([]);
  const [removalReason, setRemovalReason] = React.useState("");
  const [cancelReason, setCancelReason] = React.useState("");
  const [changeReason, setChangeReason] = React.useState("");
  const [newDriver, setNewDriver] = React.useState(null);
  const [removeProgress, setRemoveProgress] = React.useState(false);
  const [cancelProgress, setCancelProgress] = React.useState(false);
  const [changeProgress, setChangeProgress] = React.useState(false);
  const [invoiceProgress, showInvoiceProgress] = React.useState(false);
  const [followUpProgress, showFollowUpProgress] = React.useState(false);
  const [assignOperatorProgress, setAssignOperatorProgress] =
    React.useState(false);
  const [taxes, setTaxes] = React.useState([]);
  const [priorities, setPriorities] = React.useState([]);
  const [zones, setZones] = React.useState([]);

  useEffect(() => {
    setTaxes(createJobConfiguration.taxes);
    setPriorities(createJobConfiguration.priorities);
    setZones(createJobConfiguration.zones);
  }, [createJobConfiguration]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCancelOpen = () => {
    setCancelOpen(true);
  };

  const handleCancelClose = () => {
    setCancelOpen(false);
  };
  const handleRemoveClose = () => {
    setRemoveJobOpen(false);
  };

  const handleAssignOperatorClose = () => {
    setAssignOperatorOpen(false);
  };
  const handleChangeDriverClose = () => {
    setChangeDriverOpen(false);
  };
  const handleCompletePaymentClose = () => {
    setCompletePaymentOpen(false);
  };
  const handleCreateJobExistsOpenClose = () => {
    setCreateJobExistsOpen(false);
  };
  useEffect(() => {
    getJobByID(props.selectedJob.jobID);
    getConfiguration();
    getOperators();
  }, []);

  const getJobByID = (id) => {
    setProgress(true);
    const url = `job/detail/${id}`;
    axios
      .get(url)
      .then((res) => {
        let response = res.data;
        if (response.success === true) {
          setJobData(response.data.job);
          setRiskAssessmentData(response.data.riskAssessmenst);
          setJobPictures(response.data.images);
          setJobTimeLine(response.data.timelines);
          setPaymentTimeLine(response.data.payments);
          setReassignments(response.data.reAssignJobs);
          setCancel(response.data.cancelJobs);
          setRemovedJobs(response.data.removedJobs);
          if (response.data.followUpJobs.length > 0) {
            setFollowUpJobs(response.data.followUpJobs);
          }
        } else {
        }
        setProgress(false);
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };
  const handleRemoveReason = (event) => {
    console.log(event.target.value, "hasdafdadasd");
    setRemovalReason(event.target.value);
  };

  const handleCancelReason = (event) => {
    console.log(event.target.value, "hasdafdadasd");
    setCancelReason(event.target.value);
  };

  const cancelJob = () => {
    setCancelProgress(true);
    const body = {
      jobID: jobData.jobID,
      driverID: jobData.operatorID,
      userID: user.userID,
      comments: cancelReason,
      invoiceNumber: jobData.invoiceNumber,
      cancelMethod: 1,
      accepted: 0,
    };
    const url = `job/cancel`;
    axios
      .post(url, body)
      .then((res) => {
        let response = res.data;
        setCancelProgress(false);
        setCancelOpen(false);
        if (response.success === true) {
          getJobByID(props.selectedJob.jobID);
          setCancelReason("");
          props.showSnackBar("Job Cancelled");
        } else {
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };
  const removeOrder = () => {
    setRemoveProgress(true);
    const body = {
      jobID: jobData.jobID,
      driverID: jobData.operatorID,
      invoiceNumber: jobData.invoiceNumber,
      userID: user.userID,
      comments: removalReason,
      accepted: 0,
    };
    const url = `job/remove`;
    axios
      .post(url, body)
      .then((res) => {
        let response = res.data;
        setRemoveProgress(false);
        setRemoveJobOpen(false);
        if (response.success === true) {
          getJobByID(props.selectedJob.jobID);
          setRemovalReason("");
          props.showSnackBar("Job Cancelled");
        } else {
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };

  const handleChangeReason = (event) => {
    setChangeReason(event.target.value);
  };

  const handleDriverChange = (event, value) => {
    console.log(value, "event");
    if (value !== null) {
      setNewDriver(value);
    } else {
      setNewDriver(null);
    }
  };
  const changeDriver = () => {
    if (newDriver == null) {
      props.showSnackBar("Select Driver");
      return;
    }
    console.log(newDriver, "newDriver");
    setChangeProgress(true);
    const body = {
      jobID: jobData.jobID,
      driverID: newDriver.driverID,
      oldDriverID: jobData.operatorID,
      invoiceNumber: jobData.invoiceNumber,
      userID: user.userID,
      comments: changeReason,
      driverName: newDriver.driverName,
    };
    const url = `job/reassign`;
    axios
      .post(url, body)
      .then((res) => {
        let response = res.data;
        if (response.success === true) {
          getJobByID(props.selectedJob.jobID);
        } else {
        }
        setNewDriver(null);
        setChangeReason("");
        setChangeDriverOpen(false);
        setChangeProgress(false);
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };

  const assignOperator = () => {
    if (newDriver == null) {
      props.showSnackBar("Select Driver");
      return;
    }
    console.log(newDriver, "newDriver");
    setAssignOperatorProgress(true);
    const body = {
      operatorID: newDriver.driverID,
      operatorName: newDriver.driverName,
      jobID: jobData.jobID,
      descriptionOfWork: jobData.descritpionOfWorks,
    };
    const url = `create/job/assign`;
    axios
      .post(url, body)
      .then((res) => {
        let response = res.data;
        if (response.success === true) {
          getJobByID(props.selectedJob.jobID);
        } else {
        }
        setNewDriver(null);
        setChangeReason("");
        setAssignOperatorOpen(false);
        setAssignOperatorProgress(false);
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };

  const completePayment = () => {
    setCompletePaymentProgress(true);
    const url = `job/complete/sagepay/payment/` + jobData.jobID;
    axios
      .get(url)
      .then((res) => {
        let response = res.data;
        if (response.success === true) {
          props.showSnackBar("Payment in progress");
          const url = response.data.paymentURL;
          window.open(url, "_blank");
        } else {
          props.showSnackBar("Complete payment error!");
        }
        setCompletePaymentOpen(false);
        setCompletePaymentProgress(false);
      })
      .catch(function (error) {
        setCompletePaymentOpen(false);
        setCompletePaymentProgress(false);
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };

  const editJob = () => {
    addEditJobData();
  };

  const getConfiguration = () => {
    let url = `create/job/configuration`;
    axios
      .get(url)
      .then((res) => {
        let response = res.data;
        if (response.success === true) {
          dispatch(setJobConfiguration(response.data));
        } else {
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 403) {
          // logoutUser();
        }
      });
  };
  const getOperators = () => {
    const url = `job/driver/all`;
    axios
      .get(url)
      .then((res) => {
        let response = res.data;
        if (response.success === true) {
          dispatch(setOperators(response.data));
        } else {
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
        }
      });
  };

  const findZoneID = (zoneid) => {
    console.log(zoneid, zones, "zoneid");
    const result = zones.find((x) => x.zoneID === zoneid);

    return result;
  };
  const findTaxReference = (vatRef) => {
    const result = taxes.find((x) => x.taxReference === vatRef);
    return result;
  };
  const findTaxReferenceOnID = (vatID) => {
    const result = taxes.find((x) => x.taxID === vatID).taxReference;
    return result;
  };
  const findPriority = (priorityID) => {
    const result = priorities.find((x) => x.priorityID === priorityID);
    return result;
  };
  const findOperator = () => {};
  const addEditJobData = () => {
    const url = `create/job/detail/` + jobData.jobID;
    axios
      .get(url)
      .then((res) => {
        let response = res.data;
        if (response.success === true) {
          const dataToUpdate = response.data;
          const propertyData = dataToUpdate.property;
          const invoiceData = dataToUpdate.invoice;
          dispatch(resetAll());
          // dispatch(setCreateJobStatus(true));
          dispatch(setJobEditStatus(true));
          dispatch(setPaymentType(dataToUpdate.paymentType));
          dispatch(setPaymentRecievedStatus(dataToUpdate.paymentReceived));
          dispatch(setCurrentStatus(dataToUpdate.currentStatus));
          const propertyDataToDispatch = {
            postcode: propertyData.postcode,
            propertyNumber: propertyData.number,
            propertyReference: propertyData.reference,
            propertyID: props.existingUser ? "" : 1,
            sageAccountRef: "",
            iwsClientAccountReference: dataToUpdate.iwsClientAccountReference,
            iwsClientID: dataToUpdate.iwsClientID,

            contact: propertyData.contact,
            propertyName: propertyData.name,
            address1: propertyData.address,
            town: propertyData.town,
            county: propertyData.county,
            telephone: propertyData.telephone,
            mobile: propertyData.mobile,
            fax: propertyData.fax,
            email: propertyData.email,
            zone: findZoneID(propertyData.zoneID),
            catagory: propertyData.category,
            type: propertyData.type,
            swipeValue: "",
            existingUser: "",
          };
          const billingInfoToDispatch = {
            propertyReference: invoiceData.name,
            address1: invoiceData.address,
            town: invoiceData.town,
            county: invoiceData.county,
            postcode: invoiceData.postcode,
            contact: invoiceData.contact,
            telephone: invoiceData.telephone,
            mobile: invoiceData.mobile,
            email: invoiceData.email,
          };
          const clientSpecificationToDispatch = {
            siteContacts: dataToUpdate.siteContact,
            descriptionOfWork: dataToUpdate.descriptionOfWork,
            specialInstructions: dataToUpdate.SpecialNotes,
          };
          const headerInfoToDispatch = {
            clientOrderNumber: dataToUpdate.clientOrderNo,
            dateRaised: dataToUpdate.dateRaised,
            accountType: dataToUpdate.accountType,
            taxReference: findTaxReference(dataToUpdate.vatRef),
            sageAccountRef: dataToUpdate.sageAccountReference,
            priorityReference: findPriority(dataToUpdate.priorityID),
            recievedDate: moment(dataToUpdate.dateReceived)
              .set(
                "hour",
                moment(dataToUpdate.timeReceived, "HH:mm:ss").format("HH")
              )
              .set(
                "minute",
                moment(dataToUpdate.timeReceived, "HH:mm:ss").format("mm")
              )
              .set(
                "second",
                moment(dataToUpdate.timeReceived, "HH:mm:ss").format("ss")
              ),
            dueCompelitionDate: moment(dataToUpdate.requestedCompletionDate)
              .set(
                "hour",
                moment(dataToUpdate.requestedCompletionTime, "HH:mm:ss").format(
                  "HH"
                )
              )
              .set(
                "minute",
                moment(dataToUpdate.requestedCompletionTime, "HH:mm:ss").format(
                  "mm"
                )
              )
              .set(
                "second",
                moment(dataToUpdate.requestedCompletionTime, "HH:mm:ss").format(
                  "ss"
                )
              ),
          };
          const jobItems = dataToUpdate.jobItems;

          const jobItemsArrayToDispatch = [];
          jobItems.map((jobItem) => {
            const jobData = {
              sorReference: jobItem.fullReference,
              taxRef: findTaxReferenceOnID(jobItem.vatID),
              vat: jobItem.vatRate,
              unitGross: jobItem.vatAmount + jobItem.unitTotal,
              dateAdded: jobItem.dateAdded,
              descriptionOne: jobItem.descriptionOne,
              descriptionTwo: jobItem.descriptionTwo,
              fullReference: jobItem.fullReference,
              indicativeQuantity: jobItem.indicativeQuantity,
              itemID: jobItem.itemID,
              quantity: jobItem.quantity,
              rate: jobItem.rate,
              unit: jobItem.unit,
              unitTotal: jobItem.rate * jobItem.quantity,
              vatAmount: jobItem.vatAmount,
              vatID: jobItem.vatID,
              vatRate: jobItem.vatRate,
              workCardItemID: jobItem.workCardItemID,
              identifier: parseInt(Math.random() * 100000000),
              isDeleted: false,
            };
            jobItemsArrayToDispatch.push(jobData);
          });

          const paymentDataTodispatch = {
            paymentReceived: dataToUpdate.paymentReceived,
            paymentType: dataToUpdate.paymentType,
            paymentAddedAt: dataToUpdate.paymentAddedAt,
          };
          // const operatorToDisptach =findOperator();
          dispatch(setProperties(propertyDataToDispatch));
          dispatch(
            setInvoiceAddressDifferent(dataToUpdate.invoiceAddressDifferent)
          );
          dispatch(setBillingInfo(billingInfoToDispatch));
          dispatch(setClientSpecifications(clientSpecificationToDispatch));
          dispatch(setHeaderInfo(headerInfoToDispatch));
          console.log(jobItemsArrayToDispatch, "jobItemsArrayToDispatch");
          dispatch(addAllJobItems(jobItemsArrayToDispatch));
          dispatch(setJobID(response.jobID));
          dispatch(setPayments(paymentDataTodispatch));
          history.push("/dashboard/updateJob");
          // this.props.history.push('/updateJob')
        } else {
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };

  const downloadInvoice = () => {
    showInvoiceProgress(true);
    const url = `reporting/invoice/${props.selectedJob.jobID}`;

    axios
      .get(url, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        showInvoiceProgress(false);
        if (res.status == 204) {
          props.showSnackBar("Unable to generate PDF");
          return;
        }
        let blob = new Blob([res.data], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `SR-${props.selectedJob.jobID}.pdf`;
        link.click();
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };

  const createFollowUp = () => {
    if (createJobFormData.jobStarted) {
      setCreateJobExistsOpen(true);
    } else {
      followUpJobCreate();
    }
  };
  const followUpJobCreate = () => {
    showFollowUpProgress(true);
    const url = `followup/job/${props.selectedJob.jobID}`;
    axios
      .get(url)
      .then((res) => {
        showFollowUpProgress(false);
        if (res.status == 204) {
          props.showSnackBar("Unable to process");
          return;
        }
        const response = res.data;
        console.log(response, "followUpJobCreate");
        if (response.success === true) {
          const dataToUpdate = response.data;
          const propertyData = dataToUpdate.property;
          const invoiceData = dataToUpdate.invoice;
          dispatch(createresetAll());
          dispatch(createSetCreateJobStatus(true));
          dispatch(createSetPaymentType(dataToUpdate.paymentType));
          dispatch(
            createSetPaymentRecievedStatus(dataToUpdate.paymentReceived)
          );
          dispatch(createSetCurrentStatus(dataToUpdate.currentStatus));
          dispatch(setIWSAccount(dataToUpdate.isIWSClient));
          dispatch(setFollowUpJobCheck(true));
          dispatch(setOldJobID(response.jobID));
          const propertyDataToDispatch = {
            postcode: propertyData.postcode,
            propertyNumber: propertyData.number,
            propertyReference: propertyData.reference,
            propertyID: props.existingUser ? "" : 1,
            sageAccountRef: "",
            iwsClientAccountReference: dataToUpdate.iwsClientAccountReference,
            iwsClientID: dataToUpdate.iwsClientID,

            contact: propertyData.contact,
            propertyName: propertyData.name,
            address1: propertyData.address,
            town: propertyData.town,
            county: propertyData.county,
            telephone: propertyData.telephone,
            mobile: propertyData.mobile,
            fax: propertyData.fax,
            email: propertyData.email,
            zone: findZoneID(propertyData.zoneID),
            catagory: propertyData.category,
            type: propertyData.type,
            swipeValue: propertyData.foundByPostcode ? 0 : 2,
            existingUser: false,
          };
          const billingInfoToDispatch = {
            propertyReference: invoiceData.name,
            address1: invoiceData.address,
            town: invoiceData.town,
            county: invoiceData.county,
            postcode: invoiceData.postcode,
            contact: invoiceData.contact,
            telephone: invoiceData.telephone,
            mobile: invoiceData.mobile,
            email: invoiceData.email,
          };
          const clientSpecificationToDispatch = {
            siteContacts: dataToUpdate.siteContact,
            descriptionOfWork: dataToUpdate.descriptionOfWork,
            specialInstructions: dataToUpdate.SpecialNotes,
          };
          const headerInfoToDispatch = {
            clientOrderNumber: dataToUpdate.clientOrderNo,
            dateRaised: dataToUpdate.dateRaised,
            accountType: dataToUpdate.accountType,
            taxReference: findTaxReference(dataToUpdate.vatRef),
            sageAccountRef: dataToUpdate.sageAccountReference,
            priorityReference: findPriority(dataToUpdate.priorityID),
            recievedDate: moment(dataToUpdate.dateReceived)
              .set(
                "hour",
                moment(dataToUpdate.timeReceived, "HH:mm:ss").format("HH")
              )
              .set(
                "minute",
                moment(dataToUpdate.timeReceived, "HH:mm:ss").format("mm")
              )
              .set(
                "second",
                moment(dataToUpdate.timeReceived, "HH:mm:ss").format("ss")
              ),
            dueCompelitionDate: moment(dataToUpdate.requestedCompletionDate)
              .set(
                "hour",
                moment(dataToUpdate.requestedCompletionTime, "HH:mm:ss").format(
                  "HH"
                )
              )
              .set(
                "minute",
                moment(dataToUpdate.requestedCompletionTime, "HH:mm:ss").format(
                  "mm"
                )
              )
              .set(
                "second",
                moment(dataToUpdate.requestedCompletionTime, "HH:mm:ss").format(
                  "ss"
                )
              ),
          };
          // const jobItems = dataToUpdate.jobItems;

          // const jobItemsArrayToDispatch = [];
          // jobItems.map((jobItem) => {
          //   const jobData = {
          //     sorReference: jobItem.fullReference,
          //     taxRef: findTaxReferenceOnID(jobItem.vatID),
          //     vat: jobItem.vatRate,
          //     unitGross: jobItem.vatAmount + jobItem.unitTotal,
          //     dateAdded: jobItem.dateAdded,
          //     descriptionOne: jobItem.descriptionOne,
          //     descriptionTwo: jobItem.descriptionTwo,
          //     fullReference: jobItem.fullReference,
          //     indicativeQuantity: jobItem.indicativeQuantity,
          //     itemID: jobItem.itemID,
          //     quantity: jobItem.quantity,
          //     rate: jobItem.rate,
          //     unit: jobItem.unit,
          //     unitTotal: jobItem.rate * jobItem.quantity,
          //     vatAmount: jobItem.vatAmount,
          //     vatID: jobItem.vatID,
          //     vatRate: jobItem.vatRate,
          //     workCardItemID: jobItem.workCardItemID,
          //     identifier: parseInt(Math.random() * 100000000),
          //     isDeleted: false,
          //   };
          //   jobItemsArrayToDispatch.push(jobData);
          // });

          const paymentDataTodispatch = {
            paymentReceived: dataToUpdate.paymentReceived,
            paymentType: dataToUpdate.paymentType,
            paymentAddedAt: dataToUpdate.paymentAddedAt,
          };
          // const operatorToDisptach =findOperator();
          dispatch(createSetProperties(propertyDataToDispatch));
          dispatch(
            createSetInvoiceAddressDifferent(
              dataToUpdate.invoiceAddressDifferent
            )
          );
          dispatch(createSetBillingInfo(billingInfoToDispatch));
          dispatch(
            createSetClientSpecifications(clientSpecificationToDispatch)
          );
          dispatch(createSetHeaderInfo(headerInfoToDispatch));
          // dispatch(addAllJobItems(jobItemsArrayToDispatch));
          // dispatch(setJobID(response.jobID));
          dispatch(createSetPayments(paymentDataTodispatch));
          history.push("/dashboard/createJob");
        } else {
        }
        console.log(res, "responseoffollowup");
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };
  return (
    <>
      {progress ? (
        <Grid
          className={classes.grid}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <CircularProgress size={50} className={classes.circularStyle} />
        </Grid>
      ) : (
        <>
          <Grid
            className={classes.grid}
            container
            direction="row"
            // justify="space-between"
            alignItems="center"
          >
            <ArrowBackOutlinedIcon
              className={classes.backIcon}
              onClick={(e) => {
                props.jobDetailBack();
              }}
            />
            <div className={classes.headerStyle}>
              <h1>{jobData.jobID}</h1>
            </div>
            <Chip
              size="medium"
              style={
                jobData.status === "Completed" ||
                jobData.status === "Invoiced" ||
                jobData.status === "Completed and Signed Off" ||
                jobData.status === "Received By Operator"
                  ? {
                      backgroundColor: "#e5f9ed",
                      color: "#1bc943",
                      width: "fit-content",
                      padding: "10px",
                      height: "35px",
                      fontSize: "17px",
                      marginTop: "5px",
                      marginLeft: "10px",
                    }
                  : jobData.status === "Completed with Follow Up"
                  ? {
                      backgroundColor: "#e5f9ed",
                      color: "#20B2AA",
                      width: "fit-content",
                      padding: "10px",
                      height: "35px",
                      fontSize: "17px",
                      marginTop: "5px",
                      marginLeft: "10px",
                    }
                  : jobData.status === "Cancelled By Portal" ||
                    jobData.status === "Aborted" ||
                    jobData.status === "Removed"
                  ? {
                      backgroundColor: "#fff5f6",
                      color: "#f83245",
                      width: "fit-content",
                      padding: "10px",
                      height: "35px",
                      fontSize: "17px",
                      marginTop: "5px",
                      marginLeft: "10px",
                    }
                  : jobData.status === "Recorded" ||
                    jobData.status === "Awaiting Operator Receipt" ||
                    jobData.status === "Amendments Submitted" ||
                    jobData.status === "Returned"
                  ? {
                      backgroundColor: "#fff5f6",
                      color: "#f15a2f",
                      width: "fit-content",
                      padding: "10px",
                      height: "35px",
                      fontSize: "17px",
                      marginTop: "5px",
                      marginLeft: "10px",
                    }
                  : {
                      backgroundColor: "#fff8e1",
                      color: "#ffc107",
                      width: "fit-content",
                      padding: "10px",
                      height: "35px",
                      fontSize: "17px",
                      marginTop: "5px",
                      marginLeft: "10px",
                    }
              }
              label={jobData.status}
              className={
                "Allocated" === true
                  ? classes.enabledChip
                  : classes.disabledChip
              }
            />

            <div className={classes.alignRight}>
              {jobData.status === "Completed with Follow Up" &&
                !jobData.followJobExist && (
                  <Button
                    variant="outlined"
                    className={classes.startAutoJob}
                    onClick={() => createFollowUp()}
                    disabled={followUpProgress}
                  >
                    {followUpProgress && (
                      <CircularProgress
                        size={20}
                        className={classes.circularProgress}
                        color="inherit"
                      />
                    )}
                    {!followUpProgress && "Create Follow Up Job"}
                  </Button>
                )}
              {(jobData.status === "Completed and Signed Off" ||
                jobData.status === "Completed with Follow Up" ||
                jobData.status === "Invoiced" ||
                jobData.status === "Aborted") && (
                <Button
                  variant="outlined"
                  className={classes.updateJob}
                  onClick={() => downloadInvoice()}
                  disabled={invoiceProgress}
                  startIcon={<SaveIcon />}
                >
                  {invoiceProgress && (
                    <CircularProgress
                      size={20}
                      className={classes.circularProgress}
                    />
                  )}
                  {!invoiceProgress && "Download SR"}
                </Button>
              )}

              {(jobData.status === "Recorded" ||
                jobData.status === "Awaiting Operator Receipt" ||
                jobData.status === "Received By Operator" ||
                jobData.status === "ReAssigned" ||
                jobData.status === "Awaiting Payment") && (
                <Button
                  variant="outlined"
                  className={classes.updateJob}
                  onClick={() => editJob()}
                >
                  Update Job
                </Button>
              )}

              {jobData.status === "Recorded" && (
                <Button
                  variant="contained"
                  className={classes.startAutoJob}
                  onClick={() => setAssignOperatorOpen(true)}
                >
                  Assign Operator
                </Button>
              )}

              {(jobData.status === "Recorded" ||
                jobData.status === "Awaiting Operator Receipt" ||
                jobData.status === "Received By Operator" ||
                jobData.status === "On Hold" ||
                jobData.status === "ReAssigned" ||
                jobData.status === "Awaiting Payment") && (
                <>
                  <Button
                    variant="contained"
                    className={classes.removeJobButton}
                    onClick={() => setRemoveJobOpen(true)}
                  >
                    Remove Job
                  </Button>
                  <Button
                    variant="outlined"
                    className={classes.cancelJob}
                    onClick={() => setCancelOpen(true)}
                  >
                    Cancel Job
                  </Button>
                </>
              )}

              {(jobData.status == "Received By Operator" ||
                jobData.status == "Awaiting Operator Receipt" ||
                jobData.status == "ReAssigned") && (
                <Button
                  variant="contained"
                  className={classes.startAutoJob}
                  onClick={() => setChangeDriverOpen(true)}
                >
                  Change Operator
                </Button>
              )}
              {jobData.status == "Recorded" &&
                jobData.isIWSClient &&
                !jobData.paymentCompleted && (
                  <Button
                    variant="contained"
                    className={classes.startAutoJob}
                    onClick={() => setCompletePaymentOpen(true)}
                  >
                    Complete Payment
                  </Button>
                )}
              {jobData.status == "Awaiting Payment" && !jobData.isIWSClient && (
                <Button
                  variant="contained"
                  className={classes.startAutoJob}
                  onClick={() => setCompletePaymentOpen(true)}
                >
                  Complete Payment
                </Button>
              )}
            </div>
          </Grid>
          <Grid
            className={classes.grid}
            container
            spacing={0}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={12}>
              <JobSub jobData={jobData} />
            </Grid>
          </Grid>
          <Grid
            className={classes.grid}
            container
            spacing={0}
            direction="row"
            justify="space-between"
            // alignItems="center"
          >
            <Grid item xs={12} sm={12} md={6} className={classes.detail}>
              <div className={classes.subHeading}>Job Details</div>
              <JobDetailList jobData={jobData} />
            </Grid>
            {Object.keys(riskAssessmentData).length > 0 && (
              <Grid item xs={12} sm={12} md={6} className={classes.detail}>
                <div className={classes.subHeading}>Risk Assessment</div>
                <RiskAssessment jobData={riskAssessmentData} />
              </Grid>
            )}
            {jobPictures.length > 0 && (
              <Grid item xs={12} sm={12} md={6} className={classes.detail}>
                <div className={classes.subHeading}>Images</div>
                <JobPictures jobPictures={jobPictures} />
              </Grid>
            )}
            {jobTimeline.length > 0 && (
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.subHeading}>Timeline</div>
                <JobTimeLine jobTimeline={jobTimeline} />
              </Grid>
            )}
            {paymentTimeline.length > 0 && (
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.subHeading}>Payments</div>
                <PaymentTimeLine paymentTimeline={paymentTimeline} />
              </Grid>
            )}
            {followUpJobs.length > 0 && (
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.subHeading}>Follow Up Jobs</div>
                <FollowUpJobTimeLine jobTimeline={followUpJobs} />
              </Grid>
            )}
            {reassignments.length > 0 && (
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.subHeading}>Ressignments</div>
                <JobReassignments
                  reassignments={reassignments}
                  allDrivers={props.allDrivers}
                />
              </Grid>
            )}
            {cancelJobs.length > 0 && (
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.subHeading}>Cancellation</div>
                <JobCancel
                  cancelJobs={cancelJobs}
                  allDrivers={props.allDrivers}
                />
              </Grid>
            )}
            {removedJobs.length > 0 && (
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.subHeading}>Removed</div>
                <JobCancel
                  cancelJobs={removedJobs}
                  allDrivers={props.allDrivers}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
      <Dialog
        fullScreen={fullScreen}
        open={removeOpen}
        onClose={handleRemoveClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Remove Job?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to Remove Job with DYNO Ref.{" "}
            <span className={classes.bold}>{jobData.jobID}</span> Please write
            Removal reason
          </DialogContentText>
          <TextField
            value={removalReason}
            className={classes.multiline}
            onChange={handleRemoveReason}
            id="filled-multiline-static"
            label="Removal Reason"
            multiline
            rows={5}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className={classes.cancelJob}
            onClick={handleRemoveClose}
          >
            Disagree
          </Button>
          <Button
            variant="contained"
            className={classes.startAutoJob}
            onClick={() => removeOrder()}
            disabled={removeProgress}
          >
            {removeProgress && (
              <CircularProgress
                size={20}
                className={classes.circularProgress}
              />
            )}
            {!removeProgress && "Remove Job"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={cancelOpen}
        onClose={handleCancelClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Cancel Job?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to cancel job with DYNO Ref.{" "}
            <span className={classes.bold}>{jobData.jobID}</span> Please write
            cancelletion reason
          </DialogContentText>
          <TextField
            value={cancelReason}
            className={classes.multiline}
            onChange={handleCancelReason}
            id="filled-multiline-static"
            label="Cancel Reason"
            multiline
            rows={5}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className={classes.cancelJob}
            onClick={handleCancelClose}
          >
            Disagree
          </Button>
          <Button
            variant="contained"
            className={classes.startAutoJob}
            onClick={() => cancelJob()}
            disabled={cancelProgress}
          >
            {cancelProgress && (
              <CircularProgress
                size={20}
                className={classes.circularProgress}
              />
            )}
            {!cancelProgress && "Cancel Job"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={changeDriverOpen}
        onClose={handleChangeDriverClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Change Job Operator?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to change the driver of the job with DYNO Ref.{" "}
            <span className={classes.bold}>{jobData.jobID}</span>
          </DialogContentText>
          <Autocomplete
            className={classes.multiline}
            id="combo-box-demo"
            options={props.allDrivers.filter(
              (item) => item.driverID !== jobData.operatorID
            )}
            onChange={(event, value) => handleDriverChange(event, value)}
            getOptionLabel={(option) => option.driverName}
            renderInput={(params) => (
              <TextField
                {...params}
                label="New Operator Name"
                variant="outlined"
              />
            )}
          />
          <TextField
            className={classes.multiline}
            id="filled-multiline-static"
            label="Change Reason"
            onChange={handleChangeReason}
            multiline
            rows={5}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className={classes.cancelJob}
            onClick={handleChangeDriverClose}
          >
            Disagree
          </Button>
          <Button
            variant="contained"
            className={classes.startAutoJob}
            disabled={changeProgress}
            onClick={() => changeDriver()}
          >
            {changeProgress && (
              <CircularProgress
                size={20}
                className={classes.circularProgress}
              />
            )}
            {!changeProgress && "Change Driver"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={assignOperatorOpen}
        onClose={handleAssignOperatorClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Assign Job Operator?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to assign Opertor to the job with DYNO Ref.{" "}
            <span className={classes.bold}>{jobData.jobID}</span>
          </DialogContentText>
          <Autocomplete
            className={classes.assignOperator}
            id="combo-box-demo"
            options={props.allDrivers}
            onChange={(event, value) => handleDriverChange(event, value)}
            getOptionLabel={(option) => option.driverName}
            renderInput={(params) => (
              <TextField
                {...params}
                label="New Operator Name"
                variant="outlined"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className={classes.cancelJob}
            onClick={handleAssignOperatorClose}
          >
            Disagree
          </Button>
          <Button
            variant="contained"
            className={classes.startAutoJob}
            disabled={assignOperatorProgress}
            onClick={() => assignOperator()}
          >
            {assignOperatorProgress && (
              <CircularProgress
                size={20}
                className={classes.circularProgress}
              />
            )}
            {!assignOperatorProgress && "Assign Operator"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={completePaymentOpen}
        onClose={handleCompletePaymentClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Complete Payment"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to complete payment of job with DYNO Ref.{" "}
            <span className={classes.bold}>{jobData.jobID}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className={classes.cancelJob}
            onClick={handleCompletePaymentClose}
          >
            Disagree
          </Button>
          <Button
            variant="contained"
            className={classes.startAutoJob}
            disabled={completePaymentProgress}
            onClick={() => completePayment()}
          >
            {completePaymentProgress && (
              <CircularProgress
                size={20}
                className={classes.circularProgress}
              />
            )}
            {!completePaymentProgress && "Complete Payment"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={createJobExistsOpen}
        onClose={handleCreateJobExistsOpenClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Job already exists"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to remove existing create job data.
            {/* <span className={classes.bold}>{jobData.jobID}</span> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className={classes.cancelJob}
            onClick={handleCreateJobExistsOpenClose}
          >
            No
          </Button>
          <Button
            variant="contained"
            className={classes.startAutoJob}
            disabled={followUpProgress}
            onClick={() => followUpJobCreate()}
          >
            {followUpProgress && (
              <CircularProgress
                size={20}
                className={classes.circularProgress}
              />
            )}
            {!followUpProgress && "Yes"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default JobDetail;
