import React, { useContext } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Button } from "@material-ui/core";
import { SignIn } from "./Pages/SignIn/SignIn";
import { ThemeProvider } from "@material-ui/core/styles";
import { Switch, Route, Redirect } from "react-router-dom";
import NotFound from "./Pages/404";
import Dashboard from "./Pages/Dashboard";
import PrivateRoute from "./utils/privateRoute";
import RedirectIfLoggedIn from "./utils/RedirectIfLoggedIn";
import { UserContextData, UserContext } from "./Contexts/userContext";

function App() {
  const {user} = useContext<UserContextData>(UserContext)
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/dashboard/"/>
      </Route>

      <PrivateRoute   path="/dashboard/">
        <Dashboard />
      </PrivateRoute>


      <RedirectIfLoggedIn path = "/signin">
        <SignIn />
      </RedirectIfLoggedIn>
   

      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default App;
