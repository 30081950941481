import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle
} from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Accordion from "@material-ui/core/Accordion";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import moment from "moment-timezone";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import Input from "@material-ui/core/Input";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { axios } from "../../utils/axiosConfig";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ReactApexChart from "react-apexcharts";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import SwipeableViews from "react-swipeable-views";
import PostCodeFind from "./PostCodeFind";
import ContactFind from "./ContactFind";
import { createJobConfigurationData } from "../../Features/createJobConfigurationSlice";
//   import BillingInfoForm from "./BillingInfoForm";
import { useFormik } from "formik";

import { useSelector, useDispatch } from "react-redux";
import {
  setClientSpecifications,
  setInvoiceAddressDifferent,
  setBillingInfo,
  createJobData
} from "../../Features/createJobDataSlice";

const useStyles = makeStyles({
  root: {
    // background: "#000"
    // padding: "8px"
  },

  pos: {
    marginBottom: 12
  },

  icon: {
    borderRadius: "50%",
    width: 22,
    height: 22,
    marginRight: "2px",
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5"
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)"
    }
  },
  oilgasIcon: {
    margin: "auto",
    marginRight: "5px",
    color: "#e8e8e8",
    width: "65px",
    height: "40px"
  },
  editIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px"
  },
  circularProgress: {
    width: "20px !important",
    height: "20px !important"
  },
  closeIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px",
    marginTop: "20px"
  },
  center: {
    height: `calc(100vh - 350px)`,
    marginTop: "15px",
    // background: "#000",
    // padding: 16,
    // display: "flex",
    backgroundColor: "rgb(255, 255, 255)",
    color: "#000",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "8px",
    position: "relative",
    zIndex: 0,
    // display: "flex",
    alignItems: "center",
    padding: "24px"
  },
  inputFeild: {
    color: "#fff",
    fontSize: "32px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  inputGrid: {
    paddingRight: "10px"
  },
  specifyGrid: {
    paddingTop: "10px",
    paddingRight: "10px"
  },
  jobCardButton: {
    color: "#fff",
    // borderRadius: '60%',
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#f15a2f"
    }
  },

  jobCardButton2: {
    // color:"#f15a2f",
    // borderRadius: '60%',
    fontSize: "12px",
    fontWeight: 600
    // "&:hover": {
    //   backgroundColor: '#ffff'
    // },
    // backgroundColor:"#ffff"
  },
  cardInfo: {
    marginTop: "15px"
  },
  tablePagination: {
    // position: 'sticky'
  },
  borderLeft: {
    borderLeft: "1px solid gray"
  },
  borderRight: {
    // paddingTop: "25px",
    // paddingBottom: "15px",
    // paddingLeft: "15px"
  },
  stackRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "16px",
    marginBottom: "8px"
  },
  stackIcon: {
    width: "24px",
    height: "24px",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(84, 214, 44)",
    backgroundColor: "rgba(84, 214, 44, 0.16)"
  },
  stackIconDetail: {
    padding: 3
  },
  stackSubtitle: {
    color: "#000",
    fontWeight: "600",
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    margin: "0px 0px 0px 8px"
  },
  chartRoot: {
    margin: "0px 0px 0px auto"
  },
  toggleButtonUnselected: {
    height: "45px",
    width: "auto",
    borderRadius: "3px",
    color: "#fff",
    fontSize: "14px",
    margin: "2px",
    borderColor: "#f15a2f",
    background: "#f15a2f",

    "&:hover": {
      background: "#f15a2f"
    }
  },
  toggleButtonSelected: {
    height: "45px",
    width: "auto",
    borderRadius: "3px",
    color: "#f15a2f",
    fontSize: "14px",
    margin: "2px",
    borderColor: "#f15a2f",
    background: "#fff",

    "&:hover": {
      background: "#fff"
    }
  },

  borderRightNone: {
    borderRight: "none"
  },
  borderLeftNone: {
    borderLeft: "none"
  },
  paymentOptionDiv: {
    marginTop: "15px",
    display: "flex",
    alignItems: "center",
    padding: "20px",
    // justifyContent: "space-between",
    borderRadius: "8px",
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    border: "1px solid rgba(145, 158, 171, 0.32)"
    // flexWrap: "wrap"
  },
  selectedPaymentOptionDiv: {
    marginTop: "15px",
    display: "flex",
    alignItems: "center",
    padding: "20px",
    // justifyContent: "space-between",
    borderRadius: "8px",
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    border: "1px solid rgba(241, 89, 47, 0.72)",
    // flexWrap: "wrap",
    // border: 1px solid rgba(145, 158, 171, 0.32);
    boxShadow: "rgb(145 158 171 / 24%) 0px 8px 16px 0px",
    width: "100%"
  },
  paymentRadio: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    "-webkit-tap-highlight-color": "transparent",
    backgroundColor: "transparent",
    outline: "0px",
    border: "0px",
    margin: "0px",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    appearance: "none",
    textDecoration: "none",
    borderRadius: "50%",
    color: "rgb(99, 115, 129)",
    padding: "8px",
    width: "100%"
  },
  formControlLabelPayment: {
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    fontWeight: 400
  },
  paymentHeadingRadio: {
    margin: "0px",
    marginLeft: "10px",
    fontWeight: "600",
    lineHeight: "1.57143",
    fontSize: "0.875rem"
  },
  paymentHeadingSubRadio: {
    margin: "0px",
    marginLeft: "10px",
    fontWeight: "400",
    lineHeight: "1.57143",

    fontSize: "0.875rem",
    color: "rgb(99, 115, 129)"
  },
  paymentContent: {
    padding: "24px"
  },
  checkRadioIcon: {
    color: "#f15a2f"
  },
  accordianMain: {
    boxShadow: "none",
    width: "100%"
  },
  accordianSummary: {
    padding: "0px",
    minHeight: "fit-content",
    width: "100%"
  },
  fullWidth: {
    width: "100%"
  }
});

const BillingInfoForm = forwardRef((props, ref) => {
  console.log("huffffOKhere", props);
  const classes = useStyles();
  const dispatch = useDispatch();
  const createJobConfiguration = useSelector(createJobConfigurationData);
  const createJobFormData = useSelector(createJobData);

  const validate = values => {
    const errors = {};

    if (!values.propertyReference) {
      errors.propertyReference = "Required";
    }

    if (!values.contact) {
      errors.contact = "Required";
    }
    if (!values.postcode) {
      errors.postcode = "Required";
    }
    if (!values.address1) {
      errors.address1 = "Required";
    }
    if (!values.town) {
      errors.town = "Required";
    }
    if (!values.county) {
      errors.county = "Required";
    }
    if (!values.telephone && !values.mobile) {
      errors.mobile = "Telephone or Mobile Required";
    }
    if (values.telephone === "N/A" && values.mobile === "N/A") {
      errors.mobile = "Telephone or Mobile Required";
    }
    var re = new RegExp("^0([1-6][0-9]{8,10}|7[0-9]{9})$");

    if (!re.test(values.telephone) && !re.test(values.mobile)) {
      errors.mobile = "Invalid Phone number";
    }

    // if (!values.fax) {
    //   errors.fax = "Required";
    // }
    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      propertyReference: "",
      address1: "",
      town: "",
      county: "",
      postcode: "",
      contact: "",
      telephone: "",
      mobile: "",
      email: ""
    },
    validateOnChange: false, // this one
    validateOnBlur: false,
    validate,
    onSubmit: values => {
      props.dataSaved();
      console.log(values,"valuesssssssssssss")
      dispatch(setBillingInfo(values));
      //   formik.resetForm();
    }
  });

  useEffect(() => {
    if (props.isPrevious && props.component === "old") {
      if (Object.keys(createJobFormData.properties).length > 0) {
        const data = createJobFormData.properties;
        updateForm(data);
      }
    }
    // if (Object.keys(createJobFormData.billingInfo).length > 0 && props.component === "new" ) {
    //   const data = createJobFormData.billingInfo;
    //   updateForm(data);
    // }
  }, [createJobFormData]);

  const updateForm = data => {
    formik.setFieldValue("postcode", data.postcode);
    formik.setFieldValue("address1", data.address1);
    formik.setFieldValue("town", data.town);
    formik.setFieldValue("county", data.county);
    formik.setFieldValue("telephone", data.telephone);
    formik.setFieldValue("mobile", data.mobile);

    formik.setFieldValue("email", data.email);
    formik.setFieldValue("propertyReference", data.propertyReference);
    formik.setFieldValue("contact", data.contact);
  };

  const handleClientNameChange = event => {
    event.preventDefault();
    const value = event.target.value;

    formik.setFieldValue("propertyReference", value);

    formik.setFieldValue("contact", value);

    // const unitTotal = value * formik.values.rate;
    // const vatAmount = (unitTotal * taxRate) / 100;
    // formik.setFieldValue("quantity", parseInt(value));
    // formik.setFieldValue("unitTotal", unitTotal);
    // formik.setFieldValue("vat", taxRate);
    // formik.setFieldValue("vatAmount", parseFloat(vatAmount.toFixed(2)));
    // formik.setFieldValue("unitGross", (unitTotal + vatAmount).toFixed(2));
  };

  useImperativeHandle(ref, () => ({
    saveData() {
      console.log("called here");
      formik.handleSubmit();
    }
  }));
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container className={classes.gridMargin}>
          {/* <Grid item xs={12} sm={12} md={8} lg={12} > */}

          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={2}
            className={classes.inputGrid}
          >
            <TextField
              label="Post Code"
              className={classes.inputFeild}
              margin="normal"
              variant="outlined"
              InputProps={{
                readOnly: props.component === "old" ? true : false
              }}
              fullWidth
              id="postcode"
              name="postcode"
              onChange={formik.handleChange}
              value={formik.values.postcode}
              error={formik.errors.postcode}
              helperText={formik.errors.postcode}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={3}
            className={classes.inputGrid}
          >
            <TextField
              label="Name"
              className={classes.inputFeild}
              margin="normal"
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: props.component === "old" ? true : false
              }}
              id="propertyReference"
              name="propertyReference"
              onChange={handleClientNameChange}
              value={formik.values.propertyReference}
              error={formik.errors.propertyReference}
              helperText={formik.errors.propertyReference}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            className={classes.inputGrid}
          >
            <TextField
              //   value={this.state.title}
              label="Contact"
              className={classes.inputFeild}
              margin="normal"
              //   onChange={this.handleChange}
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: props.component === "old" ? true : false
              }}
              id="contact"
              name="contact"
              onChange={formik.handleChange}
              value={formik.values.contact}
              error={formik.errors.contact}
              helperText={formik.errors.contact}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={4}
            className={classes.inputGrid}
          >
            <TextField
              //   value={this.state.title}
              label="Address 1"
              className={classes.inputFeild}
              margin="normal"
              //   onChange={this.handleChange}
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: props.component === "old" ? true : false
              }}
              id="address1"
              name="address1"
              onChange={formik.handleChange}
              value={formik.values.address1}
              error={formik.errors.address1}
              helperText={formik.errors.address1}
              //   error={errorTitle}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={2}
            className={classes.inputGrid}
          >
            <TextField
              //   value={this.state.title}
              label="Town"
              className={classes.inputFeild}
              margin="normal"
              //   onChange={this.handleChange}
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: props.component === "old" ? true : false
              }}
              id="town"
              name="town"
              onChange={formik.handleChange}
              value={formik.values.town}
              error={formik.errors.town}
              helperText={formik.errors.town}
              //   error={errorTitle}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={2}
            className={classes.inputGrid}
          >
            <TextField
              //   value={this.state.title}
              label="County"
              className={classes.inputFeild}
              margin="normal"
              //   onChange={this.handleChange}
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: props.component === "old" ? true : false
              }}
              id="county"
              name="county"
              onChange={formik.handleChange}
              value={formik.values.county}
              error={formik.errors.county}
              helperText={formik.errors.county}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={2}
            className={classes.inputGrid}
          >
            <TextField
              //   value={this.state.title}
              label="Contact Telephone"
              className={classes.inputFeild}
              margin="normal"
              //   onChange={this.handleChange}
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: props.component === "old" ? true : false
              }}
              id="telephone"
              name="telephone"
              onChange={formik.handleChange}
              value={formik.values.telephone}
              error={formik.errors.telephone}
              helperText={formik.errors.telephone}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={2}
            className={classes.inputGrid}
          >
            <TextField
              //   value={this.state.title}
              label="Mobile"
              className={classes.inputFeild}
              margin="normal"
              //   onChange={this.handleChange}
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: props.component === "old" ? true : false
              }}
              id="mobile"
              name="mobile"
              onChange={formik.handleChange}
              value={formik.values.mobile}
              error={formik.errors.mobile}
              helperText={formik.errors.mobile}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className={classes.inputGrid}
          >
            <TextField
              //   value={this.state.title}
              label="Email"
              className={classes.inputFeild}
              margin="normal"
              //   onChange={this.handleChange}
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: props.component === "old" ? true : false
              }}
              id="email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              error={formik.errors.email}
              helperText={formik.errors.email}
              //   error={errorTitle}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
});

export default BillingInfoForm;
