import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Chip from "@material-ui/core/Chip";
import DeleteIcon from "@material-ui/icons/Delete";
import TableChartIcon from "@material-ui/icons/TableChart";
import DoneIcon from "@material-ui/icons/Done";
import TableHead from "@material-ui/core/TableHead";
import GetAppIcon from "@material-ui/icons/GetApp";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Grid from "@material-ui/core/Grid";

import EditIcon from "@material-ui/icons/Edit";

import { useSelector, useDispatch } from "react-redux";
import {
  setProperties,
  addJobItem,
  editJobData,
  deleteJobItem,
  addAllJobItems,
} from "../../Features/editJobDataSlice";
import {
  createJobConfigurationData,
  setJobItems,
  setUpdatedJobItems,
} from "../../Features/createJobConfigurationSlice";

const useStyles = makeStyles({
  startAutoJob: {
    color: "#fff",
    background: "#f15a2f",
    width: "auto",
    height: "53px",
    marginRight: "10px",

    marginTop: "10px",
    marginBottom: "10px",
    marginLeft: "auto",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#f15a2f",
      color: "#fff",
    },
    float: "right",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  titleDiv: {
    display: "flex",
  },
  status: {
    fontSize: 14,
    color: "#fff",
    marginTop: "2px",
    fontStyle: "italic",
  },
  statusTime: {
    marginTop: 15,
  },
  pos: {
    marginBottom: 12,
  },
  linearProgress: {
    marginTop: "15px",
  },
  chip: {
    color: "primary",
    height: "23px",
  },
  jobCardTitle: {
    color: "#000",
    fontWeight: 600,
    lineHeight: "1.57143",
    fontSize: "1.275rem",
    margin: "none",
  },
  jobCardPrice: {
    color: "#000",
    fontSize: "2rem",
    marginTop: "7px",
    marginLeft: "7px",
    // fontWeight: '600',
    letterSpacing: "0.1rem",
    lineHeight: 1.5,
  },
  snackbar: {
    backgroundColor: "rgb(50, 50, 50)",
  },
  close: {
    color: "#fff",
  },
  icon: {
    color: "#fff",
    margin: "4px 4px",
  },
  oilgasIcon: {
    margin: "auto",
    marginRight: "5px",
    color: "#e8e8e8",
    width: "65px",
    height: "40px",
  },
  editIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px",
  },
  circularProgress: {
    width: "20px !important",
    height: "20px !important",
    color: "#fff",
  },
  closeIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px",
    marginTop: "20px",
  },

  feildsGrid: {
    marginTop: "8px",
  },
  jobCardButton: {
    color: "#fff",
    // borderRadius: '60%',
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#f15a2f",
    },
  },

  jobCardButton2: {
    // color:"#f15a2f",
    // borderRadius: '60%',
    fontSize: "12px",
    fontWeight: 600,
    // "&:hover": {
    //   backgroundColor: '#ffff'
    // },
    // backgroundColor:"#ffff"
  },
  cardInfo: {
    marginTop: "15px",
  },
  tablePagination: {
    // position: 'sticky'
  },
  borderLeft: {
    borderLeft: "1px solid gray",
  },
  borderRight: {
    margin: "25px",
    // paddingBottom: "15px",
    // paddingLeft: "15px"
  },
  notificationsGrid: {
    margin: "0 25px 25px 25px",
  },
  stackRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "16px",
    marginBottom: "8px",
  },
  stackIcon: {
    width: "24px",
    height: "24px",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(84, 214, 44)",
    backgroundColor: "rgba(84, 214, 44, 0.16)",
  },
  stackIconDetail: {
    padding: 3,
  },
  stackSubtitle: {
    color: "#000",
    fontWeight: "600",
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    margin: "0px 0px 0px 8px",
  },
  chartRoot: {
    margin: "0px 0px 0px auto",
  },
  cardSubTitle: {
    fontWeight: 400,
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    // textAlign:"left",
    // margin: '4px 0px 0px',
    color: "rgb(99, 115, 129)",
    display: "block",
  },
  textField: {
    // width: "100%",
    marginLeft: "5px",
    marginRight: "5px",
  },
  autoComplete: {
    width: "15%",
    marginTop: "5px",
    marginLeft: "5px",
    marginRight: "5px",
    /* display: flex; */

    display: "inline-flex",
    position: "relative",
    minWidth: 0,
    flexDirection: "column",
    verticalAlign: "top",
  },
  selectFeild: {
    width: "10%",
    marginTop: "15px",
    marginLeft: "5px",
    marginRight: "5px",
  },
  findNotification: {
    color: "#fff",
    background: "#000;",
    width: "auto",
    height: "53px",
    marginTop: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#000;",
      color: "#fff",
    },
  },
  tableHeading: {
    padding: "15px",
    fontSize: "20px",
    color: "#fff",
    display: "flex",
    background: "#000;",
  },
  tableBold: {
    fontWeight: 600,
    fontSize: "17px",
    color: "#000;",
  },

  searchIcon: {
    cursor: "pointer",
    display: "flex",
    alignItems: "right",
    justifyContent: "right",
    marginLeft: "auto",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: "8px, 8px, 8px, 8px",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + 32px)`,
    width: "100%",
  },
  tableFooter: {
    width: "fit-content",
    marginTop: "20px",
    marginLeft: "auto",
  },
  noDataFoundText: {
    color: "#000",
    fontSize: "1rem",
    marginBottom: "7px",
    marginTop: "7px",
    margin: "auto",
    textAlign: "center",
    fontWeight: "600",
    // letterSpacing: "0.1rem",
    lineHeight: 1.5,
  },
  root: {
    // background: "#000"
    // padding: "8px"
  },

  center: {
    height: `calc(100vh - 350px)`,
    marginTop: "15px",
    // background: "#000",
    // padding: 16,
    // display: "flex",
    backgroundColor: "rgb(255, 255, 255)",
    color: "#000",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "8px",
    position: "relative",
    zIndex: 0,
    // display: "flex",
    alignItems: "center",
    padding: "24px",
  },
  inputFeild: {
    color: "#fff",
    fontSize: "32px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },

  toggleButtonUnselected: {
    height: "45px",
    width: "auto",
    borderRadius: "3px",
    color: "#fff",
    fontSize: "14px",
    margin: "2px",
    borderColor: "#f15a2f",
    background: "#f15a2f",

    "&:hover": {
      background: "#f15a2f",
    },
  },
  toggleButtonSelected: {
    height: "45px",
    width: "auto",
    borderRadius: "3px",
    color: "#f15a2f",
    fontSize: "14px",
    margin: "2px",
    borderColor: "#f15a2f",
    background: "#fff",

    "&:hover": {
      background: "#fff",
    },
  },

  borderRightNone: {
    borderRight: "none",
  },
  borderLeftNone: {
    borderLeft: "none",
  },
  iconContent: {
    cursor: "pointer",
    marginTop: "6rem",
    transition: "box-shadow 0.3s ease",
    boxShadow: "0 1px 3px rgb(77 83 88 / 23%)",
    backgroundColor: "#fff",
    border: "1px solid #C6C6C6",
    padding: "1.5rem 1.25rem",
    margin: "auto",
    width: "300px",
    "&:hover": {
      transition: "box-shadow 0.3s ease",
      boxShadow: "0 5px 10px rgb(77 83 88 / 23%)",
    },
  },
  fullWidth: {
    width: "100%",
  },
  addIcon: {
    margin: "auto",
    width: "100%",
    fontSize: "50px",
  },
  moreVertIcon: {
    cursor: "pointer",
  },
  menuIcon: {
    cursor: "pointer",
    marginLeft: "10px",
    fontSize: "30px",
    color: "#333333",
    // marginLeft: "auto"
  },
  tableContainer: {
    maxHeight: "420px",
    overflow: "auto",
  },
});

function capitalizeFirstLetter([first, ...rest], locale = navigator.language) {
  return [first.toLocaleUpperCase(locale), ...rest].join("");
}

function ItemsTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [alignment, setAlignment] = React.useState(false);
  const [tableColumns, setColumnTables] = React.useState([
    {
      field: "sorReference",
      title: "SOR Ref",
    },
    // {
    //   field: "customerAccountRef",
    //   title: "Customer Account Ref"
    // },

    {
      field: "descriptionOne",
      title: "Description 1",
    },
    {
      field: "quantity",
      title: "QTY",
    },
    {
      field: "unit",
      title: "Unit",
    },
    {
      field: "rate",
      title: "Rate",
    },
    {
      field: "taxRef",
      title: "Tax Ref",
    },
    {
      field: "vatAmount",
      title: "Vat",
    },
    {
      field: "action",
      title: "Action",
    },
  ]);
  const [value, setValue] = React.useState(1);
  const [jobItems, setJobItems] = React.useState([]);
  const editJobFormData = useSelector(editJobData);
  const createJobConfiguration = useSelector(createJobConfigurationData);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [paymentRecieved, setPaymentRecieved] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (row) => {
    props.handleItemEdit(row);
  };

  const handleDelete = (row) => {
    console.log(row.identifier, "row.identifier");
    dispatch(deleteJobItem(row.identifier));
  };
  useEffect(() => {
    if (editJobFormData.paymentRecieved) {
      setPaymentRecieved(true);
      setColumnTables([
        {
          field: "sorReference",
          title: "SOR Ref",
        },
        // {
        //   field: "customerAccountRef",
        //   title: "Customer Account Ref"
        // },

        {
          field: "descriptionOne",
          title: "Description 1",
        },
        {
          field: "quantity",
          title: "QTY",
        },
        {
          field: "unit",
          title: "Unit",
        },
        {
          field: "rate",
          title: "Rate",
        },
        {
          field: "taxRef",
          title: "Tax Ref",
        },
        {
          field: "vatAmount",
          title: "Vat",
        },
      ]);
    }
    const jobItems = editJobFormData.jobItems.filter(
      (pro) => pro.isDeleted !== true
    );

    if (jobItems.length > 0) {
      console.log(editJobFormData.jobItems, "row.identifier");
      // setJobItems(jobItems);

      const selectedZone = editJobFormData.properties.zone.zoneID;
      const newZone = editJobFormData.jobItems.findIndex(
        (jobItem) => jobItem.zoneID !== selectedZone
      );
      if (newZone !== -1) {
        const allJobs = createJobConfiguration.jobItems;
        const updatedJobs = editJobFormData.jobItems.map((item) => {
          const newRate = allJobs.find((x) => x.ID === item.itemID).rate;
          const unitTotal = item.quantity * newRate;
          const vatAmount = (unitTotal * item.vatRate) / 100;
          console.log("newJobstest", item);
          return {
            ...item,
            zoneID: selectedZone,
          };
        });
        dispatch(addAllJobItems(updatedJobs));
      } else {
        setJobItems(editJobFormData.jobItems);
      }
    } else {
      setJobItems([]);
    }
  }, [editJobFormData.jobItems]);

  return (
    <Grid
      className={classes.grid}
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <div className={classes.tableHeading}>
        <Typography>Items</Typography>
        {!paymentRecieved && (
          <AddCircleOutlineIcon
            className={classes.searchIcon}
            onClick={() => props.handleOpenItemFormOpen()}
          />
        )}
      </div>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="custom pagination table" className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableHead}>
              {tableColumns.map((column) => (
                <TableCell
                  className={classes.tableHeadCol}
                  variant="head"
                  align="center"
                >
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody className={classes.tableHead}>
            {jobItems.map((row) => (
              <TableRow>
                {tableColumns.map((column) => (
                  <TableCell component="th" scope="row" align="center">
                    {column.field == "action" ? (
                      <>
                        <EditIcon
                          onClick={() => handleEdit(row)}
                          className={classes.menuIcon}
                        />
                        <DeleteIcon
                          onClick={() => handleDelete(row)}
                          className={classes.menuIcon}
                        />
                      </>
                    ) : (
                      row[column.field]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>

          <TableFooter>
            <TableRow></TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <div className={classes.tableFooter}>
        {/* <Pagination
          count={totalPages}
          variant="outlined"
          onChange={this.handlePageChange}
        /> */}
      </div>
    </Grid>
  );
}

export default ItemsTable;
