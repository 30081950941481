import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarContent from "@material-ui/core/SnackbarContent"
import React from 'react';

const ConfirmedSnackBar = (props) => {
  const {
    classes, open, message, handleClose,
  } = props;
  return (
    <Snackbar
    className={classes.root}
    // 
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
     
    >
       <SnackbarContent
          className={classes.snackbar}
          message={<span id="message-id">{message}</span>}
           
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          // color="inherit"
          className={classes.close}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}
          
        />
        </Snackbar>
  );
};
export default ConfirmedSnackBar;
