import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Chip from "@material-ui/core/Chip";
import DeleteIcon from "@material-ui/icons/Delete";
import TableChartIcon from "@material-ui/icons/TableChart";
import DoneIcon from "@material-ui/icons/Done";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import GetAppIcon from "@material-ui/icons/GetApp";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import moment from "moment-timezone";
import Grid from "@material-ui/core/Grid";
import ItemsTable from "./ItemsTable";
import AddItemFormDialog from "./AddItemFormDialog";
import { useSelector, useDispatch } from "react-redux";
import {
  createJobConfigurationData,
  setJobItems,
  setUpdatedJobItems
} from "../../Features/createJobConfigurationSlice";
import {
  setSelectedZone,
  setProperties,
  addJobItem,
  deleteJobItem,
  createJobData
} from "../../Features/createJobDataSlice";
import EditItemFormDialog from "./EditItemFormDialog";

const useStyles = makeStyles({
  root: {
    // background: "#000"
    // padding: "8px"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  titleDiv: {
    display: "flex"
  },
  status: {
    fontSize: 14,
    color: "#fff",
    marginTop: "2px",
    fontStyle: "italic"
  },
  statusTime: {
    marginTop: 15
  },
  pos: {
    marginBottom: 12
  },
  linearProgress: {
    marginTop: "15px"
  },
  chip: {
    color: "primary",
    height: "23px"
  },
  jobCardTitle: {
    color: "#000",
    fontWeight: 600,
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    margin: "none"
  },
  cardSubTitle: {
    fontWeight: 400,
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    // margin: '4px 0px 0px',
    color: "rgb(99, 115, 129)",
    display: "block"
  },
  jobCardPrice: {
    color: "#000",
    fontSize: "1rem",
    marginBottom: "7px",
    fontWeight: "600",
    // letterSpacing: "0.1rem",
    lineHeight: 1.5
  },
  icon: {
    color: "#fff",
    margin: "4px 4px"
  },
  oilgasIcon: {
    margin: "auto",
    marginRight: "5px",
    color: "#e8e8e8",
    width: "65px",
    height: "40px"
  },
  editIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px"
  },
  circularProgress: {
    width: "20px !important",
    height: "20px !important"
  },
  closeIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px",
    marginTop: "20px"
  },
  center: {
    height: `calc(100vh - 350px)`,
    marginTop: "15px",
    // background: "#000",
    // padding: 16,
    // display: "flex",
    backgroundColor: "rgb(255, 255, 255)",
    color: "#000",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "8px",
    position: "relative",
    zIndex: 0,
    // display: "flex",
    alignItems: "center",
    padding: "24px"
  },
  inputFeild: {
    color: "#fff",
    fontSize: "32px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  jobCardButton: {
    color: "#fff",
    // borderRadius: '60%',
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#f15a2f"
    }
  },

  jobCardButton2: {
    // color:"#f15a2f",
    // borderRadius: '60%',
    fontSize: "12px",
    fontWeight: 600
    // "&:hover": {
    //   backgroundColor: '#ffff'
    // },
    // backgroundColor:"#ffff"
  },
  cardInfo: {
    marginTop: "15px"
  },
  tablePagination: {
    // position: 'sticky'
  },
  borderLeft: {
    borderLeft: "1px solid gray"
  },
  borderRight: {
    // paddingTop: "25px",
    // paddingBottom: "15px",
    // paddingLeft: "15px"
  },
  stackRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "16px",
    marginBottom: "8px"
  },
  stackIcon: {
    width: "24px",
    height: "24px",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(84, 214, 44)",
    backgroundColor: "rgba(84, 214, 44, 0.16)"
  },
  stackIconDetail: {
    padding: 3
  },
  stackSubtitle: {
    color: "#000",
    fontWeight: "600",
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    margin: "0px 0px 0px 8px"
  },
  chartRoot: {
    margin: "0px 0px 0px auto"
  },
  toggleButtonUnselected: {
    height: "45px",
    width: "auto",
    borderRadius: "3px",
    color: "#fff",
    fontSize: "14px",
    margin: "2px",
    borderColor: "#f15a2f",
    background: "#f15a2f",

    "&:hover": {
      background: "#f15a2f"
    }
  },
  toggleButtonSelected: {
    height: "45px",
    width: "auto",
    borderRadius: "3px",
    color: "#f15a2f",
    fontSize: "14px",
    margin: "2px",
    borderColor: "#f15a2f",
    background: "#fff",

    "&:hover": {
      background: "#fff"
    }
  },

  borderRightNone: {
    borderRight: "none"
  },
  borderLeftNone: {
    borderLeft: "none"
  },
  iconContent: {
    cursor: "pointer",
    marginTop: "6rem",
    transition: "box-shadow 0.3s ease",
    boxShadow: "0 1px 3px rgb(77 83 88 / 23%)",
    backgroundColor: "#fff",
    // border: "1px solid #C6C6C6",
    padding: "1.5rem 1.25rem",
    margin: "auto",
    width: "300px",
    "&:hover": {
      transition: "box-shadow 0.3s ease",
      boxShadow: "0 5px 10px rgb(77 83 88 / 23%)"
    }
  },
  fullWidth: {
    width: "100%"
  },
  addIcon: {
    margin: "auto",
    width: "100%",
    fontSize: "50px"
  },
  displayFlex: {
    display: "flex"
  }
});

function capitalizeFirstLetter([first, ...rest], locale = navigator.language) {
  return [first.toLocaleUpperCase(locale), ...rest].join("");
}

function AddItems(props) {
  const [openItemsForm, setOpenItemsForm] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const createJobFormData = useSelector(createJobData);
  const createJobConfiguration = useSelector(createJobConfigurationData);
  const [showTable, setShowTable] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectJobItemToEdit, setSelectJobItemToEdit] = useState({});

  useEffect(() => {
    if (createJobFormData.jobItems.length > 0) {
      setShowTable(true);
    }
  }, [createJobFormData]);
  useEffect(() => {
    if (
      Object.keys(createJobFormData.properties).length > 0 &&
      createJobFormData.properties.zone
    ) {
      const selectedZone = createJobFormData.properties.zone.zoneID;
      const oldJobs = createJobConfiguration.jobItems;
      const updatedJobs = oldJobs.map(item => {
        const newRate = item.rates.find(x => x.zoneID === selectedZone).rate;

        return { ...item, rate: newRate };
      });
      console.log("newJobs", updatedJobs, selectedZone);

      dispatch(setSelectedZone(selectedZone));
      dispatch(setUpdatedJobItems(updatedJobs));
    }
  }, []);

  const handleOpenItemFormClose = () => {
    setOpenItemsForm(false);
  };
  const handleOpenItemFormOpen = () => {
    setOpenItemsForm(true);
  };
  const handleEditDialogOpen = () => {
    setOpenEditDialog(false);
    setSelectJobItemToEdit({});
  };

  const handleItemEdit = row => {
    setSelectJobItemToEdit(row);
    setOpenEditDialog(true);
  };
  return (
    <Grid container direction="row">
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.borderRight}
      >
        <Card className={classes.center}>
          <div>
            <div className={classes.displayFlex}>
              <Typography
                variant="h6"
                component="h6"
                className={classes.jobCardPrice}
              >
                Add Items
              </Typography>
              {createJobFormData.iwsAccount !== null && (
                <>
                  {createJobFormData.iwsAccount ? (
                    <Chip
                      size="small"
                      style={{
                        backgroundColor: "#e5f9ed",
                        color: "#1bc943",
                        fontWeight: 600,
                        padding: "5px",
                        marginLeft: "auto"
                      }}
                      label={"IWS Client"}
                    />
                  ) : (
                    <Chip
                      size="small"
                      style={{
                        marginLeft: "auto",
                        backgroundColor: "#fff5f6",
                        color: "#f83245",
                        fontWeight: 600,
                        padding: "5px"
                      }}
                      label={"Non IWS Client"}
                    />
                  )}
                </>
              )}
            </div>

            {!showTable && (
              <div className={classes.fullWidth}>
                <div
                  className={classes.iconContent}
                  onClick={handleOpenItemFormOpen}
                >
                  <AddCircleOutlineIcon
                    className={classes.addIcon}
                    color="primary"
                  />
                  <Typography variant="h6" align="center" color="primary">
                    Add Item
                  </Typography>
                </div>
              </div>
            )}
            {openItemsForm && (
              <AddItemFormDialog
                open={openItemsForm}
                handleClose={handleOpenItemFormClose}
              />
            )}

            {openEditDialog && (
              <EditItemFormDialog
                open={openEditDialog}
                handleClose={handleEditDialogOpen}
                selectJobItemToEdit={selectJobItemToEdit}
              />
            )}

            {showTable && (
              <ItemsTable
                handleItemEdit={handleItemEdit}
                handleOpenItemFormOpen={handleOpenItemFormOpen}
              />
            )}
          </div>
        </Card>
      </Grid>
    </Grid>
  );
}

export default AddItems;
