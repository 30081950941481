import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteIcon from "@material-ui/icons/Delete";
import TableChartIcon from "@material-ui/icons/TableChart";
import DoneIcon from "@material-ui/icons/Done";
import GetAppIcon from "@material-ui/icons/GetApp";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import moment from "moment-timezone";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { createJobConfigurationData } from "../../Features/createJobConfigurationSlice";
import {
  setProperties,
  createJobData,
  setIWSAccount
} from "../../Features/createJobDataSlice";
const useStyles = makeStyles({
  root: {
    // background: "#000"
    // padding: "8px"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  titleDiv: {
    display: "flex"
  },
  status: {
    fontSize: 14,
    color: "#fff",
    marginTop: "2px",
    fontStyle: "italic"
  },
  statusTime: {
    marginTop: 15
  },
  pos: {
    marginBottom: 12
  },
  linearProgress: {
    marginTop: "15px"
  },
  chip: {
    color: "primary",
    height: "23px"
  },
  jobCardTitle: {
    color: "#000",
    fontWeight: 600,
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    margin: "none"
  },
  cardSubTitle: {
    fontWeight: 400,
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    // margin: '4px 0px 0px',
    color: "rgb(99, 115, 129)",
    display: "block"
  },
  jobCardPrice: {
    color: "#000",
    fontSize: "1rem",
    marginBottom: "7px",
    fontWeight: "600",
    // letterSpacing: "0.1rem",
    lineHeight: 1.5
  },
  icon: {
    color: "#fff",
    margin: "4px 4px"
  },
  oilgasIcon: {
    margin: "auto",
    marginRight: "5px",
    color: "#e8e8e8",
    width: "65px",
    height: "40px"
  },
  editIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px"
  },
  circularProgress: {
    width: "20px !important",
    height: "20px !important"
  },
  closeIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px",
    marginTop: "20px"
  },
  center: {
    height: `calc(100vh - 390px)`,
    marginTop: "15px",
    // background: "#000",
    // padding: 16,
    // display: "flex",
    backgroundColor: "rgb(255, 255, 255)",
    color: "#000",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "8px",
    position: "relative",
    zIndex: 0,
    // display: "flex",
    alignItems: "center",
    padding: "24px"
  },
  inputFeild: {
    color: "#fff",
    fontSize: "32px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  inputGrid: {
    paddingRight: "10px"
  },
  jobCardButton: {
    color: "#fff",
    // borderRadius: '60%',
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#f15a2f"
    }
  },

  jobCardButton2: {
    // color:"#f15a2f",
    // borderRadius: '60%',
    fontSize: "12px",
    fontWeight: 600
    // "&:hover": {
    //   backgroundColor: '#ffff'
    // },
    // backgroundColor:"#ffff"
  },
  cardInfo: {
    marginTop: "15px"
  },
  tablePagination: {
    // position: 'sticky'
  },
  borderLeft: {
    borderLeft: "1px solid gray"
  },
  borderRight: {
    // paddingTop: "25px",
    // paddingBottom: "15px",
    // paddingLeft: "15px"
  },
  stackRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "16px",
    marginBottom: "8px"
  },
  stackIcon: {
    width: "24px",
    height: "24px",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(84, 214, 44)",
    backgroundColor: "rgba(84, 214, 44, 0.16)"
  },
  stackIconDetail: {
    padding: 3
  },
  stackSubtitle: {
    color: "#000",
    fontWeight: "600",
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    margin: "0px 0px 0px 8px"
  },
  chartRoot: {
    margin: "0px 0px 0px auto"
  },
  toggleButtonUnselected: {
    height: "45px",
    width: "auto",
    borderRadius: "3px",
    color: "#fff",
    fontSize: "14px",
    margin: "2px",
    borderColor: "#f15a2f",
    background: "#f15a2f",

    "&:hover": {
      background: "#f15a2f"
    }
  },
  toggleButtonSelected: {
    height: "45px",
    width: "auto",
    borderRadius: "3px",
    color: "#f15a2f",
    fontSize: "14px",
    margin: "2px",
    borderColor: "#f15a2f",
    background: "#fff",

    "&:hover": {
      background: "#fff"
    }
  },

  borderRightNone: {
    borderRight: "none"
  },
  borderLeftNone: {
    borderLeft: "none"
  },
  gridMargin: {
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: "10px",
    display: "flex"
  },
  findPostCode: {
    color: "#fff",
    fontWeight: 600,
    background: "#f15a2f",
    width: "350px",
    height: "53px",

    marginTop: "17px",

    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#f15a2f",
      color: "#fff"
    },
    float: "right"
  },
  textFeildMargin: {
    marginLeft: "10px"
  }
});

const AddPropertyForm = forwardRef((props, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const createJobConfiguration = useSelector(createJobConfigurationData);
  const createJobFormData = useSelector(createJobData);
  console.log(createJobConfiguration, "createJOB");
  const [zones, setZones] = useState([]);
  const [catagories, setCatagories] = useState([]);
  const [types, setTypes] = useState([]);
  const validate = values => {
    const errors = {};

    if (!values.postcode) {
      errors.postcode = "Required";
    }
    if (!values.propertyNumber) {
      errors.propertyNumber = "Required";
    }
    if (!values.propertyReference) {
      errors.propertyReference = "Required";
    }

    if (!values.propertyName) {
      errors.propertyName = "Required";
    }
    if (!values.contact) {
      errors.contact = "Required";
    }
    if (!values.address1) {
      errors.address1 = "Required";
    }
    if (!values.town) {
      errors.town = "Required";
    }
    if (!values.county) {
      errors.county = "Required";
    }
    if (!values.telephone && !values.mobile) {
      errors.mobile = "Telephone or Mobile Required";
    }
    if (values.telephone === "N/A" && values.mobile === "N/A") {
      errors.mobile = "Telephone or Mobile Required";
    }
    var re = new RegExp("^0([1-6][0-9]{8,10}|7[0-9]{9})$");

    if (!re.test(values.telephone) && !re.test(values.mobile)) {
      errors.mobile = "Invalid Phone number";
    }

    // if (!values.fax) {
    //   errors.fax = "Required";
    // }
    if (!values.email) {
      formik.setFieldValue("email", "info@dynoni.net");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.zone) {
      errors.zone = "Required";
    }
    if (!values.catagory) {
      errors.catagory = "Required";
    }
    if (!values.type) {
      errors.type = "Required";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      postcode: "",
      propertyNumber: "",
      propertyReference: "",
      propertyID: props.existingUser ? "" : 1,
      sageAccountRef: "",
      iwsClientAccountReference: "N/A",
      iwsClientID: -1,
      contact: "",
      propertyName: "",
      address1: "",
      town: "",
      county: "",
      telephone: "",
      mobile: "",
      fax: "",
      email: "",
      zone: "",
      catagory: "",
      type: ""
    },
    validateOnChange: false, // this one
    validateOnBlur: false,
    validate,
    onSubmit: values => {
      values.swipeValue = props.swipeValue;
      values.existingUser = props.existingUser;
      console.log("calledcalled");
      props.dataSaved();
      if (createJobFormData.iwsAccount == null) {
        dispatch(setIWSAccount(false));
      }
      dispatch(setProperties(values));
      formik.resetForm();
    }
  });
  useEffect(() => {
    setZones(createJobConfiguration.zones);
    setCatagories(createJobConfiguration.categories);
    setTypes(createJobConfiguration.types);

    formik.setFieldValue(
      "sageAccountRef",
      createJobConfiguration.sageAccountReference
    );
    formik.setFieldValue("catagory", "PRIVATE");
    formik.setFieldValue("type", "HOUSE");
  }, [createJobConfiguration]);

  useImperativeHandle(ref, () => ({
    saveData() {
      formik.handleSubmit();
    }
  }));

  const updateForm = createJobFormData => {
    if (Object.keys(createJobFormData.properties).length > 0) {
      const data = createJobFormData.properties;
      console.log("updatefetchedData2222555555", data);
      formik.setFieldValue(
        "sageAccountRef",
        createJobConfiguration.sageAccountReference
      );
      formik.setFieldValue(
        "iwsClientAccountReference",
        data.iwsClientAccountReference
      );
      formik.setFieldValue("iwsClientID", data.iwsClientID);
      formik.setFieldValue("propertyID", data.propertyID);
      formik.setFieldValue("propertyNumber", data.propertyNumber);
      formik.setFieldValue("postcode", data.postcode);
      formik.setFieldValue("propertyReference", data.propertyReference);

      formik.setFieldValue("contact", data.contact);
      formik.setFieldValue("propertyName", data.propertyName);
      formik.setFieldValue("address1", data.address1);
      formik.setFieldValue("town", data.town);
      formik.setFieldValue("county", data.county);

      formik.setFieldValue("telephone", data.telephone);
      formik.setFieldValue("mobile", data.mobile);
      formik.setFieldValue("fax", data.fax);
      formik.setFieldValue("email", data.email);

      formik.setFieldValue("zone", data.zone);
      formik.setFieldValue("catagory", data.catagory);
      formik.setFieldValue("type", data.type);
    }
  };

  useEffect(() => {
    console.log(
      createJobFormData,
      "createJobFormDatacreateJobFormDatacreateJobFormData"
    );
    updateForm(createJobFormData);
  }, [createJobFormData]);
  useEffect(() => {
    updateForm(createJobFormData);
  }, []);

  useEffect(() => {
    // formik.resetForm();
    if (props.fetchedData) {
      formik.setFieldValue(
        "sageAccountRef",
        createJobConfiguration.sageAccountReference
      );
      formik.setFieldValue(
        "iwsClientAccountReference",
        props.fetchedData.iwsClientAccountReference
      );
      formik.setFieldValue("iwsClientID", props.fetchedData.iwsClientID);
      formik.setFieldValue("propertyID", props.fetchedData.propertyID);

      formik.setFieldValue("propertyNumber", props.fetchedData.propertyNumber);
      formik.setFieldValue("postcode", props.fetchedData.postcode);
      formik.setFieldValue(
        "propertyReference",
        props.fetchedData.propertyReference
      );

      formik.setFieldValue("contact", props.fetchedData.propertyReference);
      formik.setFieldValue("propertyName", props.fetchedData.propertyReference);
      formik.setFieldValue("address1", props.fetchedData.propertyAddressOne);
      formik.setFieldValue("town", props.fetchedData.town);
      formik.setFieldValue("county", props.fetchedData.county);

      formik.setFieldValue("telephone", props.fetchedData.telephone);
      formik.setFieldValue("mobile", props.fetchedData.mobile);
      formik.setFieldValue("fax", props.fetchedData.fax);
      formik.setFieldValue("email", props.fetchedData.email);

      if (props.fetchedData.type && props.fetchedData.type != "N/A") {
        formik.setFieldValue("type", props.fetchedData.type);
      }
      if (props.fetchedData.catagory && props.fetchedData.catagory != "N/A") {
        formik.setFieldValue("catagory", props.fetchedData.catagory);
      }

      if (
        props.fetchedData.zoneReference &&
        props.fetchedData.zoneReference != "N/A"
      ) {
        console.log("zones", createJobConfiguration.zones);
        const zone = createJobConfiguration.zones.find(
          zone => zone.zoneName == props.fetchedData.zoneReference
        );
        formik.setFieldValue("zone", zone);
      }
    }
  }, [props.fetchedData]);

  const handleClientNameChange = event => {
    event.preventDefault();
    const value = event.target.value;

    formik.setFieldValue("propertyReference", value);

    formik.setFieldValue("contact", value);
    formik.setFieldValue("propertyName", value);

    // const unitTotal = value * formik.values.rate;
    // const vatAmount = (unitTotal * taxRate) / 100;
    // formik.setFieldValue("quantity", parseInt(value));
    // formik.setFieldValue("unitTotal", unitTotal);
    // formik.setFieldValue("vat", taxRate);
    // formik.setFieldValue("vatAmount", parseFloat(vatAmount.toFixed(2)));
    // formik.setFieldValue("unitGross", (unitTotal + vatAmount).toFixed(2));
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container className={classes.gridMargin}>
          {/* <Grid item xs={12} sm={12} md={8} lg={12} > */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.inputGrid}
          >
            <Typography
              variant="h6"
              component="h6"
              className={classes.jobCardPrice}
            >
              Properties
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={3}
            className={classes.inputGrid}
          >
            <TextField
              label="Post Code"
              className={classes.inputFeild}
              margin="normal"
              variant="outlined"
              fullWidth
              id="postcode"
              name="postcode"
              onChange={formik.handleChange}
              value={formik.values.postcode}
              error={formik.errors.postcode}
              helperText={formik.errors.postcode}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={3}
            className={classes.inputGrid}
          >
            <TextField
              label="Property Number"
              className={classes.inputFeild}
              margin="normal"
              variant="outlined"
              fullWidth
              id="propertyNumber"
              name="propertyNumber"
              onChange={formik.handleChange}
              value={formik.values.propertyNumber}
              error={formik.errors.propertyNumber}
              helperText={formik.errors.propertyNumber}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={3}
            className={classes.inputGrid}
          >
            <TextField
              label="Client Name/Description"
              className={classes.inputFeild}
              margin="normal"
              variant="outlined"
              fullWidth
              id="propertyReference"
              name="propertyReference"
              onChange={handleClientNameChange}
              value={formik.values.propertyReference}
              error={formik.errors.propertyReference}
              helperText={formik.errors.propertyReference}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={3}
            className={classes.inputGrid}
          >
            <TextField
              label="IWS Account Reference"
              className={classes.inputFeild}
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
              fullWidth
              id="iwsClientAccountReference"
              name="iwsClientAccountReference"
              onChange={formik.handleChange}
              value={formik.values.iwsClientAccountReference}
              error={formik.errors.iwsClientAccountReference}
              helperText={formik.errors.iwsClientAccountReference}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            className={classes.inputGrid}
          >
            <TextField
              label="Sage Account Ref"
              className={classes.inputFeild}
              margin="normal"
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              id="sageAccountRef"
              name="sageAccountRef"
              onChange={formik.handleChange}
              value={formik.values.sageAccountRef}
              error={formik.errors.sageAccountRef}
              helperText={formik.errors.sageAccountRef}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            className={classes.inputGrid}
          >
            <TextField
              //   value={this.state.title}
              label="Payer (Passed in Sagepay)"
              className={classes.inputFeild}
              margin="normal"
              //   onChange={this.handleChange}
              variant="outlined"
              fullWidth
              id="contact"
              name="contact"
              onChange={formik.handleChange}
              value={formik.values.contact}
              error={formik.errors.contact}
              helperText={formik.errors.contact}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              //   value={this.state.title}
              label="Property Name/ Description (Displayed on Mobile App)"
              className={classes.inputFeild}
              margin="normal"
              //   onChange={this.handleChange}
              variant="outlined"
              fullWidth
              id="propertyName"
              name="propertyName"
              onChange={formik.handleChange}
              value={formik.values.propertyName}
              error={formik.errors.propertyName}
              helperText={formik.errors.propertyName}

              //   error={errorTitle}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            className={classes.inputGrid}
          >
            <TextField
              //   value={this.state.title}
              label="Address 1"
              className={classes.inputFeild}
              margin="normal"
              //   onChange={this.handleChange}
              variant="outlined"
              fullWidth
              id="address1"
              name="address1"
              onChange={formik.handleChange}
              value={formik.values.address1}
              error={formik.errors.address1}
              helperText={formik.errors.address1}
              //   error={errorTitle}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            className={classes.inputGrid}
          >
            <TextField
              //   value={this.state.title}
              label="Town"
              className={classes.inputFeild}
              margin="normal"
              //   onChange={this.handleChange}
              variant="outlined"
              fullWidth
              id="town"
              name="town"
              onChange={formik.handleChange}
              value={formik.values.town}
              error={formik.errors.town}
              helperText={formik.errors.town}
              //   error={errorTitle}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            className={classes.inputGrid}
          >
            <TextField
              //   value={this.state.title}
              label="County"
              className={classes.inputFeild}
              margin="normal"
              //   onChange={this.handleChange}
              variant="outlined"
              fullWidth
              id="county"
              name="county"
              onChange={formik.handleChange}
              value={formik.values.county}
              error={formik.errors.county}
              helperText={formik.errors.county}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            className={classes.inputGrid}
          >
            <TextField
              //   value={this.state.title}
              label="Contact Telephone"
              className={classes.inputFeild}
              margin="normal"
              //   onChange={this.handleChange}
              variant="outlined"
              fullWidth
              id="telephone"
              name="telephone"
              onChange={formik.handleChange}
              value={formik.values.telephone}
              error={formik.errors.telephone}
              helperText={formik.errors.telephone}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            className={classes.inputGrid}
          >
            <TextField
              //   value={this.state.title}
              label="Mobile"
              className={classes.inputFeild}
              margin="normal"
              //   onChange={this.handleChange}
              variant="outlined"
              fullWidth
              id="mobile"
              name="mobile"
              onChange={formik.handleChange}
              value={formik.values.mobile}
              error={formik.errors.mobile}
              helperText={formik.errors.mobile}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <TextField
              //   value={this.state.title}
              label="CRM Number"
              className={classes.inputFeild}
              margin="normal"
              //   onChange={this.handleChange}
              variant="outlined"
              fullWidth
              id="fax"
              name="fax"
              onChange={formik.handleChange}
              value={formik.values.fax}
              error={formik.errors.fax}
              helperText={formik.errors.fax}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            className={classes.inputGrid}
          >
            <TextField
              //   value={this.state.title}
              label="Email"
              className={classes.inputFeild}
              margin="normal"
              //   onChange={this.handleChange}
              variant="outlined"
              fullWidth
              id="email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              error={formik.errors.email}
              helperText={formik.errors.email}
              //   error={errorTitle}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            className={classes.inputGrid}
          >
            <Autocomplete
              name="zone"
              options={zones}
              getOptionLabel={option => option.zoneName}
              //  onChange={(event, value) =>
              //    this.handleDriverChange(event, value)
              //  }
              id="zone"
              // onChange={formik.handleChange}
              value={formik.values.zone}
              onChange={(e, value) => formik.setFieldValue("zone", value || "")}
              //  onChange={(event, value) =>
              //    this.handleDriverChange(event, value)
              //  }
              fullWidth
              margin="normal"
              className={classes.inputFeild}
              renderInput={params => (
                <TextField
                  //  value={this.state.driverName}
                  className={classes.inputFeild}
                  {...params}
                  fullWidth
                  label="Zone"
                  margin="normal"
                  error={formik.errors.zone}
                  helperText={formik.errors.zone}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            className={classes.inputGrid}
          >
            <Autocomplete
              name="catagory"
              options={catagories}
              getOptionLabel={option => option}
              id="catagory"
              onChange={(e, value) =>
                formik.setFieldValue("catagory", value || "")
              }
              value={formik.values.catagory}
              fullWidth
              margin="normal"
              className={classes.inputFeild}
              renderInput={params => (
                <TextField
                  //  value={this.state.driverName}
                  className={classes.inputFeild}
                  {...params}
                  fullWidth
                  label="Catagory"
                  margin="normal"
                  variant="outlined"
                  error={formik.errors.catagory}
                  helperText={formik.errors.catagory}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            className={classes.inputGrid}
          >
            <Autocomplete
              name="type"
              options={types}
              getOptionLabel={option => option}
              id="type"
              onChange={(e, value) => formik.setFieldValue("type", value || "")}
              value={formik.values.type}
              fullWidth
              margin="normal"
              className={classes.inputFeild}
              renderInput={params => (
                <TextField
                  //  value={this.state.driverName}
                  className={classes.inputFeild}
                  {...params}
                  fullWidth
                  label="Type"
                  margin="normal"
                  variant="outlined"
                  error={formik.errors.type}
                  helperText={formik.errors.type}
                />
              )}
            />
          </Grid>

          {/* <Button
          variant="contained"
          className={classes.findpostcode}
          // onClick={() => setChangeDriverOpen(true)}
        >
          Find Post Code
        </Button> */}
          {/* </Grid> */}
        </Grid>
      </form>
    </>
  );
});

export default AddPropertyForm;
