import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Card from "@material-ui/core/Card";
import moment from "moment-timezone";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/Info";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
const useStyles = makeStyles(theme => ({
  root: {
    background: "#000",
    padding: "10px 20px"
    // padding: "8px"
  },
  jobCardTitle: {
    color: "#fff"
  },
  jobCardTitleValue: {
    marginLeft: "auto",
    color: "#fff",
    fontWeight: 600
  },
  jobCardPrice: {
    color: "#fff",
    fontSize: "20px",
    // marginTop: "7px",
    marginBottom: "7px"
  },
  paper: {
    padding: "16px 16px"
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main
  },
  displayFlex: {
    display: "flex"
  }
}));

function ChecqueIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <g>
          <circle cx="502" cy="234" r="10" />
          <path d="m508.997 123.999c-1.834-1.833-4.334-2.999-6.997-2.999 0 0-491.991 0-492 0-5.313 0-10 4.485-10 10v250c0 5.449 4.551 10 10 10h492c5.451 0 10-4.551 10-10v-102c0-5.521-4.49-10-10-10s-10 4.479-10 10v92h-472v-230h472v48c0 5.51 4.49 10 10 10s10-4.49 10-10c0 0 0-57.846 0-58 0-2.668-1.169-5.168-3.003-7.001z" />
          <path d="m310 226h-260c-5.523 0-10 4.478-10 10s4.477 10 10 10h260c5.523 0 10-4.478 10-10s-4.477-10-10-10z" />
          <path d="m280 266h-230c-5.523 0-10 4.478-10 10s4.477 10 10 10h230c5.523 0 10-4.478 10-10s-4.477-10-10-10z" />
          <path d="m50 186h60c5.523 0 10-4.478 10-10s-4.477-10-10-10h-60c-5.523 0-10 4.478-10 10s4.477 10 10 10z" />
          <path d="m411.998 226h30c5.523 0 10-4.478 10-10s-4.477-10-10-10h-15v-10c0-5.522-4.477-10-10-10s-10 4.478-10 10v10.425c-14.169 2.388-25 14.737-25 29.575 0 16.542 13.458 30 30 30h10c5.514 0 10 4.486 10 10s-4.486 10-10 10h-30c-5.523 0-10 4.478-10 10s4.477 10 10 10h15v10c0 5.522 4.477 10 10 10s10-4.478 10-10v-10.425c14.169-2.388 25-14.737 25-29.575 0-16.542-13.458-30-30-30h-10c-5.514 0-10-4.486-10-10s4.485-10 10-10z" />
        </g>
      </svg>
    </SvgIcon>
  );
}
function VoucherIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg x="0px" y="0px" viewBox="0 0 512 512">
        <g>
          <g>
            <path
              d="M497.231,211.692c8.157,0,14.769-6.613,14.769-14.769v-78.769c0-8.157-6.613-14.769-14.769-14.769H14.769
			C6.613,103.385,0,109.997,0,118.154v78.769c0,8.157,6.613,14.769,14.769,14.769c24.431,0,44.308,19.876,44.308,44.308
			s-19.876,44.308-44.308,44.308C6.613,300.308,0,306.92,0,315.077v78.769c0,8.157,6.613,14.769,14.769,14.769h482.462
			c8.157,0,14.769-6.613,14.769-14.769v-78.769c0-8.157-6.613-14.769-14.769-14.769c-24.431,0-44.308-19.876-44.308-44.308
			S472.799,211.692,497.231,211.692z M482.462,328.362v50.715H172.308v-44.308c0-8.157-6.613-14.769-14.769-14.769
			s-14.769,6.613-14.769,14.769v44.308H29.538v-50.715c33.665-6.862,59.077-36.701,59.077-72.362s-25.412-65.501-59.077-72.362
			v-50.715h113.231v44.308c0,8.157,6.613,14.769,14.769,14.769s14.769-6.613,14.769-14.769v-44.308h310.154v50.715
			c-33.665,6.862-59.077,36.701-59.077,72.362S448.797,321.501,482.462,328.362z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M157.538,221.538c-8.157,0-14.769,6.613-14.769,14.769v39.385c0,8.157,6.613,14.769,14.769,14.769
			s14.769-6.613,14.769-14.769v-39.385C172.308,228.151,165.695,221.538,157.538,221.538z"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
export default function JobTimeLine(props) {
  const classes = useStyles();
  const [timeline, setTimeline] = React.useState([]);
  useEffect(() => {
    setTimeline(props.paymentTimeline);
  }, [props.paymentTimeline]);

  return (
    <Timeline align="left">
      {timeline &&
        timeline.map((item, index) => (
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Payment Type : {item.paymentType}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Payment on :{" "}
                {moment(item.paymentAddedAt)
                  .tz("Europe/London")
                  .format("YYYY-MM-DD HH:mm")}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot>
                {item.paymentType == "Cash" ? 
                  <LocalAtmOutlinedIcon />
                 : item.paymentType == "Cheque" ? 
                  <ChecqueIcon />
                 : 
                  <VoucherIcon />
                }
        
              </TimelineDot>
              {index !== timeline.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Card className={classes.root}>
                <div className={classes.displayFlex}>
                  <Typography
                    variant="p"
                    component="p"
                    className={classes.jobCardTitle}
                  >
                    Total Amount:
                  </Typography>
                  <Typography
                    variant="p"
                    component="p"
                    align="right"
                    className={classes.jobCardTitleValue}
                  >
                    {item.totalAmount}
                  </Typography>
                </div>
                <div className={classes.displayFlex}>
                  <Typography
                    variant="p"
                    component="p"
                    className={classes.jobCardTitle}
                  >
                    Payment Amount:
                  </Typography>
                  <Typography
                    variant="p"
                    component="p"
                    align="right"
                    className={classes.jobCardTitleValue}
                  >
                    {item.paymentAmount}
                  </Typography>
                </div>
                <div className={classes.displayFlex}>
                  <Typography
                    variant="p"
                    component="p"
                    className={classes.jobCardTitle}
                  >
                    Payment Change:
                  </Typography>
                  <Typography
                    variant="p"
                    component="p"
                    align="right"
                    className={classes.jobCardTitleValue}
                  >
                    {item.paymentChange}
                  </Typography>
                </div>
                <div className={classes.displayFlex}>
                  <Typography
                    variant="p"
                    component="p"
                    className={classes.jobCardTitle}
                  >
                    Change Given:
                  </Typography>
                  <Typography
                    variant="p"
                    component="p"
                    align="right"
                    className={classes.jobCardTitleValue}
                  >
                    {item.changeGiven}
                  </Typography>
                </div>
                <div className={classes.displayFlex}>
                  <Typography
                    variant="p"
                    component="p"
                    className={classes.jobCardTitle}
                  >
                    Owed Amount:
                  </Typography>
                  <Typography
                    variant="p"
                    component="p"
                    align="right"
                    className={classes.jobCardTitleValue}
                  >
                    {item.owedAmount}
                  </Typography>
                </div>
              </Card>
            </TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
}
