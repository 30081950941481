import React, { PureComponent, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import FormControl from "@material-ui/core/FormControl";
import CardContent from "@material-ui/core/CardContent";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "../../utils/react-big-calendar.css";
import IconButton from "@material-ui/core/IconButton";
import PageviewIcon from "@material-ui/icons/Pageview";

import { alpha } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { axios } from "../../utils/axiosConfig";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ConfirmedSnackBar from "../../utils/ConfirmedSnackbar";
import { UserContextData, UserContext } from "../../Contexts/userContext";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import Card from "@material-ui/core/Card";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import {
  setJobConfiguration,
  setOperators,
  setJobItems,
  resetConfiguration,
} from "../../Features/createJobConfigurationSlice";
import { Typography } from "@material-ui/core";
const localizer = momentLocalizer(moment);
const dayFormat = (date, culture, localizer) =>
  localizer.format(date, "DD/MM/YYYY HH:mm:ss", culture);

const styles = {
  findCalendarCSS: {
    marginLeft: "10px",
    color: "#fff",
    fontWeight: 600,
    background: "#f15a2f",
    width: "250px",
    height: "53px",

    marginTop: "17px",

    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#f15a2f",
      color: "#fff",
    },
    // float: "right"
  },
  textField: {
    // width: "100%",
    marginLeft: "5px",
    marginRight: "5px",
    marginBottom: "15px",
  },

  grid: {
    padding: "16px",
  },

  datePicker: {
    selectColor: "black",
  },

  formControl: {
    width: "65%",
  },
  center: {
    // background: "#000",
    margin: "20px 16px 16px 16px",
    // display: "flex",
    backgroundColor: "rgb(255, 255, 255)",
    color: "#000",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "8px",
    position: "relative",
    zIndex: 0,
    alignItems: "center",
    padding: "24px",
  },

  card: {
    width: "100%",
    backgroud: "#000; !important",
  },

  showCircularProgressStyle: {
    margin: "auto",
    width: "fit-content",
  },

  tableHeading: {
    padding: "15px",
    fontSize: "20px",
    color: "#fff",
    display: "flex",
    background: "#000;",
  },
  tableBold: {
    fontWeight: 600,
    fontSize: "17px",
    color: "#000;",
  },

  gridHere: {
    // borderRight:"1px solid #000;"
  },

  editIcon: {
    color: "#fff",
    fontSize: "16px",
    cursor: "pointer",
    // marginLeft: "20px",
    // marginBottom: "15px"
  },

  expandIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    // marginLeft: "20px",
    // marginBottom: "15px"
  },

  inputFeild: {
    color: "#000;",
    fontSize: "16px",
    width: "380px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  inputFeildHaulage: {
    color: "#000;",
    fontSize: "20px",
    background: "none",
    width: "40px",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  inputFeildHaulageHovered: {
    color: "#fff",
    fontSize: "20px",
    background: "none",
    width: "40px",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },

  startAutoJob: {
    color: "#fff",
    background: "#000;",
    width: "auto",
    height: "53px",
    marginTop: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#000;",
      color: "#fff",
    },
  },
  startCron: {
    marginLeft: "auto",
    marginTop: "auto",
    marginBottom: "auto",
  },

  circularProgressCenter: {
    margin: "auto",
  },
  autoCircularProgress: {
    margin: "auto",
  },
  circularStyle: {
    width: "100%",
    margin: "auto",
    marginTop: "15%",
    marginBottom: "15%",
  },
  circularProgressBase: {
    width: "100%",
    height: "75vh",
  },
  circularProgressBase2: {
    width: "fit-content",
    padding: "20%",
    margin: "auto",
  },
  snackbar: {
    backgroundColor: "rgb(50, 50, 50)",
  },
  fullWidth: {
    width: "100%",
  },
  close: {
    color: "#fff",
  },
  menuButtonsIconPrimary: {
    marginLeft: "auto",
    color: "#fff",
    cursor: "pointer",
    // height: "50px"
  },
  dialogContent: {
    margin: "10px",
  },
  newCronTimePicker: {
    marginTop: "15px",
  },
  headerStyle: {
    display: "flex",
    width: "100%",
  },
  autoComplete: {
    width: "20%",
    marginRight: "10px",
    marginTop: "15px",
    /* display: flex; */

    display: "inline-flex",
    position: "relative",
    minWidth: 0,
    flexDirection: "column",
    verticalAlign: "top",
  },
  subHeading: {
    fontSize: "15px",
    fontWeight: 600,
  },
  noDataFoundText: {
    color: "#000",
    fontSize: "1rem",
    marginBottom: "7px",
    marginTop: "7px",
    margin: "auto",
    textAlign: "center",
    fontWeight: "600",
    // letterSpacing: "0.1rem",
    lineHeight: 1.5,
  },
  dateSearch: {
    display: "flex",
  },
  jobCardPrice: {
    width: "50%",
    color: "#000",
    fontSize: "1rem",
    marginTop: "25px",
    fontWeight: "600",
    // letterSpacing: "0.1rem",
    lineHeight: 1.5,
  },
  searchIconDiv: {
    marginTop: "15px",
  },
  searchIcon: {
    fontSize: "35px",
  },
};

class BookingCalendar extends PureComponent {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      openSnackbar: false,
      snackBarMessage: null,
      showCircularProgress: false,
      events: [],

      jobListData: [],
      jobListFilteredData: [],
      allDrivers: [],
      selectedDriver: [],
      selectedDate: new Date(),
    };
  }

  componentDidMount() {
    this.calenderJob();
    this.getAllDrivers();
  }

  calenderJob = () => {
    const { logoutUser } = this.props;
    this.setState({ showCircularProgress: true });
    const url =
      `job/calendar?date=` +
      moment(this.state.selectedDate).format("YYYY-MM-DD");
    axios
      .get(url)
      .then((res) => {
        let response = res.data;
        if (response.success === true) {
          let eventsData = [];
          if (response.data.calendarRecords.length > 0) {
            const map = response.data.calendarRecords.map((records) => {
              const startYear = moment(
                records.start,
                "DD/MM/YYYY HH:mm:ss"
              ).year();
              const startMonth = moment(
                records.start,
                "DD/MM/YYYY HH:mm:ss"
              ).month();
              const startDate = moment(
                records.start,
                "DD/MM/YYYY HH:mm:ss"
              ).date();
              const startHour = moment(
                records.start,
                "DD/MM/YYYY HH:mm:ss"
              ).hour();
              const startMinute = moment(
                records.start,
                "DD/MM/YYYY HH:mm:ss"
              ).minute();
              const startSecond = moment(
                records.start,
                "DD/MM/YYYY HH:mm:ss"
              ).second();

              const endYear = moment(records.end, "DD/MM/YYYY HH:mm:ss").year();
              const endMonth = moment(
                records.end,
                "DD/MM/YYYY HH:mm:ss"
              ).month();
              const endDate = moment(records.end, "DD/MM/YYYY HH:mm:ss").date();
              const endHour = moment(records.end, "DD/MM/YYYY HH:mm:ss").hour();
              const endMinute = moment(
                records.end,
                "DD/MM/YYYY HH:mm:ss"
              ).minute();
              const endSecond = moment(
                records.end,
                "DD/MM/YYYY HH:mm:ss"
              ).second();
              eventsData.push({
                id: records.id,
                title: records.title,

                start: new Date(
                  startYear,
                  startMonth,
                  startDate,
                  startHour,
                  startMinute,
                  startSecond
                ),
                end: new Date(
                  endYear,
                  endMonth,
                  endDate,
                  endHour,
                  endMinute,
                  endSecond
                ),
                resourceId: records.resourceId,
              });
            });
            console.log(eventsData, "testData");

            this.setState({ events: eventsData });
          }
        } else {
        }
        this.setState({ showCircularProgress: false });
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };

  getAllDrivers = () => {
    const { logoutUser } = this.props;
    const url = `job/driver/all`;
    axios
      .get(url)
      .then((res) => {
        let response = res.data;
        if (response.success === true) {
          let resources = [];
          const map = response.data.map((driver) => {
            resources.push({
              resourceId: driver.driverID,
              resourceTitle: driver.driverName,
            });
          });

          this.setState({ allDrivers: resources });
        } else {
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };

  showSnackBar = (message) => {
    this.setState({ openSnackbar: true, snackBarMessage: message });
  };
  handleSnackBarClose = () => {
    this.setState({ openSnackbar: false });
  };

  mapToRBCFormat = (e) => {
    Object.assign({}, e, {
      start: new Date(e.start),
      end: new Date(e.end),
    });
  };
  handleByDateChange = (date) => {
    console.log("pageChange", date);
    this.setState({ selectedDate: date });
  };

  render() {
    const { classes } = this.props;
    const {
      openSnackbar,
      selectedDate,
      showCircularProgress,
      snackBarMessage,
      events,
      allDrivers,
    } = this.state;
    return (
      <>
        <Grid container direction="row" alignItems="center">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.borderRight}
          >
            <Card className={classes.center}>
              <div className={classes.dateSearch}>
                <Typography className={classes.jobCardPrice}>
                  Jobs Calender
                </Typography>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-end"
                  justify="flex-end"
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Select Date"
                      value={selectedDate}
                      onChange={this.handleByDateChange}
                      name="selectedDate"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      className={classes.textField}
                    />
                  </MuiPickersUtilsProvider>
                  <IconButton
                    aria-label="delete"
                    className={classes.searchIconDiv}
                    onClick={(e) => this.calenderJob()}
                  >
                    <PageviewIcon className={classes.searchIcon} />
                  </IconButton>
                </Grid>
              </div>
              {showCircularProgress && (
                <div className={classes.circularProgressBase}>
                  <div className={classes.circularProgressBase2}>
                    <CircularProgress
                      size={35}
                      className={classes.showCircularProgressStyle}
                    />
                  </div>
                </div>
              )}

              {!showCircularProgress && (
                <Calendar
                  events={events}
                  localizer={localizer}
                  defaultView={Views.DAY}
                  views={["day", "work_week"]} 
                  step={30}
                  defaultDate={new Date()}
                  resources={allDrivers}
                  resourceIdAccessor="resourceId"
                  resourceTitleAccessor="resourceTitle"
                  // style={{ '& .rbc-event':{
                  //       color:'red'
                  // }  }}
                  showMultiDayTimes={true}
                  formats={{
                    dayFormat,
                  }}
                />
              )}
            </Card>
          </Grid>
        </Grid>
        <ConfirmedSnackBar
          open={openSnackbar}
          message={snackBarMessage}
          handleClose={this.handleSnackBarClose}
          classes={classes}
        />
      </>
    );
  }
}

export default withStyles(styles)(BookingCalendar);
