import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Link from '@material-ui/core/Link';
const ConfirmDialog = (props) => {
  const {
    openConfirmDialog,
    onCloseConfirmDialog,
    onYesConfirmDialog,
    confirmDialogMessage,
    classes
  } = props;
  return (
    <Dialog
      open={openConfirmDialog}
      onClose={onCloseConfirmDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="alert-dialog-title">Confirm Action</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {confirmDialogMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
     
      <Button
          variant="contained"
          type="submit"
          onClick={onYesConfirmDialog}
          className={classes.createUser}
          disabled={!openConfirmDialog}
          color="primary"
        >
          Yes
        </Button>
        <Button
          variant="contained"
          type="submit"
          onClick={onCloseConfirmDialog}
          className={classes.createUserSecondary}
        >
           No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog  ;
