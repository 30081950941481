import React from "react";
import ReactMarkdown from 'react-markdown'

const input = 'Help text will be inserted here'

export default function Help() {
  return (
    <>
      <h1>Help / FAQ</h1>
      <ReactMarkdown source={input} />
    </>
  );
}
