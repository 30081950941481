// set axios interceptors to attach token to every request made to a specific domain only 

import axios  from 'axios'

const instance = axios.create({
    baseURL: 'https://test.sagepay.com/gateway/service/'
})

instance.interceptors.request.use(function (config) {

   
        // const integrationKey: String ="DemnMcVtxB6Hcz0mkMUujWj1IhpoVuCQ2xRpkRFPcRagIzBewd";
        // const integrationPassword: String ="vJ9eUk7a5DybfdUD47aozlxhvUiGa2lIJiGW0zRz6e4GZpedGuk6sxsOslsacF5oe";
        // const userTokenData: String = btoa(`${integrationKey}:${integrationPassword}`);
        // console.log(userTokenData,'userTokenDataHere')
        // config.headers.Authorization = `Basic ${userTokenData}`;
        // config.headers.ContentType = 'application/json';
    
    return config;
});




export {
    instance as axiosPayment
}