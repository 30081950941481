import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext, UserContextData } from "../Contexts/userContext";

// check to see if user is logged in.
// if user login is true show the intended page
// other wise redirect to login

interface PrivateRouteProps {
  children: React.ReactNode[] | React.ReactNode;
  path: string;
  exact?: boolean;
}

export default function PrivateRoute({
  children,
  path,
  exact = false
}: PrivateRouteProps) {
  const { user } = useContext<UserContextData>(UserContext);

  return (
    <Route exact={exact} path={path}>
      {user ? children : <Redirect to="/signin" />}
    </Route>
  );
}
