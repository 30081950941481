import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import Grid from "@material-ui/core/Grid";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import FormControl from "@material-ui/core/FormControl";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import BusinessIcon from "@material-ui/icons/Business";
import CheckIcon from "@material-ui/icons/Check";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import CardContent from "@material-ui/core/CardContent";
import PeopleIcon from "@material-ui/icons/People";
import EditIcon from "@material-ui/icons/Edit";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import HeadsetIcon from "@material-ui/icons/Headset";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import SearchIcon from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import InputLabel from "@material-ui/core/InputLabel";
import LaunchIcon from "@material-ui/icons/Launch";
import LastPageIcon from "@material-ui/icons/LastPage";
import InputBase from "@material-ui/core/InputBase";
import { alpha } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { axios } from "../../utils/axiosConfig";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ConfirmedSnackBar from "../../utils/ConfirmedSnackbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import JobDetail from "./JobDetail";
import TablePagination from "@material-ui/core/TablePagination";
import DateFnsUtils from "@date-io/date-fns";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import TimerIcon from "@material-ui/icons/Timer";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import LocalDrinkIcon from "@material-ui/icons/LocalDrink";
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 450
  },
  listSection: {
    backgroundColor: "inherit"
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0
  },
  textField: {
    // width: "100%",
    marginLeft: "5px",
    marginRight: "5px"
  },
  selectFeild: {
    width: "15%",
    marginTop: "15px",
    marginLeft: "5px",
    marginRight: "5px"
  },

  tableHeadFull: {
    width: "100%"
  },
  datePicker: {
    selectColor: "black"
  },
  priceTextFeild: {
    margin: "26px"
  },
  jobCardPrice: {
    color: "#fff",
    fontSize: "52px",
    marginTop: "7px",
    marginBottom: "7px"
  },
  formControl: {
    width: "65%"
  },
  center: {
    margin: "auto",
    width: "fit-content"
  },
  search: {
    position: "relative",
    borderRadius: "4px",
    backgroundColor: alpha("#fff", 0.15),
    "&:hover": {
      backgroundColor: alpha("#fff", 0.25)
    },
    // marginLeft: 0,
    width: "250px",
    marginLeft: "auto"
  },
  searchIcon: {
    padding: "16px",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: "8px, 8px, 8px, 8px",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + 32px)`,
    width: "100%"
  },
  card: {
    width: "100%",
    backgroud: "#000; !important"
  },
  jobCardTitle: {
    marginTop: "10px",
    color: "#fff"
  },
  titleDiv: {
    display: "flex"
  },
  switchGrid: {
    margin: "auto",
    display: "flex"
  },
  switchClass: {
    margin: "3px 10px 5px 10px"
  },
  timeContainer: {
    margin: "43px 0px 0px 0px"
  },
  rootCard: {
    background: "#000",
    width: "100%",
    marginRight: "80px"
  },
  showCircularProgressStyle: {
    margin: "auto",
    width: "fit-content",
    padding: "50%"
  },

  tableHeading: {
    padding: "15px",
    fontSize: "20px",
    color: "#fff",
    display: "flex",
    background: "#000;"
  },
  tableBold: {
    fontWeight: 600,
    fontSize: "17px",
    color: "#000;"
  },

  gridHere: {
    // borderRight:"1px solid #000;"
  },

  editIcon: {
    color: "#fff",
    fontSize: "16px",
    cursor: "pointer"
    // marginLeft: "20px",
    // marginBottom: "15px"
  },

  expandIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer"
    // marginLeft: "20px",
    // marginBottom: "15px"
  },
  launchIcon: {
    color: "#000;",
    cursor: "pointer"
  },
  oilIcons: {
    fontSize: "40px",
    color: "#fff",
    margin: "4px 4px"
  },
  priceIcons: {
    fontSize: "50px",
    color: "#fff",
    margin: "4px 4px"
  },
  editIconChip: {
    fontSize: "16px",
    cursor: "pointer"
  },
  tableCellClass: {
    display: "inline-flex"
  },
  inputFeildHovered: {
    color: "#fff",
    fontSize: "16px",
    width: "380px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  inputFeild: {
    color: "#000;",
    fontSize: "16px",
    width: "380px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  inputFeildHaulage: {
    color: "#000;",
    fontSize: "20px",
    background: "none",
    width: "40px",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  inputFeildHaulageHovered: {
    color: "#fff",
    fontSize: "20px",
    background: "none",
    width: "40px",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },

  startAutoJob: {
    color: "#fff",
    background: "#000;",
    width: "auto",
    height: "53px",
    marginTop: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#000;",
      color: "#fff"
    }
  },
  startCron: {
    marginLeft: "auto",
    marginTop: "auto",
    marginBottom: "auto"
  },
  tablePagination: {
    marginLeft: "auto"
  },
  enabledChip: {
    backgroudColor: "green"
  },
  disabledChip: {
    backgroud: "red"
  },
  "& .MuiTablePagination-toolbar": {
    marginLeft: "auto"
  },
  tableFooter: {
    width: "fit-content",
    marginTop: "20px",
    marginLeft: "auto"
  },
  circularProgress: {
    marginLeft: "10px"
  },
  circularProgressCenter: {
    margin: "auto"
  },
  autoCircularProgress: {
    margin: "auto"
  },
  circularStyle: {
    width: "100%",
    margin: "auto",
    marginTop: "15%",
    marginBottom: "15%"
  },
  circularProgressBase: {
    width: "100%"
  },
  snackbar: {
    backgroundColor: "rgb(50, 50, 50)"
  },
  fullWidth: {
    width: "100%"
  },
  close: {
    color: "#fff"
  },
  menuButtonsIconPrimary: {
    marginLeft: "auto",
    color: "#fff",
    cursor: "pointer"
    // height: "50px"
  },
  dialogContent: {
    margin: "10px"
  },
  newCronTimePicker: {
    marginTop: "15px"
  },
  headerStyle: {
    display: "flex",
    width: "100%"
  },
  borderBottom: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)"
  },
  grid: {
    border: "1px solid rgba(0, 0, 0, 0.1)"
  },
  texts: {
    marginLeft: "15px"
  }
}));

export default function RiskAssessment(props) {
  const classes = useStyles();
  const [job, setJob] = React.useState(false);

  useEffect(() => {
    setJob(props.jobData);
  }, [props.jobData]);

  const findIcon = name => {
    switch (name) {
      case "operatorName":
        return <AccountCircleIcon />;
      case "raDate":
        return <CalendarTodayIcon />;
      case "generalLocation":
        return <LocationSearchingIcon />;
      case "jobtypeDescription":
        return <InfoOutlinedIcon />;
      case "basicJobDescription":
        return <InfoOutlinedIcon />;
      case "movementOfPeople":
        return <PeopleIcon />;
      case "movementOfVehicles":
        return <LocalShippingIcon />;
      case "movementOfPlant":
        return <InfoOutlinedIcon />;
      case "chemicalsUsed":
        return <LocalDrinkIcon />;
      case "additionalDescription":
        return <InfoOutlinedIcon />;
      case "customerLiaise":
        return <InfoOutlinedIcon />;
      case "noCustomerLiaise":
        return <InfoOutlinedIcon />;
      case "generalSiteInspect":
        return <InfoOutlinedIcon />;
      case "noGeneralSiteInspect":
        return <InfoOutlinedIcon />;
      case "closeOff":
        return <InfoOutlinedIcon />;
      case "noCloseOff":
        return <InfoOutlinedIcon />;
      case "helmet":
        return <InfoOutlinedIcon />;
      case "visor":
        return <InfoOutlinedIcon />;
      case "ear":
        return <HeadsetIcon />;

      case "gloves":
        return <InfoOutlinedIcon />;
      case "wetsuit":
        return <InfoOutlinedIcon />;
      case "boots":
        return <InfoOutlinedIcon />;
      case "wellingtons":
        return <InfoOutlinedIcon />;
      case "hivis":
        return <InfoOutlinedIcon />;
      case "identifyHazards":
        return <WarningIcon />;
      case "overcomeHazMethod":
        return <WarningIcon />;
      case "contactOffice":
        return <PhoneIcon />;
      case "noContactOffice":
        return <PhoneIcon />;
      case "completed":
        return <CheckIcon />;

      case "completedAt":
        return <CheckIcon />;
      case "createdAt":
        return <EditIcon />;
      case "customerAccountRef":
        return <InfoOutlinedIcon />;
      case "customerAddress":
        return <BusinessIcon />;
      case "customerEmail":
        return <EmailIcon />;
      case "customerHomeTelephoneNumber":
        return <PhoneIcon />;
      case "customerID":
        return <InfoOutlinedIcon />;
      case "customerLatitude":
        return <LocationSearchingIcon />;
      case "customerLongitude":
        return <LocationSearchingIcon />;
      case "customerMobile":
        return <PhoneIcon />;
      case "customerName":
        return <AccountCircleIcon />;
      case "customerOrderNumber":
        return <InfoOutlinedIcon />;
      case "deliveryAddress":
        return <BusinessIcon />;
      case "deliveryBy":
        return <CalendarTodayIcon />;
      case "deliveryFrom":
        return <CalendarTodayIcon />;
      case "deliveryLatitude":
        return <LocationSearchingIcon />;
      case "deliveryLongitude":
        return <LocationSearchingIcon />;
      case "deliveryName":
        return <InfoOutlinedIcon />;
      case "depot":
        return <InfoOutlinedIcon />;
      case "driverName":
        return <InfoOutlinedIcon />;
      case "invoiceNumber":
        return <InfoOutlinedIcon />;
      case "jobID":
        return <InfoOutlinedIcon />;
      case "loadJobID":
        return <InfoOutlinedIcon />;
      case "orderNumber":
        return <InfoOutlinedIcon />;
      case "priority":
        return <InfoOutlinedIcon />;
      case "product":
        return <InfoOutlinedIcon />;
      case "quantity":
        return <InfoOutlinedIcon />;
      case "specialInstructions":
        return <InfoOutlinedIcon />;
      case "startedAt":
        return <CalendarTodayIcon />;
      case "status":
        return <InfoOutlinedIcon />;
      case "totalAmount":
        return <InfoOutlinedIcon />;
      case "totalTimeTaken":
        return <TimerIcon />;
      case "updatedAt":
        return <CalendarTodayIcon />;
      case "valueOne":
        return <InfoOutlinedIcon />;
      case "vat":
        return <InfoOutlinedIcon />;
      case "vehicleRegNo":
        return <DirectionsCarIcon />;
      default:
        return <InfoIcon />;
    }
  };
  const findKeyName = name => {
    switch (name) {
      case "completedAt":
        return "Completed At";
      case "createdAt":
        return "Created At";
      case "operatorName":
        return "Operator Name";
      case "raDate":
        return "Risk Assessment Date";
      case "generalLocation":
        return "General Location";
      case "jobtypeDescription":
        return "Order type Description";
      case "basicJobDescription":
        return "Basic Order Description";
      case "movementOfPeople":
        return "Movement Of People";
      case "movementOfVehicles":
        return "Movement Of Vehicles";
      case "movementOfPlant":
        return "Movement Of Plant";
      case "chemicalsUsed":
        return "Chemicals Used";
      case "additionalDescription":
        return "Additional Description";
      case "customerLiaise":
        return "Customer Liaise";
      case "noCustomerLiaise":
        return "No Customer Liaise";
      case "generalSiteInspect":
        return "General Site Inspect";
      case "noGeneralSiteInspect":
        return "No General Site Inspect";
      case "closeOff":
        return "Close Off";
      case "noCloseOff":
        return "No Close Off";
      case "helmet":
        return "Helmet";
      case "visor":
        return "Visor";
      case "ear":
        return "Ear";
      case "jobID":
        return "IWS Reference";
      case "gloves":
        return "Gloves";
      case "wetsuit":
        return "Wetsuit";
      case "boots":
        return "Boots";
      case "wellingtons":
        return "Wellingtons";
      case "hivis":
        return "Hivis";
      case "identifyHazards":
        return "Identify Hazards";
      case "overcomeHazMethod":
        return "Over come Haz Method";
      case "contactOffice":
        return "Contact Office";
      case "noContactOffice":
        return "No Contact Office";
      case "completed":
        return "Completed";
      case "updatedAt":
        return "Updated At";
      case "valueOne":
        return "Value One";
      case "vat":
        return "Vat";
      case "vehicleRegNo":
        return "Vehicle Reg No";
      case "driverComments":
        return "Engineer Comments";
      case "litres_collected":
        return "Litres Collected";
      case "print_litres_collected_receipt":
        return "Print Litres Collected Receipt";
      default:
        return name;
    }
  };

  return (
    <>
      <Grid
        className={classes.grid}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <div className={classes.tableHeading}>
          <Typography>Details</Typography>
        </div>
        <List className={classes.root} subheader={<li />}>
          {job &&
            Object.keys(job).map(item => (
              <ListItem key={`item-`} className={classes.borderBottom}>
                {findIcon(item)}
                <ListItemText
                  className={classes.texts}
                  primary={`${job[item]}`}
                  secondary={findKeyName(item)}
                />
              </ListItem>
            ))}
        </List>
      </Grid>
    </>
  );
}
