import React, { PureComponent, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import FormControl from "@material-ui/core/FormControl";
import CardContent from "@material-ui/core/CardContent";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import { alpha } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { axios } from "../../utils/axiosConfig";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import ConfirmedSnackBar from "../../utils/ConfirmedSnackbar";
import { UserContextData, UserContext } from "../../Contexts/userContext";

import TablePagination from "@material-ui/core/TablePagination";
import DateFnsUtils from "@date-io/date-fns";
import { API_URL } from "../../utils/constants";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import Typography from "@material-ui/core/Typography";

import moment from "moment-timezone";

const styles = {
  textField: {
    // width: "100%",
    marginLeft: "5px",
    marginRight: "5px",
  },
  selectFeild: {
    width: "15%",
    marginTop: "15px",
    marginLeft: "5px",
    marginRight: "5px",
  },
  grid: {
    padding: "16px",
  },
  tableHeadFull: {
    width: "100%",
  },
  datePicker: {
    selectColor: "black",
  },
  priceTextFeild: {
    margin: "26px",
  },
  jobCardPrice: {
    color: "#fff",
    fontSize: "52px",
    marginTop: "7px",
    marginBottom: "7px",
  },
  formControl: {
    width: "65%",
  },
  center: {
    margin: "auto",
    width: "fit-content",
  },
  search: {
    position: "relative",
    borderRadius: "4px",
    backgroundColor: alpha("#fff", 0.15),
    "&:hover": {
      backgroundColor: alpha("#fff", 0.25),
    },
    // marginLeft: 0,
    width: "250px",
    marginLeft: "auto",
  },
  searchIcon: {
    padding: "16px",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: "8px, 8px, 8px, 8px",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + 32px)`,
    width: "100%",
  },
  card: {
    width: "100%",
    backgroud: "#000; !important",
  },
  jobCardTitle: {
    marginTop: "10px",
    color: "#fff",
  },
  titleDiv: {
    display: "flex",
  },
  switchGrid: {
    margin: "auto",
    display: "flex",
  },
  switchClass: {
    margin: "3px 10px 5px 10px",
  },
  timeContainer: {
    margin: "43px 0px 0px 0px",
  },
  rootCard: {
    background: "#000",
    width: "100%",
    marginRight: "80px",
  },
  showCircularProgressStyle: {
    margin: "auto",
    width: "fit-content",
    padding: "50%",
  },

  tableHeading: {
    padding: "15px",
    fontSize: "20px",
    color: "#fff",
    display: "flex",
    background: "#000;",
  },
  tableBold: {
    fontWeight: 600,
    fontSize: "17px",
    color: "#000;",
  },

  gridHere: {
    // borderRight:"1px solid #000;"
  },

  editIcon: {
    color: "#fff",
    fontSize: "16px",
    cursor: "pointer",
    // marginLeft: "20px",
    // marginBottom: "15px"
  },

  expandIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    // marginLeft: "20px",
    // marginBottom: "15px"
  },
  launchIcon: {
    color: "#000;",
    cursor: "pointer",
  },
  oilIcons: {
    fontSize: "40px",
    color: "#fff",
    margin: "4px 4px",
  },
  priceIcons: {
    fontSize: "50px",
    color: "#fff",
    margin: "4px 4px",
  },
  editIconChip: {
    fontSize: "16px",
    cursor: "pointer",
  },
  tableCellClass: {
    display: "inline-flex",
  },
  inputFeildHovered: {
    color: "#fff",
    fontSize: "16px",
    width: "380px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  inputFeild: {
    color: "#000;",
    fontSize: "16px",
    width: "180px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  inputFeildHaulage: {
    color: "#000;",
    fontSize: "20px",
    background: "none",
    width: "40px",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  inputFeildHaulageHovered: {
    color: "#fff",
    fontSize: "20px",
    background: "none",
    width: "40px",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },

  startAutoJob: {
    color: "#fff",
    background: "#000;",
    width: "auto",
    height: "53px",
    marginTop: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#000;",
      color: "#fff",
    },
  },
  startCron: {
    marginLeft: "auto",
    marginTop: "auto",
    marginBottom: "auto",
  },
  tablePagination: {
    marginLeft: "auto",
  },
  enabledChip: {
    backgroudColor: "green",
  },
  disabledChip: {
    backgroud: "red",
  },
  "& .MuiTablePagination-toolbar": {
    marginLeft: "auto",
  },
  tableFooter: {
    width: "fit-content",
    marginTop: "20px",
    marginLeft: "auto",
  },
  circularProgress: {
    marginLeft: "10px",
  },
  circularProgressCenter: {
    margin: "auto",
  },
  autoCircularProgress: {
    margin: "auto",
  },
  circularStyle: {
    width: "100%",
    margin: "auto",
    marginTop: "15%",
    marginBottom: "15%",
  },
  circularProgressBase: {
    width: "100%",
  },
  snackbar: {
    backgroundColor: "rgb(50, 50, 50)",
  },
  fullWidth: {
    width: "100%",
  },
  close: {
    color: "#fff",
  },
  menuButtonsIconPrimary: {
    marginLeft: "auto",
    color: "#fff",
    cursor: "pointer",
    // height: "50px"
  },
  dialogContent: {
    margin: "10px",
  },
  newCronTimePicker: {
    marginTop: "15px",
  },
  headerStyle: {
    display: "flex",
    width: "100%",
  },
  autoComplete: {
    width: "20%",
    marginRight: "10px",
    marginTop: "15px",
    /* display: flex; */

    display: "inline-flex",
    position: "relative",
    minWidth: 0,
    flexDirection: "column",
    verticalAlign: "top",
  },
  autoComplete2: {
    width: "12%",
    marginRight: "10px",
    marginTop: "15px",
    /* display: flex; */

    display: "inline-flex",
    position: "relative",
    minWidth: 0,
    flexDirection: "column",
    verticalAlign: "top",
  },
  subHeading: {
    fontSize: "15px",
    fontWeight: 600,
  },
  noDataFoundText: {
    color: "#000",
    fontSize: "2rem",
    marginBottom: "7px",
    marginTop: "270px",
    margin: "auto",
    textAlign: "center",
    fontWeight: "600",
    // letterSpacing: "0.1rem",
    lineHeight: 1.5,
  },
};

class Summary extends PureComponent {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      openSnackbar: false,
      snackBarMessage: null,
      showCircularProgress: false,

      filters: "",
      filterPage: 1,
      pageSize: 50,
      status: "",
      deliveryBy: moment().subtract(1, "month"),
      // deliveryBy: moment(),
      deliveryFrom: moment().add(1, "days"),
      // deliveryFrom: moment(),
      jobID: "",
      orderNumber: "",
      invoiceNumber: "",
      driverName: "",
      driverID: "",
      customerName: "",
      product: "",
      quantity: "",
      zoneValue: {},
      allDrivers: [],

      allVehicles: [],
      zones: [],
      jobDetailView: false,
      selectedJob: {},
      countOfPaymentsByLastWeekChartOption: {},
      sumOfPaymentsByLastWeekChartOption: {},
      toBeInvoiced: "",
      noRecord: false,
    };
  }

  componentDidMount() {
    this.getAllDrivers();
  }
  handleByDateChange = (date) => {
    console.log("pageChange", date);
    this.setState({ deliveryBy: date });
  };
  handleFromDateChange = (date) => {
    console.log("pageChange", date);
    this.setState({ deliveryFrom: date });
  };
  dashbboardDetails = () => {
    const { logoutUser } = this.props;
    this.setState({ showCircularProgress: true });
    const {
      status,
      deliveryBy,
      deliveryFrom,
      toBeInvoiced,
      driverID,

      product,
    } = this.state;

    console.log(product, "productHere");
    let url = `job/summary/all?${
      deliveryBy !== ""
        ? `dateFrom=${moment(deliveryBy)
            .tz("Europe/London")
            .format("YYYY-MM-DD")}`
        : ""
    }${
      deliveryFrom !== ""
        ? `&dateTo=${moment(deliveryFrom)
            .tz("Europe/London")
            .format("YYYY-MM-DD")}`
        : ""
    }${driverID !== "" ? `&operatorID=${driverID}` : ""}${
      status !== "" && toBeInvoiced != "yes" ? `&status=${status}` : ""
    }${toBeInvoiced !== "" ? `&toBeInvoiced=${toBeInvoiced}` : ""}`;
    this.setState({ noRecord: false });

    axios
      .get(url)
      .then((res) => {
        let response = res.data;

        console.log(res, "reshere");
        if (response) {
          let excelURL = `${API_URL}/rest/api/job/summary/all?${
            deliveryBy !== ""
              ? `dateFrom=${moment(deliveryBy)
                  .tz("Europe/London")
                  .format("YYYY-MM-DD")}`
              : ""
          }${
            deliveryFrom !== ""
              ? `&dateTo=${moment(deliveryFrom)
                  .tz("Europe/London")
                  .format("YYYY-MM-DD")}`
              : ""
          }${driverID !== "" ? `&operatorID=${driverID}` : ""}${
            status !== "" && toBeInvoiced != "yes" ? `&status=${status}` : ""
          }${toBeInvoiced !== "" ? `&toBeInvoiced=${toBeInvoiced}` : ""}`;
          this.openInNewTab(excelURL);

          //   let blob = new Blob([response], {
          //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          //   });
          //   let link = document.createElement("a");
          //   link.href = window.URL.createObjectURL(blob);
          //   link.download = `Summary.xlsx`;
          //   link.click();
        } else {
          this.setState({ noRecord: true });
          this.setState({ showCircularProgress: false });
          this.resetJob();
        }
        this.setState({ showCircularProgress: false });
      })
      .catch(function (error) {
        // if (error.response.status === 403) {
        //   logoutUser();
        // }
      });
  };

  //   exportItem = async (UID: string) => {

  //     // setExportProgress(true);
  //     // try {
  //     //   const response = await axios.get(url);
  //     //   setExportProgress(false);
  //     //   console.log(response.data, 'response');
  //     //   let blob = new Blob([response.data], {
  //     //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  //     //   });
  //     //   let link = document.createElement('a');
  //     //   link.href = window.URL.createObjectURL(blob);
  //     //   link.download = `Inspection Report-${UID}-excelexport-03_02_2022 09_18_42.xlsx`;
  //     //   link.click();
  //     // } catch (error) {
  //     //   console.error(error);
  //     //   enqueueSnackbar('Unable to Export inspection', { variant: 'error' });
  //     //   setExportProgress(false);
  //     //   // setErrors(error);
  //     // }
  //   };
  openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleDriverChange = (event, value) => {
    console.log(value, "event");
    if (value !== null) {
      this.setState({ driverName: value.driverName });
      this.setState({ driverID: value.driverID });
    } else {
      this.setState({ driverName: "", driverID: "" });
    }
  };
  handleProductChange = (event, value) => {
    console.log(value, "event");
    if (value !== null) {
      this.setState({ product: value });
    } else {
      this.setState({ product: "" });
    }
  };

  searchJob = () => {
    this.dashbboardDetails();
  };

  getAllDrivers = () => {
    const { logoutUser } = this.props;
    const url = `job/driver/all`;
    axios
      .get(url)
      .then((res) => {
        let response = res.data;
        if (response.success === true) {
          this.setState({ allDrivers: response.data });
        } else {
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };

  showSnackBar = (message) => {
    this.setState({ openSnackbar: true, snackBarMessage: message });
  };
  handleSnackBarClose = () => {
    this.setState({ openSnackbar: false });
  };

  render() {
    const { classes } = this.props;
    const {
      openSnackbar,
      snackBarMessage,
      zones,
      noRecord,
      deliveryFrom,
      deliveryBy,
      showCircularProgress,
      allDrivers,
    } = this.state;
    return (
      <>
        <>
          <Grid
            className={classes.grid}
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid
              className={classes.grid}
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={12} md={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Select Date"
                    value={deliveryBy}
                    onChange={this.handleByDateChange}
                    name="deliverBy"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    className={classes.textField}
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date To"
                    name="deliveryFrom"
                    value={deliveryFrom}
                    onChange={this.handleFromDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    className={classes.textField}
                  />
                </MuiPickersUtilsProvider>

                <Autocomplete
                  className={classes.autoComplete}
                  name="driverName"
                  options={allDrivers}
                  getOptionLabel={(option) => option.driverName}
                  onChange={(event, value) =>
                    this.handleDriverChange(event, value)
                  }
                  renderInput={(params) => (
                    <TextField
                      value={this.state.driverName}
                      className={classes.textField}
                      {...params}
                      label="Operator Name"
                      variant="outlined"
                    />
                  )}
                />

                <FormControl variant="outlined" className={classes.selectFeild}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={this.state.status}
                    name="status"
                    onChange={this.handleChange}
                    label="status"
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    <MenuItem value={"1"}>Recorded</MenuItem>
                    <MenuItem value={"2"}>Awaiting Operator Receipt</MenuItem>
                    <MenuItem value={"3"}>Received By Operator</MenuItem>
                    <MenuItem value={"4"}>Amendments Submitted</MenuItem>
                    <MenuItem value={"5"}>Work Commenced</MenuItem>
                    <MenuItem value={"6"}>Completed and Signed Off</MenuItem>
                    <MenuItem value={"22"}>Completed with Follow Up</MenuItem>
                    <MenuItem value={"7"}>Returned</MenuItem>
                    <MenuItem value={"10"}>On Hold</MenuItem>
                    <MenuItem value={"11"}>Aborted</MenuItem>
                    <MenuItem value={"12"}>ReAssigned</MenuItem>
                    <MenuItem value={"13"}>Cancelled By Portal</MenuItem>
                    <MenuItem value={"14"}>Removed</MenuItem>
                    <MenuItem value={"20"}>Invoiced</MenuItem>
                    <MenuItem value={"21"}>Awaiting Payment</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.selectFeild}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    To Be Invoiced
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={this.state.toBeInvoiced}
                    name="toBeInvoiced"
                    onChange={this.handleChange}
                    label="to Be Invoiced"
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={"yes"}>Yes</MenuItem>
                    <MenuItem value={"no"}>No</MenuItem>

                    {/* <MenuItem value={"Awaiting Payment"}>Invoiced</MenuItem> */}
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  className={classes.startAutoJob}
                  disabled={showCircularProgress}
                  onClick={() => this.searchJob()}
                >
                  {showCircularProgress && (
                    <CircularProgress
                      size={20}
                      className={classes.circularProgress}
                    />
                  )}
                  Download Report
                </Button>
              </Grid>
            </Grid>
            {/* </AccordionDetails>
              </Accordion> */}
          </Grid>
        </>

        {noRecord && (
          <Typography
            variant="h6"
            component="h6"
            className={classes.noDataFoundText}
          >
            No Summary Record Found
          </Typography>
        )}

        <ConfirmedSnackBar
          open={openSnackbar}
          message={snackBarMessage}
          handleClose={this.handleSnackBarClose}
          classes={classes}
        />
      </>
    );
  }
}

export default withStyles(styles)(Summary);
