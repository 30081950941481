import React, { PureComponent, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import FormControl from "@material-ui/core/FormControl";
import CardContent from "@material-ui/core/CardContent";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import SearchIcon from "@material-ui/icons/Search";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import InputLabel from "@material-ui/core/InputLabel";
import Icon from "@material-ui/core/Icon";
import LaunchIcon from "@material-ui/icons/Launch";
import LastPageIcon from "@material-ui/icons/LastPage";
import InputBase from "@material-ui/core/InputBase";
import { alpha } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { axios } from "../../utils/axiosConfig";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ConfirmedSnackBar from "../../utils/ConfirmedSnackbar";
import { UserContextData, UserContext } from "../../Contexts/userContext";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";

import TablePagination from "@material-ui/core/TablePagination";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";

import TableHead from "@material-ui/core/TableHead";

import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Switch from "@material-ui/core/Switch";
import Chip from "@material-ui/core/Chip";
import SaveIcon from "@material-ui/icons/Save";
import moment from "moment-timezone";
import Card from "@material-ui/core/Card";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const styles = {
  root: {
    // background: "#000"
    // padding: "8px"
  },
  startAutoJob: {
    color: "#fff",
    background: "#f15a2f",
    width: "auto",
    height: "53px",
    marginRight:'10px',

    marginTop: "10px",
    marginBottom: "10px",
    marginLeft: "auto",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#f15a2f",
      color: "#fff"
    },
    float: "right"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  titleDiv: {
    display: "flex"
  },
  status: {
    fontSize: 14,
    color: "#fff",
    marginTop: "2px",
    fontStyle: "italic"
  },
  statusTime: {
    marginTop: 15
  },
  pos: {
    marginBottom: 12
  },
  linearProgress: {
    marginTop: "15px"
  },
  chip: {
    color: "primary",
    height: "23px"
  },
  jobCardTitle: {
    color: "#000",
    fontWeight: 600,
    lineHeight: "1.57143",
    fontSize: "1.275rem",
    margin: "none"
  },
  jobCardPrice: {
    color: "#000",
    fontSize: "2rem",
    marginTop: "7px",
    marginLeft: "7px",
    // fontWeight: '600',
    letterSpacing: "0.1rem",
    lineHeight: 1.5
  },
  snackbar: {
    backgroundColor: "rgb(50, 50, 50)"
  },
  close: {
    color: "#fff"
  },
  icon: {
    color: "#fff",
    margin: "4px 4px"
  },
  oilgasIcon: {
    margin: "auto",
    marginRight: "5px",
    color: "#e8e8e8",
    width: "65px",
    height: "40px"
  },
  editIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px"
  },
  circularProgress: {
    width: "20px !important",
    height: "20px !important",
    color: "#fff"
  },
  closeIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px",
    marginTop: "20px"
  },
  center: {
    // background: "#000",
    // padding: 16,
    // display: "flex",
    backgroundColor: "rgb(255, 255, 255)",
    color: "#000",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "8px",
    position: "relative",
    zIndex: 0,
    alignItems: "center",
    padding: "24px"
  },
  inputFeild: {
    width: "100%",
    marginTop: "12px"
  },
  feildsGrid: {
    marginTop: "8px"
  },
  jobCardButton: {
    color: "#fff",
    // borderRadius: '60%',
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#f15a2f"
    }
  },

  jobCardButton2: {
    // color:"#f15a2f",
    // borderRadius: '60%',
    fontSize: "12px",
    fontWeight: 600
    // "&:hover": {
    //   backgroundColor: '#ffff'
    // },
    // backgroundColor:"#ffff"
  },
  cardInfo: {
    marginTop: "15px"
  },
  tablePagination: {
    // position: 'sticky'
  },
  borderLeft: {
    borderLeft: "1px solid gray"
  },
  borderRight: {
    margin: "25px"
    // paddingBottom: "15px",
    // paddingLeft: "15px"
  },
  notificationsGrid: {
    margin: "0 25px 25px 25px"
  },
  stackRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "16px",
    marginBottom: "8px"
  },
  stackIcon: {
    width: "24px",
    height: "24px",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(84, 214, 44)",
    backgroundColor: "rgba(84, 214, 44, 0.16)"
  },
  stackIconDetail: {
    padding: 3
  },
  stackSubtitle: {
    color: "#000",
    fontWeight: "600",
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    margin: "0px 0px 0px 8px"
  },
  chartRoot: {
    margin: "0px 0px 0px auto"
  },
  cardSubTitle: {
    fontWeight: 400,
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    // textAlign:"left",
    // margin: '4px 0px 0px',
    color: "rgb(99, 115, 129)",
    display: "block"
  },
  textField: {
    // width: "100%",
    marginLeft: "5px",
    marginRight: "5px"
  },
  autoComplete: {
    width: "15%",
    marginTop:"5px",
    marginLeft: "5px",
    marginRight: "5px",
    /* display: flex; */

    display: "inline-flex",
    position: "relative",
    minWidth: 0,
    flexDirection: "column",
    verticalAlign: "top"
  },
  selectFeild: {
    width: "10%",
    marginTop: "15px",
    marginLeft: "5px",
    marginRight: "5px"
  },
  findNotification: {
    color: "#fff",
    background: "#000;",
    width: "auto",
    height: "53px",
    marginTop: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#000;",
      color: "#fff"
    }
  },
  tableHeading: {
    padding: "15px",
    fontSize: "20px",
    color: "#fff",
    display: "flex",
    background: "#000;"
  },
  tableBold: {
    fontWeight: 600,
    fontSize: "17px",
    color: "#000;"
  },
  search: {
    position: "relative",
    borderRadius: "4px",
    backgroundColor: alpha("#fff", 0.15),
    "&:hover": {
      backgroundColor: alpha("#fff", 0.25)
    },
    // marginLeft: 0,
    width: "250px",
    marginLeft: "auto"
  },
  searchIcon: {
    padding: "16px",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: "8px, 8px, 8px, 8px",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + 32px)`,
    width: "100%"
  },
  tableFooter: {
    width: "fit-content",
    marginTop: "20px",
    marginLeft: "auto"
  },
  noDataFoundText: {
    color: "#000",
    fontSize: "1rem",
    marginBottom: "7px",
    marginTop: "7px",
    margin:"auto",
    textAlign:"center",
    fontWeight: "600",
    // letterSpacing: "0.1rem",
    lineHeight: 1.5
  },
};
class Notification extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      openSnackbar: false,
      snackBarMessage: null,
      allDrivers: [],
      errorTitle: false,
      driverName: "",
      driverID: "",
      errorNotification: false,
      title: "",
      notification: "",
      progress: false,

      totalPages: 1,
      filters: "",
      filterPage: 1,
      pageSize: 10,
      showCircularProgress: false,



      tableColumns: [
        {
          field: "title",
          title: "Title"
        },
        // {
        //   field: "customerAccountRef",
        //   title: "Customer Account Ref"
        // },

        {
          field: "message",
          title: "Message"
        },
        {
          field: "sentBy",
          title: "Sent By"
        },
        {
          field: "operatorID",
          title: "Operator ID"
        },
        {
          field: "operatorName",
          title: "Operator Name"
        },
        {
          field: "sentAt",
          title: "Sent At"
        },
      ],

      dateFrom: moment().subtract(1, "month"),
      // deliveryBy: moment(),
      dateTo: moment(),
      operatorName:"",
      operatorID:"",
      email:"",
      notificationListFilteredData: [],
      notificationListData:[],
    };
  }
  componentDidMount() {
    this.getAllDrivers();
    this.notificationList(1);
  }
  showSnackBar = message => {
    this.setState({ openSnackbar: true, snackBarMessage: message });
  };
  handleSnackBarClose = () => {
    this.setState({ openSnackbar: false });
  };
  getAllDrivers = () => {
    const { logoutUser } = this.props;
    const url = `job/driver/all`;
    axios
      .get(url)
      .then(res => {
        let response = res.data;
        if (response.success === true) {
          this.setState({ allDrivers: response.data });
        } else {
        }
      })
      .catch(function(error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };

  sendNotification = () => {
    // const { logoutUser } = this.props;
    const { title, notification, driverID } = this.state;
    if (title == "" || title == null) {
      this.setState({ errorTitle: true });
      this.showSnackBar("Title Invalid");
      return;
    }
    if (notification == "" || notification == null) {
      this.setState({ errorNotification: true });
      this.showSnackBar("Notification Invalid");
      return;
    }

    if (driverID == "" || driverID == null) {
      this.showSnackBar("Operator not selected");
      return;
    }

    const url = `job/send/notification`;
    const body = {
      driverID: driverID,
      title: title,
      message: notification
    };
    this.setState({ progress: true });
    axios
      .post(url, body)
      .then(res => {
        let response = res.data;
        if (response.success === true) {
        } else {
        }
        this.showSnackBar(response.message);
        this.setState({ progress: false });
        this.setState({
          driverName: "",
          driverID: "",
          title: "",
          notification: ""
        });
      })
      .catch(function(error) {
        if (error.response.status === 403) {
        }
      });
  };
  handleDriverChange = (event, value) => {
    console.log(value, "event");
    if (value !== null) {
      this.setState({ driverName: value.driverName });
      this.setState({ driverID: value.driverID });
    } else {
      this.setState({ driverName: "", driverID: "" });
    }
  };
  handleOperatorChange = (event, value) => {
    console.log(value, "event");
    if (value !== null) {
      this.setState({ operatorName: value.operatorName });
      this.setState({ operatorID: value.driverID });
    } else {
      this.setState({ operatorName: "", operatorID: "" });
    }
  };

  handleChange = event => {
    this.setState({
      errorTitle: false,
      errorNotification: false
    });
    this.setState({ [event.target.name]: event.target.value });
  };


  handleFromDateChange = date => {
    this.setState({ dateFrom: date });
  };
  handleToDateChange = date => {
    console.log("pageChange", date);
    this.setState({ dateTo: date });
  };


  notificationList = pageNumber => {
    const { logoutUser } = this.props;
    this.setState({ showCircularProgress: true });
    const {
      pageSize,
      dateFrom,
      dateTo,
      
      email,
      operatorID
    } = this.state;

    let url = `job/notification?page=${pageNumber}&size=${pageSize}${
      dateFrom !== ""
        ? `&dateFrom=${moment(dateFrom)
            .tz("Europe/London")
            .format("YYYY-MM-DD")}`
        : ""
    }${
      dateTo !== ""
        ? `&dateTo=${moment(dateTo)
            .tz("Europe/London")
            .format("YYYY-MM-DD")}`
        : ""
    }${operatorID !== "" ? `&operatorID=${operatorID}` : ""}${
      email !== "" ? `&email=${email}` : ""
    }`;

    axios
      .get(url)
      .then(res => {
        let response = res.data;
        console.log(res, "reshere");
        if (response.success === true) {
          this.setState({ totalPages: response.data.totalPages });
          this.setState({
            notificationListData: response.data.notifications,
            notificationListFilteredData: response.data.notifications
          });
        } else {
          this.setState({ totalPages: 1 });
          this.setState({
            notificationListData: [],
            notificationListFilteredData: []
          });
        }
        this.setState({ showCircularProgress: false });
      })
      .catch(function(error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };

  searchNotifications = () => {
    this.notificationList(1);
  };
  handlePageChange = (event, pageNumber) => {
    console.log("pageChange", pageNumber);
    this.notificationList(pageNumber);
    this.setState({ filterPage: pageNumber });
  };
  render() {
    const { classes } = this.props;
    const {
      allDrivers,
      errorTitle,
      errorNotification,
      openSnackbar,
      snackBarMessage,
      progress,
      tableColumns,
      showCircularProgress,
      notificationListFilteredData,
      notificationListData,
      totalPages,
      dateFrom,
      operatorName,
      dateTo,
    } = this.state;
    return (
      <>
        <Grid container direction="row" spacing={2} alignItems="center">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.borderRight}
          >
            <Card className={classes.center}>
              {/* <CustomerAccountRefIcon className={classes.oilgasIcon} /> */}
              <div>
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  alignContent="space-between"
                >
                  <Typography
                    variant="h4"
                    component="h4"
                    className={classes.jobCardTitle}
                  >
                    Send Notification
                  </Typography>

                </Grid>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                
                >
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <TextField
                      value={this.state.title}
                      label="Title"
                      className={classes.inputFeild}
                      margin="normal"
                      name="title"
                      onChange={this.handleChange}
                      variant="outlined"
                      fullWidth
                      error={errorTitle}
                    />

                    <Autocomplete
             
                      name="driverName"
                      options={allDrivers}
                      getOptionLabel={option => option.driverName}
                      onChange={(event, value) =>
                        this.handleDriverChange(event, value)
                      }
                      renderInput={params => (
                        <TextField
                          value={this.state.driverName}
                          className={classes.inputFeild}
                          {...params}
                          label="Operator Name"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={8} lg={8}>
                    <TextField
                      value={this.state.notification}
                      id="outlined-multiline-static"
                      label="Message"
                      className={classes.inputFeild}
                      multiline
                      rows={5}
                      name="notification"
                      onChange={this.handleChange}
                      variant="outlined"
                      error={errorNotification}
                    />
                  </Grid>

                  <Button
                    variant="contained"
                    className={classes.startAutoJob}
                    onClick={() => this.sendNotification()}
                    endIcon={!progress && <Icon>send</Icon>}
                  >
                    {!progress && "Send Notification"}
                    {progress && (
                      <CircularProgress
                        size={20}
                        color="primary"
                        className={classes.circularProgress}
                      />
                    )}
                  </Button>
                </Grid>
              </div>
            </Card>
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={2} alignItems="center">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.notificationsGrid}
          >
            <Card className={classes.center}>
              {/* <CustomerAccountRefIcon className={classes.oilgasIcon} /> */}
              <div>
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  alignContent="space-between"
                >
                  <Typography
                    variant="h4"
                    component="h4"
                    className={classes.jobCardTitle}
                  >
                    Notifications
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  className={classes.feildsGrid}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    
                
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Date From"
                        // value={deliveryBy}
                        onChange={this.handleFromDateChange}
                        name="deliverBy"
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        className={classes.textField}
                      />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Date To"
                        name="deliveryFrom"
                        // value={deliveryFrom}
                        onChange={this.handleToDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        className={classes.textField}
                      />
                    </MuiPickersUtilsProvider>

                    <Autocomplete
                      className={classes.autoComplete}
                      name="driverName"
                      options={allDrivers}
                      getOptionLabel={option => option.driverName}
                      onChange={(event, value) =>
                        this.handleOperatorChange(event, value)
                      }
                      renderInput={params => (
                        <TextField
                          value={this.state.operatorName}
                          className={classes.inputFeild}
                          {...params}
                          label="Operator Name"
                          variant="outlined"
                        />
                      )}
                    />
                    <TextField
                      value={this.state.product}
                      name="email"
                      label="Sent By"
                      className={classes.textField}
                      margin="normal"
                      onChange={this.handleChange}
                      variant="outlined"
                      // error={errorFirstName}
                    />
                    <FormControl
                      variant="outlined"
                      className={classes.selectFeild}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Page Size
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={this.state.pageSize}
                        name="pageSize"
                        onChange={this.handleChange}
                        label="Page Size"
                      >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                      </Select>
                    </FormControl>
                    <Button
                    variant="contained"
                    className={classes.findNotification}
                    disabled={showCircularProgress}
                    onClick={() => this.searchNotifications()}
                  >
                    {showCircularProgress && (
                      <CircularProgress
                        size={20}
                        className={classes.circularProgress}
                      />
                    )}
                     Search
                  </Button>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  className={classes.feildsGrid}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    
                
                  >
                     <Grid
              className={classes.grid}
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <div className={classes.tableHeading}>
                <Typography>Notifications</Typography>
                {/* <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    onChange={e => this.handleSearch(e)}
                    placeholder="Search IWS Ref… "
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput
                    }}
                    inputProps={{ "aria-label": "search" }}
                  />
                </div> */}
              </div>

              <TableContainer
                component={Paper}
                className={classes.tableContainer}
              >
                <Table
                  aria-label="custom pagination table"
                  className={classes.table}
                >
                  <TableHead>
                    <TableRow className={classes.tableHead}>
                      {tableColumns.map(column => (
                        <TableCell
                          className={classes.tableHeadCol}
                          variant="head"
                          align="center"
                        >
                          {column.title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {showCircularProgress && (
                    <div className={classes.tableHeadFull}>
                      <Grid
                        className={classes.grid}
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                      >
                        <CircularProgress
                          size={50}
                          className={classes.circularStyle}
                        />
                      </Grid>
                    </div>
                  )}
                  {!showCircularProgress && (
                    <TableBody className={classes.tableHead}>
                      {notificationListFilteredData.map(row => (
                        <TableRow
                        //   key={row.name}
                        >
                          {tableColumns.map(column => (
                            <TableCell
                              // className={
                              //   isCellHovered == row.postCode + "-" + column.field &&
                              //   column.field !== "isEnabled" &&
                              //   column.field !== "currentlyPricing"
                              //     ? classes.tableRowHovered
                              //     : classes.tableRow
                              // }
                              // onMouseOver={() =>
                              //   cellHover(row.postCode + "-" + column.field)
                              // }
                              // onMouseLeave={() =>
                              //   cellLeave(row.postCode + "-" + column.field)
                              // }
                              component="th"
                              scope="row"
                              align="center"
                              // onClick={() => handleCellClick(row, column.field)}
                              // className={classes.tableBody}
                            >
                              {column.field === "status" ? (
                                <Chip
                                  size="small"
                                  style={
                                    row[column.field] === "Completed"||
                                    row[column.field] === "Invoiced"||
                                    row[column.field] === "Received By Operator"
                                      ? {
                                          backgroundColor: "#e5f9ed",
                                          color: "#1bc943",
                                          fontWeight: 600,
                                          padding: "5px"
                                        }
                                      : row[column.field] === "Cancelled By Portal" ||
                                        row[column.field] === "Aborted" ||
                                        row[column.field] === "Removed"
                                      ? {
                                          backgroundColor: "#fff5f6",
                                          color: "#f83245",
                                          fontWeight: 600,
                                          padding: "5px"
                                        }
                                      : 
                                      row[column.field] === "Recorded" ||
                                      row[column.field] === "Awaiting Operator Receipt" ||
                                      row[column.field] === "Amendments Submitted" ||
                                      row[column.field] === "Returned" 
                                    ? {
                                        backgroundColor: "#fff5f6",
                                        color: "#f15a2f",
                                        fontWeight: 600,
                                        padding: "5px"
                                      }
                                    : {
                                          backgroundColor: "#fff8e1",
                                          color: "#ffc107",
                                          fontWeight: 600,
                                          padding: "5px"
                                        }
                                  }
                                  label={row[column.field]}
                                  className={
                                    row[column.field] === true
                                      ? classes.enabledChip
                                      : classes.disabledChip
                                  }
                                />
                              ) : (column.field === "jobID"||
                                column.field === "customerName" ) ? (
                                <div className={classes.tableBold}>
                                  {row[column.field]}
                                </div>
                              ) : column.field === "deliveryDate" 
                               ? (
                                <div>
                                  {moment(row[column.field])
                                    .tz("Europe/London")
                                    .format("DD/MM/YYYY hh:mm A")}
                                </div>
                              ) 
                              : column.field === "deliveryBy" 
                              ? (
                               <div>
                                 {row[column.field]
                                  }
                               </div>
                             ) 
                             : column.field === "action" ? (
                                <div className={classes.tableBold}>
                                  <LaunchIcon
                                    className={classes.launchIcon}
                                    onClick={e => this.handleJobDetailView(row)}
                                  />
                                </div>
                              ) : (
                                <>{row[column.field]}</>
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                         {notificationListFilteredData.length === 0 &&
                      <Typography variant="h6"
                      component="h6" className={classes.noDataFoundText}>No Data Found</Typography>}
                   
                    </TableBody>
                  )}

                  <TableFooter>
                    <TableRow></TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
              <div className={classes.tableFooter}>
                <Pagination
                  count={totalPages}
                  variant="outlined"
                  onChange={this.handlePageChange}
                />
              </div>
            </Grid>
          
                   
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
        </Grid>

        <ConfirmedSnackBar
          open={openSnackbar}
          message={snackBarMessage}
          handleClose={this.handleSnackBarClose}
          classes={classes}
        />
      </>
    );
  }
}

export default withStyles(styles)(Notification);
