import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jobStarted: false,
  jobCompleted: false,
  updatingJob: false,
  paymentType: "",
  paymentRecieved: false,
  invoiceAddressDifferent: false,
  properties: {},
  clientSpecifcations: {},
  billingInfo: {},
  headerInfo: {},
  jobItems: [],
  payments: {},
  operator: {},
  jobID: "",
  selectedZone: "",
  currentStatus: 0,
};

export const editJob = createSlice({
  name: "editJob",
  initialState,
  reducers: {
    setCreateJobStatus: (state, action) => {
      state.jobStarted = action.payload;
    },
    setCompleteStatus: (state, action) => {
      state.jobCompleted = action.payload;
    },
    setJobEditStatus: (state, action) => {
      state.updatingJob = action.payload;
    },
    setPaymentType: (state, action) => {
      state.paymentType = action.payload;
    },
    setPaymentRecievedStatus: (state, action) => {
      state.paymentRecieved = action.payload;
    },

    setInvoiceAddressDifferent: (state, action) => {
      state.invoiceAddressDifferent = action.payload;
    },
    setProperties: (state, action) => {
      state.properties = action.payload;
    },
    setClientSpecifications: (state, action) => {
      state.clientSpecifcations = action.payload;
    },
    setBillingInfo: (state, action) => {
      state.billingInfo = action.payload;
    },
    setHeaderInfo: (state, action) => {
      state.headerInfo = action.payload;
    },
    addJobItem: (state, action) => {
      const data = action.payload;
      data.identifier = parseInt(Math.random() * 100000000);
      state.jobItems.push(data);
    },
    addAllJobItems: (state, action) => {
      // const data = action.payload;
      // data.identifier =
      state.jobItems = action.payload;
    },
    updateJobItems: (state, action) => {
      var foundIndex = state.jobItems.findIndex(
        (x) => x.identifier == action.payload.identifier
      );
      state.jobItems[foundIndex] = action.payload;
    },
    deleteJobItem: (state, action) => {
      state.jobItems = state.jobItems.map((data) =>
        data.identifier === action.payload ? { ...data, isDeleted: true } : data
      );

      // currentStateFetch(action.payload)
    },
    setPayments: (state, action) => {
      state.payments = action.payload;
    },
    setOperator: (state, action) => {
      state.operator = action.payload;
    },
    setJobID: (state, action) => {
      state.jobID = action.payload;
    },
    setCurrentStatus: (state, action) => {
      state.currentStatus = action.payload;
    },
    setSelectedZone: (state, action) => {
      state.selectedZone = action.payload;
    },
    resetAll: (state, action) => {
      state.jobStarted = false;
      state.jobCompleted = false;
      state.updatingJob = false;
      state.invoiceAddressDifferent = false;
      state.properties = {};
      state.clientSpecifcations = {};
      state.billingInfo = {};
      state.headerInfo = {};
      state.jobItems = [];
      state.payments = {};
      state.operator = {};
      state.jobID = "";
      state.selectedZone = "";
      state.currentStatus = 0;
    },
  },
});

export const {
  setProperties,
  setJobEditStatus,
  setClientSpecifications,
  setBillingInfo,
  setHeaderInfo,
  addJobItem,
  updateJobItems,
  deleteJobItem,
  setSelectedZone,
  setCreateJobStatus,
  setPayments,
  setOperator,
  setCurrentStatus,
  addAllJobItems,
  setCompleteStatus,
  setInvoiceAddressDifferent,
  setPaymentType,
  setPaymentRecievedStatus,
  resetAll,
  setJobID,
} = editJob.actions;
export const editJobData = (state) => state.editJob;
export default editJob.reducer;
