import React, { PureComponent, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import FormControl from "@material-ui/core/FormControl";
import CardContent from "@material-ui/core/CardContent";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import SearchIcon from "@material-ui/icons/Search";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import InputLabel from "@material-ui/core/InputLabel";
import LaunchIcon from "@material-ui/icons/Launch";
import LastPageIcon from "@material-ui/icons/LastPage";
import InputBase from "@material-ui/core/InputBase";
import { alpha } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { axios } from "../../utils/axiosConfig";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ConfirmedSnackBar from "../../utils/ConfirmedSnackbar";
import { UserContextData, UserContext } from "../../Contexts/userContext";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
// import JobDetail from "./JobDetail";
import TablePagination from "@material-ui/core/TablePagination";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";

import TableHead from "@material-ui/core/TableHead";

import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Switch from "@material-ui/core/Switch";
import Chip from "@material-ui/core/Chip";
import SaveIcon from "@material-ui/icons/Save";
import moment from "moment-timezone";
import Card from "@material-ui/core/Card";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import {
  setJobConfiguration,
  setOperators,
  setJobItems,
  resetConfiguration,
} from "../../Features/createJobConfigurationSlice";
import DashboardChart from "./DashboardChart";
import PaymentSubs from "./PaymentSubs";
const styles = {
  textField: {
    // width: "100%",
    marginLeft: "5px",
    marginRight: "5px",
  },
  selectFeild: {
    width: "15%",
    marginTop: "15px",
    marginLeft: "5px",
    marginRight: "5px",
  },
  grid: {
    padding: "16px",
  },
  tableHeadFull: {
    width: "100%",
  },
  datePicker: {
    selectColor: "black",
  },
  priceTextFeild: {
    margin: "26px",
  },
  jobCardPrice: {
    color: "#fff",
    fontSize: "52px",
    marginTop: "7px",
    marginBottom: "7px",
  },
  formControl: {
    width: "65%",
  },
  center: {
    margin: "auto",
    width: "fit-content",
  },
  search: {
    position: "relative",
    borderRadius: "4px",
    backgroundColor: alpha("#fff", 0.15),
    "&:hover": {
      backgroundColor: alpha("#fff", 0.25),
    },
    // marginLeft: 0,
    width: "250px",
    marginLeft: "auto",
  },
  searchIcon: {
    padding: "16px",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: "8px, 8px, 8px, 8px",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + 32px)`,
    width: "100%",
  },
  card: {
    width: "100%",
    backgroud: "#000; !important",
  },
  jobCardTitle: {
    marginTop: "10px",
    color: "#fff",
  },
  titleDiv: {
    display: "flex",
  },
  switchGrid: {
    margin: "auto",
    display: "flex",
  },
  switchClass: {
    margin: "3px 10px 5px 10px",
  },
  timeContainer: {
    margin: "43px 0px 0px 0px",
  },
  rootCard: {
    background: "#000",
    width: "100%",
    marginRight: "80px",
  },
  showCircularProgressStyle: {
    margin: "auto",
    width: "fit-content",
    padding: "50%",
  },

  tableHeading: {
    padding: "15px",
    fontSize: "20px",
    color: "#fff",
    display: "flex",
    background: "#000;",
  },
  tableBold: {
    fontWeight: 600,
    fontSize: "17px",
    color: "#000;",
  },

  gridHere: {
    // borderRight:"1px solid #000;"
  },

  editIcon: {
    color: "#fff",
    fontSize: "16px",
    cursor: "pointer",
    // marginLeft: "20px",
    // marginBottom: "15px"
  },

  expandIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    // marginLeft: "20px",
    // marginBottom: "15px"
  },
  launchIcon: {
    color: "#000;",
    cursor: "pointer",
  },
  oilIcons: {
    fontSize: "40px",
    color: "#fff",
    margin: "4px 4px",
  },
  priceIcons: {
    fontSize: "50px",
    color: "#fff",
    margin: "4px 4px",
  },
  editIconChip: {
    fontSize: "16px",
    cursor: "pointer",
  },
  tableCellClass: {
    display: "inline-flex",
  },
  inputFeildHovered: {
    color: "#fff",
    fontSize: "16px",
    width: "380px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  inputFeild: {
    color: "#000;",
    fontSize: "16px",
    width: "180px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  inputFeildHaulage: {
    color: "#000;",
    fontSize: "20px",
    background: "none",
    width: "40px",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  inputFeildHaulageHovered: {
    color: "#fff",
    fontSize: "20px",
    background: "none",
    width: "40px",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },

  startAutoJob: {
    color: "#fff",
    background: "#000;",
    width: "auto",
    height: "53px",
    marginTop: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#000;",
      color: "#fff",
    },
  },
  startCron: {
    marginLeft: "auto",
    marginTop: "auto",
    marginBottom: "auto",
  },
  tablePagination: {
    marginLeft: "auto",
  },
  enabledChip: {
    backgroudColor: "green",
  },
  disabledChip: {
    backgroud: "red",
  },
  "& .MuiTablePagination-toolbar": {
    marginLeft: "auto",
  },
  tableFooter: {
    width: "fit-content",
    marginTop: "20px",
    marginLeft: "auto",
  },
  circularProgress: {
    marginLeft: "10px",
  },
  circularProgressCenter: {
    margin: "auto",
  },
  autoCircularProgress: {
    margin: "auto",
  },
  circularStyle: {
    width: "100%",
    margin: "auto",
    marginTop: "15%",
    marginBottom: "15%",
  },
  circularProgressBase: {
    width: "100%",
  },
  snackbar: {
    backgroundColor: "rgb(50, 50, 50)",
  },
  fullWidth: {
    width: "100%",
  },
  close: {
    color: "#fff",
  },
  menuButtonsIconPrimary: {
    marginLeft: "auto",
    color: "#fff",
    cursor: "pointer",
    // height: "50px"
  },
  dialogContent: {
    margin: "10px",
  },
  newCronTimePicker: {
    marginTop: "15px",
  },
  headerStyle: {
    display: "flex",
    width: "100%",
  },
  autoComplete: {
    width: "20%",
    marginRight: "10px",
    marginTop: "15px",
    /* display: flex; */

    display: "inline-flex",
    position: "relative",
    minWidth: 0,
    flexDirection: "column",
    verticalAlign: "top",
  },
  autoComplete2: {
    width: "12%",
    marginRight: "10px",
    marginTop: "15px",
    /* display: flex; */

    display: "inline-flex",
    position: "relative",
    minWidth: 0,
    flexDirection: "column",
    verticalAlign: "top",
  },
  subHeading: {
    fontSize: "15px",
    fontWeight: 600,
  },
  noDataFoundText: {
    color: "#000",
    fontSize: "2rem",
    marginBottom: "7px",
    marginTop: "270px",
    margin: "auto",
    textAlign: "center",
    fontWeight: "600",
    // letterSpacing: "0.1rem",
    lineHeight: 1.5,
  },
  topPerformerTable: {
    padding: "20px",
    paddingBottom: "100px",
  },
};
var formatter = new Intl.NumberFormat("ru", {
  style: "currency",
  currency: "GBP",
});

class DashboardApp extends PureComponent {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      openSnackbar: false,
      snackBarMessage: null,
      showCircularProgress: false,

      filters: "",
      filterPage: 1,
      pageSize: 50,
      status: "",
      deliveryBy: moment().subtract(1, "month"),
      // deliveryBy: moment(),
      deliveryFrom: moment().add(1, "days"),
      // deliveryFrom: moment(),
      jobID: "",
      orderNumber: "",
      invoiceNumber: "",
      driverName: "",
      driverID: "",
      customerName: "",
      product: "",
      quantity: "",
      zoneValue: {},
      allDrivers: [],

      allVehicles: [],
      zones: [],
      jobDetailView: false,
      selectedJob: {},
      countOfPaymentsByLastWeekChartOption: {},
      sumOfPaymentsByLastWeekChartOption: {},

      totalJobs: 0,
      totalAmountEarned: 0,
      jobsByDate: {},
      earningByDate: [],
      jobsByAmountEarned: [],
      jobsByOperator: [],
      jobsByStatus: [],
      sumOfPaymentsByDriverChartOptions: {},
      sumOfPaymentsByTypeChartOptions: {},
      totalPaymentsChartOption: {},
      noDataFound: false,
      topOperatorByPerformance: [],
      tableColumns: [
        {
          field: "operatorName",
          title: "Operator Name",
        },
        {
          field: "completedJobs",
          title: "Completed Jobs",
        },
        // {
        //   field: "customerAccountRef",
        //   title: "Customer Account Ref"
        // },

        {
          field: "cancelledJobs",
          title: "Cancelled Jobs",
        },
        {
          field: "totalTimeTakenFormatted",
          title: "Total Time Taken",
        },
      ],
    };
  }

  componentDidMount() {
    this.dashbboardDetails();
    this.getAllDrivers();
    this.zoneList();
  }
  handleByDateChange = (date) => {
    console.log("pageChange", date);
    this.setState({ deliveryBy: date });
  };
  handleFromDateChange = (date) => {
    console.log("pageChange", date);
    this.setState({ deliveryFrom: date });
  };

  dashbboardDetails = () => {
    const { logoutUser } = this.props;
    this.setState({ showCircularProgress: true });
    const {
      status,
      deliveryBy,
      deliveryFrom,
      jobID,
      orderNumber,
      invoiceNumber,
      driverName,
      driverID,
      customerName,
      product,
      quantity,

      zoneValue,
    } = this.state;

    console.log(product, "productHere");
    let url = `job/dashboard/all?${
      deliveryBy !== ""
        ? `dateFrom=${moment(deliveryBy)
            .tz("Europe/London")
            .format("YYYY-MM-DD")}`
        : ""
    }${
      deliveryFrom !== ""
        ? `&dateTo=${moment(deliveryFrom)
            .tz("Europe/London")
            .format("YYYY-MM-DD")}`
        : ""
    }${driverID !== "" ? `&operatorID=${driverID}` : ""}${
      status !== "" ? `&jobStatus=${status}` : ""
    }${
      zoneValue.zoneID && zoneValue.zoneID !== ""
        ? `&zone=${zoneValue.zoneID}`
        : ""
    }`;

    axios
      .get(url)
      .then((res) => {
        let response = res.data;
        console.log(res, "reshere");
        if (response.success === true) {
          this.setState({
            totalJobs: response.data.totalJobs,
            totalAmountEarned: response.data.totalAmountEarned,
            earningByDate: response.data.earningByDate,
            jobsByAmountEarned: response.data.jobsByAmountEarned,
            jobsByOperator: response.data.jobsByOperator,
            jobsByStatus: response.data.jobsByStatus,
            jobsByDate: response.data.jobsByDate,
            topOperatorByPerformance: response.data.topOperatorByPerformance,
          });
          this.setState({
            sumOfPaymentsByDriverChartOptions: {
              options: {
                colors: [
                  "#FFB52E",
                  "#A36A00",
                  "#D18700",
                  "#FFA500",
                  "#FFB52E",
                  "#FFC55C",
                  "#FFD68A",
                  "#FFF8B3",
                ],
                legend: {
                  //   customLegendItems: ["Cash", "Checque", "Voucher"],
                  fontSize: "14px",
                  labels: {
                    colors: [
                      "#FFB52E",
                      "#A36A00",
                      "#D18700",
                      "#FFA500",
                      "#FFB52E",
                      "#FFC55C",
                      "#FFD68A",
                      "#FFF8B3",
                    ],
                  },
                  position: "bottom",
                  itemMargin: {
                    horizontal: 5,
                    vertical: 0,
                  },
                  markers: {
                    offsetX: -2,
                    offsetY: 1,
                  },
                },
                fill: {
                  colors: [
                    "#FFE700",
                    "#f1b640",
                    "#f8de7e",
                    "#fada5e",
                    "#d2b55b",
                    "#fcf4a3",
                    "#fcd12a",
                    "#FFF8B3",
                  ],
                },
                labels: response.data.jobsByOperator.map(
                  (item) => item.operatorName
                ),
                dataLabels: {
                  enabled: false,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      size: "90%",
                      labels: {
                        show: true,

                        total: {
                          show: true,
                          label: "Total",
                          color: "#373d3f",
                          fontSize: "16px",
                          fontFamily: undefined,
                          formatter: function (w) {
                            return w.globals.seriesTotals.reduce((a, b) => {
                              return Math.round((a + b) * 100) / 100;
                            }, 0);
                          },
                        },
                      },
                    },
                  },
                },
                chart: {
                  type: "donut",
                },
                responsive: [
                  {
                    breakpoint: 480,
                    options: {
                      chart: {
                        width: 200,
                      },
                    },
                  },
                ],
              },
            },
          });
          this.setState({
            sumOfPaymentsByTypeChartOptions: {
              options: {
                colors: [
                  "#FFB52E",
                  "#A36A00",
                  "#D18700",
                  "#FFA500",
                  "#FFB52E",
                  "#FFC55C",
                  "#FFD68A",
                  "#FFF8B3",
                ],
                legend: {
                  //   customLegendItems: ["Cash", "Checque", "Voucher"],
                  fontSize: "14px",
                  labels: {
                    colors: [
                      "#FFB52E",
                      "#A36A00",
                      "#D18700",
                      "#FFA500",
                      "#FFB52E",
                      "#FFC55C",
                      "#FFD68A",
                      "#FFF8B3",
                    ],
                  },
                  position: "bottom",
                  itemMargin: {
                    horizontal: 5,
                    vertical: 0,
                  },
                  markers: {
                    offsetX: -2,
                    offsetY: 1,
                  },
                },
                fill: {
                  colors: [
                    "#FFB52E",
                    "#A36A00",
                    "#D18700",
                    "#FFA500",
                    "#FFB52E",
                    "#FFC55C",
                    "#FFD68A",
                    "#FFF8B3",
                  ],
                },
                labels: response.data.jobsByStatus.map(
                  (item) => item.jobStatus
                ),
                dataLabels: {
                  enabled: false,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      size: "90%",
                      labels: {
                        show: true,

                        total: {
                          show: true,
                          label: "Total",
                          color: "#373d3f",
                          fontSize: "16px",
                          fontFamily: undefined,
                          formatter: function (w) {
                            return w.globals.seriesTotals.reduce((a, b) => {
                              return Math.round((a + b) * 100) / 100;
                            }, 0);
                          },
                        },
                      },
                    },
                  },
                },
                chart: {
                  type: "donut",
                },
                responsive: [
                  {
                    breakpoint: 480,
                    options: {
                      chart: {
                        width: 200,
                      },
                    },
                  },
                ],
              },
            },
          });
          this.setState({
            totalPaymentsChartOption: {
              series: [
                {
                  name: "Total Amount",
                  data: response.data.jobsByAmountEarned.map(
                    (item) => item.amountEarned
                  ),
                },
                {
                  name: "Total jobs",
                  data: response.data.jobsByAmountEarned.map(
                    (item) => item.countOfJobs
                  ),
                },
              ],
              options: {
                colors: ["rgba(35,35,35, 0.9)", "#FFA500"],
                fill: {
                  colors: ["rgba(35,35,35, 0.9)", "#FFA500"],
                  opacity: 0.9,
                  type: "image",
                },
                chart: {
                  background: "#fff",
                  height: 350,
                  type: "area",
                  toolbar: {
                    show: false,
                  },
                  zoom: {
                    enabled: false,
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: "smooth",
                  colors: ["rgba(35,35,35, 0.9)", "#f1b640"],
                },
                xaxis: {
                  type: "datetime",
                  categories: response.data.jobsByAmountEarned.map((item) =>
                    item.createdAt.split("/").reverse().join("-")
                  ),
                },
                // yaxis: {

                //   categories: response.data.paymentsForChartList.map(
                //     item => item.totalAmount
                //   )
                // },
                tooltip: {
                  x: {
                    show: false,
                    format: "dd/MM/yy",
                  },
                },
              },
            },
          });
          this.setState({
            countOfPaymentsByLastWeekChartOption: {
              options: {
                fill: {
                  colors: ["rgba(35,35,35, 0.9)"],
                },
                chart: {
                  id: "basic-bar",
                  toolbar: {
                    show: false,
                  },
                  zoom: {
                    enabled: false,
                  },

                  colors: ["rgba(35,35,35, 0.9)", "rgba(35,35,35, 0.9)"],
                  foreColor: "rgba(35,35,35, 0.9)",
                  stroke: {
                    show: true,
                    width: 10,
                    colors: ["transparent"],
                  },
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    borderRadius: 2,
                  },
                },
                grid: {
                  show: false,
                  xaxis: {
                    lines: {
                      show: false,
                    },
                  },
                  yaxis: {
                    lines: {
                      show: false,
                    },
                  },
                },
                xaxis: {
                  labels: {
                    show: false,
                  },
                  enabled: false,
                  categories: response.data.jobsByDate.map(
                    (item) => item.createdAt
                  ),
                  axisBorder: {
                    show: false,
                  },
                  axisTicks: {
                    show: false,
                  },
                },
                yaxis: {
                  labels: {
                    show: false,
                  },
                },
                dataLabels: {
                  enabled: false,
                },
              },
              series: [
                {
                  name: "Count of Jobs in last week",
                  data: response.data.jobsByDate.map(
                    (item) => item.countOfJobs
                  ),
                },
              ],
            },
          });
          this.setState({
            sumOfPaymentsByLastWeekChartOption: {
              options: {
                fill: {
                  colors: ["#f1b640"],
                },
                chart: {
                  id: "basic-bar",
                  toolbar: {
                    show: false,
                  },
                  zoom: {
                    enabled: false,
                  },

                  colors: ["rgb(255, 231, 0)", "rgb(255, 231, 0)"],
                  foreColor: "rgb(255, 231, 0)",
                  stroke: {
                    show: true,
                    width: 10,
                    colors: ["transparent"],
                  },
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    borderRadius: 2,
                  },
                },
                grid: {
                  show: false,
                  xaxis: {
                    lines: {
                      show: false,
                    },
                  },
                  yaxis: {
                    lines: {
                      show: false,
                    },
                  },
                },
                xaxis: {
                  labels: {
                    show: false,
                  },
                  enabled: false,
                  categories: response.data.earningByDate.map(
                    (item) => item.createdAt
                  ),
                  axisBorder: {
                    show: false,
                  },
                  axisTicks: {
                    show: false,
                  },
                },
                yaxis: {
                  labels: {
                    show: false,
                  },
                },
                dataLabels: {
                  enabled: false,
                },
              },
              series: [
                {
                  name: "Total Amount earned in last week",
                  data: response.data.earningByDate.map(
                    (item) => item.amountEarned
                  ),
                },
              ],
            },
          });
        } else {
          this.resetJob();
        }
        this.setState({ showCircularProgress: false });
      })
      .catch(function (error) {
        // if (error.response.status === 403) {
        //   logoutUser();
        // }
      });
  };

  resetJob = () => {
    this.setState({
      totalJobs: 0,
      totalAmountEarned: 0,
      jobsByDate: {},
      earningByDate: [],
      jobsByAmountEarned: [],
      jobsByOperator: [],
      jobsByStatus: [],
      sumOfPaymentsByDriverChartOptions: {},
      sumOfPaymentsByTypeChartOptions: {},
      totalPaymentsChartOption: {},
      countOfPaymentsByLastWeekChartOption: {},
      sumOfPaymentsByLastWeekChartOption: {},
    });
  };

  zoneList = () => {
    let url = `create/job/configuration`;

    axios
      .get(url)
      .then((res) => {
        let response = res.data;
        console.log(res, "reshere");
        if (response.success === true) {
          this.setState({ zones: response.data.zones });
        } else {
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          // logoutUser();
        }
      });
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleDriverChange = (event, value) => {
    console.log(value, "event");
    if (value !== null) {
      this.setState({ driverName: value.driverName });
      this.setState({ driverID: value.driverID });
    } else {
      this.setState({ driverName: "", driverID: "" });
    }
  };
  handleProductChange = (event, value) => {
    console.log(value, "event");
    if (value !== null) {
      this.setState({ product: value });
    } else {
      this.setState({ product: "" });
    }
  };

  handleByDateChange = (date) => {
    console.log("pageChange", date);
    this.setState({ deliveryBy: date });
  };
  handleFromDateChange = (date) => {
    console.log("pageChange", date);
    this.setState({ deliveryFrom: date });
  };
  handlePageChange = (event, pageNumber) => {
    console.log("pageChange", pageNumber);
    this.jobList(pageNumber);
    this.setState({ filterPage: pageNumber });
  };
  searchJob = () => {
    this.dashbboardDetails();
  };

  getAllDrivers = () => {
    const { logoutUser } = this.props;
    const url = `job/driver/all`;
    axios
      .get(url)
      .then((res) => {
        let response = res.data;
        if (response.success === true) {
          this.setState({ allDrivers: response.data });
        } else {
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };

  getAllVehicles = () => {
    // const { logoutUser } = this.props;
    // const url = `job/vehicle/all`;
    // axios
    //   .get(url)
    //   .then(res => {
    //     let response = res.data;
    //     if (response.success === true) {
    //       this.setState({ allVehicles: response.data });
    //     } else {
    //     }
    //   })
    //   .catch(function(error) {
    //     if (error.response.status === 403) {
    //       logoutUser();
    //     }
    //   });
  };
  jobDetailBack = () => {
    this.setState({ jobDetailView: false });
  };
  //   startAutoJob=()=>{
  //     this.setState({ currentRunningJob: true });
  //     let url = "/start/automated/job";
  //     axios.post(url).then(res => {
  //       let response = res.data;
  //       if (response.success === true) {
  //         this.setState({ currentRunningJob: true });
  //         this.showSnackBar(response.message);
  //       } else {
  //         this.setState({ currentRunningJob: false });
  //         this.showSnackBar(response.message);
  //         // this.setState({jobStages:})
  //       }
  //     });
  //   }

  handleJobDetailView = (row) => {
    this.setState({ jobDetailView: true, selectedJob: row });
  };

  showSnackBar = (message) => {
    this.setState({ openSnackbar: true, snackBarMessage: message });
  };
  handleSnackBarClose = () => {
    this.setState({ openSnackbar: false });
  };

  render() {
    const { classes } = this.props;
    const {
      openSnackbar,
      snackBarMessage,
      zones,
      totalJobs,
      totalAmountEarned,
      earningByDate,
      jobsByAmountEarned,
      jobsByOperator,
      jobsByStatus,

      litres,
      postCode,
      litre,
      productType,
      onlinePrice,
      targetPrice,
      errorPostCode,
      errorLitre,
      deliveryFrom,
      deliveryBy,
      showCircularProgress,
      jobDetailView,
      selectedJob,
      allDrivers,
      topOperatorByPerformance,
      allVehicles,
      jobsByDate,
      zoneValue,
      sumOfPaymentsByDriverChartOptions,
      sumOfPaymentsByTypeChartOptions,
      totalPaymentsChartOption,
      countOfPaymentsByLastWeekChartOption,
      sumOfPaymentsByLastWeekChartOption,
      tableColumns,
    } = this.state;
    return (
      <>
        <>
          <Grid
            className={classes.grid}
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid
              className={classes.grid}
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={12} md={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Select Date"
                    value={deliveryBy}
                    onChange={this.handleByDateChange}
                    name="deliverBy"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    className={classes.textField}
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date To"
                    name="deliveryFrom"
                    value={deliveryFrom}
                    onChange={this.handleFromDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    className={classes.textField}
                  />
                </MuiPickersUtilsProvider>

                <Autocomplete
                  className={classes.autoComplete}
                  name="driverName"
                  options={allDrivers}
                  getOptionLabel={(option) => option.driverName}
                  onChange={(event, value) =>
                    this.handleDriverChange(event, value)
                  }
                  renderInput={(params) => (
                    <TextField
                      value={this.state.driverName}
                      className={classes.textField}
                      {...params}
                      label="Operator Name"
                      variant="outlined"
                    />
                  )}
                />

                <FormControl variant="outlined" className={classes.selectFeild}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={this.state.status}
                    name="status"
                    onChange={this.handleChange}
                    label="status"
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    <MenuItem value={"1"}>Recorded</MenuItem>
                    <MenuItem value={"2"}>Awaiting Operator Receipt</MenuItem>
                    <MenuItem value={"3"}>Received By Operator</MenuItem>
                    <MenuItem value={"4"}>Amendments Submitted</MenuItem>
                    <MenuItem value={"5"}>Work Commenced</MenuItem>
                    <MenuItem value={"6"}>Completed and Signed Off</MenuItem>
                    <MenuItem value={"22"}>Completed with Follow Up</MenuItem>
                    <MenuItem value={"7"}>Returned</MenuItem>
                    <MenuItem value={"10"}>On Hold</MenuItem>
                    <MenuItem value={"11"}>Aborted</MenuItem>
                    <MenuItem value={"12"}>ReAssigned</MenuItem>
                    <MenuItem value={"13"}>Cancelled By Portal</MenuItem>
                    <MenuItem value={"14"}>Removed</MenuItem>
                    <MenuItem value={"20"}>Invoiced</MenuItem>
                    <MenuItem value={"21"}>Awaiting Payment</MenuItem>
                  </Select>
                </FormControl>
                <Autocomplete
                  className={classes.autoComplete2}
                  name="zones"
                  options={zones}
                  getOptionLabel={(option) => option.zoneName}
                  value={zoneValue}
                  onChange={(e, value) => this.setState({ zoneValue: value })}
                  renderInput={(params) => (
                    <TextField
                      v
                      className={classes.textField}
                      {...params}
                      label="Zone"
                      variant="outlined"
                    />
                  )}
                />

                <Button
                  variant="contained"
                  className={classes.startAutoJob}
                  disabled={showCircularProgress}
                  onClick={() => this.searchJob()}
                >
                  {showCircularProgress && (
                    <CircularProgress
                      size={20}
                      className={classes.circularProgress}
                    />
                  )}
                  Find Details
                </Button>
              </Grid>
            </Grid>
            {/* </AccordionDetails>
              </Accordion> */}
          </Grid>
        </>
        <Grid
          className={classes.grid}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          {totalJobs > 0 && (
            <PaymentSubs
              totalJobs={totalJobs}
              countOfJobsByLastWeekChartOption={
                countOfPaymentsByLastWeekChartOption
              }
              // paymentsForChartList={paymentsForChartList}
              totalAmountEarned={totalAmountEarned}
              // sumOfPaymentsByDriver={sumOfPaymentsByDriver}
              // sumOfPaymentsByLastWeek={sumOfPaymentsByLastWeek}
              // sumOfPaymentsByType={sumOfPaymentsByType}
              countOfPaymentsByLastWeekChartOption={
                countOfPaymentsByLastWeekChartOption
              }
              sumOfPaymentsByLastWeekChartOption={
                sumOfPaymentsByLastWeekChartOption
              }
            />
          )}
        </Grid>
        <Grid
          className={classes.grid}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          {totalJobs > 0 && (
            <DashboardChart
              jobsByOperator={jobsByOperator}
              sumOfPaymentsByType={jobsByStatus}
              sumOfPaymentsByDriverChartOptions={
                sumOfPaymentsByDriverChartOptions
              }
              sumOfPaymentsByTypeChartOptions={sumOfPaymentsByTypeChartOptions}
              totalPaymentsChartOption={totalPaymentsChartOption}
            />
          )}
        </Grid>
        {totalJobs === 0 && !showCircularProgress && (
          <Typography
            variant="h6"
            component="h6"
            className={classes.noDataFoundText}
          >
            No Data Found
          </Typography>
        )}
        {topOperatorByPerformance.length > 0 && (
          <div className={classes.topPerformerTable}>
            <div className={classes.tableHeading}>
              <Typography>Top performer</Typography>
            </div>
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table
                aria-label="custom pagination table"
                className={classes.table}
              >
                <TableHead>
                  <TableRow className={classes.tableHead}>
                    {tableColumns.map((column) => (
                      <TableCell
                        className={classes.tableHeadCol}
                        variant="head"
                        align="center"
                      >
                        {column.title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {showCircularProgress && (
                  <div className={classes.tableHeadFull}>
                    <Grid
                      className={classes.grid}
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <CircularProgress
                        size={50}
                        className={classes.circularStyle}
                      />
                    </Grid>
                  </div>
                )}
                {!showCircularProgress && (
                  <TableBody className={classes.tableHead}>
                    {topOperatorByPerformance.map((row) => (
                      <TableRow
                      //   key={row.name}
                      >
                        {tableColumns.map((column) => (
                          <TableCell
                            // className={
                            //   isCellHovered == row.postCode + "-" + column.field &&
                            //   column.field !== "isEnabled" &&
                            //   column.field !== "currentlyPricing"
                            //     ? classes.tableRowHovered
                            //     : classes.tableRow
                            // }
                            // onMouseOver={() =>
                            //   cellHover(row.postCode + "-" + column.field)
                            // }
                            // onMouseLeave={() =>
                            //   cellLeave(row.postCode + "-" + column.field)
                            // }
                            component="th"
                            scope="row"
                            align="center"
                            // onClick={() => handleCellClick(row, column.field)}
                            // className={classes.tableBody}
                          >
                            {column.field === "operatorName" ? (
                              <div className={classes.tableBold}>
                                {row[column.field]}
                              </div>
                            ) : (
                              <>{row[column.field]}</>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                )}

                <TableFooter>
                  <TableRow></TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        )}

        {/* {jobDetailView && (
          <JobDetail
            selectedJob={selectedJob}
            allDrivers={allDrivers}
            jobDetailBack={this.jobDetailBack}
            showSnackBar={this.showSnackBar}
          />
        )} */}
        <ConfirmedSnackBar
          open={openSnackbar}
          message={snackBarMessage}
          handleClose={this.handleSnackBarClose}
          classes={classes}
        />
      </>
    );
  }
}

export default withStyles(styles)(DashboardApp);
