import { createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';

export default  createTheme({
    palette: {
      background: {
        default: "#fffff"
      },
        type: 'light',
        primary: {
          main: "#f15a2f",
        },
        secondary: red,
       
    },
    typography: {
      fontFamily: 
        'Montserrat, sans-serif',
    },
  });

