import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle
} from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Chip from "@material-ui/core/Chip";
import DeleteIcon from "@material-ui/icons/Delete";
import TableChartIcon from "@material-ui/icons/TableChart";
import DoneIcon from "@material-ui/icons/Done";
import GetAppIcon from "@material-ui/icons/GetApp";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import moment from "moment-timezone";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import Input from "@material-ui/core/Input";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { axios } from "../../utils/axiosConfig";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ReactApexChart from "react-apexcharts";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useSelector, useDispatch } from "react-redux";
import { createJobConfigurationData } from "../../Features/createJobConfigurationSlice";
import {
  setProperties,
  createJobData
} from "../../Features/createJobDataSlice";
import SwipeableViews from "react-swipeable-views";
import PostCodeFind from "./PostCodeFind";
import ContactFind from "./ContactFind";
import AddPropertyForm from "./AddPropertyForm";

const useStyles = makeStyles({
  root: {
    // background: "#000"
    // padding: "8px"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  titleDiv: {
    display: "flex"
  },
  status: {
    fontSize: 14,
    color: "#fff",
    marginTop: "2px",
    fontStyle: "italic"
  },
  statusTime: {
    marginTop: 15
  },
  pos: {
    marginBottom: 12
  },
  linearProgress: {
    marginTop: "15px"
  },
  chip: {
    color: "primary",
    height: "23px"
  },
  jobCardTitle: {
    color: "#000",
    fontWeight: 600,
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    margin: "none"
  },
  cardSubTitle: {
    fontWeight: 400,
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    // margin: '4px 0px 0px',
    color: "rgb(99, 115, 129)",
    display: "block"
  },
  jobCardPrice: {
    color: "#000",
    fontSize: "1rem",
    // marginBottom: "px",
    fontWeight: "600",
    // letterSpacing: "0.1rem",
    lineHeight: 1.5
  },
  icon: {
    color: "#fff",
    margin: "4px 4px"
  },
  oilgasIcon: {
    margin: "auto",
    marginRight: "5px",
    color: "#e8e8e8",
    width: "65px",
    height: "40px"
  },
  editIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px"
  },
  circularProgress: {
    width: "20px !important",
    height: "20px !important"
  },
  closeIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px",
    marginTop: "20px"
  },
  center: {
    height: `calc(100vh - 350px)`,
    marginTop: "15px",
    // background: "#000",
    // padding: 16,
    // display: "flex",
    backgroundColor: "rgb(255, 255, 255)",
    color: "#000",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "8px",
    position: "relative",
    zIndex: 0,
    // display: "flex",
    alignItems: "center",
    padding: "24px"
  },
  inputFeild: {
    color: "#fff",
    fontSize: "32px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  jobCardButton: {
    color: "#fff",
    // borderRadius: '60%',
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#f15a2f"
    }
  },

  jobCardButton2: {
    // color:"#f15a2f",
    // borderRadius: '60%',
    fontSize: "12px",
    fontWeight: 600
    // "&:hover": {
    //   backgroundColor: '#ffff'
    // },
    // backgroundColor:"#ffff"
  },
  cardInfo: {
    marginTop: "15px"
  },
  tablePagination: {
    // position: 'sticky'
  },
  borderLeft: {
    borderLeft: "1px solid gray"
  },
  borderRight: {
    // paddingTop: "25px",
    // paddingBottom: "15px",
    // paddingLeft: "15px"
  },
  stackRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "16px",
    marginBottom: "8px"
  },
  stackIcon: {
    width: "24px",
    height: "24px",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(84, 214, 44)",
    backgroundColor: "rgba(84, 214, 44, 0.16)"
  },
  stackIconDetail: {
    padding: 3
  },
  stackSubtitle: {
    color: "#000",
    fontWeight: "600",
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    margin: "0px 0px 0px 8px"
  },
  chartRoot: {
    margin: "0px 0px 0px auto"
  },
  toggleButtonUnselected: {
    height: "45px",
    width: "auto",
    borderRadius: "3px",
    color: "#fff",
    fontSize: "14px",
    margin: "2px",
    borderColor: "#f15a2f",
    background: "#f15a2f",

    "&:hover": {
      background: "#f15a2f"
    }
  },
  toggleButtonSelected: {
    height: "45px",
    width: "auto",
    borderRadius: "3px",
    color: "#f15a2f",
    fontSize: "14px",
    margin: "2px",
    borderColor: "#f15a2f",
    background: "#fff",

    "&:hover": {
      background: "#fff"
    }
  },

  borderRightNone: {
    borderRight: "none"
  },
  borderLeftNone: {
    borderLeft: "none"
  },
  displayFlex: {
    display: "flex"
  }
});

const AddProperty = forwardRef((props, ref) => {
  const classes = useStyles();
  const createJobFormData = useSelector(createJobData);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (createJobFormData.properties.swipeValue) {
      console.log(createJobFormData.properties, "createJobFormData.iwsAccount");
      setValue(createJobFormData.properties.swipeValue);
    }
  }, [createJobFormData]);

  return (
    <Grid container direction="row">
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.borderRight}
      >
        <Card className={classes.center}>
          <div className={classes.displayFlex}>
            <Typography
              variant="h6"
              component="h6"
              className={classes.jobCardPrice}
            >
              Add Property
            </Typography>
            {createJobFormData.iwsAccount !== null && (
              <>
                {createJobFormData.iwsAccount ? (
                  <Chip
                    size="small"
                    style={{
                      backgroundColor: "#e5f9ed",
                      color: "#1bc943",
                      fontWeight: 600,
                      padding: "5px",
                      marginLeft: "auto"
                    }}
                    label={"IWS Client"}
                  />
                ) : (
                  <Chip
                    size="small"
                    style={{
                      marginLeft: "auto",
                      backgroundColor: "#fff5f6",
                      color: "#f83245",
                      fontWeight: 600,
                      padding: "5px"
                    }}
                    label={"Non IWS Client"}
                  />
                )}
              </>
            )}
          </div>

          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            centered
            aria-label="disabled tabs example"
          >
            <Tab label="Find Address By Postcode" />
            <Tab label="Find Address By Contact Name" />
            <Tab label="Add New Address" />
          </Tabs>
          <SwipeableViews
            //   axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChange}
          >
            <PostCodeFind
              swipeValue={value}
              index={0}
              dataSaved={props.dataSaved}
              ref={value === 0 ? ref : null}
            />
            <ContactFind
              swipeValue={value}
              index={1}
              dataSaved={props.dataSaved}
              ref={value === 1 ? ref : null}
            />
            <AddPropertyForm
              swipeValue={value}
              index={2}
              dataSaved={props.dataSaved}
              existingUser={false}
              ref={value === 2 ? ref : null}
            />
          </SwipeableViews>
        </Card>
      </Grid>
    </Grid>
  );
});

export default AddProperty;
