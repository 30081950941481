import React, { useContext, useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  CssBaseline,
  Hidden,
  Button,
} from "@material-ui/core";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import { IS_PROD } from "../../utils/constants";
import { UserContext, UserContextData } from "../../Contexts/userContext";

import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import MenuIcon from "@material-ui/icons/Menu";
import HelpIcon from "@material-ui/icons/Help";
import { UserRoles } from "../../NetworkInterfaces/loginResponse";
import { User } from "./Components/User";
import { Switch, Route, Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import UserMenu from "./Components/Menu";
import JobList from "../JobList/JobList";
import Help from "../Help/Help";
import logo from "../../images/logo-white.png";
import Notification from "../Notification/Notification";
import CreateJob from "../CreateJob/CreateJob";
import NotFound from "../404";
import Success from "../Success";
import Failure from "../Failure";
import UpdateJob from "../UpdateJob/UpdateJob";
import BookingCalendar from "../BookingCalendar/BookingCalendar";
import ItemsPrice from "../ItemsPrice/ItemsPrice";
import App from "../../App";
import DashboardApp from "../DashboardApp/DashboardApp";
import CalenderMonth from "../CalenderMonth/CalenderMonth";
import Summmary from "../Summary/Summmary";
const drawerWidth = 180;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%",
    },

    // to push the button to the right completely
    title: {
      flexGrow: 1,
    },
    logoClass: {
      width: "100%",
      backgroundColor: theme.palette.primary.main,
      height: "64px",
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    logoImage: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    iconImage: {
      margin: "auto",
    },
    toolbar: theme.mixins.toolbar,
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    content: {
      // flexGrow: 1,
      // padding: theme.spacing(2),
      overflow: "auto",
      overflowY: "hidden",
      marginTop: theme.spacing(8),
      width: "100%",
    },
    drawerPaper: {
      width: drawerWidth,
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },

    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    menuButtons: {
      display: "content",
      margin: "auto",

      color: "#e8e8e8",
    },
    menuButtonsIcon: {
      margin: "auto",
      color: "#e8e8e8",
      width: "100%",
      height: "35px",
      marginTop: "4px",
      marginBottom: "4px",
    },
    menuButtonsIconPrimary: {
      margin: "auto",
      color: theme.palette.primary.main,
      width: "100%",
      height: "35px",
    },
    listDivider: {
      background: "#454545",
    },
    menuList: {
      backgroundColor: "#000",
      height: "100%",
      overflowX: "hidden",
    },
    iconText: {
      // marginTop: theme.spacing(1),
      margin: "auto",
      color: "#e8e8e8",
    },
    iconTextPrimary: {
      margin: "auto",
      color: theme.palette.primary.main,
    },
  })
);

function UserIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
        <g>
          <path d="M94.677,82.929l-5.013-1.074-.018-.006L77.622,79.277A13.7,13.7,0,0,0,77.75,77.5V71.916A25.516,25.516,0,0,0,89.53,50.43V45.88h2.747a1.749,1.749,0,0,0,1.75-1.75v-6a1.749,1.749,0,0,0-1.75-1.75H91.014A26.953,26.953,0,0,0,70.25,10.473V6.5A1.749,1.749,0,0,0,68.5,4.75h-9A1.75,1.75,0,0,0,57.75,6.5v3.973A26.953,26.953,0,0,0,36.986,36.38H35.723a1.749,1.749,0,0,0-1.75,1.75v6a1.749,1.749,0,0,0,1.75,1.75H38.47v4.55A25.516,25.516,0,0,0,50.25,71.916V77.5a13.7,13.7,0,0,0,.128,1.777L38.354,81.849l-.013,0-5.016,1.074A13.816,13.816,0,0,0,22.44,96.37V121.5a1.75,1.75,0,0,0,1.75,1.75h79.62a1.75,1.75,0,0,0,1.75-1.75V96.37A13.815,13.815,0,0,0,94.677,82.929ZM87.514,36.38H79.25V26.13a1.75,1.75,0,1,0-3.5,0V36.38h-5.5V14.057A23.478,23.478,0,0,1,87.514,36.38ZM61.25,8.25h5.5V36.38h-5.5Zm-3.5,5.808V36.38h-5.5V26.13a1.75,1.75,0,1,0-3.5,0V36.38H40.486A23.476,23.476,0,0,1,57.75,14.058ZM37.473,39.88H90.527v2.5H37.473Zm4.5,10.55V45.88H86.03v4.55a22.03,22.03,0,0,1-44.06,0ZM64,75.95A25.394,25.394,0,0,0,74.25,73.8v3.7a10.25,10.25,0,0,1-20.5,0V73.8A25.394,25.394,0,0,0,64,75.95ZM25.94,96.37a10.3,10.3,0,0,1,8.117-10.019l2.913-.624V119.75H25.94ZM40.47,84.978l10.8-2.312a13.774,13.774,0,0,0,10.984,8.46V119.75H40.47ZM65.75,119.75V91.126a13.772,13.772,0,0,0,10.984-8.46l10.8,2.312V119.75Zm36.31,0H91.03V85.728l2.914.624A10.3,10.3,0,0,1,102.06,96.37Z" />
          <path d="M97.81,102.058a1.749,1.749,0,0,0-1.75,1.75v6.255a1.75,1.75,0,0,0,3.5,0v-6.255A1.75,1.75,0,0,0,97.81,102.058Z" />
          <path d="M55.86,98.08h-9a1.75,1.75,0,0,0,0,3.5h9a1.75,1.75,0,0,0,0-3.5Z" />
        </g>
      </svg>
    </SvgIcon>
  );
}

function NotificationIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        id="Layer_1"
        viewBox="0 0 128 128"
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
      >
        <path d="m121.5 13.6h-115a1.751 1.751 0 0 0 -1.75 1.75v97.308a1.751 1.751 0 0 0 1.75 1.742h115a1.751 1.751 0 0 0 1.75-1.75v-97.304a1.751 1.751 0 0 0 -1.75-1.746zm-113.25 3.5h111.5v10.637h-111.5zm0 93.808v-79.671h111.5v79.663z" />
        <path d="m82.958 19.393a3.024 3.024 0 1 0 3.024 3.024 3.024 3.024 0 0 0 -3.024-3.024z" />
        <path d="m94.792 19.393a3.024 3.024 0 1 0 3.023 3.024 3.024 3.024 0 0 0 -3.023-3.024z" />
        <path d="m106.625 19.393a3.024 3.024 0 1 0 3.023 3.024 3.023 3.023 0 0 0 -3.023-3.024z" />
        <path d="m97.521 53.3-25.034 25.036a1.753 1.753 0 0 0 -.239.3c-.015.025-.026.053-.041.078a1.812 1.812 0 0 0 -.134.3c-.006.019-.018.035-.024.054l-3.144 10.447a1.75 1.75 0 0 0 2.179 2.18l10.451-3.139c.02-.006.036-.018.055-.025a1.727 1.727 0 0 0 .3-.132c.027-.015.055-.027.081-.043a1.8 1.8 0 0 0 .3-.239l25.029-25.034a6.916 6.916 0 1 0 -9.779-9.783zm-24.327 34.1 1.363-4.527 3.166 3.166zm31.634-26.8-23.8 23.8-4.828-4.827 23.8-23.8a3.417 3.417 0 1 1 4.832 4.832z" />
        <path d="m98.729 59.4-12.993 12.994a1.75 1.75 0 0 0 2.474 2.475l12.99-12.994a1.75 1.75 0 0 0 -2.471-2.475z" />
        <path d="m52.652 69.32h-32.228a1.75 1.75 0 0 0 0 3.5h32.228a1.75 1.75 0 0 0 0-3.5z" />
        <path d="m20.421 82.294h32.228a1.75 1.75 0 0 0 0-3.5h-32.228a1.75 1.75 0 0 0 0 3.5z" />
        <path d="m52.652 88.269h-32.231a1.75 1.75 0 1 0 0 3.5h32.231a1.75 1.75 0 0 0 0-3.5z" />
        <path d="m52.652 50.372h-32.228a1.75 1.75 0 1 0 0 3.5h32.228a1.75 1.75 0 0 0 0-3.5z" />
        <path d="m20.421 63.345h32.228a1.75 1.75 0 0 0 0-3.5h-32.228a1.75 1.75 0 1 0 0 3.5z" />
      </svg>
    </SvgIcon>
  );
}

function PriceListIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      ]{" "}
      <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M47.1,45.347A15.654,15.654,0,1,0,31.443,29.693,15.672,15.672,0,0,0,47.1,45.347Zm-12.014-13.9H46.373l7.978,7.979a12.116,12.116,0,0,1-19.267-7.979Zm24.167-1.75a12.071,12.071,0,0,1-2.426,7.254l-7.977-7.978V17.68A12.162,12.162,0,0,1,59.251,29.693ZM45.348,17.68V27.943H35.084A12.161,12.161,0,0,1,45.348,17.68Z" />
          <path d="M33.193,57.927H94.809a1.75,1.75,0,0,0,0-3.5H33.193a1.75,1.75,0,1,0,0,3.5Z" />
          <path d="M33.19,65.93H80.5a1.75,1.75,0,0,0,0-3.5H33.19a1.75,1.75,0,1,0,0,3.5Z" />
          <path d="M33.19,73.94H75.18a1.75,1.75,0,0,0,0-3.5H33.19a1.75,1.75,0,1,0,0,3.5Z" />
          <path d="M33.19,81.94H72.94a1.75,1.75,0,0,0,0-3.5H33.19a1.75,1.75,0,1,0,0,3.5Z" />
          <path d="M33.19,89.95H73.01a1.75,1.75,0,0,0,0-3.5H33.19a1.75,1.75,0,1,0,0,3.5Z" />
          <path d="M33.19,97.95H75.44a1.75,1.75,0,0,0,0-3.5H33.19a1.75,1.75,0,0,0,0,3.5Z" />
          <path d="M33.19,105.96H81.05a1.75,1.75,0,0,0,0-3.5H33.19a1.75,1.75,0,1,0,0,3.5Z" />
          <path d="M94.809,110.461H33.193a1.75,1.75,0,1,0,0,3.5H94.809a1.75,1.75,0,0,0,0-3.5Z" />
          <path d="M70,37.337h6a1.75,1.75,0,0,0,0-3.5H70a1.75,1.75,0,0,0,0,3.5Z" />
          <path d="M84.562,37.337h10.2a1.75,1.75,0,0,0,0-3.5h-10.2a1.75,1.75,0,0,0,0,3.5Z" />
          <path d="M70,45.347H94.809a1.75,1.75,0,0,0,0-3.5H70a1.75,1.75,0,0,0,0,3.5Z" />
          <path d="M123.25,83.9A21.685,21.685,0,0,0,105.56,62.62V18.5a1.75,1.75,0,0,0-.513-1.237l-12-12A1.752,1.752,0,0,0,91.81,4.75H24.19A1.75,1.75,0,0,0,22.44,6.5v115a1.75,1.75,0,0,0,1.75,1.75h79.62a1.75,1.75,0,0,0,1.75-1.75V105.188A21.686,21.686,0,0,0,123.25,83.9ZM93.56,10.725l6.025,6.025H93.56Zm8.5,109.025H25.94V8.25H90.06V18.5a1.749,1.749,0,0,0,1.75,1.75h10.25V62.262c-.155,0-.309-.012-.464-.012a21.654,21.654,0,0,0,0,43.308c.155,0,.309-.009.464-.012Zm-.464-17.692A18.154,18.154,0,1,1,119.75,83.9,18.175,18.175,0,0,1,101.6,102.058Z" />
          <path d="M115.431,76.584a15.85,15.85,0,0,0-4.6-5.311,1.75,1.75,0,0,0-2.07,2.823,12.318,12.318,0,0,1,3.574,4.129,1.75,1.75,0,0,0,3.092-1.641Z" />
          <path d="M100.341,74.3c-.664.683-6.495,6.828-6.495,11.99a7.75,7.75,0,0,0,15.5,0c0-5.162-5.831-11.307-6.5-11.99A1.8,1.8,0,0,0,100.341,74.3Zm1.255,16.24a4.254,4.254,0,0,1-4.25-4.25c0-2.5,2.456-6,4.25-8.144,1.794,2.148,4.25,5.644,4.25,8.144A4.255,4.255,0,0,1,101.6,90.539Z" />
        </g>
      </svg>
    </SvgIcon>
  );
}
function CreateJobIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      ]{" "}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Outline"
        viewBox="0 0 512 512"
      >
        <path d="M128,176a8,8,0,0,0,0,16h48a8,8,0,0,0,0-16Z" />
        <path d="M200,184a8,8,0,0,0,8,8H320a8,8,0,0,0,0-16H208A8,8,0,0,0,200,184Z" />
        <path d="M96,224H208a8,8,0,0,0,0-16H96a8,8,0,0,0,0,16Z" />
        <path d="M96,256H208a8,8,0,0,0,0-16H96a8,8,0,0,0,0,16Z" />
        <path d="M320,208H240a8,8,0,0,0-8,8v32a8,8,0,0,0,8,8h80a8,8,0,0,0,8-8V216A8,8,0,0,0,320,208Zm-8,32H248V224h64Z" />
        <path d="M96,160H208a8,8,0,0,0,8-8V72a8,8,0,0,0-8-8H96a8,8,0,0,0-8,8v80A8,8,0,0,0,96,160Zm8-80h96v64H104Z" />
        <path d="M320,272H128a8,8,0,0,0,0,16H320a8,8,0,0,0,0-16Z" />
        <path d="M160,304H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Z" />
        <path d="M144,336H96a8,8,0,0,0-8,8v96a8,8,0,0,0,8,8h48a8,8,0,0,0,8-8V344A8,8,0,0,0,144,336Zm-8,96H104V352h32Z" />
        <path d="M464,352H441.992q-.925-2.372-1.989-4.69l13.654-13.653a8,8,0,0,0,0-11.314l-32-32a8,8,0,0,0-11.314,0L396.69,304q-2.316-1.062-4.69-1.989V280a8,8,0,0,0-8-8h-8V152a8,8,0,0,0-2.164-5.472l-120-128A8,8,0,0,0,248,16H48a8,8,0,0,0-8,8V488a8,8,0,0,0,8,8H384a8,8,0,0,0,8-8V465.992q2.372-.925,4.69-1.989l13.653,13.654a8,8,0,0,0,11.314,0l32-32a8,8,0,0,0,0-11.314L440,420.69q1.062-2.316,1.989-4.69H464a8,8,0,0,0,8-8V360A8,8,0,0,0,464,352ZM256,44.23,349.534,144H256ZM56,32H240V152a8,8,0,0,0,8,8H360V272H336a8,8,0,0,0-8,8v22.008q-2.372.925-4.69,1.989l-13.653-13.654a8,8,0,0,0-11.314,0L284.685,304H192a8,8,0,0,0,0,16h76.685l-2.342,2.343a8,8,0,0,0,0,11.314L280,347.31q-1.062,2.316-1.989,4.69H256a8,8,0,0,0-8,8v48a8,8,0,0,0,8,8h22.008q.925,2.372,1.989,4.69l-13.654,13.653a8,8,0,0,0,0,11.314l32,32a8,8,0,0,0,11.314,0L323.31,464q2.316,1.062,4.69,1.989V480H56ZM456,400H436.332a8,8,0,0,0-7.632,5.6,71.769,71.769,0,0,1-5.455,12.833,8,8,0,0,0,1.365,9.489L436.686,440,416,460.686,403.925,448.61a8,8,0,0,0-9.489-1.365A71.769,71.769,0,0,1,381.6,452.7a8,8,0,0,0-5.6,7.632V480H344V460.332a8,8,0,0,0-5.6-7.632,71.769,71.769,0,0,1-12.833-5.455,8,8,0,0,0-9.489,1.365L304,460.686,283.314,440l12.076-12.075a8,8,0,0,0,1.365-9.489A71.769,71.769,0,0,1,291.3,405.6a8,8,0,0,0-7.632-5.6H264V368h19.668a8,8,0,0,0,7.632-5.6,71.769,71.769,0,0,1,5.455-12.833,8,8,0,0,0-1.365-9.489L283.314,328,304,307.314l12.075,12.076a8,8,0,0,0,9.489,1.365A71.769,71.769,0,0,1,338.4,315.3a8,8,0,0,0,5.6-7.632V288h32v19.668a8,8,0,0,0,5.6,7.632,71.769,71.769,0,0,1,12.833,5.455,8,8,0,0,0,9.489-1.365L416,307.314,436.686,328,424.61,340.075a8,8,0,0,0-1.365,9.489A71.769,71.769,0,0,1,428.7,362.4a8,8,0,0,0,7.632,5.6H456Z" />
        <path d="M360,328a56,56,0,1,0,56,56A56.063,56.063,0,0,0,360,328Zm0,96a40,40,0,1,1,40-40A40.045,40.045,0,0,1,360,424Z" />
        <path d="M224,336H176a8,8,0,0,0-8,8v96a8,8,0,0,0,8,8h48a8,8,0,0,0,8-8V344A8,8,0,0,0,224,336Zm-8,96H184V352h32Z" />
      </svg>
    </SvgIcon>
  );
}
function PriceItemsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 128 128">
        <path d="m111.807 103.678 7.958-4.381a1.751 1.751 0 0 0 -.391-3.224l-4.751-1.273a59.233 59.233 0 1 0 -19.823 19.823l1.273 4.75a1.75 1.75 0 0 0 3.223.391l4.382-7.956 10.93 10.929a1.749 1.749 0 0 0 2.475 0l5.654-5.654a1.749 1.749 0 0 0 0-2.475zm-47.807 16.072a55.75 55.75 0 1 1 47.085-25.9l-20.618-5.527a1.75 1.75 0 0 0 -2.144 2.144l5.528 20.618a55.517 55.517 0 0 1 -29.851 8.665zm51.846-.725-11.34-11.339a1.745 1.745 0 0 0 -1.237-.513 1.864 1.864 0 0 0 -.247.017 1.751 1.751 0 0 0 -1.286.889l-3.408 6.188-5.839-21.778 21.779 5.839-6.189 3.407a1.75 1.75 0 0 0 -.393 2.771l11.339 11.34z" />
        <path d="m73.853 63.292a45.321 45.321 0 0 0 -7.053-2.183v-11.918a24.575 24.575 0 0 1 9.559 3.185l2.419-5.959a21.321 21.321 0 0 0 -5.517-2.477 27.65 27.65 0 0 0 -6.461-1.121v-6.549h-4.835v6.667q-6.609.651-10.089 4.012a10.9 10.9 0 0 0 -3.476 8.142 9.491 9.491 0 0 0 1.887 6.165 12.1 12.1 0 0 0 4.691 3.54 43.378 43.378 0 0 0 6.992 2.213v11.8a23.576 23.576 0 0 1 -6.491-1.5 19.509 19.509 0 0 1 -5.309-2.979l-2.66 5.954a17.99 17.99 0 0 0 6.194 3.3 33.053 33.053 0 0 0 8.261 1.593v6.553h4.835v-6.608q6.667-.708 10.178-4.041a10.768 10.768 0 0 0 3.51-8.113 9.4 9.4 0 0 0 -1.915-6.168 12.13 12.13 0 0 0 -4.72-3.508zm-11.888-3.422a13.283 13.283 0 0 1 -4.425-2.006 3.747 3.747 0 0 1 -1.534-3.127 4.635 4.635 0 0 1 1.475-3.481 8.169 8.169 0 0 1 4.484-1.888zm9.469 16.992a8.8 8.8 0 0 1 -4.634 1.829v-10.443a13.658 13.658 0 0 1 4.543 2.036 3.839 3.839 0 0 1 1.596 3.216 4.347 4.347 0 0 1 -1.505 3.362z" />
        <path d="m64 25.4a1.75 1.75 0 0 0 1.75-1.75v-8.525a1.75 1.75 0 0 0 -3.5 0v8.523a1.75 1.75 0 0 0 1.75 1.752z" />
        <path d="m92.533 37.217a1.745 1.745 0 0 0 1.238-.513l6.029-6.026a1.75 1.75 0 1 0 -2.477-2.478l-6.023 6.029a1.751 1.751 0 0 0 1.237 2.988z" />
        <path d="m104.352 65.75h8.523a1.75 1.75 0 0 0 0-3.5h-8.523a1.75 1.75 0 0 0 0 3.5z" />
        <path d="m64 102.6a1.75 1.75 0 0 0 -1.75 1.75v8.523a1.75 1.75 0 0 0 3.5 0v-8.523a1.75 1.75 0 0 0 -1.75-1.75z" />
        <path d="m34.229 91.3-6.029 6.022a1.75 1.75 0 1 0 2.477 2.478l6.023-6.029a1.75 1.75 0 0 0 -2.471-2.471z" />
        <path d="m23.648 62.25h-8.523a1.75 1.75 0 0 0 0 3.5h8.523a1.75 1.75 0 0 0 0-3.5z" />
        <path d="m30.677 28.2a1.75 1.75 0 1 0 -2.477 2.478l6.029 6.022a1.75 1.75 0 0 0 2.471-2.471z" />
      </svg>
    </SvgIcon>
  );
}
function CalenderIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      ]{" "}
      <svg id="Layer_1" version="1.1" viewBox="0 0 100.353 100.353">
        <g>
          <path d="M32.286,42.441h-9.762c-0.829,0-1.5,0.671-1.5,1.5v9.762c0,0.828,0.671,1.5,1.5,1.5h9.762c0.829,0,1.5-0.672,1.5-1.5   v-9.762C33.786,43.113,33.115,42.441,32.286,42.441z M30.786,52.203h-6.762v-6.762h6.762V52.203z" />
          <path d="M55.054,42.441h-9.762c-0.829,0-1.5,0.671-1.5,1.5v9.762c0,0.828,0.671,1.5,1.5,1.5h9.762c0.828,0,1.5-0.672,1.5-1.5   v-9.762C56.554,43.113,55.882,42.441,55.054,42.441z M53.554,52.203h-6.762v-6.762h6.762V52.203z" />
          <path d="M77.12,42.441h-9.762c-0.828,0-1.5,0.671-1.5,1.5v9.762c0,0.828,0.672,1.5,1.5,1.5h9.762c0.828,0,1.5-0.672,1.5-1.5v-9.762   C78.62,43.113,77.948,42.441,77.12,42.441z M75.62,52.203h-6.762v-6.762h6.762V52.203z" />
          <path d="M32.286,64.677h-9.762c-0.829,0-1.5,0.672-1.5,1.5v9.762c0,0.828,0.671,1.5,1.5,1.5h9.762c0.829,0,1.5-0.672,1.5-1.5   v-9.762C33.786,65.349,33.115,64.677,32.286,64.677z M30.786,74.439h-6.762v-6.762h6.762V74.439z" />
          <path d="M55.054,64.677h-9.762c-0.829,0-1.5,0.672-1.5,1.5v9.762c0,0.828,0.671,1.5,1.5,1.5h9.762c0.828,0,1.5-0.672,1.5-1.5   v-9.762C56.554,65.349,55.882,64.677,55.054,64.677z M53.554,74.439h-6.762v-6.762h6.762V74.439z" />
          <path d="M77.12,64.677h-9.762c-0.828,0-1.5,0.672-1.5,1.5v9.762c0,0.828,0.672,1.5,1.5,1.5h9.762c0.828,0,1.5-0.672,1.5-1.5v-9.762   C78.62,65.349,77.948,64.677,77.12,64.677z M75.62,74.439h-6.762v-6.762h6.762V74.439z" />
          <path d="M89,13.394h-9.907c-0.013,0-0.024,0.003-0.037,0.004V11.4c0-3.268-2.658-5.926-5.926-5.926s-5.926,2.659-5.926,5.926v1.994   H56.041V11.4c0-3.268-2.658-5.926-5.926-5.926s-5.926,2.659-5.926,5.926v1.994H33.025V11.4c0-3.268-2.658-5.926-5.926-5.926   s-5.926,2.659-5.926,5.926v1.995c-0.005,0-0.01-0.001-0.015-0.001h-9.905c-0.829,0-1.5,0.671-1.5,1.5V92.64   c0,0.828,0.671,1.5,1.5,1.5H89c0.828,0,1.5-0.672,1.5-1.5V14.894C90.5,14.065,89.828,13.394,89,13.394z M70.204,11.4   c0-1.614,1.312-2.926,2.926-2.926s2.926,1.312,2.926,2.926v8.277c0,1.613-1.312,2.926-2.926,2.926s-2.926-1.312-2.926-2.926V11.4z    M50.115,8.474c1.613,0,2.926,1.312,2.926,2.926v8.277c0,1.613-1.312,2.926-2.926,2.926c-1.614,0-2.926-1.312-2.926-2.926v-4.643   c0.004-0.047,0.014-0.092,0.014-0.141s-0.01-0.094-0.014-0.141V11.4C47.189,9.786,48.501,8.474,50.115,8.474z M24.173,11.4   c0-1.614,1.312-2.926,2.926-2.926c1.613,0,2.926,1.312,2.926,2.926v8.277c0,1.613-1.312,2.926-2.926,2.926   c-1.614,0-2.926-1.312-2.926-2.926V11.4z M87.5,91.14H12.753V16.394h8.405c0.005,0,0.01-0.001,0.015-0.001v3.285   c0,3.268,2.659,5.926,5.926,5.926s5.926-2.658,5.926-5.926v-3.283h11.164v3.283c0,3.268,2.659,5.926,5.926,5.926   s5.926-2.658,5.926-5.926v-3.283h11.163v3.283c0,3.268,2.658,5.926,5.926,5.926s5.926-2.658,5.926-5.926V16.39   c0.013,0,0.024,0.004,0.037,0.004H87.5V91.14z" />
        </g>
      </svg>
    </SvgIcon>
  );
}
function ExcelIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      ]{" "}
      <svg viewBox="0 0 1024 1024">
        <path d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494zM514.1 580.1l-61.8-102.4c-2.2-3.6-6.1-5.8-10.3-5.8h-38.4c-2.3 0-4.5.6-6.4 1.9-5.6 3.5-7.3 10.9-3.7 16.6l82.3 130.4-83.4 132.8a12.04 12.04 0 0 0 10.2 18.4h34.5c4.2 0 8-2.2 10.2-5.7L510 664.8l62.3 101.4c2.2 3.6 6.1 5.7 10.2 5.7H620c2.3 0 4.5-.7 6.5-1.9 5.6-3.6 7.2-11 3.6-16.6l-84-130.4 85.3-132.5a12.04 12.04 0 0 0-10.1-18.5h-35.7c-4.2 0-8.1 2.2-10.3 5.8l-61.2 102.3z" />
      </svg>
    </SvgIcon>
  );
}
function MonthIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      ]{" "}
      <svg viewBox="0 0 21 21">
        <g fill="none" fill-rule="evenodd" transform="translate(2 2)">
          <path
            d="m2.5.5h12c1.1045695 0 2 .8954305 2 2v12c0 1.1045695-.8954305 2-2 2h-12c-1.1045695 0-2-.8954305-2-2v-12c0-1.1045695.8954305-2 2-2z"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="m.5 4.5h16"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <g fill="currentColor">
            <circle cx="8.5" cy="8.5" r="1" />
            <circle cx="4.5" cy="8.5" r="1" />
            <circle cx="12.5" cy="8.5" r="1" />
            <circle cx="8.5" cy="12.5" r="1" />
            <circle cx="4.5" cy="12.5" r="1" />
            <circle cx="12.5" cy="12.5" r="1" />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
function DashboardIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      ]{" "}
      <svg x="0px" y="0px" viewBox="0 0 511.999 511.999">
        <g>
          <g>
            <polygon
              points="479.558,135.73 479.558,151.4 495.672,151.4 495.672,431.142 16.327,431.142 16.327,151.4 32.441,151.4 
			32.441,135.73 0.658,135.73 0.658,446.812 511.341,446.812 511.341,135.73 		"
            />
          </g>
        </g>
        <g>
          <g>
            <rect x="24.819" y="384.555" width="462.766" height="15.669" />
          </g>
        </g>
        <g>
          <g>
            <rect x="40.488" y="407.537" width="15.669" height="15.669" />
          </g>
        </g>
        <g>
          <g>
            <rect x="64.514" y="407.537" width="15.669" height="15.669" />
          </g>
        </g>
        <g>
          <g>
            <rect x="88.541" y="407.537" width="15.669" height="15.669" />
          </g>
        </g>
        <g>
          <g>
            <rect x="207.628" y="496.33" width="97.15" height="15.669" />
          </g>
        </g>
        <g>
          <g>
            <polygon
              points="207.627,456.635 207.627,472.305 246.278,472.305 246.278,486.929 261.947,486.929 261.947,472.305 
			304.776,472.305 304.776,456.635 		"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M40.579,0v287.129h180.237l35.183,35.181l35.184-35.182H471.42V0H40.579z M455.751,271.46H284.693l-28.694,28.691
			l-28.694-28.691H56.248V15.669h399.503V271.46z"
            />
          </g>
        </g>
        <g>
          <g>
            <rect x="80.184" y="33.564" width="64.766" height="15.669" />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M267.224,200.704l-16.253-16.233c8.096-11.611,12.862-25.709,12.862-40.905c0-39.54-32.168-71.708-71.708-71.708
			c-8.756,0-17.148,1.584-24.912,4.47L147.47,56.546H80.183v15.669h60.789l11.572,11.595
			c-19.344,12.856-32.126,34.837-32.126,59.754c0,39.54,32.168,71.708,71.708,71.708c18.699,0,35.743-7.202,48.521-18.967
			l20.092,20.067h67.016v-15.669H267.224z M183.601,140.332v58.623c-26.868-4.119-47.511-27.389-47.511-55.391
			c0-20.61,11.192-38.646,27.811-48.379l18.607,18.642l11.091-11.07l-13.819-13.842c3.976-0.898,8.105-1.388,12.348-1.388
			c12.4,0,23.866,4.055,33.158,10.9L183.601,140.332z M239.669,173.181l-10.118-10.106l-11.073,11.087l11.078,11.065
			c-8.258,7.426-18.728,12.427-30.287,13.905v-52.333l37.218-37.415c7.312,9.467,11.678,21.322,11.678,34.182
			C248.165,154.433,245.045,164.581,239.669,173.181z"
            />
          </g>
        </g>
        <g>
          <g>
            <rect x="264.037" y="224.729" width="63.722" height="15.669" />
          </g>
        </g>
        <g>
          <g>
            <rect x="304.777" y="49.233" width="15.669" height="15.669" />
          </g>
        </g>
        <g>
          <g>
            <rect x="336.116" y="49.233" width="96.105" height="15.669" />
          </g>
        </g>
        <g>
          <g>
            <rect x="304.777" y="73.259" width="15.669" height="15.669" />
          </g>
        </g>
        <g>
          <g>
            <rect x="336.116" y="73.259" width="96.105" height="15.669" />
          </g>
        </g>
        <g>
          <g>
            <rect x="304.777" y="96.241" width="15.669" height="15.669" />
          </g>
        </g>
        <g>
          <g>
            <rect x="336.116" y="96.241" width="96.105" height="15.669" />
          </g>
        </g>
        <g>
          <g>
            <rect x="80.184" y="240.398" width="64.766" height="15.669" />
          </g>
        </g>
        <g>
          <g>
            <rect x="367.455" y="136.981" width="64.766" height="15.669" />
          </g>
        </g>
        <g>
          <g>
            <rect x="391.481" y="169.364" width="40.74" height="15.669" />
          </g>
        </g>
        <g>
          <g>
            <rect x="407.15" y="200.703" width="25.071" height="15.669" />
          </g>
        </g>
        <g>
          <g>
            <rect x="415.507" y="232.041" width="16.714" height="15.669" />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}

const drawerItems = [
  {
    text: "Jobs ",
    icon: PriceListIcon,
    url: "/dashboard/orderList",
    id: 2,
  },
  {
    text: "Create Job",
    icon: CreateJobIcon,
    url: "/dashboard/createJob",
    id: 3,
  },

  // {
  //   text: "Dashboard",
  //   icon: PriceItemsIcon,
  //   url: "/dashboard/app",
  //   id: 7,
  // },
  {
    text: "Month",
    icon: PriceItemsIcon,
    url: "/dashboard/month",
    id: 5,
  },
  {
    text: "Items Price",
    icon: PriceItemsIcon,
    url: "/dashboard/items-price",
    id: 7,
  },
  {
    text: "Calendar",
    icon: CreateJobIcon,
    url: "/dashboard/calendar",
    id: 8,
  },
  {
    text: "Notifications",
    icon: NotificationIcon,
    url: "/dashboard/notification",
    id: 9,
  },
  // {
  //   text: "Summary",
  //   icon: ExcelIcon,
  //   url: "/dashboard/summary",
  //   id: 9,
  // },
];

function DrawerContent() {
  const classes = useStyles();
  const { user } = useContext<UserContextData>(UserContext);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  useEffect(() => {
    // Update the document title using the browser API
    switch (window.location.pathname) {
      case "/dashboard/summary": {
        setSelectedIndex(6);
        break;
      }
      case "/dashboard/month": {
        setSelectedIndex(5);
        break;
      }
      case "/dashboard/app": {
        setSelectedIndex(4);
        break;
      }
      case "/dashboard/items-price": {
        setSelectedIndex(7);
        break;
      }
      case "/dashboard/calendar": {
        setSelectedIndex(8);
        break;
      }

      case "/dashboard/notification": {
        setSelectedIndex(9);
        break;
      }
      case "/dashboard/createJob": {
        setSelectedIndex(3);
        break;
      }
      case "/dashboard/orderList": {
        setSelectedIndex(2);
        break;
      }
      case "/dashboard/user": {
        setSelectedIndex(1);
        break;
      }
    }
  });

  const userManagmentInsideList = drawerItems.find((x) => x.text === "Users");

  if (user?.role === UserRoles.ADMIN && !userManagmentInsideList) {
    // drawerItems.push();
    drawerItems.splice(2, 0, {
      text: "Dashboard",
      icon: PriceItemsIcon,
      url: "/dashboard/app",
      id: 4,
    });
    drawerItems.splice(4, 0, {
      text: "Summary",
      icon: ExcelIcon,
      url: "/dashboard/summary",
      id: 6,
    });
    // drawerItems.unshift();
    drawerItems.unshift({
      text: "Users",
      icon: UserIcon,
      url: "/dashboard/user",
      id: 1,
    });
  } else if (user?.role !== UserRoles.ADMIN && userManagmentInsideList) {
    // remove first item since we prepended it earlier
    drawerItems.shift();
  }
  const handleListItemClick = (index: number) => {
    setSelectedIndex(index);
  };

  return (
    <div className={classes.menuList}>
      <div className={classes.logoClass}>
        <img
          src={logo}
          alt="Kitten"
          width="85%"
          className={classes.logoImage}
        />
      </div>

      <Divider />
      <List>
        {drawerItems.map((item, index) => (
          <>
            <ListItem
              button
              key={item.text}
              component={Link}
              onClick={() => handleListItemClick(item.id)}
              to={item.url}
            >
              <div className={classes.menuButtons}>
                <ListItemIcon className={classes.menuButtonsIcon}>
                  {item.id == 1 ? (
                    <UserIcon
                      className={
                        selectedIndex == item.id
                          ? classes.menuButtonsIconPrimary
                          : classes.menuButtonsIcon
                      }
                    />
                  ) : item.id == 2 ? (
                    <PriceListIcon
                      className={
                        selectedIndex == item.id
                          ? classes.menuButtonsIconPrimary
                          : classes.menuButtonsIcon
                      }
                    />
                  ) : item.id == 3 ? (
                    <CreateJobIcon
                      className={
                        selectedIndex == item.id
                          ? classes.menuButtonsIconPrimary
                          : classes.menuButtonsIcon
                      }
                    />
                  ) : item.id == 4 ? (
                    <DashboardIcon
                      className={
                        selectedIndex == item.id
                          ? classes.menuButtonsIconPrimary
                          : classes.menuButtonsIcon
                      }
                    />
                  ) : item.id == 9 ? (
                    <NotificationIcon
                      className={
                        selectedIndex == item.id
                          ? classes.menuButtonsIconPrimary
                          : classes.menuButtonsIcon
                      }
                    />
                  ) : item.id == 8 ? (
                    <CalenderIcon
                      className={
                        selectedIndex == item.id
                          ? classes.menuButtonsIconPrimary
                          : classes.menuButtonsIcon
                      }
                    />
                  ) : item.id == 7 ? (
                    <PriceItemsIcon
                      className={
                        selectedIndex == item.id
                          ? classes.menuButtonsIconPrimary
                          : classes.menuButtonsIcon
                      }
                    />
                  ) : item.id == 5 ? (
                    <MonthIcon
                      className={
                        selectedIndex == item.id
                          ? classes.menuButtonsIconPrimary
                          : classes.menuButtonsIcon
                      }
                    />
                  ) : item.id == 6 ? (
                    <ExcelIcon
                      className={
                        selectedIndex == item.id
                          ? classes.menuButtonsIconPrimary
                          : classes.menuButtonsIcon
                      }
                    />
                  ) : (
                    <NotificationIcon
                      className={
                        selectedIndex == item.id
                          ? classes.menuButtonsIconPrimary
                          : classes.menuButtonsIcon
                      }
                    />
                  )}
                </ListItemIcon>
                <Typography
                  variant="body2"
                  // component="body1"
                  align="center"
                  className={
                    selectedIndex == item.id
                      ? classes.iconTextPrimary
                      : classes.iconText
                  }
                >
                  {item.text}
                </Typography>
              </div>
            </ListItem>
            <Divider className={classes.listDivider} />
          </>
        ))}

        <Divider />
        <ListItem
          button
          key="Sage Pay"
          component={Link}
          to={{
            pathname: "https://live.sagepay.com/mysagepay/login.msp",
          }}
          target="_blank"
        >
          <ListItemIcon>
            <AccountBalanceIcon className={classes.iconText} />
          </ListItemIcon>
          <ListItemText className={classes.iconText} primary="Sage Pay" />
        </ListItem>
      </List>
    </div>
  );
}

const container = window !== undefined ? () => window.document.body : undefined;

export default function Dashboard() {
  const { user, logoutUser } = useContext<UserContextData>(UserContext);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  let Redirection = null;
  if (user?.role === UserRoles.ADMIN) {
    Redirection = <Redirect to="/dashboard/orderList" />;
  }

  if (user?.role === UserRoles.USER) {
    Redirection = <Redirect to="/dashboard/orderList" />;
  }

  const isUserAdmin = user?.role === UserRoles.ADMIN;

  const handleDrawerToggle = () => {
    setDrawerIsOpen(!drawerIsOpen);
  };
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* fixed app bar */}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          {IS_PROD == "false" && (
            <Typography className={classes.title} variant="h5" noWrap>
              Order Management Dashboard - Test Environment
            </Typography>
          )}
          {IS_PROD == "true" && (
            <Typography className={classes.title} variant="h5" noWrap>
              Order Management Dashboard
            </Typography>
          )}

          <UserMenu />
        </Toolbar>
      </AppBar>
      {/* nav is needed to push the content away from the drawer since drawer is above every thing in z-index terms and its position is fixed*/}
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            open={drawerIsOpen}
            anchor="left"
            container={container}
            onClose={handleDrawerToggle}
            classes={{ paper: classes.drawerPaper }}
            variant="temporary"
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <DrawerContent />
          </Drawer>
        </Hidden>

        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <DrawerContent />
          </Drawer>
        </Hidden>
      </nav>

      <main className={classes.content}>
        <Switch>
          <Route exact path="/dashboard">
            {Redirection}
          </Route>
          <Route path="/dashboard/user">
            {isUserAdmin ? <User /> : <Redirect to="/dashboard/orderList" />}
          </Route>
          <Route path="/dashboard/orderList">
            <JobList logoutUser={logoutUser} />
          </Route>
          <Route path="/dashboard/notification">
            <Notification logoutUser={logoutUser} />
          </Route>
          <Route path="/dashboard/createJob">
            <CreateJob logoutUser={logoutUser} />
          </Route>
          <Route path="/dashboard/updateJob">
            <UpdateJob />
          </Route>
          <Route path="/dashboard/calendar">
            <BookingCalendar />
          </Route>
          <Route path="/dashboard/items-price">
            <ItemsPrice />
          </Route>
          <Route path="/dashboard/app">
            <DashboardApp />
          </Route>
          <Route path="/dashboard/month">
            <CalenderMonth />
          </Route>
          <Route path="/dashboard/summary">
            <Summmary />
          </Route>
          <Route path="/dashboard/help">
            <Help />
          </Route>
          <Route path="/dashboard/404">
            <NotFound />
          </Route>
          <Route path="/dashboard/Success">
            <Success />
          </Route>
          <Route path="/dashboard/Failure">
            <Failure />
          </Route>
          <Route path="/dashboard/*">
            <NotFound />
          </Route>
        </Switch>
      </main>
    </div>
  );
}
