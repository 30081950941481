import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Chip from "@material-ui/core/Chip";
import DeleteIcon from "@material-ui/icons/Delete";
import TableChartIcon from "@material-ui/icons/TableChart";
import DoneIcon from "@material-ui/icons/Done";
import GetAppIcon from "@material-ui/icons/GetApp";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from "@material-ui/core/Radio";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import moment from "moment-timezone";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import Input from "@material-ui/core/Input";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { axios } from "../../utils/axiosConfig";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ReactApexChart from "react-apexcharts";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import SwipeableViews from "react-swipeable-views";
import AddPropertyForm from "./AddPropertyForm";
import { useSelector, useDispatch } from "react-redux";
import { createJobConfigurationData } from "../../Features/createJobConfigurationSlice";
import {
  setProperties,
  createJobData,
  setIWSAccount
} from "../../Features/createJobDataSlice";
import { useFormik } from "formik";

const useStyles = makeStyles({
  stickyRoot: {
    width: "100%",
    position: "relative",
    overflow: "auto",
    maxHeight: 300
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  titleDiv: {
    display: "flex"
  },
  status: {
    fontSize: 14,
    color: "#fff",
    marginTop: "2px",
    fontStyle: "italic"
  },
  statusTime: {
    marginTop: 15
  },
  pos: {
    marginBottom: 12
  },
  linearProgress: {
    marginTop: "15px"
  },
  chip: {
    color: "primary",
    height: "23px"
  },
  jobCardTitle: {
    color: "#000",
    fontWeight: 600,
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    margin: "none"
  },
  cardSubTitle: {
    fontWeight: 400,
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    // margin: '4px 0px 0px',
    color: "rgb(99, 115, 129)",
    display: "block"
  },
  jobCardPrice: {
    color: "#000",
    fontSize: "1rem",
    marginBottom: "7px",
    fontWeight: "600",
    // letterSpacing: "0.1rem",
    lineHeight: 1.5
  },
  icon: {
    color: "#fff",
    margin: "4px 4px"
  },
  oilgasIcon: {
    margin: "auto",
    marginRight: "5px",
    color: "#e8e8e8",
    width: "65px",
    height: "40px"
  },
  editIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px"
  },
  circularProgress: {
    width: "20px !important",
    height: "20px !important"
  },
  closeIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px",
    marginTop: "20px"
  },
  center: {
    height: `calc(100vh - 390px)`,
    marginTop: "15px",
    // background: "#000",
    // padding: 16,
    // display: "flex",
    backgroundColor: "rgb(255, 255, 255)",
    color: "#000",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "8px",
    position: "relative",
    zIndex: 0,
    // display: "flex",
    alignItems: "center",
    padding: "24px"
  },
  inputFeild: {
    marginRight: "10px",
    color: "#fff",
    fontSize: "32px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  jobCardButton: {
    color: "#fff",
    // borderRadius: '60%',
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#f15a2f"
    }
  },

  jobCardButton2: {
    // color:"#f15a2f",
    // borderRadius: '60%',
    fontSize: "12px",
    fontWeight: 600
    // "&:hover": {
    //   backgroundColor: '#ffff'
    // },
    // backgroundColor:"#ffff"
  },
  cardInfo: {
    marginTop: "15px"
  },
  tablePagination: {
    // position: 'sticky'
  },
  borderLeft: {
    borderLeft: "1px solid gray"
  },
  borderRight: {
    // paddingTop: "25px",
    // paddingBottom: "15px",
    // paddingLeft: "15px"
  },
  stackRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "16px",
    marginBottom: "8px"
  },
  stackIcon: {
    width: "24px",
    height: "24px",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(84, 214, 44)",
    backgroundColor: "rgba(84, 214, 44, 0.16)"
  },
  stackIconDetail: {
    padding: 3
  },
  stackSubtitle: {
    color: "#000",
    fontWeight: "600",
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    margin: "0px 0px 0px 8px"
  },
  chartRoot: {
    margin: "0px 0px 0px auto"
  },
  toggleButtonUnselected: {
    height: "45px",
    width: "auto",
    borderRadius: "3px",
    color: "#fff",
    fontSize: "14px",
    margin: "2px",
    borderColor: "#f15a2f",
    background: "#f15a2f",

    "&:hover": {
      background: "#f15a2f"
    }
  },
  displayFlex: {
    marginRight: "auto",
    marginLeft: "auto",
    display: "flex"
  },
  toggleButtonSelected: {
    height: "45px",
    width: "auto",
    borderRadius: "3px",
    color: "#f15a2f",
    fontSize: "14px",
    margin: "2px",
    borderColor: "#f15a2f",
    background: "#fff",

    "&:hover": {
      background: "#fff"
    }
  },

  borderRightNone: {
    borderRight: "none"
  },
  borderLeftNone: {
    borderLeft: "none"
  },
  gridMargin: {
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: "30px",
    display: "flex"
  },
  findPostCode: {
    color: "#fff",
    fontWeight: 600,
    background: "#f15a2f",
    width: "350px",
    height: "53px",

    marginTop: "17px",

    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#f15a2f",
      color: "#fff"
    },
    float: "right"
  },
  textFeildMargin: {
    marginLeft: "10px"
  },
  radioOption: {
    cursor: "pointer",
    border: "1px solid rgba(224, 224, 224, 1)",
    borderRadius: "5px",
    marginBottom: "10px",
    "&:hover": {
      border: "2px solid #f15a2f",
      transition: "box-shadow 0.3s ease",
      boxShadow: "0 5px 10px rgb(77 83 88 / 23%)"
    }
  },
  noRecordFound: {
    marginTop: "80px"
  }
});

function capitalizeFirstLetter([first, ...rest], locale = navigator.language) {
  return [first.toLocaleUpperCase(locale), ...rest].join("");
}

const ContactFind = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [
    postCodeCircularProgress,
    setPostCodeCircularProgress
  ] = React.useState(false);
  const [noRecordFound, setNoRecordFound] = React.useState(false);
  const [recordFound, setRecordFound] = React.useState(false);
  const [fetchedDataFound, setfetchedDataFound] = React.useState(false);
  const [fetchedDataRows, setFetchedDataRows] = React.useState([]);
  const [fetchedData, setFetchedData] = React.useState(null);
  const [value, setValue] = React.useState(2);
  const createJobFormData = useSelector(createJobData);

  useEffect(() => {
    if (
      createJobFormData.properties.swipeValue !== undefined &&
      createJobFormData.properties.swipeValue === 1
    ) {
      console.log(
        createJobFormData.properties.swipeValue,
        ":createJobFormData.properties.swipeValue"
      );
      setfetchedDataFound(true);
    }
  }, [createJobFormData]);

  const validate = values => {
    const errors = {};

    if (!values.propertyName) {
      errors.propertyName = "Required";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      propertyName: ""
    },
    validateOnChange: false, // this one
    validateOnBlur: false,
    validate,
    onSubmit: values => {
      fetchAdresses(values.propertyName);
    }
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchAdresses = propertyName => {
    setPostCodeCircularProgress(true);
    setNoRecordFound(false);
    setRecordFound(false);
    setfetchedDataFound(false);
    let url = `create/job/fetch/address?propertyName=${propertyName}`;
    axios
      .get(url)
      .then(res => {
        let response = res.data;
        setPostCodeCircularProgress(false);
        console.log(res, "reshere");
        if (response.success === true) {
          setRecordFound(true);
          setFetchedDataRows(response.data.addresses);
          //   this.setState({ totalPages: response.data.totalPages });
          //   this.setState({
          //     jobListData: response.data.jobs,
          //     jobListFilteredData: response.data.jobs
          //   });
          // } else {
          //   this.setState({ totalPages: 1 });
          //   this.setState({
          //     jobListData: [],
          //     jobListFilteredData: []
          //   });
        } else {
          setNoRecordFound(true);
        }
        // this.setState({ showCircularProgress: false });
      })
      .catch(function(error) {
        if (error.response.status === 403) {
          // logoutUser();
        }
      });
  };

  const handleRadioOptionClicked = row => {

    console.log(row,"rowwwData")
    dispatch(setIWSAccount(row.isIWSClient))
    setRecordFound(false);
    setfetchedDataFound(true);
    setFetchedData(row);
  };
  return (
    <>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.gridMargin}>
          <form onSubmit={formik.handleSubmit} className={classes.displayFlex}>
            <TextField
              //   value={this.state.title}
              label="Contact Name"
              className={classes.inputFeild}
              margin="normal"
              //   onChange={this.handleChange}
              variant="outlined"
              fullWidth
              id="propertyName"
              name="propertyName"
              onChange={formik.handleChange}
              value={formik.values.propertyName}
              error={formik.errors.propertyName}
              helperText={formik.errors.propertyName}
            />

            <Button
              variant="contained"
              className={classes.findPostCode}
              type="submit"
              // onClick={() => setChangeDriverOpen(true)}
            >
              {postCodeCircularProgress && (
                <CircularProgress
                  size={30}
                  color="inherit"
                  // className={classes.circularProgress}
                />
              )}
              {!postCodeCircularProgress && "Find Contact"}
            </Button>
          </form>
        </Grid>
      </Grid>
      {noRecordFound && (
        <Typography
          align="center"
          variant="h6"
          className={classes.noRecordFound}
        >
          No Record Found
        </Typography>
      )}
      {recordFound && (
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.gridMargin}
          >
            <List className={classes.stickyRoot} subheader={<li />}>
              {fetchedDataRows.map(row => (
                <ListItem
                  className={classes.radioOption}
                  onClick={() => handleRadioOptionClicked(row)}
                >
                  <Radio
                    // checked={selectedValue === 'a'}
                    onChange={handleChange}
                    value="a"
                    name="radio-button-demo"
                    inputProps={{ "aria-label": "A" }}
                  />
                  {/* </ListItemAvatar> */}
                  <ListItemText
                    primary={`${row.propertyReference} | ${row.propertyName} | ${row.propertyContact} | ${row.propertyAddressOne}`}
                    secondary={row.propertyAddressOne}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      )}
      {fetchedDataFound && (
        <AddPropertyForm
          swipeValue={props.swipeValue}
          dataSaved={props.dataSaved}
          fetchedData={fetchedData}
          existingUser={true}
          ref={ref}
        />
      )}
    </>
  );
});

export default ContactFind;
