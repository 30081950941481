import React, { PureComponent } from "react";
import ConfirmedSnackBar from "../../utils/ConfirmedSnackbar";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// import Sidebar from "../../layouts/Sidebar";
// import Header from "../../layouts/Header";
import Grid from "@material-ui/core/Grid";
import AddUser from "./AddUser";
import UsersTable from "./UsersTable";
// import InteractionDialog from "../../common/InteractionDialog";
// import UpdateConfirmDialog from "../../common/ConfirmDialog";
// import DeleteConfirmDialog from "../../common/ConfirmDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import { axios } from "../../utils/axiosConfig";
import { UserContextData, UserContext } from "../../Contexts/userContext";
const styles = {
  grid: {
    padding: "16px"
  },
  switchGrid: {
    width: "181px"
  },
  root: {
    width: "100%"
  },
  tableContainer: {
    marginTop: "20px",
    width: "100%",
    background: "#000;"
  },
  tableHeading: {
    padding: "15px",
    fontSize: "20px",
    color: "#fff"
  },
  tableHead: {
    margin: "15px",
    background: "#fff",
    color: "#fffff",
    height: "10px"
  },
  tableHeadCol: {
    color: "#000;",
    fontSize: "15px",
    fontWeight: "600"
  },
  tableRow: {
    color: "#000;",
    fontSize: "15px",
    borderLeft: "1px solid rgba(35,35,35, 0.1);"
  },
  tableRowHovered: {
    background: "#000;",
    color: "#fff",
    fontSize: "15px",
    borderLeft: "1px solid rgba(35,35,35, 0.1);"
  },
  editIcon: {
    color: "#fff",
    fontSize: "16px",
    cursor: "pointer"
    // marginLeft: "20px",
    // marginBottom: "15px"
  },
  editIconChip: {
    fontSize: "16px",
    cursor: "pointer"
  },
  tableCellClass: {
    display: "inline-flex"
  },
  tableCellButtonClass:{
    display: "inline-flex", 
  },
  inputFeildHovered: {
    color: "#fff",
    fontSize: "16px",
    width: "380px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  inputFeild: {
    color: "#000;",
    fontSize: "16px",
    width: "380px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  inputFeildHaulage: {
    color: "#000;",
    fontSize: "20px",
    background: "none",
    width: "250px",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  inputFeildHaulageHovered: {
    color: "#fff",
    fontSize: "20px",
    background: "none",
    width: "250px",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  iconHovered:{
    color: "#fff",
  },
  saveData: {
    color: "#fff",
    background: "#000;",
    width: "130px",
    margin: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#000;",
      color: "#fff"
    }
  },
  tablePagination: {
    marginLeft: "auto"
  },
  enabledChip: {
    backgroudColor: "green"
  },
  disabledChip: {
    backgroud: "red"
  },
  "& .MuiTablePagination-toolbar": {
    marginLeft: "auto"
  },
  tableFooter: {
    width: "100%",
    display: "flex"
  },
  circularProgress: {
    margin: "4px",
    // marginTop:"10px",s
  },
  circularStyle: {
    width: "100%",
    margin: "auto",
    marginTop: "15%",
    marginBottom: "15%"
  },
  circularProgressBase: {
    width: "100%"
  },
  snackbar: {
    backgroundColor: "rgb(50, 50, 50)"
  },
  close: {
    color: "#fff"
  }
};

class Users extends PureComponent {
  static contextType = UserContext;
  constructor(props) {
    super(props);

    this.state = {
      userTableColumns: [
        {
          field: "firstName",
          title: "First Name"
        },

        {
          field: "lastName",
          title: "Last Name"
        },
        {
          field: "role",
          title: "Role"
        },
        {
          field: "createdAt",
          title: "Created At"
        },
        {
          field: "updatedAt",
          title: "Updated At"
        },
        {
            field: "password",
            title: "Password"
          },
        {
          field: "action",
          title: "Action"
        }
      ],
      users: [],
      showUserTableLoader: false,
      openSnackbar: false,
      snackBarMessage: null
    };
  }

  componentDidMount() {
    this.getAllUsers(this.props);
  }

  getAllUsers = (props) => {
    this.setState({ showUserTableLoader: true });
    let url = "/user/all";
    axios(url).then(res => {
      let response = res.data;
      if (response.success === true) {
        this.setState({ users: response.data });
        this.setState({ showUserTableLoader: false });
      } else {
        this.setState({ showUserTableLoader: false });
      }
    }).catch(function(error) {
      if (error.response.status === 403) {
        props.logoutUser();
        
      ;
      }
    });
  };

  showSnackBar = message => {
    this.setState({ openSnackbar: true, snackBarMessage: message });
  };
  handleSnackBarClose = () => {
    this.setState({ openSnackbar: false });
  };
  render() {
    const { classes } = this.props;
    console.log(this.props,"props");
    const {
      users,
      userTableColumns,
      showUserTableLoader,
      showGasTableLoader,
      tableColumns,
      gasTableData,
      showBoilerJuiceTableLoader,
      boilerJuiceTableColumns,
      boilerJuiceTableData,
      keroTableColumns,
      showHaulageTableLoader,
      showConfigurationLoader,
      configurationData,
      haulageTableData,
      haulageTableColumns,
      openSnackbar,
      commissionTableData,
      showCommissionTableLoader,
      commissiomTableColumns,
      snackBarMessage
    } = this.state;
    return (
      <>
        <Grid container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            md={12}
            sm={3}
            alignItems="stretch"
            direction="column"
          >
            <Grid container className={classes.addUserCard}>
              <AddUser
                getAllUsers={this.getAllUsers}
                showSnackBar={this.showSnackBar}
              />
            </Grid>
            {showUserTableLoader && (
            <Grid
              className={classes.grid}
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <CircularProgress className={classes.circularStyle} />
            </Grid>
          )}
           {!showUserTableLoader && users && (
             <Grid
             className={classes.grid}
             container
             direction="row"
             justify="space-between"
             alignItems="center"
           >

             <Grid item xs={12}>
             <UsersTable
                  rows={users}
                  tableColumns={userTableColumns}
                  classes={classes}
                  tableName="Users"
                  getAllUsers={this.getAllUsers}
                  showSnackBar={this.showSnackBar}
                />
               </Grid>
             </Grid>

          
          )}
          </Grid>
        </Grid>
        {/* <UpdateDialog
          openUpdateDialog={openUpdateDialog}
          onCloseUpdateDialog={this.onCloseUpdateDialog}
          onYesUpdateDialog={this.onYesUpdateDialog}
          classes={classes}
          firstName={firstName}
          lastName={lastName}
          emailAddress={emailAddress}
          userRole={userRole}
          accessLevelsValues={accessLevelsValues}
          userRoles={userRoles}
          accessLevels={accessLevels}
          onChange={this.onChange}
          handleCheck={this.handleCheck}
          userID={userID}
          errorFirstName={errorFirstName}
          errorLastName={errorLastName}
          errorEmailAddress={errorEmailAddress}
          errorRole={errorRole}
          showUpdateLoader={showUpdateLoader}
        /> */}
        {/* <UpdateConfirmDialog
          openConfirmDialog={openUpdateConfirmDialog}
          onCloseConfirmDialog={this.onCloseUpdateConfirmDialog}
          onYesConfirmDialog={this.onYesUpdateConfirmDialog}
          confirmDialogMessage={confirmUpdateDialogMessage}
          classes={classes}
        /> */}
        {/* <DeleteConfirmDialog
          openConfirmDialog={openDeleteConfirmDialog}
          onCloseConfirmDialog={this.onCloseDeleteConfirmDialog}
          onYesConfirmDialog={this.onYesDeleteConfirmDialog}
          confirmDialogMessage={confirmDeleteDialogMessage}
          classes={classes}
        /> */}
        <ConfirmedSnackBar
          open={openSnackbar}
          message={snackBarMessage}
          handleClose={this.handleSnackBarClose}
          classes={classes}
        />
      </>
    );
  }
}

export default withStyles(styles)(Users);
