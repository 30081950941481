import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jobStarted: false,
  jobCompleted: false,
  updatingJob: false,
  invoiceAddressDifferent: false,
  properties: {},
  clientSpecifcations: {},
  billingInfo: {},
  headerInfo: {},
  jobItems: [],
  payments: {},
  operator: {},
  jobID: "",
  selectedZone: "",
  iwsAccount: null,
  currentStatus: 0,
  paymentType: "",
  paymentRecieved: false,
  followUpJob: false,
  oldJobID: null,
};

export const createJob = createSlice({
  name: "createJob",
  initialState,
  reducers: {
    setCreateJobStatus: (state, action) => {
      state.jobStarted = action.payload;
    },
    setCompleteStatus: (state, action) => {
      state.jobCompleted = action.payload;
    },
    setJobEditStatus: (state, action) => {
      state.updatingJob = action.payload;
    },
    setPaymentType: (state, action) => {
      state.paymentType = action.payload;
    },
    setPaymentRecievedStatus: (state, action) => {
      state.paymentRecieved = action.payload;
    },
    setInvoiceAddressDifferent: (state, action) => {
      state.invoiceAddressDifferent = action.payload;
    },
    setProperties: (state, action) => {
      state.properties = action.payload;
    },
    setClientSpecifications: (state, action) => {
      state.clientSpecifcations = action.payload;
    },
    setCurrentStatus: (state, action) => {
      state.currentStatus = action.payload;
    },
    setBillingInfo: (state, action) => {
      state.billingInfo = action.payload;
    },
    setHeaderInfo: (state, action) => {
      state.headerInfo = action.payload;
    },
    addJobItem: (state, action) => {
      const data = action.payload;
      data.identifier = parseInt(Math.random() * 100000000);
      state.jobItems.push(data);
    },
    addAllJobItems: (state, action) => {
      // const data = action.payload;
      // data.identifier =
      state.jobItems = action.payload;
    },
    updateJobItems: (state, action) => {
      var foundIndex = state.jobItems.findIndex(
        (x) => x.identifier == action.payload.identifier
      );
      state.jobItems[foundIndex] = action.payload;
    },
    deleteJobItem: (state, action) => {
      state.jobItems = state.jobItems.filter(
        (pro) => pro.identifier !== action.payload
      );

      // currentStateFetch(action.payload)
    },
    setPayments: (state, action) => {
      state.payments = action.payload;
    },
    setOperator: (state, action) => {
      state.operator = action.payload;
    },
    setJobID: (state, action) => {
      state.jobID = action.payload;
    },
    setIWSAccount: (state, action) => {
      state.iwsAccount = action.payload;
    },
    setSelectedZone: (state, action) => {
      state.selectedZone = action.payload;
    },
    setFollowUpJobCheck: (state, action) => {
      state.followUpJob = action.payload;
    },
    setOldJobID: (state, action) => {
      state.oldJobID = action.payload;
    },
    resetAll: (state, action) => {
      state.jobStarted = false;
      state.jobCompleted = false;
      state.updatingJob = false;
      state.invoiceAddressDifferent = false;
      state.properties = {};
      state.clientSpecifcations = {};
      state.billingInfo = {};
      state.headerInfo = {};
      state.jobItems = [];
      state.payments = {};
      state.operator = {};
      state.jobID = "";
      state.selectedZone = "";
      state.iwsAccount = null;
      state.currentStatus = 0;
      state.followUpJob = false;
      state.oldJobID = null;
    },
  },
});

export const {
  setProperties,
  setJobEditStatus,
  setClientSpecifications,
  setBillingInfo,
  setHeaderInfo,
  addJobItem,
  updateJobItems,
  setSelectedZone,
  deleteJobItem,
  setCreateJobStatus,
  setPayments,
  setOperator,
  addAllJobItems,
  setCurrentStatus,
  setCompleteStatus,
  setJobID,
  setPaymentType,
  setPaymentRecievedStatus,
  setInvoiceAddressDifferent,
  setIWSAccount,
  resetAll,
  setOldJobID,
  setFollowUpJobCheck

} = createJob.actions;
export const createJobData = (state) => state.createJob;
export default createJob.reducer;
