import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useContext,
  useImperativeHandle
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import BusinessIcon from "@material-ui/icons/Business";
import StepLabel from "@material-ui/core/StepLabel";
import MuiAlert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ReceiptIcon from "@material-ui/icons/Receipt";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import RefreshIcon from "@material-ui/icons/Refresh";
import clsx from "clsx";
import StepButton from "@material-ui/core/StepButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { alpha } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment-timezone";
import { createJobConfigurationData } from "../../Features/createJobConfigurationSlice";
import {
  editJobData,
  setCreateJobStatus,
  resetAll
} from "../../Features/editJobDataSlice";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import StepConnector from "@material-ui/core/StepConnector";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import SwipeableViews from "react-swipeable-views";
import PageviewIcon from "@material-ui/icons/Pageview";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { UserContextData, UserContext } from "../../Contexts/userContext";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import ConfirmedSnackBar from "../../utils/ConfirmedSnackbar";
import { useSelector, useDispatch } from "react-redux";
import AddPropertyForm from "./AddPropertyForm";
import SpecifyClient from "./SpecifyClient";
import HeaderInfo from "./HeaderInfo";
import AddItems from "./AddItems";
import Payments from "./Payment";
import BillingInfo from "./BillingInfo";
import { axios } from "../../utils/axiosConfig";
import {
  setJobConfiguration,
  setOperators,
  setJobItems,
  resetConfiguration
} from "../../Features/createJobConfigurationSlice";
const useStyles = makeStyles({
  stepperIcon: {
    cursor: "pointer !important"
  },
  stepperGrid: {
    backgroundColor: "rgb(255, 255, 255)",
    color: "#000",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "8px",
    position: "relative",
    zIndex: 0,
    alignItems: "center"
  },

  root: {
    // background: "#000"
    // padding: "8px"
  },
  startAutoJob: {
    color: "#fff",
    fontWeight: 600,
    background: "#f15a2f",
    width: "200px",
    height: "53px",
    marginRight: "25px",

    marginTop: "10px",
    marginBottom: "10px",
    marginLeft: "auto",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#f15a2f",
      color: "#fff"
    },
    float: "right"
  },
  refreshButton: {
    color: "#fff",
    fontWeight: 600,
    background: "#000",
    width: "200px",
    height: "53px",
    // marginRight: "25px",

    marginTop: "10px",
    marginBottom: "10px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#000",
      color: "#fff"
    },
    float: "left"
  },

  center: {
    margin: "auto",
    width: "100%"
  },
  createJobButton: {
    // width:'100%',
    color: "#fff",
    fontWeight: 600,
    background: "#f15a2f",
    width: "200px",
    display: "block",
    height: "53px",
    margin: "auto",
    marginTop: "300px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#f15a2f",
      color: "#fff"
    }
  },
  backButton: {
    color: "#f15a2f",
    fontWeight: 600,
    // background: "#f15a2f",
    width: "200px",
    height: "53px",
    marginRight: "25px",
    borderColor: "#f15a2f",

    marginTop: "10px",
    marginBottom: "10px",
    marginLeft: "auto",
    fontSize: "16px",
    "&:hover": {
      // backgroundColor: "#f15a2f",
      color: "#f15a2f"
    },
    float: "right"
  },

  titleDiv: {
    display: "flex"
  },
  status: {
    fontSize: 14,
    color: "#fff",
    marginTop: "2px",
    fontStyle: "italic"
  },
  statusTime: {
    marginTop: 15
  },
  pos: {
    marginBottom: 12
  },
  linearProgress: {
    marginTop: "15px"
  },
  chip: {
    color: "primary",
    height: "23px"
  },
  jobCardTitle: {
    color: "#000",
    fontWeight: 600,
    lineHeight: "1.57143",
    fontSize: "1.275rem",
    margin: "none"
  },
  jobCardPrice: {
    color: "#000",
    fontSize: "2rem",
    marginTop: "7px",
    marginLeft: "7px",
    // fontWeight: '600',
    letterSpacing: "0.1rem",
    lineHeight: 1.5
  },
  snackbar: {
    backgroundColor: "rgb(50, 50, 50)"
  },
  close: {
    color: "#fff"
  },
  icon: {
    color: "#fff",
    margin: "4px 4px"
  },
  oilgasIcon: {
    margin: "auto",
    marginRight: "5px",
    color: "#e8e8e8",
    width: "65px",
    height: "40px"
  },
  editIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px"
  },
  circularProgress: {
    width: "20px !important",
    height: "20px !important",
    color: "#fff"
  },
  closeIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px",
    marginTop: "20px"
  },
  center: {
    // background: "#000",
    // padding: 16,
    // display: "flex",
    backgroundColor: "rgb(255, 255, 255)",
    color: "#000",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "8px",
    position: "relative",
    zIndex: 0,
    alignItems: "center",
    padding: "24px"
  },
  inputFeild: {
    width: "100%",
    marginTop: "12px"
  },
  feildsGrid: {
    marginTop: "8px"
  },
  jobCardButton: {
    color: "#fff",
    // borderRadius: '60%',
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#f15a2f"
    }
  },

  jobCardButton2: {
    // color:"#f15a2f",
    // borderRadius: '60%',
    fontSize: "12px",
    fontWeight: 600
    // "&:hover": {
    //   backgroundColor: '#ffff'
    // },
    // backgroundColor:"#ffff"
  },
  cardInfo: {
    marginTop: "15px"
  },
  tablePagination: {
    // position: 'sticky'
  },
  borderLeft: {
    borderLeft: "1px solid gray"
  },
  borderRight: {
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "20px"
    // paddingBottom: "15px",
    // paddingLeft: "15px"
  },
  marginGrid: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "20px",
    marginTop: "10px"
  },
  notificationsGrid: {
    margin: "0 25px 25px 25px"
  },
  stackRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "16px",
    marginBottom: "8px"
  },
  stackIcon: {
    width: "24px",
    height: "24px",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(84, 214, 44)",
    backgroundColor: "rgba(84, 214, 44, 0.16)"
  },
  stackIconDetail: {
    padding: 3
  },
  stackSubtitle: {
    color: "#000",
    fontWeight: "600",
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    margin: "0px 0px 0px 8px"
  },
  chartRoot: {
    margin: "0px 0px 0px auto"
  },
  cardSubTitle: {
    fontWeight: 400,
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    // textAlign:"left",
    // margin: '4px 0px 0px',
    color: "rgb(99, 115, 129)",
    display: "block"
  },
  textField: {
    // width: "100%",
    marginLeft: "5px",
    marginRight: "5px"
  },
  autoComplete: {
    width: "15%",
    marginTop: "5px",
    marginLeft: "5px",
    marginRight: "5px",
    /* display: flex; */

    display: "inline-flex",
    position: "relative",
    minWidth: 0,
    flexDirection: "column",
    verticalAlign: "top"
  },
  selectFeild: {
    width: "10%",
    marginTop: "15px",
    marginLeft: "5px",
    marginRight: "5px"
  },
  findNotification: {
    color: "#fff",
    background: "#000;",
    width: "auto",
    height: "53px",
    marginTop: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#000;",
      color: "#fff"
    }
  },
  tableHeading: {
    padding: "15px",
    fontSize: "20px",
    color: "#fff",
    display: "flex",
    background: "#000;"
  },
  tableBold: {
    fontWeight: 600,
    fontSize: "17px",
    color: "#000;"
  },
  search: {
    position: "relative",
    borderRadius: "4px",
    backgroundColor: alpha("#fff", 0.15),
    "&:hover": {
      backgroundColor: alpha("#fff", 0.25)
    },
    // marginLeft: 0,
    width: "250px",
    marginLeft: "auto"
  },
  searchIcon: {
    padding: "16px",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: "8px, 8px, 8px, 8px",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + 32px)`,
    width: "100%"
  },
  tableFooter: {
    width: "fit-content",
    marginTop: "20px",
    marginLeft: "auto"
  },
  noDataFoundText: {
    color: "#000",
    fontSize: "1rem",
    marginBottom: "7px",
    marginTop: "7px",
    margin: "auto",
    textAlign: "center",
    fontWeight: "600",
    // letterSpacing: "0.1rem",
    lineHeight: 1.5
  },
  marginTopNone: {
    marginTop: "0px !important"
  },
  refreshDialogButton: {
    color: "#fff",
    background: "#f15a2f",
    width: "auto",
    height: "53px",
    marginTop: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#f15a2f",
      color: "#fff"
    }
  },
  cancelJob: {
    color: "#000",
    // background: "#f44336",
    width: "auto",
    height: "53px",
    marginTop: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#000",
      color: "#fff"
    }
  }
});

function UpdateJob(props) {
  let history = useHistory();
  const [steps, setSteps] = useState([
    "Add Property",
    "Billing Info",
    "Specify Client",
    "Header Info",
    "Add Items",
    "Payments"
  ]);
  const [activeStep, setActiveStep] = useState(0);
  const [swipeValue, setSwipeValue] = useState(0);
  const { user, logoutUser } = useContext(UserContext);
  const [createJobProgress, setCreateJobProgress] = useState(false);
  const [loadJobProgress, setLoadJobProgress] = useState(false);
  const [clientSpecifcations, setClientSpecifcations] = useState({});
  const [headerInfo, setHeaderInfo] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [refreshOpen, setRefreshOpen] = useState(false);
  const [createJobOpen, setCreateJobOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [jobItemsData, setJobItemsData] = useState([]);
  const [properties, setProperties] = useState({});
  const [billingInfo, setBillingInfo] = useState({});
  const [paymentsData, setPaymentsData] = useState({});
  const [operatorData, setOperatorsData] = useState({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const createJobFinalData = useSelector(editJobData);
  console.log(createJobFinalData, "createJobFinalData");
  const configurationData = useSelector(createJobConfigurationData);
  const addPropertyRef = useRef();
  const specifyClientRef = useRef();
  const billingInfoRef = useRef();
  const headerInfoRef = useRef();
  const paymentRef = useRef();

  useEffect(() => {
    getConfiguration();
    getJobItems();
    getOperators();
  }, []);

  useEffect(() => {
    if (
      createJobFinalData.paymentType === "ACCOUNT" ||
      createJobFinalData.paymentRecieved === true
    ) {
      setSteps([
        "Add Property",
        "Billing Info",
        "Specify Client",
        "Header Info",
        "Items"
      ]);
    }

    setClientSpecifcations(createJobFinalData.clientSpecifcations);
    setHeaderInfo(createJobFinalData.headerInfo);
    setJobItemsData(createJobFinalData.jobItems);

    setProperties(createJobFinalData.properties);
    setBillingInfo(createJobFinalData.billingInfo);
    setOperatorsData(createJobFinalData.operator);
    setPaymentsData(createJobFinalData.payments);
  }, [createJobFinalData]);

  const invoiceTotal = () => {
    if (headerInfo.taxReference && jobItemsData.length > 0) {
      const subtotal = jobItemsData
        .map(({ unitTotal }) => unitTotal)
        .reduce((sum, i) => sum + i, 0);
      const tax = (subtotal * headerInfo.taxReference.taxRate) / 100;
      return tax + subtotal;
    } else {
      return 0;
    }
  };
  const invoiceTotalVat = () => {
    if (headerInfo.taxReference && jobItemsData.length > 0) {
      const subtotal = jobItemsData
        .map(({ vatAmount }) => vatAmount)
        .reduce((sum, i) => sum + i, 0);

      return subtotal;
    } else {
      return 0;
    }
  };

  const invoiceTotalAmount =()=>{
    if (headerInfo.taxReference && jobItemsData.length > 0) {
      const subtotal = jobItemsData
        .map(({ unitTotal }) => unitTotal)
        .reduce((sum, i) => sum + i, 0);

      return subtotal;
    } else {
      return 0;
    }


  }

  const handleStartJob = () => {
    setLoadJobProgress(true);
    getConfiguration();
    getJobItems();
    getOperators();
  };

  const getConfiguration = () => {
    let url = `create/job/configuration`;
    axios
      .get(url)
      .then(res => {
        let response = res.data;
        // setPostCodeCircularProgress(false);
        console.log(res, "reshere");
        if (response.success === true) {
          dispatch(setJobConfiguration(response.data));
        } else {
        }
      })
      .catch(function(error) {
        if (error.response && error.response.status === 403) {
          // logoutUser();
        }
      });
  };
  const getJobItems = () => {
    let url = `create/job/item`;
    axios
      .get(url)
      .then(res => {
        let response = res.data;
        console.log(res, "reshere");
        if (response.success === true) {
          dispatch(setJobItems(response.data));
        } else {
        }
      })
      .catch(function(error) {
        if (error.response && error.response.status === 403) {
          // logoutUser();
        }
      });
  };

  const getOperators = () => {
    const url = `job/driver/all`;
    axios
      .get(url)
      .then(res => {
        let response = res.data;
        if (response.success === true) {
          dispatch(setOperators(response.data));
        } else {
        }
      })
      .catch(function(error) {
        if (error.response.status === 403) {
        }
      });
  };
  const handleNext = () => {
    if (activeStep === steps.length) {
      return;
    }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      return;
    }
    setActiveStep(activeStep - 1);
  };
  const handleSwipeChange = (event, newValue) => {
    setSwipeValue(newValue);
  };
  const stepIconClick = index => {
    setActiveStep(index);
  };

  const dataSaved = () => {
    if (activeStep === steps.length - 1) {
      return;
    }

    // showSnackBar("data saved");

    setActiveStep(activeStep + 1);
  };

  const handleSave = () => {
    switch (activeStep) {
      case 0: {
        addPropertyRef.current && addPropertyRef.current.saveData();
        return;
      }
      case 1: {
        billingInfoRef.current && billingInfoRef.current.saveData();
        return;
      }
      case 2: {
        specifyClientRef.current && specifyClientRef.current.saveData();
        return;
      }
      case 3: {
        headerInfoRef.current && headerInfoRef.current.saveData();

        return;
      }
      case 4: {
        if (jobItemsData.length === 0) {
          showSnackBar("No Items Added");
          return;
        }
        dataSaved();
        return;
      }
      case 5: {
        dataSaved();
        return;
      }
      default: {
        return;
      }
    }
  };

  const showSnackBar = message => {
    setSnackBarMessage(message);
    setOpenSnackbar(true);
  };
  const handleSnackBarClose = () => {
    setSnackBarMessage("");
    setOpenSnackbar(false);
  };

  const handleCreateJob = () => {
    if (
      paymentsData.paymentType == "PRE" &&
      paymentsData.preAuthPaymentTransID == ""
    ) {
      showSnackBar("Please provide Transaction ID");
      setCreateJobOpen(false);
      return;
    }

    setCreateJobProgress(true);
    updateJob();

    // if (steps.length == 4 || createJobFinalData.paymentType === "ACCOUNT") {

    // } else {
    //   updatePayment();
    // }
  };

  const updatePayment = () => {
    const body = {
      jobID: createJobFinalData.jobID,
      paymentType: paymentsData.paymentType ? paymentsData.paymentType : "",
      paymentAddedAt: paymentsData.paymentAddedAt
        ? paymentsData.paymentAddedAt == "N/A"
          ? paymentsData.paymentAddedAt
          : moment(paymentsData.paymentAddedAt)
              .tz("Europe/London")
              .format("YYYY-MM-DD HH:mm:ss")
        : "",
      paymentReceived: paymentsData.paymentReceived
        ? paymentsData.paymentReceived
        : false
    };
    const url = "create/job/accept/payment";
    axios
      .post(url, body)
      .then(res => {
        let response = res.data;

        if (response.success === true) {
          console.log("payment success");
          updateJob();
        } else {
          showSnackBar("Unable to updateJob job");
          setCreateJobProgress(false);
          setCreateJobOpen(false);
        }
      })
      .catch(function(error) {
        if (error.response.status === 403) {
          // logoutUser();
        }
      });
  };
  const updateJob = () => {
    const body2 = {
      sageAccountReference: properties.sageAccountRef
        ? properties.sageAccountRef
        : "",
      clientOrderNo: headerInfo.clientOrderNumber
        ? headerInfo.clientOrderNumber
        : "",
      updatedBy: `${user.firstName} ${user.lastName}`,
      iwsClientID: properties.iwsClientID ? properties.iwsClientID : -1,
      iwsClientAccountReference: properties.iwsClientAccountReference
        ? properties.iwsClientAccountReference
        : "N/A",
      property: {
        ID: properties.propertyID ? properties.propertyID : "",
        number: properties.propertyNumber ? properties.propertyNumber : "",
        contact: properties.contact ? properties.contact : "",
        reference: properties.propertyReference
          ? properties.propertyReference
          : "",
        name: properties.propertyName ? properties.propertyName : "",
        address: properties.address1 ? properties.address1 : "",
        town: properties.town ? properties.town : "",
        county: properties.county ? properties.county : "",
        postcode: properties.postcode ? properties.postcode : "",
        mobile: properties.mobile ? properties.mobile : "",
        fax: properties.fax ? properties.fax : "",
        telephone: properties.telephone ? properties.telephone : "",
        email: properties.email ? properties.email : "",
        zoneID: properties.zone ? properties.zone.zoneID : "",
        zone: properties.zone ? properties.zone.zoneName : "",
        type: properties.type ? properties.type : "",
        category: properties.catagory ? properties.catagory : ""
      },
      descriptionOfWork: clientSpecifcations.descriptionOfWork
        ? clientSpecifcations.descriptionOfWork
        : "",
      siteContact: clientSpecifcations.siteContacts
        ? clientSpecifcations.siteContacts
        : "",
      SpecialNotes: clientSpecifcations.specialInstructions
        ? clientSpecifcations.specialInstructions
        : "",
      priorityID: headerInfo.priorityReference
        ? headerInfo.priorityReference.priorityID
        : "",
      priorityDescription: headerInfo.priorityReference
        ? headerInfo.priorityReference.priorityReference
        : "",
      daysOrHours: headerInfo.priorityReference
        ? headerInfo.priorityReference.priorityType === "DAYS"
          ? "D"
          : "H"
        : "",
      timeQuantity: headerInfo.priorityReference
        ? headerInfo.priorityReference.priorityPeriod
        : "",
      requestedCompletionDate: headerInfo.dueCompelitionDate
        ? moment(headerInfo.dueCompelitionDate)
            .tz("Europe/London")
            .format("YYYY-MM-DD")
        : "",
      requestedCompletionTime: headerInfo.dueCompelitionDate
        ? moment(headerInfo.dueCompelitionDate)
            .tz("Europe/London")
            .format("HH:mm:ss")
        : "",
      totalNumberOfItems: jobItemsData.length,
      vatRate: headerInfo.taxReference ? headerInfo.taxReference.taxRate : "",
      vatID: headerInfo.taxReference ? headerInfo.taxReference.taxID : "",
      vatRef: headerInfo.taxReference
        ? headerInfo.taxReference.taxReference
        : "",
      totalNet: invoiceTotal(),
      totalVAT: invoiceTotalVat(),
      totalAmount:invoiceTotalAmount(),
      accountType: headerInfo.accountType ? headerInfo.accountType : "",
      currentStatus: createJobFinalData.currentStatus,
      jobItems: jobItemsData,
      invoiceAddressDifferent: createJobFinalData.invoiceAddressDifferent,
      invoice: {
        contact: billingInfo.contact ? billingInfo.contact : "",
        name: billingInfo.propertyReference
          ? billingInfo.propertyReference
          : "",
        address: billingInfo.address1 ? billingInfo.address1 : "",
        town: billingInfo.town ? billingInfo.town : "",
        county: billingInfo.county ? billingInfo.county : "",
        postcode: billingInfo.postcode ? billingInfo.postcode : "",
        mobile: billingInfo.mobile ? billingInfo.mobile : "",
        telephone: billingInfo.telephone ? billingInfo.telephone : "",
        email: billingInfo.email ? billingInfo.email : ""
      },
      paymentType: paymentsData.paymentType
        ? paymentsData.paymentType == "PRE"
          ? "CARD"
          : paymentsData.paymentType
        : "",
      preAuthPaymentTransID: paymentsData.preAuthPaymentTransID ? paymentsData.preAuthPaymentTransID : "",
    };
    console.log(body2, "createJobConfiguration222");

    const url = `update/job/` + createJobFinalData.jobID;
    axios
      .put(url, body2)
      .then(res => {
        let response = res.data;

        setCreateJobProgress(false);
        setCreateJobOpen(false);
        if (response.success === true) {
          history.push("/dashboard/orderList");
          dispatch(resetAll());
        } else {
          showSnackBar("Unable to update job");
        }
      })
      .catch(function(error) {
        if (error.response.status === 403) {
          // logoutUser();
        }
      });
  };

  const resetJob = () => {
    showSnackBar("Job Created");

    setTimeout(function() {
      setActiveStep(0);
      dispatch(resetAll());
      dispatch(resetConfiguration());
    }, 1000);
  };

  const handleRefresh = () => {
    setRefreshOpen(true);
  };
  const handleCreateJobModal = () => {
    paymentRef.current && paymentRef.current.saveData();
    setCreateJobOpen(true);
  };
  const refreshJob = () => {
    setRefreshOpen(false);
    setActiveStep(0);
    dispatch(resetAll());
    dispatch(resetConfiguration());
    history.push("/dashboard/orderList");
  };
  return (
    <>
      {createJobFinalData.updatingJob && (
        <>
          <Grid container direction="row" alignItems="center">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.borderRight}
            >
              <Card className={classes.stepperGrid}>
                <div>
                  <Grid container direction="row">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Stepper
                        alternativeLabel
                        activeStep={activeStep}
                        connector={<ColorlibConnector />}
                      >
                        {steps.map((label, index) => (
                          <Step
                            key={label}
                            className={classes.stepperIcon}
                            //   onClick={this.handleStep(index)}
                          >
                            <StepLabel
                              // onClick={() => stepIconClick(index)}
                              StepIconComponent={ColorlibStepIcon}
                              classes={{
                                label: classes.marginTopNone
                              }}
                            >
                              {label}
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </Grid>
          </Grid>

          <SwipeableViews index={activeStep} onChangeIndex={handleSwipeChange}>
            <Grid
              container
              direction="row"
              alignItems="center"
              value={activeStep}
              index={0}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.marginGrid}
              >
                {activeStep === 0 && (
                  <AddPropertyForm ref={addPropertyRef} dataSaved={dataSaved} />
                )}
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              value={swipeValue}
              index={1}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.marginGrid}
              >
                <BillingInfo ref={billingInfoRef} dataSaved={dataSaved} />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              alignItems="center"
              value={swipeValue}
              index={1}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.marginGrid}
              >
                {activeStep === 2 && (
                  <SpecifyClient ref={specifyClientRef} dataSaved={dataSaved} />
                )}
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              value={swipeValue}
              index={2}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.marginGrid}
              >
                {activeStep === 3 && (
                  <HeaderInfo ref={headerInfoRef} dataSaved={dataSaved} />
                )}
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              value={swipeValue}
              index={3}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.marginGrid}
              >
                {activeStep === 4 && <AddItems />}
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              alignItems="center"
              value={swipeValue}
              index={4}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.marginGrid}
              >
                {activeStep === 5 && (
                  <Payments
                    ref={paymentRef}
                    dataSaved={dataSaved}
                    refreshJob={refreshJob}
                    resetJob={resetJob}
                    showSnackBar={showSnackBar}
                  />
                )}
              </Grid>
            </Grid>
          </SwipeableViews>

          {activeStep == steps.length - 1 && !createJobFinalData.jobCompleted && (
            <Button
              variant="contained"
              className={classes.startAutoJob}
              disabled={createJobProgress}
              onClick={handleCreateJobModal}
            >
              {createJobProgress && (
                <CircularProgress
                  size={20}
                  className={classes.circularProgress}
                />
              )}
              Update Job
            </Button>
          )}

          <Button
            variant="contained"
            className={classes.refreshButton}
            onClick={handleRefresh}
            //   startIcon={<RefreshIcon />}
          >
            Cancel
          </Button>

          {activeStep !== steps.length - 1 && (
            <Button
              variant="contained"
              className={classes.startAutoJob}
              onClick={handleSave}
            >
              Continue
            </Button>
          )}
          {!createJobFinalData.jobCompleted && (
            <Button
              variant="outlined"
              className={classes.backButton}
              onClick={handleBack}
            >
              Back
            </Button>
          )}

          <ConfirmedSnackBar
            open={openSnackbar}
            message={snackBarMessage}
            handleClose={handleSnackBarClose}
            classes={classes}
          />
        </>
      )}

      {!createJobFinalData.updatingJob && (
        <h1 className={classes.center}>Nothing to update</h1>
      )}

      <Dialog
        open={refreshOpen}
        onClose={() => setRefreshOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Cancel Updating Job?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to cancel updating of Job.{" "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className={classes.cancelJob}
            onClick={() => setRefreshOpen(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            className={classes.refreshDialogButton}
            onClick={() => refreshJob()}
            // disabled={removeProgress}
          >
            {"Yes"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={createJobOpen}
        onClose={() => setCreateJobOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Update Job?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            I am happy to proceed with updating job{" "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className={classes.cancelJob}
            disabled={createJobProgress}
            onClick={() => setCreateJobOpen(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            className={classes.refreshDialogButton}
            disabled={createJobProgress}
            onClick={() => handleCreateJob()}
          >
            {createJobProgress && (
              <CircularProgress
                size={20}
                className={classes.circularProgress}
              />
            )}
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const useColorlibStepIconStyles = makeStyles({
  root: {
    //   backgroundColor: "#ffe4ad",
    zIndex: 1,
    color: "#f2c5b8",
    width: 75,
    height: 75,
    display: "flex",
    //   borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  active: {
    color: "#f15a2f"
    //   backgroundImage: "linear-gradient(to right, #f5bb45, #f2c94c);",
    //   boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
  },
  completed: {
    color: "#f15a2f"
  }
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <AddToQueueIcon fontSize="large" />,
    2: <BusinessIcon fontSize="large" />,

    3: <LocalAtmIcon fontSize="large" />,
    4: <VideoLabelIcon fontSize="large" />,
    5: <AddToQueueIcon fontSize="large" />,
    6: <VideoLabelIcon fontSize="large" />,
    7: <ReceiptIcon fontSize="large" />,
    8: <VideoLabelIcon fontSize="large" />
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 37
  },
  active: {
    "& $line": {
      backgroundColor: "#f15a2f"
      //   backgroundImage: "linear-gradient(to right, #f5bb45, #f2c94c);"
    }
  },
  completed: {
    "& $line": {
      backgroundColor: "#f15a2f"
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1
  }
})(StepConnector);
export default UpdateJob;
