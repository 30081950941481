import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { CardMedia } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import FormControl from "@material-ui/core/FormControl";
import CardContent from "@material-ui/core/CardContent";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import SearchIcon from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography";
import moment from "moment-timezone";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import InputLabel from "@material-ui/core/InputLabel";
import LaunchIcon from "@material-ui/icons/Launch";
import LastPageIcon from "@material-ui/icons/LastPage";
import InputBase from "@material-ui/core/InputBase";
import { alpha } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { axios } from "../../utils/axiosConfig";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ConfirmedSnackBar from "../../utils/ConfirmedSnackbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import JobDetail from "./JobDetail";
import TablePagination from "@material-ui/core/TablePagination";
import DateFnsUtils from "@date-io/date-fns";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 450
  },
  listSection: {
    backgroundColor: "inherit"
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0
  },
  textField: {
    // width: "100%",
    marginLeft: "5px",
    marginRight: "5px"
  },
  selectFeild: {
    width: "15%",
    marginTop: "15px",
    marginLeft: "5px",
    marginRight: "5px"
  },

  tableHeadFull: {
    width: "100%"
  },
  datePicker: {
    selectColor: "black"
  },
  priceTextFeild: {
    margin: "26px"
  },
  jobCardPrice: {
    color: "#fff",
    fontSize: "52px",
    marginTop: "7px",
    marginBottom: "7px"
  },
  formControl: {
    width: "65%"
  },
  center: {
    margin: "auto",
    width: "fit-content"
  },
  search: {
    position: "relative",
    borderRadius: "4px",
    backgroundColor: alpha("#fff", 0.15),
    "&:hover": {
      backgroundColor: alpha("#fff", 0.25)
    },
    // marginLeft: 0,
    width: "250px",
    marginLeft: "auto"
  },
  searchIcon: {
    padding: "16px",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: "8px, 8px, 8px, 8px",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + 32px)`,
    width: "100%"
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  card: {
    width: "100%",
    backgroud: "#000; !important"
  },
  jobCardTitle: {
    marginTop: "10px",
    color: "#fff"
  },
  titleDiv: {
    display: "flex"
  },
  switchGrid: {
    margin: "auto",
    display: "flex"
  },
  switchClass: {
    margin: "3px 10px 5px 10px"
  },
  timeContainer: {
    margin: "43px 0px 0px 0px"
  },
  rootCard: {
    background: "#000",
    width: "100%",
    marginRight: "80px"
  },
  showCircularProgressStyle: {
    margin: "auto",
    width: "fit-content",
    padding: "50%"
  },

  tableHeading: {
    padding: "15px",
    fontSize: "20px",
    color: "#fff",
    display: "flex",
    background: "#000;"
  },
  tableBold: {
    fontWeight: 600,
    fontSize: "17px",
    color: "#000;"
  },

  gridHere: {
    // borderRight:"1px solid #000;"
  },

  editIcon: {
    color: "#fff",
    fontSize: "16px",
    cursor: "pointer"
    // marginLeft: "20px",
    // marginBottom: "15px"
  },

  expandIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer"
    // marginLeft: "20px",
    // marginBottom: "15px"
  },
  launchIcon: {
    color: "#000;",
    cursor: "pointer"
  },
  oilIcons: {
    fontSize: "40px",
    color: "#fff",
    margin: "4px 4px"
  },
  priceIcons: {
    fontSize: "50px",
    color: "#fff",
    margin: "4px 4px"
  },
  editIconChip: {
    fontSize: "16px",
    cursor: "pointer"
  },
  tableCellClass: {
    display: "inline-flex"
  },
  inputFeildHovered: {
    color: "#fff",
    fontSize: "16px",
    width: "380px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  inputFeild: {
    color: "#000;",
    fontSize: "16px",
    width: "380px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  inputFeildHaulage: {
    color: "#000;",
    fontSize: "20px",
    background: "none",
    width: "40px",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  inputFeildHaulageHovered: {
    color: "#fff",
    fontSize: "20px",
    background: "none",
    width: "40px",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },

  startAutoJob: {
    color: "#fff",
    background: "#000;",
    width: "auto",
    height: "53px",
    marginTop: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#000;",
      color: "#fff"
    }
  },
  startCron: {
    marginLeft: "auto",
    marginTop: "auto",
    marginBottom: "auto"
  },
  tablePagination: {
    marginLeft: "auto"
  },
  enabledChip: {
    backgroudColor: "green"
  },
  disabledChip: {
    backgroud: "red"
  },
  "& .MuiTablePagination-toolbar": {
    marginLeft: "auto"
  },
  tableFooter: {
    width: "fit-content",
    marginTop: "20px",
    marginLeft: "auto"
  },
  circularProgress: {
    marginLeft: "10px"
  },
  circularProgressCenter: {
    margin: "auto"
  },
  autoCircularProgress: {
    margin: "auto"
  },
  circularStyle: {
    width: "100%",
    margin: "auto",
    marginTop: "15%",
    marginBottom: "15%"
  },
  circularProgressBase: {
    width: "100%"
  },
  snackbar: {
    backgroundColor: "rgb(50, 50, 50)"
  },
  fullWidth: {
    width: "100%"
  },
  close: {
    color: "#fff"
  },
  menuButtonsIconPrimary: {
    marginLeft: "auto",
    color: "#fff",
    cursor: "pointer"
    // height: "50px"
  },
  dialogContent: {
    margin: "10px"
  },
  newCronTimePicker: {
    marginTop: "15px"
  },
  headerStyle: {
    display: "flex",
    width: "100%"
  },
  borderBottom: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)"
  },
  grid: {
    border: "1px solid rgba(0, 0, 0, 0.1)"
  },
  image: {
    margin: "5px"
  },
  texts: {
    marginLeft: "15px"
  },
  "& .MuiTypography-body1": {
    fontWeight: 600,
    fontSize: "1.1rem"
  }
}));

export default function JobPictures(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [pictures, setPictures] = React.useState([]);
  const [selectedImage, setImage] = React.useState("");
  useEffect(() => {
    setPictures(props.jobPictures);
  }, [props.jobPictures]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Grid
        className={classes.grid}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <div className={classes.tableHeading}>
          <Typography>Images</Typography>
        </div>
        <List className={classes.root} subheader={<li />}>
          {pictures &&
            pictures.map(item => (
              <ListItem key={`item-`} className={classes.borderBottom}>
                <img
                  src={item.imageURL}
                  height="100px"
                  width="100px"
                  alt=""
                  className={classes.image}
                ></img>
                <ListItemText  
                  className={classes.texts}
                  primary={
                    <Typography variant="p" style={{ fontWeight: 600 }}>
                      {item.imageType}
                    </Typography>
                  }
                  secondary={
                    `on ` +
                    moment(item.takenAt)
                      .tz("Europe/London")
                      .format("YYYY-MM-DD HH:mm")
                  }
                />

                <Button
                  href="#text-buttons"
                  color="primary"
                  variant="outlined"
                  onClick={e => {
                    setOpen(true);
                    setImage(item.imageURL);
                  }}
                >
                  Preview
                </Button>
              </ListItem>
            ))}
        </List>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Job Image"}
        </DialogTitle>
        <DialogContent>
          <img
            src={selectedImage}
            height="100%"
            width="100%"
            alt=""
            className={classes.image}
          ></img>
        </DialogContent>
      </Dialog>
    </>
  );
}
