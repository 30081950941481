import React, { useState, useEffect ,forwardRef, useRef, useImperativeHandle} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Accordion from "@material-ui/core/Accordion";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import moment from "moment-timezone";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import Input from "@material-ui/core/Input";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { axios } from "../../utils/axiosConfig";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ReactApexChart from "react-apexcharts";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import SwipeableViews from "react-swipeable-views";
import PostCodeFind from "./PostCodeFind";
import NewRadio from "./NewRadio";
import PreviousRadio from "./PreviousRadio";
import { useFormik } from "formik";

import { useSelector, useDispatch } from "react-redux";
import { createJobConfigurationData } from "../../Features/createJobConfigurationSlice";
import {
  setClientSpecifications,
  setInvoiceAddressDifferent,
  createJobData
} from "../../Features/createJobDataSlice";

const useStyles = makeStyles({
  root: {
    // background: "#000"
    // padding: "8px"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  titleDiv: {
    display: "flex"
  },
  status: {
    fontSize: 14,
    color: "#fff",
    marginTop: "2px",
    fontStyle: "italic"
  },
  statusTime: {
    marginTop: 15
  },
  pos: {
    marginBottom: 12
  },
  linearProgress: {
    marginTop: "15px"
  },
  chip: {
    color: "primary",
    height: "23px"
  },
  jobCardTitle: {
    color: "#000",
    fontWeight: 600,
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    margin: "none"
  },
  cardSubTitle: {
    fontWeight: 400,
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    // margin: '4px 0px 0px',
    color: "rgb(99, 115, 129)",
    display: "block"
  },
  jobCardPrice: {
    color: "#000",
    fontSize: "1rem",
    marginBottom: "7px",
    fontWeight: "600",
    // letterSpacing: "0.1rem",
    lineHeight: 1.5
  },
  icon: {
    borderRadius: "50%",
    width: 22,
    height: 22,
    marginRight: "2px",
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5"
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)"
    }
  },
  oilgasIcon: {
    margin: "auto",
    marginRight: "5px",
    color: "#e8e8e8",
    width: "65px",
    height: "40px"
  },
  editIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px"
  },
  circularProgress: {
    width: "20px !important",
    height: "20px !important"
  },
  closeIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px",
    marginTop: "20px"
  },
  center: {
    height: `calc(100vh - 350px)`,
    marginTop: "15px",
    // background: "#000",
    // padding: 16,
    // display: "flex",
    backgroundColor: "rgb(255, 255, 255)",
    color: "#000",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "8px",
    position: "relative",
    zIndex: 0,
    // display: "flex",
    alignItems: "center",
    padding: "24px"
  },
  inputFeild: {
    color: "#fff",
    fontSize: "32px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  inputGrid: {
    paddingRight: "10px"
  },
  specifyGrid: {
    paddingTop: "10px",
    paddingRight: "10px"
  },
  jobCardButton: {
    color: "#fff",
    // borderRadius: '60%',
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#f15a2f"
    }
  },

  jobCardButton2: {
    // color:"#f15a2f",
    // borderRadius: '60%',
    fontSize: "12px",
    fontWeight: 600
    // "&:hover": {
    //   backgroundColor: '#ffff'
    // },
    // backgroundColor:"#ffff"
  },
  cardInfo: {
    marginTop: "15px"
  },
  tablePagination: {
    // position: 'sticky'
  },
  borderLeft: {
    borderLeft: "1px solid gray"
  },
  borderRight: {
    // paddingTop: "25px",
    // paddingBottom: "15px",
    // paddingLeft: "15px"
  },
  stackRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "16px",
    marginBottom: "8px"
  },
  stackIcon: {
    width: "24px",
    height: "24px",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(84, 214, 44)",
    backgroundColor: "rgba(84, 214, 44, 0.16)"
  },
  stackIconDetail: {
    padding: 3
  },
  stackSubtitle: {
    color: "#000",
    fontWeight: "600",
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    margin: "0px 0px 0px 8px"
  },
  chartRoot: {
    margin: "0px 0px 0px auto"
  },
  toggleButtonUnselected: {
    height: "45px",
    width: "auto",
    borderRadius: "3px",
    color: "#fff",
    fontSize: "14px",
    margin: "2px",
    borderColor: "#f15a2f",
    background: "#f15a2f",

    "&:hover": {
      background: "#f15a2f"
    }
  },
  toggleButtonSelected: {
    height: "45px",
    width: "auto",
    borderRadius: "3px",
    color: "#f15a2f",
    fontSize: "14px",
    margin: "2px",
    borderColor: "#f15a2f",
    background: "#fff",

    "&:hover": {
      background: "#fff"
    }
  },

  borderRightNone: {
    borderRight: "none"
  },
  borderLeftNone: {
    borderLeft: "none"
  },
  paymentOptionDiv: {
    marginTop: "15px",
    display: "flex",
    alignItems: "center",
    padding: "20px",
    // justifyContent: "space-between",
    borderRadius: "8px",
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    border: "1px solid rgba(145, 158, 171, 0.32)"
    // flexWrap: "wrap"
  },
  selectedPaymentOptionDiv: {
    marginTop: "15px",
    display: "flex",
    alignItems: "center",
    padding: "20px",
    // justifyContent: "space-between",
    borderRadius: "8px",
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    border: "1px solid rgba(241, 89, 47, 0.72)",
    // flexWrap: "wrap",
    // border: 1px solid rgba(145, 158, 171, 0.32);
    boxShadow: "rgb(145 158 171 / 24%) 0px 8px 16px 0px",
    width: "100%"
  },
  paymentRadio: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    "-webkit-tap-highlight-color": "transparent",
    backgroundColor: "transparent",
    outline: "0px",
    border: "0px",
    margin: "0px",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    appearance: "none",
    textDecoration: "none",
    borderRadius: "50%",
    color: "rgb(99, 115, 129)",
    padding: "8px",
    width: "100%"
  },
  formControlLabelPayment: {
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    fontWeight: 400
  },
  paymentHeadingRadio: {
    margin: "0px",
    marginLeft: "10px",
    fontWeight: "600",
    lineHeight: "1.57143",
    fontSize: "0.875rem"
  },
  paymentHeadingSubRadio: {
    margin: "0px",
    marginLeft: "10px",
    fontWeight: "400",
    lineHeight: "1.57143",

    fontSize: "0.875rem",
    color: "rgb(99, 115, 129)"
  },
  paymentContent: {
    padding: "24px"
  },
  checkRadioIcon: {
    color: "#f15a2f"
  },
  accordianMain: {
    boxShadow: "none",
    width: "100%"
  },
  accordianSummary: {
    padding: "0px",
    minHeight: "fit-content",
    width: "100%"
  },
  fullWidth: {
    width: "100%"
  },
  displayFlex: {
    display: "flex"
  }
});
const BillingInfo = forwardRef((props, ref) => {
  const classes = useStyles();
  const [value, setValue] = React.useState("previous");
  const dispatch = useDispatch();
  const createJobFormData = useSelector(createJobData);

  useEffect(() => {
    if(createJobFormData.invoiceAddressDifferent){
        setValue("new")
    }
  }, [createJobFormData]);
  const handleChange = value => {
    setValue(value);
    let valueToDispatch = value === "previous" ? false : true;
    dispatch(setInvoiceAddressDifferent(valueToDispatch));
  };

  return (
    <Grid container direction="row">
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.borderRight}
      >
        <Card className={classes.center}>
          <div>
            
            <div className={classes.displayFlex}>
            <Typography
              variant="h6"
              component="h6"
              className={classes.jobCardPrice}
            >
              Billing Information
            </Typography>
            {createJobFormData.iwsAccount !== null && (
              <>
                {createJobFormData.iwsAccount ? (
                  <Chip
                    size="small"
                    style={{
                      backgroundColor: "#e5f9ed",
                      color: "#1bc943",
                      fontWeight: 600,
                      padding: "5px",
                      marginLeft: "auto"
                    }}
                    label={"IWS Client"}
                  />
                ) : (
                  <Chip
                    size="small"
                    style={{
                      marginLeft: "auto",
                      backgroundColor: "#fff5f6",
                      color: "#f83245",
                      fontWeight: 600,
                      padding: "5px"
                    }}
                    label={"Non IWS Client"}
                  />
                )}
              </>
            )}
          </div>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.specifyGrid}
            >
              <FormControl component="fieldset" className={classes.fullWidth}>
                <RadioGroup
                  aria-label="gender"
                  value={value}
                  name="customized-radios"
                >
                  <div
                    className={
                      value === "previous"
                        ? classes.selectedPaymentOptionDiv
                        : classes.paymentOptionDiv
                    }
                    onClick={() => handleChange("previous")}
                  >
                    <PreviousRadio
                      checked={value === "previous" ? true : false}
                      expanded={value === "previous" ? true : false}
                      isPrevious={value === "previous" ? true : false}
                      component="old"
                      dataSaved={props.dataSaved}
                      ref={ref}
                    />
                  </div>
                  <div
                    className={
                      value === "new"
                        ? classes.selectedPaymentOptionDiv
                        : classes.paymentOptionDiv
                    }
                    onClick={() => handleChange("new")}
                  >
                    <NewRadio
                      checked={value === "new" ? true : false}
                      expanded={value === "new" ? true : false}
                      isPrevious={value === "previous" ? true : false}
                        component="new"
                        dataSaved={props.dataSaved}
                      ref={ref}
                    />
                  </div>
                </RadioGroup>
              </FormControl>
            </Grid>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
});

export default BillingInfo;
